import { axiosInstance } from "./base";

// 파워볼 정산 업체 옵션
export interface IpbIdbOption {
    pk: number;
    distributor: {
        pk: number;
        name: string;
    };
    siteInfo: number;
    company_name: string;
    start_date: string;
    end_date: string;
    login_rate: number;
    selected_login_list: string;
    share_rate: number;
    selected_share_list: string;
    created_at: string;
    updated_at: string;
}

export interface IpostPBIdbOptionParams {
    'siteInfo_pk': number,
    'company_name': string,
    'start_date': string,
    'end_date': string,
}

export const getPBIdbOption = (params: IpostPBIdbOptionParams) => (
    axiosInstance.get(
        `pbidvoption/`,
        {
            params: params,
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data as IpbIdbOption)
)

export const getPBIdbOptionDetail = ({ pk }: { pk: number; }) => (
    axiosInstance.get(
        `pbidvoption/${pk}/`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data as IpbIdbOption)
)

interface IputPBIdbOptionParams {
    'IdvOpthon_pk': number,
    'siteInfo_pk': number,
    'company_name': string,
    'start_date': string,
    'end_date': string,
    'login_rate': number,
    'selected_login_list': string,
    'share_rate': number,
    'selected_share_list': string,
}

export const putPBIdbOption = (params: IputPBIdbOptionParams) => (
    axiosInstance.put(
        `pbidvoption/${params.IdvOpthon_pk}/`,
        {
            siteInfo_pk: params.siteInfo_pk,
            company_name: params.company_name,
            start_date: params.start_date,
            end_date: params.end_date,
            login_rate: params.login_rate,
            selected_login_list: params.selected_login_list,
            share_rate: params.share_rate,
            selected_share_list: params.selected_share_list,
        },
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)