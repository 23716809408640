import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { getMonthRangeFromString, IApiData, transformData } from '../../../../lib/util';
import { getSiteData } from '../../../../api/sitedata';
import { SelectedMenuAtom } from '../../../../atoms/topSelect';

export function useHoldemData() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);
    const [holdemData, setHoldemData] = useState<IApiData>({ headers: [], rows: [] });

    const MonthSiteDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                setHoldemData({ headers: [], rows: [] });
                return;
            }
            const data_list = data.map((sitedata: any) => JSON.parse(sitedata.data));
            const transformedData = transformData(data_list);
            setHoldemData(transformedData);
        },
        onError: (error) => {
            console.error("사이트 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });

    useEffect(() => {
        const monthRange = getMonthRangeFromString(SelectedMenu.Month);
        if (SelectedMenu.site.pk !== -1 && SelectedMenu.category !== "" && SelectedMenu.Month !== "") {
            MonthSiteDataMutation.mutate({
                siteinfo: SelectedMenu.site.pk,
                datatype: "holdem",
                start_date: monthRange[0],
                end_date: monthRange[1],
            });
        }
    }, [SelectedMenu]);

    return holdemData;
}


const data = {
    headers: [
        'Date', '홀덤', '합계'
    ],
    rows: [
        { date: '3/1', holdem: '-463,298', total: '-463,298' },
        { date: '3/2', holdem: '37,136,508', total: '37,136,508' },
        { date: '3/3', holdem: '17,788,942', total: '17,788,942' },
        { date: '3/4', holdem: '-14,660,163', total: '-14,660,163' },
        { date: '3/5', holdem: '8,625,833', total: '8,625,833' },
        { date: '3/6', holdem: '12,693,607', total: '12,693,607' },
        { date: '3/7', holdem: '2,937,031', total: '2,937,031' },
        { date: '3/8', holdem: '-341,448', total: '-341,448' },
        { date: '3/9', holdem: '1,028,376', total: '1,028,376' },
        { date: '3/10', holdem: '1,002,310', total: '1,002,310' },
        { date: '3/11', holdem: '1,910,516', total: '1,910,516' },
        { date: '3/12', holdem: '2,350,340', total: '2,350,340' },
        { date: '3/13', holdem: '2,350,340', total: '2,350,340' },
        { date: '3/14', holdem: '16,140,397', total: '16,140,397' },
        { date: '3/15', holdem: '-1,499,853', total: '-1,499,853' },
        { date: '3/16', holdem: '3,063,870', total: '3,063,870' },
        { date: '3/17', holdem: '18,905,102', total: '18,905,102' },
        { date: '3/18', holdem: '5,587,755', total: '5,587,755' },
        { date: '3/19', holdem: '-1,048,714', total: '-1,048,714' },
        { date: '3/20', holdem: '2,493,944', total: '2,493,944' },
        { date: '3/21', holdem: '2,494,987', total: '2,494,987' },
        { date: '3/22', holdem: '1,719,873', total: '1,719,873' },
        { date: '3/23', holdem: '483,897', total: '483,897' },
        { date: '3/24', holdem: '-265,441', total: '-265,441' },
        { date: '3/25', holdem: '565,594', total: '565,594' },
        { date: '3/26', holdem: '-1,168,042', total: '-1,168,042' },
        { date: 'TOTAL', holdem: '125,940,687', total: '125,940,687' }
    ]
};

