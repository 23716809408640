import { atom } from "recoil";
import { IpbComInfo } from "../api/pbcominfo";

// 일 선택
// 현재 날짜를 구하는 함수
const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

// 어제 날짜를 구하는 함수
const getYesterDayDate = () => {
    const today = new Date();
    today.setDate(today.getDate() - 1); // 날짜를 하루 전으로 설정
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
};

// 월 선택 
// 현재 월을 구하는 함수
const getCurrentMonth = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
    return `${year}년 ${month}월`;
};

// 년 선택
// 현재 년도를 구하는 함수
const getCurrentYear = () => {
    const today = new Date();
    return today.getFullYear().toString();
};


export interface ISelectMenu {
    pk: number,
    name: string,
}
export interface ISelectedMenu {
    pageSelect: string,

    sitelist: ISelectMenu[],    // 사이트 리스트
    site: ISelectMenu                  // 사이트 선택

    category: string            // 종목 선택

    CSComList: ISelectMenu[],   // 카지노 업체 리스트
    CSCom: ISelectMenu                   // 카지노 업체 선택

    PBComList: ISelectMenu[],   // PB 업체 리스트
    PBCom: ISelectMenu                   // PB 업체 선택

    Year: string                // 년 선택
    Month: string               // 월 선택 
    Date: string                // 일 선택
    start_date: string          // 시작일 선택
    end_date: string            // 종료일 선택

    BankSetting: boolean        // 뱅크 설정창 on off 선택

    reload_tag: number          // 리로드 태그
    CSreload_tag: number        // 카지노 업체 리로드 태그
}

export let SelectedMenuDefault: ISelectedMenu = {
    pageSelect: "",

    sitelist: [],
    site: { pk: -1, name: "사이트 선택" },

    category: "파워볼",

    CSComList: [],
    CSCom: { pk: -1, name: "업체 선택" },

    PBComList: [],
    PBCom: { pk: -1, name: "업체 선택" },

    Year: getCurrentYear(),
    Month: getCurrentMonth(),
    Date: getYesterDayDate(),
    start_date: getCurrentDate(),
    end_date: getCurrentDate(),

    BankSetting: false,

    reload_tag: new Date().getTime(),
    CSreload_tag: new Date().getTime(),
}

// 상단 설정 전부
export const SelectedMenuAtom = atom<ISelectedMenu>({
    key: "SelectedMenu",
    default: SelectedMenuDefault,
})





