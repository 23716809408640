import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useRecoilValue } from 'recoil';
import { getMonthRangeFromString, IApiData, transformData } from '../../../../lib/util';
import { getSiteData } from '../../../../api/sitedata';
import { SelectedMenuAtom } from '../../../../atoms/topSelect';

export function useCasinoSlotData() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);
    const [casinoslotData, setCasinoslotData] = useState<IApiData>({ headers: [], rows: [] });

    const MonthSiteDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                setCasinoslotData({ headers: [], rows: [] });
                return;
            }

            const data_list = data.map((sitedata: any) => JSON.parse(sitedata.data));
            const transformedData = transformData(data_list);
            setCasinoslotData(transformedData);
        },
        onError: (error) => {
            console.error("사이트 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });

    useEffect(() => {
        const monthRange = getMonthRangeFromString(SelectedMenu.Month);
        if (SelectedMenu.site.pk !== -1 && SelectedMenu.category !== "" && SelectedMenu.Month !== "") {
            MonthSiteDataMutation.mutate({
                siteinfo: SelectedMenu.site.pk,
                datatype: "casinoslot",
                start_date: monthRange[0],
                end_date: monthRange[1],
            });
        }
    }, [SelectedMenu]);

    return casinoslotData;
}


const data = {
    headers: [
        'Date', '카지노', '슬롯', '합계'
    ],
    rows: [
        { date: '3/1', casino: '-463,298', slot: '448,078', total: '-15,220' },
        { date: '3/2', casino: '37,136,508', slot: '267,766', total: '37,404,274' },
        { date: '3/3', casino: '17,788,042', slot: '-1,676,511', total: '16,111,531' },
        { date: '3/4', casino: '-14,660,163', slot: '2,146,752', total: '-12,513,411' },
        { date: '3/5', casino: '-9,662,136', slot: '1,147,750', total: '-8,514,386' },
        { date: '3/6', casino: '1,299,070', slot: '7,005,614', total: '8,304,684' },
        { date: '3/7', casino: '2,937,031', slot: '-1,152,908', total: '1,784,123' },
        { date: '3/8', casino: '8,994,874', slot: '114,692', total: '9,109,566' },
        { date: '3/9', casino: '-590,307', slot: '324,587', total: '-265,720' },
        { date: '3/10', casino: '-936,813', slot: '2,165,769', total: '1,228,956' },
        { date: '3/11', casino: '1,002,310', slot: '-774,004', total: '228,306' },
        { date: '3/12', casino: '1,910,516', slot: '-705,270', total: '1,205,246' },
        { date: '3/13', casino: '1,200,038', slot: '1,493,393', total: '2,693,431' },
        { date: '3/14', casino: '-2,063,474', slot: '1,680,801', total: '-382,673' },
        { date: '3/15', casino: '-946,145', slot: '1,939,588', total: '993,443' },
        { date: '3/16', casino: '19,304,407', slot: '-3,135,000', total: '16,169,407' },
        { date: '3/17', casino: '-1,499,853', slot: '1,450,730', total: '-49,123' },
        { date: '3/18', casino: '3,063,870', slot: '1,781,550', total: '4,845,420' },
        { date: '3/19', casino: '10,587,715', slot: '-9,708,340', total: '879,375' },
        { date: '3/20', casino: '3,988,775', slot: '2,647,539', total: '6,636,314' },
        { date: '3/21', casino: '9,307,095', slot: '-8,457,004', total: '850,091' },
        { date: '3/22', casino: '2,093,944', slot: '-616,792', total: '1,477,152' },
        { date: '3/23', casino: '4,247,684', slot: '-1,594,823', total: '2,652,861' },
        { date: '3/24', casino: '-3,894,735', slot: '2,037,182', total: '-1,857,743' },
        { date: '3/25', casino: '-2,130,374', slot: '-292,152', total: '-2,422,526' },
        { date: '3/26', casino: '-66,982', slot: '1,100,572', total: '1,033,590' },
        { date: '3/27', casino: '20,244,781', slot: '520,152', total: '20,764,933' },
        { date: '3/28', casino: '-20,683,652', slot: '-301,668', total: '-20,985,320' },
        { date: '3/29', casino: '2,927,146', slot: '1,520,504', total: '4,447,650' },
        { date: '3/30', casino: '-1,499,853', slot: '1,450,730', total: '-49,123' },
        { date: '3/31', casino: '-1,168,042', slot: '-4,997,123', total: '-6,165,165' },
        { date: 'TOTAL', casino: '125,940,687', slot: '8,917,048', total: '134,857,735' }
    ]
};
