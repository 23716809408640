import { useRecoilValue } from "recoil";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";
import { useEffect, useState } from "react";
import { useGetTop5DataMutation } from "../../../userMainContesnts/SiteDailySettlement/compnents/costiomMutatons";
import { getMonthFirstAndLastDay } from "../../../../lib/dashboardAndDalilySettlementUtil";
import { Center, Flex, useColorModeValue } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import MBaseTop5Table from "../../CommContents/Table/MBaseTop5Table";

export default function MDailyTop5Table() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    const [top5Data, setTop5Data] = useState<any>(null);
    const [top5RverseData, setTop5RverseData] = useState<any>(null);
    const [topCPSTopData, setTopCPSTopData] = useState<any>(null);

    const top5DataMutation = useGetTop5DataMutation(setTop5Data, setTop5RverseData, setTopCPSTopData);

    useEffect(() => {
        const dateRange = getMonthFirstAndLastDay(SelectedMenu.Date);
        const top5Parms = {
            siteinfo: SelectedMenu.site.pk,
            datatype: "atom",
            start_date: dateRange[1],
            end_date: dateRange[1],
        }
        top5DataMutation.mutateAsync(top5Parms);
    }, [SelectedMenu]);

    const mainBgColor = useColorModeValue(lightTheme_colors.mainBgColor, darkTheme_colors.mainBgColor);
    const mainFontColor = useColorModeValue(lightTheme_colors.mainFontColor, darkTheme_colors.mainFontColor);
    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);
    return (
        <Flex direction="column" padding="0.1rem" bg={mainBgColor} color={mainFontColor} borderRadius="md" h={"100%"} w={"100%"}>
            <Flex direction="column" bg={bgColor} borderRadius="md" overflow="hidden">
                <Center>충환 수익 TOP 5</Center>
                <MBaseTop5Table tableData={top5Data} />
            </Flex>
            <Flex direction="column" mt="2" bg={bgColor} borderRadius="md" overflow="hidden">
                <Center>충환 손실 TOP 5</Center>
                <MBaseTop5Table tableData={top5RverseData} />
            </Flex>

            <Flex direction="column" mt="2" bg={bgColor} borderRadius="md" overflow="hidden">
                <Center>배팅금액 TOP 10</Center>
                <MBaseTop5Table tableData={topCPSTopData} />
            </Flex>
        </Flex>
    );
}