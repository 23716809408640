import { useRecoilState } from "recoil";
import { SelectedMenuAtom } from "../../../atoms/topSelect";
import { useMutation } from "@tanstack/react-query";
import { getSiteData } from "../../../api/sitedata";
import { getCombinedData } from "../../../lib/conversionData";
import { Center, Flex, Grid, GridItem, useColorModeValue, useToast } from "@chakra-ui/react";
import SiteComMonComOptTopSelect from "../CommContents/TopSelect/SiteComMonComOptTopSelect";
import { useEffect, useRef, useState } from "react";
import { get10RangeFromString, get15RangeFromString, getMonthRangeFromString } from "../../../lib/util";
import { getCSIdbOption, putCSIdbOption } from "../../../api/csidvoption";
import UserTreeTable, { IUserTreeDataProps } from "../CSLosiongShreComm/Contents/UserTreeTable";
import UserTreeTableNoData from "../CSLosiongShreComm/Contents/UserTreeTableNoData";
import UserSharTable, { IUserSharDataProps } from "../CSLosiongShreComm/Contents/UserSharTable";
import { darkTheme_colors, lightTheme_colors } from "../../../theme";
import { GetUserSharDataProps, getUserTreeDatas } from "../CSLosiongShreComm/util/util";



export default function CSLosingShreMontly() {
    /**
    - 한달, 보름, 10일 데이터를 먼저 만든다.
    - 그 데이터의 유저를 보고 각 유저별 idv 옵션을 불러와서. 다시 만든다. 
     */

    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)

    const UserTreeDataProps_mon = useRef({} as IUserTreeDataProps);
    const [TreeDataProps_mon, setTreeDataProps_mon] = useState<IUserTreeDataProps>();

    const UserTreeDataProps_15_01 = useRef({} as IUserTreeDataProps);
    const UserTreeDataProps_15_02 = useRef({} as IUserTreeDataProps);
    const [TreeDataProps_15_01, setTreeDataProps_15_01] = useState<IUserTreeDataProps>();
    const [TreeDataProps_15_02, setTreeDataProps_15_02] = useState<IUserTreeDataProps>();

    const UserTreeDataProps_10_01 = useRef({} as IUserTreeDataProps);
    const UserTreeDataProps_10_02 = useRef({} as IUserTreeDataProps);
    const UserTreeDataProps_10_03 = useRef({} as IUserTreeDataProps);
    const [TreeDataProps_10_01, setTreeDataProps_10_01] = useState<IUserTreeDataProps>();
    const [TreeDataProps_10_02, setTreeDataProps_10_02] = useState<IUserTreeDataProps>();
    const [TreeDataProps_10_03, setTreeDataProps_10_03] = useState<IUserTreeDataProps>();

    const dataReady = useRef(false);

    useEffect(() => {
        const executeMutations = async () => {
            if (SelectedMenu.site.pk === -1 || SelectedMenu.CSCom.pk === -1) {
                return;
            }
            dataReady.current = false;

            // 날짜 범위 생성
            const date_list_mon = getMonthRangeFromString(SelectedMenu.Month);
            const date_list_15 = get15RangeFromString(SelectedMenu.Month);
            const date_list_10 = get10RangeFromString(SelectedMenu.Month);

            // Atom 데이터는 한달치 가져온다. 
            const getAtomdataparams = {
                siteinfo: SelectedMenu.site.pk,
                datatype: "atom",
                start_date: date_list_mon[0],
                end_date: date_list_mon[1],
            };

            // 한달 옵션데이터
            UserTreeDataProps_mon.current.site_name = SelectedMenu.site.name;
            UserTreeDataProps_mon.current.company_name = SelectedMenu.CSCom.name;
            UserTreeDataProps_mon.current.start_date = date_list_mon[0];
            UserTreeDataProps_mon.current.end_date = date_list_mon[1];

            const getMonCSidvoptionparams = {
                siteInfo_pk: SelectedMenu.site.pk,
                company_name: SelectedMenu.CSCom.name,
                start_date: date_list_mon[0],
                end_date: date_list_mon[1],
                type: "mon",
            };

            // 15일 옵션데이터
            UserTreeDataProps_15_01.current.site_name = SelectedMenu.site.name;
            UserTreeDataProps_15_01.current.company_name = SelectedMenu.CSCom.name;
            UserTreeDataProps_15_01.current.start_date = date_list_15[0];
            UserTreeDataProps_15_01.current.end_date = date_list_15[1];

            UserTreeDataProps_15_02.current.site_name = SelectedMenu.site.name;
            UserTreeDataProps_15_02.current.company_name = SelectedMenu.CSCom.name;
            UserTreeDataProps_15_02.current.start_date = date_list_15[2];
            UserTreeDataProps_15_02.current.end_date = date_list_15[3];

            const get15_01CSidvoptionparams = {
                siteInfo_pk: SelectedMenu.site.pk,
                company_name: SelectedMenu.CSCom.name,
                start_date: date_list_15[0],
                end_date: date_list_15[1],
                type: "15_01",
            };

            const get15_02CSidvoptionparams = {
                siteInfo_pk: SelectedMenu.site.pk,
                company_name: SelectedMenu.CSCom.name,
                start_date: date_list_15[2],
                end_date: date_list_15[3],
                type: "15_02",
            };

            // 10일 옵션데이터
            UserTreeDataProps_10_01.current.site_name = SelectedMenu.site.name;
            UserTreeDataProps_10_01.current.company_name = SelectedMenu.CSCom.name;
            UserTreeDataProps_10_01.current.start_date = date_list_10[0];
            UserTreeDataProps_10_01.current.end_date = date_list_10[1];

            UserTreeDataProps_10_02.current.site_name = SelectedMenu.site.name;
            UserTreeDataProps_10_02.current.company_name = SelectedMenu.CSCom.name;
            UserTreeDataProps_10_02.current.start_date = date_list_10[2];
            UserTreeDataProps_10_02.current.end_date = date_list_10[3];

            UserTreeDataProps_10_03.current.site_name = SelectedMenu.site.name;
            UserTreeDataProps_10_03.current.company_name = SelectedMenu.CSCom.name;
            UserTreeDataProps_10_03.current.start_date = date_list_10[4];
            UserTreeDataProps_10_03.current.end_date = date_list_10[5];

            const get10_01CSidvoptionparams = {
                siteInfo_pk: SelectedMenu.site.pk,
                company_name: SelectedMenu.CSCom.name,
                start_date: date_list_10[0],
                end_date: date_list_10[1],
                type: "10_01",
            };

            const get10_02CSidvoptionparams = {
                siteInfo_pk: SelectedMenu.site.pk,
                company_name: SelectedMenu.CSCom.name,
                start_date: date_list_10[2],
                end_date: date_list_10[3],
                type: "10_02",
            };

            const get10_03CSidvoptionparams = {
                siteInfo_pk: SelectedMenu.site.pk,
                company_name: SelectedMenu.CSCom.name,
                start_date: date_list_10[4],
                end_date: date_list_10[5],
                type: "10_03",
            };

            try {
                // 옵션 데이터 가져온다.
                await getCSIdvOptionMutation.mutateAsync(getMonCSidvoptionparams);
                await getCSIdvOptionMutation.mutateAsync(get15_01CSidvoptionparams);
                await getCSIdvOptionMutation.mutateAsync(get15_02CSidvoptionparams);
                await getCSIdvOptionMutation.mutateAsync(get10_01CSidvoptionparams);
                await getCSIdvOptionMutation.mutateAsync(get10_02CSidvoptionparams);
                await getCSIdvOptionMutation.mutateAsync(get10_03CSidvoptionparams);

                // 원자 데이터를 가져온다.
                await atomDataMutation.mutateAsync(getAtomdataparams);

                // 모든 mutation 완료 후 dataReady를 true로 변경(데이터가 모두 준비된 상태)          
                dataReady.current = true;

            } catch (error) {
                console.error("오류 발생:", error);
            }
        };

        // executeMutations 함수 호출 (서버에서 필요한 데이터는 다 가져온 상태)

        executeMutations();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedMenu]);

    // 개별옵션 불러온다. (기본옵션 처리는 서버에서 처리한다.)
    const getCSIdvOptionMutation = useMutation(getCSIdbOption, {
        onSuccess: (data, variables) => {
            const type = variables.type;

            // 옵션을 저장한다. 
            if (type === "mon") {
                UserTreeDataProps_mon.current.IvdOptin = data;
            }
            else if (type === "15_01") {
                // TargetIdvBaseOptin_15_01.current = data;
                UserTreeDataProps_15_01.current.IvdOptin = data;

            }
            else if (type === "15_02") {
                // TargetIdvBaseOptin_15_02.current = data;
                UserTreeDataProps_15_02.current.IvdOptin = data;
            }
            else if (type === "10_01") {
                // TargetIdvBaseOptin_10_01.current = data;
                UserTreeDataProps_10_01.current.IvdOptin = data;
            }
            else if (type === "10_02") {
                // TargetIdvBaseOptin_10_02.current = data
                UserTreeDataProps_10_02.current.IvdOptin = data;
            }
            else if (type === "10_03") {
                // TargetIdvBaseOptin_10_03.current = data;
                UserTreeDataProps_10_03.current.IvdOptin = data;
            }
        }
    });

    // 원자 데이터 불러온다.
    const atomDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            let target_atom_data_mon = [] as any;
            let target_atom_data_15_01 = [] as any;
            let target_atom_data_15_02 = [] as any;
            let target_atom_data_10_01 = [] as any;
            let target_atom_data_10_02 = [] as any;
            let target_atom_data_10_03 = [] as any;

            data.forEach((item: any) => {
                // 날짜 문자열에서 일(day) 부분 추출
                const day = parseInt(item.target_date.split("-")[2]);

                // 월별 데이터
                target_atom_data_mon.push(JSON.parse(item.data));

                // 1일부터 15일 사이의 데이터
                if (day >= 1 && day <= 15) {
                    target_atom_data_15_01.push(JSON.parse(item.data));
                }
                // 16일부터 31일 사이의 데이터
                if (day >= 16 && day <= 31) {
                    target_atom_data_15_02.push(JSON.parse(item.data));
                }
                // 1일부터 10일 사이의 데이터
                if (day >= 1 && day <= 10) {
                    target_atom_data_10_01.push(JSON.parse(item.data));
                }
                // 11일부터 20일 사이의 데이터
                if (day >= 11 && day <= 20) {
                    target_atom_data_10_02.push(JSON.parse(item.data));
                }
                // 21일부터 31일 사이의 데이터
                if (day >= 21 && day <= 31) {
                    target_atom_data_10_03.push(JSON.parse(item.data));
                }

            });
            //re_atom_data_list  리스트를 역순으로 뒤집는다. 
            target_atom_data_mon = target_atom_data_mon.reverse();
            target_atom_data_15_01 = target_atom_data_15_01.reverse();
            target_atom_data_15_02 = target_atom_data_15_02.reverse();
            target_atom_data_10_01 = target_atom_data_10_01.reverse();
            target_atom_data_10_02 = target_atom_data_10_02.reverse();
            target_atom_data_10_03 = target_atom_data_10_03.reverse();

            // 역순으로 뒤집어서 데이터를 합친다. 
            let target_combined_data_mon = [] as any;
            for (const atom_data of target_atom_data_mon) {
                target_combined_data_mon = getCombinedData(target_combined_data_mon, atom_data);
            }
            let target_combined_data_15_01 = [] as any;
            for (const atom_data of target_atom_data_15_01) {
                target_combined_data_15_01 = getCombinedData(target_combined_data_15_01, atom_data);
            }
            let target_combined_data_15_02 = [] as any;
            for (const atom_data of target_atom_data_15_02) {
                target_combined_data_15_02 = getCombinedData(target_combined_data_15_02, atom_data);
            }
            let target_combined_data_10_01 = [] as any;
            for (const atom_data of target_atom_data_10_01) {
                target_combined_data_10_01 = getCombinedData(target_combined_data_10_01, atom_data);
            }
            let target_combined_data_10_02 = [] as any;
            for (const atom_data of target_atom_data_10_02) {
                target_combined_data_10_02 = getCombinedData(target_combined_data_10_02, atom_data);
            }
            let target_combined_data_10_03 = [] as any;
            for (const atom_data of target_atom_data_10_03) {
                target_combined_data_10_03 = getCombinedData(target_combined_data_10_03, atom_data);
            }

            // 합친 데이터에서. user_id 가 SelectedMenu.company_name 인 데이터와 status_id_list 에 SelectedMenu.company_name 이 있는 데이터를 찾는다.
            let AtomUserTreeDatas_mon = target_combined_data_mon.filter((item: any) => item.user_id === SelectedMenu.CSCom.name || item.status_id_list.includes(SelectedMenu.CSCom.name));
            let AtomUserTreeDatas_15_01 = target_combined_data_15_01.filter((item: any) => item.user_id === SelectedMenu.CSCom.name || item.status_id_list.includes(SelectedMenu.CSCom.name));
            let AtomUserTreeDatas_15_02 = target_combined_data_15_02.filter((item: any) => item.user_id === SelectedMenu.CSCom.name || item.status_id_list.includes(SelectedMenu.CSCom.name));
            let AtomUserTreeDatas_10_01 = target_combined_data_10_01.filter((item: any) => item.user_id === SelectedMenu.CSCom.name || item.status_id_list.includes(SelectedMenu.CSCom.name));
            let AtomUserTreeDatas_10_02 = target_combined_data_10_02.filter((item: any) => item.user_id === SelectedMenu.CSCom.name || item.status_id_list.includes(SelectedMenu.CSCom.name));
            let AtomUserTreeDatas_10_03 = target_combined_data_10_03.filter((item: any) => item.user_id === SelectedMenu.CSCom.name || item.status_id_list.includes(SelectedMenu.CSCom.name));


            // 여기서 알값 데이터를 뽑아야한다. 
            UserTreeDataProps_mon.current.UserAtomData = AtomUserTreeDatas_mon;
            UserTreeDataProps_15_01.current.UserAtomData = AtomUserTreeDatas_15_01;
            UserTreeDataProps_15_02.current.UserAtomData = AtomUserTreeDatas_15_02;
            UserTreeDataProps_10_01.current.UserAtomData = AtomUserTreeDatas_10_01;
            UserTreeDataProps_10_02.current.UserAtomData = AtomUserTreeDatas_10_02;
            UserTreeDataProps_10_03.current.UserAtomData = AtomUserTreeDatas_10_03;


            //한달 데이터를 저장한다.
            UserTreeDataProps_mon.current.UserTreeDatas = getUserTreeDatas(AtomUserTreeDatas_mon, UserTreeDataProps_mon.current.IvdOptin);
            setTreeDataProps_mon(UserTreeDataProps_mon.current);


            //15일 데이터를 저장한다.
            UserTreeDataProps_15_01.current.UserTreeDatas = getUserTreeDatas(AtomUserTreeDatas_15_01, UserTreeDataProps_15_01.current.IvdOptin);
            UserTreeDataProps_15_02.current.UserTreeDatas = getUserTreeDatas(AtomUserTreeDatas_15_02, UserTreeDataProps_15_02.current.IvdOptin);
            setTreeDataProps_15_01(UserTreeDataProps_15_01.current);
            setTreeDataProps_15_02(UserTreeDataProps_15_02.current);

            //10일 데이터를 저장한다.
            UserTreeDataProps_10_01.current.UserTreeDatas = getUserTreeDatas(AtomUserTreeDatas_10_01, UserTreeDataProps_10_01.current.IvdOptin);
            UserTreeDataProps_10_02.current.UserTreeDatas = getUserTreeDatas(AtomUserTreeDatas_10_02, UserTreeDataProps_10_02.current.IvdOptin);
            UserTreeDataProps_10_03.current.UserTreeDatas = getUserTreeDatas(AtomUserTreeDatas_10_03, UserTreeDataProps_10_03.current.IvdOptin);
            setTreeDataProps_10_01(UserTreeDataProps_10_01.current);
            setTreeDataProps_10_02(UserTreeDataProps_10_02.current);
            setTreeDataProps_10_03(UserTreeDataProps_10_03.current);

        }
    });

    // 개별 옵션 유저가 입력시 처리 
    const toast = useToast();
    const putCSIdvOptionMutation = useMutation(putCSIdbOption, {
        onSuccess: (data) => {
            toast({
                title: "성공적으로 수정되었습니다.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });

            setSelectedMenu({ ...SelectedMenu, CSreload_tag: new Date().getTime() });
        },
        onError: (error) => {
            toast({
                title: "오류가 발생했습니다.",
                description: "다시 시도해 주세요.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    });

    const handleDataFromChild = (InputIvdUserOptin: any) => {
        // 수정데이터를 JSON 문자열로 변환
        const ivd_login_rates = JSON.stringify(InputIvdUserOptin.IvdUserOptin);

        let putCSIdvOptionParams = {
            IdvOpthon_pk: InputIvdUserOptin.targetIvdOptin.pk,
            siteInfo_pk: InputIvdUserOptin.targetIvdOptin.siteInfo,
            company_name: InputIvdUserOptin.targetIvdOptin.company_name,
            start_date: InputIvdUserOptin.targetIvdOptin.start_date,
            end_date: InputIvdUserOptin.targetIvdOptin.end_date,
            login_show_optin: InputIvdUserOptin.targetIvdOptin.login_show_optin,
            share_show_option: InputIvdUserOptin.targetIvdOptin.share_show_option,
            user_show_option: InputIvdUserOptin.targetIvdOptin.user_show_option,
            r_rate: InputIvdUserOptin.targetIvdOptin.r_rate,
            casino_login_rate: InputIvdUserOptin.targetIvdOptin.casino_login_rate,
            casino_share_rate: InputIvdUserOptin.targetIvdOptin.casino_share_rate,
            casino_activate: InputIvdUserOptin.targetIvdOptin.casino_activate,
            slot_login_rate: InputIvdUserOptin.targetIvdOptin.slot_login_rate,
            slot_share_rate: InputIvdUserOptin.targetIvdOptin.slot_share_rate,
            slot_activate: InputIvdUserOptin.targetIvdOptin.slot_activate,
            holdem_login_rate: InputIvdUserOptin.targetIvdOptin.holdem_login_rate,
            holdem_share_rate: InputIvdUserOptin.targetIvdOptin.holdem_share_rate,
            holdem_activate: InputIvdUserOptin.targetIvdOptin.holdem_activate,
            idv_login_rates: ivd_login_rates,
        }
        putCSIdvOptionMutation.mutateAsync(putCSIdvOptionParams);
    };


    // UserSharData 만든다.
    const [SharDataProps_mon, setSharDataProps_mon] = useState<any>();

    const [SharDataProps_15_01, setSharDataProps_15_01] = useState<any>();
    const [SharDataProps_15_02, setSharDataProps_15_02] = useState<any>();

    const [SharDataProps_10_01, setSharDataProps_10_01] = useState<any>();
    const [SharDataProps_10_02, setSharDataProps_10_02] = useState<any>();
    const [SharDataProps_10_03, setSharDataProps_10_03] = useState<any>();


    useEffect(() => {
        if (!dataReady.current) {
            return;
        }
        if (!TreeDataProps_mon || !TreeDataProps_15_01 || !TreeDataProps_15_02 || !TreeDataProps_10_01 || !TreeDataProps_10_02 || !TreeDataProps_10_03) {
            return;
        }

        // 데이터 설정
        setSharDataProps_mon(GetUserSharDataProps(TreeDataProps_mon, UserTreeDataProps_mon.current.IvdOptin));
        setSharDataProps_15_01(GetUserSharDataProps(TreeDataProps_15_01, UserTreeDataProps_15_01.current.IvdOptin));
        setSharDataProps_15_02(GetUserSharDataProps(TreeDataProps_15_02, UserTreeDataProps_15_02.current.IvdOptin));
        setSharDataProps_10_01(GetUserSharDataProps(TreeDataProps_10_01, UserTreeDataProps_10_01.current.IvdOptin));
        setSharDataProps_10_02(GetUserSharDataProps(TreeDataProps_10_02, UserTreeDataProps_10_02.current.IvdOptin));
        setSharDataProps_10_03(GetUserSharDataProps(TreeDataProps_10_03, UserTreeDataProps_10_03.current.IvdOptin));
    }, [dataReady.current]);



    // 테마 관련 코드 
    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);
    return (
        <Grid padding={"0.2rem"}
            h='100%'
            templateColumns='repeat(10, 1fr)'
            templateRows='repeat(10, 1fr)'
            gap={1}
        >
            <GridItem borderRadius={"xl"} paddingX={"0.5rem"} colSpan={10} rowSpan={1}>
                <SiteComMonComOptTopSelect />
            </GridItem>

            {(SelectedMenu.site.pk === -1 || SelectedMenu.CSCom.pk === -1) && (
                <GridItem colSpan={10} rowSpan={10} alignItems={"center"} justifyContent={"center"}>
                    <Center margin={10}>업체를 등록하여 선택해 주세요.. </Center>
                </GridItem>
            )}

            {(SelectedMenu.site.pk !== -1 && SelectedMenu.CSCom.pk !== -1) && (
                <>
                    <GridItem paddingX={"0.5rem"} colSpan={7} rowSpan={9} alignItems={"start"} paddingTop={"0.5rem"}>
                        <Flex
                            flexDirection={'column'}
                            w={'100%'}
                            h={'100%'}
                            alignItems={'center'}
                            gap={8}
                        >
                            {(TreeDataProps_mon && TreeDataProps_mon.IvdOptin.login_show_optin === "month") && (
                                (TreeDataProps_mon.UserTreeDatas.length > 0) ?
                                    (
                                        <UserTreeTable
                                            site_name={TreeDataProps_mon.site_name}
                                            company_name={TreeDataProps_mon.company_name}
                                            start_date={TreeDataProps_mon.start_date}
                                            end_date={TreeDataProps_mon.end_date}
                                            UserTreeDatas={TreeDataProps_mon.UserTreeDatas}
                                            UserAtomData={TreeDataProps_mon.UserAtomData}
                                            IvdOptin={TreeDataProps_mon.IvdOptin}
                                            KeyIvdOptin={TreeDataProps_mon.KeyIvdOptin}
                                            onSendData={handleDataFromChild}
                                        />

                                    ) : (
                                        <UserTreeTableNoData
                                            site_name={TreeDataProps_mon.site_name}
                                            company_name={TreeDataProps_mon.company_name}
                                            start_date={TreeDataProps_mon.start_date}
                                            end_date={TreeDataProps_mon.end_date}
                                        />
                                    )
                            )}


                            {(TreeDataProps_15_01 && TreeDataProps_15_01.IvdOptin.login_show_optin === "month15") && (
                                TreeDataProps_15_01.UserTreeDatas.length > 0 ? (
                                    <UserTreeTable
                                        site_name={TreeDataProps_15_01.site_name}
                                        company_name={TreeDataProps_15_01.company_name}
                                        start_date={TreeDataProps_15_01.start_date}
                                        end_date={TreeDataProps_15_01.end_date}
                                        UserTreeDatas={TreeDataProps_15_01.UserTreeDatas}
                                        UserAtomData={TreeDataProps_15_01.UserAtomData}
                                        IvdOptin={TreeDataProps_15_01.IvdOptin}
                                        KeyIvdOptin={TreeDataProps_15_01.KeyIvdOptin}
                                        onSendData={handleDataFromChild}
                                    />
                                ) : (
                                    <UserTreeTableNoData
                                        site_name={TreeDataProps_15_01.site_name}
                                        company_name={TreeDataProps_15_01.company_name}
                                        start_date={TreeDataProps_15_01.start_date}
                                        end_date={TreeDataProps_15_01.end_date}
                                    />
                                )
                            )}

                            {(TreeDataProps_15_02 && TreeDataProps_15_02.IvdOptin.login_show_optin === "month15") && (
                                TreeDataProps_15_02.UserTreeDatas.length > 0 ? (
                                    <UserTreeTable
                                        site_name={TreeDataProps_15_02.site_name}
                                        company_name={TreeDataProps_15_02.company_name}
                                        start_date={TreeDataProps_15_02.start_date}
                                        end_date={TreeDataProps_15_02.end_date}
                                        UserTreeDatas={TreeDataProps_15_02.UserTreeDatas}
                                        UserAtomData={TreeDataProps_15_02.UserAtomData}
                                        IvdOptin={TreeDataProps_15_02.IvdOptin}
                                        KeyIvdOptin={TreeDataProps_15_02.KeyIvdOptin}
                                        onSendData={handleDataFromChild}
                                    />
                                ) : (
                                    <UserTreeTableNoData
                                        site_name={TreeDataProps_15_02.site_name}
                                        company_name={TreeDataProps_15_02.company_name}
                                        start_date={TreeDataProps_15_02.start_date}
                                        end_date={TreeDataProps_15_02.end_date}
                                    />
                                )
                            )}


                            {(TreeDataProps_10_01 && TreeDataProps_10_01.IvdOptin.login_show_optin === "month10") && (
                                TreeDataProps_10_01.UserTreeDatas.length > 0 ? (
                                    <UserTreeTable
                                        site_name={TreeDataProps_10_01.site_name}
                                        company_name={TreeDataProps_10_01.company_name}
                                        start_date={TreeDataProps_10_01.start_date}
                                        end_date={TreeDataProps_10_01.end_date}
                                        UserTreeDatas={TreeDataProps_10_01.UserTreeDatas}
                                        UserAtomData={TreeDataProps_10_01.UserAtomData}
                                        IvdOptin={TreeDataProps_10_01.IvdOptin}
                                        KeyIvdOptin={TreeDataProps_10_01.KeyIvdOptin}
                                        onSendData={handleDataFromChild}
                                    />
                                ) : (
                                    <UserTreeTableNoData
                                        site_name={TreeDataProps_10_01.site_name}
                                        company_name={TreeDataProps_10_01.company_name}
                                        start_date={TreeDataProps_10_01.start_date}
                                        end_date={TreeDataProps_10_01.end_date}
                                    />
                                )
                            )}


                            {(TreeDataProps_10_02 && TreeDataProps_10_02.IvdOptin.login_show_optin === "month10") && (
                                TreeDataProps_10_02.UserTreeDatas.length > 0 ? (
                                    <UserTreeTable
                                        site_name={TreeDataProps_10_02.site_name}
                                        company_name={TreeDataProps_10_02.company_name}
                                        start_date={TreeDataProps_10_02.start_date}
                                        end_date={TreeDataProps_10_02.end_date}
                                        UserTreeDatas={TreeDataProps_10_02.UserTreeDatas}
                                        UserAtomData={TreeDataProps_10_02.UserAtomData}
                                        IvdOptin={TreeDataProps_10_02.IvdOptin}
                                        KeyIvdOptin={TreeDataProps_10_02.KeyIvdOptin}
                                        onSendData={handleDataFromChild}
                                    />
                                ) : (
                                    <UserTreeTableNoData
                                        site_name={TreeDataProps_10_02.site_name}
                                        company_name={TreeDataProps_10_02.company_name}
                                        start_date={TreeDataProps_10_02.start_date}
                                        end_date={TreeDataProps_10_02.end_date}
                                    />
                                )
                            )}


                            {(TreeDataProps_10_03 && TreeDataProps_10_03.IvdOptin.login_show_optin === "month10") && (
                                TreeDataProps_10_03.UserTreeDatas.length > 0 ? (
                                    <UserTreeTable
                                        site_name={TreeDataProps_10_03.site_name}
                                        company_name={TreeDataProps_10_03.company_name}
                                        start_date={TreeDataProps_10_03.start_date}
                                        end_date={TreeDataProps_10_03.end_date}
                                        UserTreeDatas={TreeDataProps_10_03.UserTreeDatas}
                                        UserAtomData={TreeDataProps_10_03.UserAtomData}
                                        IvdOptin={TreeDataProps_10_03.IvdOptin}
                                        KeyIvdOptin={TreeDataProps_10_03.KeyIvdOptin}
                                        onSendData={handleDataFromChild}
                                    />
                                ) : (
                                    <UserTreeTableNoData
                                        site_name={TreeDataProps_10_03.site_name}
                                        company_name={TreeDataProps_10_03.company_name}
                                        start_date={TreeDataProps_10_03.start_date}
                                        end_date={TreeDataProps_10_03.end_date}
                                    />
                                )

                            )}
                        </Flex>
                    </GridItem>

                    <GridItem paddingX={"0.5rem"} colSpan={3} rowSpan={9} alignItems={"start"} paddingTop={"0.5rem"}>
                        <Flex
                            flexDirection={'column'}
                            w={'100%'} h={'100%'}
                            alignItems={'center'}
                            gap={8}
                            overflowY={"auto"}
                            css={{
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                    backgroundColor: scrollBarColor,
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: scrollBarThumbColor,
                                    borderRadius: '4px',
                                },
                                '&:hover::-webkit-scrollbar-thumb': {
                                    backgroundColor: scrollBarThumbHoverColor,
                                },
                            }}>
                            {(SharDataProps_mon && SharDataProps_mon.UserShartitle.idv_show_optin === "month") && <UserSharTable
                                UserSharData={SharDataProps_mon.UserSharData}
                                UserShartitle={SharDataProps_mon.UserShartitle}
                                IvdOptin={SharDataProps_mon.IvdOptin}
                                onSendData={handleDataFromChild}
                            />}

                            {(SharDataProps_15_01 && SharDataProps_15_01.UserShartitle.idv_show_optin === "month15") && <UserSharTable
                                UserSharData={SharDataProps_15_01.UserSharData}
                                UserShartitle={SharDataProps_15_01.UserShartitle}
                                IvdOptin={SharDataProps_15_01.IvdOptin}
                                onSendData={handleDataFromChild}
                            />}

                            {(SharDataProps_15_02 && SharDataProps_15_02.UserShartitle.idv_show_optin === "month15") && <UserSharTable
                                UserSharData={SharDataProps_15_02.UserSharData}
                                UserShartitle={SharDataProps_15_02.UserShartitle}
                                IvdOptin={SharDataProps_15_02.IvdOptin}
                                onSendData={handleDataFromChild}
                            />}

                            {(SharDataProps_10_01 && SharDataProps_10_01.UserShartitle.idv_show_optin === "month10") && <UserSharTable
                                UserSharData={SharDataProps_10_01.UserSharData}
                                UserShartitle={SharDataProps_10_01.UserShartitle}
                                IvdOptin={SharDataProps_10_01.IvdOptin}
                                onSendData={handleDataFromChild}
                            />}

                            {(SharDataProps_10_02 && SharDataProps_10_02.UserShartitle.idv_show_optin === "month10") && <UserSharTable
                                UserSharData={SharDataProps_10_02.UserSharData}
                                UserShartitle={SharDataProps_10_02.UserShartitle}
                                IvdOptin={SharDataProps_10_02.IvdOptin}
                                onSendData={handleDataFromChild}
                            />}

                            {(SharDataProps_10_03 && SharDataProps_10_03.UserShartitle.idv_show_optin === "month10") && <UserSharTable
                                UserSharData={SharDataProps_10_03.UserSharData}
                                UserShartitle={SharDataProps_10_03.UserShartitle}
                                IvdOptin={SharDataProps_10_03.IvdOptin}
                                onSendData={handleDataFromChild}

                            />}
                        </Flex>
                    </GridItem>
                </>
            )}
        </Grid>
    )

}


/**
 * 
 * 

1 . transformTargetData_listTargetIdvOption
 transformTargetData_list = [
    {
        "user_id": "hite43431",
        "user_nickname": "승짱1",
        "status": "본사",
        "status_id_list": [
            "LOL6"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": -2301469,
        "슬롯 손익금": -1383262,
        "홀덤 손익금": 0,
        "손익금 합계": -3684731,
        "카지노 루징금": -460294,
        "슬롯 루징금": -276652,
        "홀덤 루징금": 0,
        "루징금 합계": -736946
    },
    {
        "user_id": "ryu951",
        "user_nickname": "ryu951",
        "status": "부본사",
        "status_id_list": [
            "LOL6",
            "hite43431"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": -444001,
        "슬롯 손익금": -64194,
        "홀덤 손익금": 0,
        "손익금 합계": -508195,
        "카지노 루징금": -88800,
        "슬롯 루징금": -12839,
        "홀덤 루징금": 0,
        "루징금 합계": -101639
    },
    {
        "user_id": "bbb12121",
        "user_nickname": "bbb12121",
        "status": "총판",
        "status_id_list": [
            "LOL6",
            "hite43431",
            "ryu951"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": 0,
        "슬롯 손익금": 9955,
        "홀덤 손익금": 0,
        "손익금 합계": 9955,
        "카지노 루징금": 0,
        "슬롯 루징금": 1991,
        "홀덤 루징금": 0,
        "루징금 합계": 1991
    },
    {
        "user_id": "aaa12121",
        "user_nickname": "aaa12121",
        "status": "총판",
        "status_id_list": [
            "LOL6",
            "hite43431",
            "ryu951"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": 0,
        "슬롯 손익금": 527,
        "홀덤 손익금": 0,
        "손익금 합계": 527,
        "카지노 루징금": 0,
        "슬롯 루징금": 105,
        "홀덤 루징금": 0,
        "루징금 합계": 105
    },
    {
        "user_id": "ccc12121",
        "user_nickname": "ccc12121",
        "status": "총판",
        "status_id_list": [
            "LOL6",
            "hite43431",
            "ryu951"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": 5881115,
        "슬롯 손익금": 0,
        "홀덤 손익금": 0,
        "손익금 합계": 5881115,
        "카지노 루징금": 1176223,
        "슬롯 루징금": 0,
        "홀덤 루징금": 0,
        "루징금 합계": 1176223
    },
    {
        "user_id": "rc001",
        "user_nickname": "rc001",
        "status": "매장",
        "status_id_list": [
            "LOL6",
            "hite43431",
            "ryu951",
            "ccc12121"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": -150050,
        "슬롯 손익금": 0,
        "홀덤 손익금": 0,
        "손익금 합계": -150050,
        "카지노 루징금": -30010,
        "슬롯 루징금": 0,
        "홀덤 루징금": 0,
        "루징금 합계": -30010
    },
    {
        "user_id": "qq01",
        "user_nickname": "퀸즈",
        "status": "부본사",
        "status_id_list": [
            "LOL6",
            "hite43431"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": -162,
        "슬롯 손익금": 899829,
        "홀덤 손익금": 0,
        "손익금 합계": 899667,
        "카지노 루징금": -32,
        "슬롯 루징금": 179966,
        "홀덤 루징금": 0,
        "루징금 합계": 179934
    },
    {
        "user_id": "mg01",
        "user_nickname": "ㅇㅇㅎ",
        "status": "부본사",
        "status_id_list": [
            "LOL6",
            "hite43431"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": -3030161,
        "슬롯 손익금": -82,
        "홀덤 손익금": 0,
        "손익금 합계": -3030243,
        "카지노 루징금": -606032,
        "슬롯 루징금": -16,
        "홀덤 루징금": 0,
        "루징금 합계": -606048
    },
    {
        "user_id": "hjy486",
        "user_nickname": "hjy486",
        "status": "총판",
        "status_id_list": [
            "LOL6",
            "hite43431",
            "mg01"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": 99732,
        "슬롯 손익금": 0,
        "홀덤 손익금": 0,
        "손익금 합계": 99732,
        "카지노 루징금": 19946,
        "슬롯 루징금": 0,
        "홀덤 루징금": 0,
        "루징금 합계": 19946
    },
    {
        "user_id": "rc0011",
        "user_nickname": "rc0011",
        "status": "총판",
        "status_id_list": [
            "LOL6",
            "hite43431",
            "mg01"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": -10608157,
        "슬롯 손익금": 0,
        "홀덤 손익금": 0,
        "손익금 합계": -10608157,
        "카지노 루징금": -2121631,
        "슬롯 루징금": 0,
        "홀덤 루징금": 0,
        "루징금 합계": -2121631
    },
    {
        "user_id": "rc0022",
        "user_nickname": "rc0022",
        "status": "매장",
        "status_id_list": [
            "LOL6",
            "hite43431",
            "mg01",
            "rc0011"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": 2475900,
        "슬롯 손익금": 0,
        "홀덤 손익금": 0,
        "손익금 합계": 2475900,
        "카지노 루징금": 495180,
        "슬롯 루징금": 0,
        "홀덤 루징금": 0,
        "루징금 합계": 495180
    },
    {
        "user_id": "vs1212",
        "user_nickname": "vs1212",
        "status": "총판",
        "status_id_list": [
            "LOL6",
            "hite43431",
            "mg01"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": 10183775,
        "슬롯 손익금": 0,
        "홀덤 손익금": 0,
        "손익금 합계": 10183775,
        "카지노 루징금": 2036755,
        "슬롯 루징금": 0,
        "홀덤 루징금": 0,
        "루징금 합계": 2036755
    },
    {
        "user_id": "jjm8484",
        "user_nickname": "jjm8484",
        "status": "총판",
        "status_id_list": [
            "LOL6",
            "hite43431",
            "mg01"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": -746417,
        "슬롯 손익금": 8948,
        "홀덤 손익금": 0,
        "손익금 합계": -737469,
        "카지노 루징금": -149283,
        "슬롯 루징금": 1790,
        "홀덤 루징금": 0,
        "루징금 합계": -147493
    },
    {
        "user_id": "chanel1347",
        "user_nickname": "chanel1347",
        "status": "총판",
        "status_id_list": [
            "LOL6",
            "hite43431",
            "mg01"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": -5478108,
        "슬롯 손익금": 0,
        "홀덤 손익금": 0,
        "손익금 합계": -5478108,
        "카지노 루징금": -1095622,
        "슬롯 루징금": 0,
        "홀덤 루징금": 0,
        "루징금 합계": -1095622
    },
    {
        "user_id": "konayoung221",
        "user_nickname": "konayoung221",
        "status": "부본사",
        "status_id_list": [
            "LOL6",
            "hite43431"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": 0,
        "슬롯 손익금": 1651061,
        "홀덤 손익금": 0,
        "손익금 합계": 1651061,
        "카지노 루징금": 0,
        "슬롯 루징금": 330212,
        "홀덤 루징금": 0,
        "루징금 합계": 330212
    },
    {
        "user_id": "ms001",
        "user_nickname": "신촌",
        "status": "부본사",
        "status_id_list": [
            "LOL6",
            "hite43431"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": 14730,
        "슬롯 손익금": -117683,
        "홀덤 손익금": 0,
        "손익금 합계": -102953,
        "카지노 루징금": 2946,
        "슬롯 루징금": -23537,
        "홀덤 루징금": 0,
        "루징금 합계": -20591
    },
    {
        "user_id": "ghkd0104",
        "user_nickname": "가꾸와",
        "status": "총판",
        "status_id_list": [
            "LOL6",
            "hite43431",
            "ms001"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": 5500,
        "슬롯 손익금": 324499,
        "홀덤 손익금": 0,
        "손익금 합계": 329999,
        "카지노 루징금": 1100,
        "슬롯 루징금": 64900,
        "홀덤 루징금": 0,
        "루징금 합계": 66000
    },
    {
        "user_id": "hite09001",
        "user_nickname": "hite09001",
        "status": "부본사",
        "status_id_list": [
            "LOL6",
            "hite43431"
        ],
        "카지노 루징율": 20,
        "슬롯 루징율": 20,
        "홀덤 루징율": 0,
        "카지노 손익금": 139017,
        "슬롯 손익금": 6666,
        "홀덤 손익금": 0,
        "손익금 합계": 145683,
        "카지노 루징금": 27803,
        "슬롯 루징금": 1333,
        "홀덤 루징금": 0,
        "루징금 합계": 29136
    }
]


샘플 유저 데이터 만들자.
위의 result 
reautl = [
    {           
        "user_id": user_id,
        "user_nickname": user_nickname,
        "status": status,
        "status_list": status_list,
        "status_id_list": status_id_list,

        "카지노 루징율": casino_activate ? casino_login_rate : 0,
        "슬롯 루징율": slot_activate ? slot_login_rate : 0,
        "홀덤 루징율": holdem_activate ? holdem_login_rate : 0,

        "카지노 손익금" : casino_activate ? (casislot_bet_카지노_ - casislot_win_카지노_ - casislot_fee_카지노_) : 0,
        "슬롯 손익금" : slot_activate ? (casislot_bet_슬롯_ - casislot_win_슬롯_ - casislot_fee_슬롯_) : 0,
        "홀덤 손익금" : holdem_activate ? (holdem_bet_홀덤_ - holdem_win_홀덤_ - holdem_fee_홀덤_) : 0,

        "손익금 합계" : (casino_activate ? (casislot_bet_카지노_ - casislot_win_카지노_ - casislot_fee_카지노_) : 0) + (slot_activate ? (casislot_bet_슬롯_ - casislot_win_슬롯_ - casislot_fee_슬롯_) : 0) + (holdem_activate ? (holdem_bet_홀덤_ - holdem_win_홀덤_ - holdem_fee_홀덤_) : 0),

        "카지노 루징금" : casino_activate ? (casislot_bet_카지노_ - casislot_win_카지노_ - casislot_fee_카지노_) * casino_login_rate : 0,
        "슬롯 루징금" : slot_activate ? (casislot_bet_슬롯_ - casislot_win_슬롯_ - casislot_fee_슬롯_) * slot_login_rate : 0,
        "홀덤 루징금" : holdem_activate ? (holdem_bet_홀덤_ - holdem_win_홀덤_ - holdem_fee_홀덤_) * holdem_login_rate : 0,

        "루징금 합계": (casino_activate ? (casislot_bet_카지노_ - casislot_win_카지노_ - casislot_fee_카지노_) * casino_login_rate : 0) + (slot_activate ? (casislot_bet_슬롯_ - casislot_win_슬롯_ - casislot_fee_슬롯_) * slot_login_rate : 0) + (holdem_activate ? (holdem_bet_홀덤_ - holdem_win_홀덤_ - holdem_fee_홀덤_) * holdem_login_rate : 0)
    },.....
 */