import { atom } from "recoil";
import { IUserInfo } from "../api/user";


export const IsLoginAtom = atom<Boolean>({
    key: "IsLoginAtom",
    default: false,
})

export const IsUserLoginVerifiedAtom = atom<Boolean>({
    key: "IsUserLoginVerifiedAtom",
    default: false,
})

export const IsManagerAtom = atom<Boolean>({
    key: "IsManagerAtom",
    default: false,
})

export const userInfoAtom = atom<IUserInfo>({
    key: "userInfoAtom",
    default: {
        pk: 0,
        username: "",
        nickname: "",
        authority: "user",
        distributor: "",
        login_verified: false,
        available_date: "",
        login_ip: "",
        login_datetime: "",
        logout_datetime: "",
        created_at: "",
    }
})