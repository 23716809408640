import { Grid, GridItem, Spinner, Center, VStack, Text } from "@chakra-ui/react";
import SiteComYearTopSelect from "../CommContents/TopSelect/SiteComYearTopSelect";
import { useRecoilValue } from "recoil";
import { SelectedMenuAtom } from "../../../atoms/topSelect";
import BaseTable from "../CommContents/Table/BaseTable";
import { useFetchCSLosingMonData } from "./conpnents/fetchData";

export default function CSLosingMonTotal() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    const { CSlosingMonTableData, loading } = useFetchCSLosingMonData(SelectedMenu);  // 커스텀 훅 사용

    return (
        <Grid padding={"0.2rem"} h="100%" templateColumns="repeat(10, 1fr)" templateRows="repeat(10, 1fr)" gap={1}>
            <GridItem borderRadius={"xl"} paddingX={"0.5rem"} colSpan={10} rowSpan={1}>
                <SiteComYearTopSelect />
            </GridItem>
            <GridItem borderRadius={"xl"} paddingX={"0.5rem"} colSpan={10} rowSpan={9}>
                {loading ? (
                    <Center h="100%">
                        <VStack spacing={4}>
                            <Spinner size="xl" />
                            <Text fontSize="lg">로딩 중...</Text>
                        </VStack>
                    </Center>
                ) : (
                    CSlosingMonTableData && <BaseTable tableData={CSlosingMonTableData.tableData} />
                )}
            </GridItem>
        </Grid>
    );
}
