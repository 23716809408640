import { axiosInstance } from "./base";

// 카지노 슬롯 정산 업체 옵션
export interface IcsComInfo {
    pk: number;
    distributor: {
        pk: number;
        name: string;
    };
    siteInfo: number;
    company_name: string;
    login_show_optin: string;
    share_show_option: string;
    user_show_option: string;
    r_rate: number;
    casino_login_rate: number;
    casino_share_rate: number;
    casino_activate: boolean;
    slot_login_rate: number;
    slot_share_rate: number;
    slot_activate: boolean;
    holdem_login_rate: number;
    holdem_share_rate: number;
    holdem_activate: boolean;
    created_at: string;
    updated_at: string;
}

interface IpostcsComInfoParmas {
    'siteInfo_pk': number,
    'company_name': string,
    'login_show_optin': string,
    'share_show_option': string,
    'user_show_option': string,
    'r_rate': number,
    'casino_login_rate': number,
    'casino_share_rate': number,
    'casino_activate': boolean,
    'slot_login_rate': number,
    'slot_share_rate': number,
    'slot_activate': boolean,
    'holdem_login_rate': number,
    'holdem_share_rate': number,
    'holdem_activate': boolean,
}

export const getCSComInfo = ({ siteInfo_pk }: { siteInfo_pk: number; }) => (
    axiosInstance.get(
        `cscominfo/`,
        {
            params: {
                siteInfo: siteInfo_pk,
            },
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data as IcsComInfo[])
)

export const postCSComInfo = (postCSComInfoParms: IpostcsComInfoParmas) => (
    axiosInstance.post(
        `cscominfo/`,
        {
            siteInfo_pk: postCSComInfoParms.siteInfo_pk,
            company_name: postCSComInfoParms.company_name,
            login_show_optin: postCSComInfoParms.login_show_optin,
            share_show_option: postCSComInfoParms.share_show_option,
            user_show_option: postCSComInfoParms.user_show_option,
            r_rate: postCSComInfoParms.r_rate,
            casino_login_rate: postCSComInfoParms.casino_login_rate,
            casino_share_rate: postCSComInfoParms.casino_share_rate,
            casino_activate: postCSComInfoParms.casino_activate,
            slot_login_rate: postCSComInfoParms.slot_login_rate,
            slot_share_rate: postCSComInfoParms.slot_share_rate,
            slot_activate: postCSComInfoParms.slot_activate,
            holdem_login_rate: postCSComInfoParms.holdem_login_rate,
            holdem_share_rate: postCSComInfoParms.holdem_share_rate,
            holdem_activate: postCSComInfoParms.holdem_activate
        },
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)


export const getCSComInfoDetail = ({ comInfo_pk }: { comInfo_pk: number }) => (
    axiosInstance.get(
        `cscominfo/${comInfo_pk}/`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data as IcsComInfo)
)

interface IputCSComInfoParmas {
    'comInfo_pk': number,
    'siteInfo_pk': number,
    'company_name': string,
    'login_show_optin': string,
    'share_show_option': string,
    'user_show_option': string,
    'r_rate': number,
    'casino_login_rate': number,
    'casino_share_rate': number,
    'casino_activate': boolean,
    'slot_login_rate': number,
    'slot_share_rate': number,
    'slot_activate': boolean,
    'holdem_login_rate': number,
    'holdem_share_rate': number,
    'holdem_activate': boolean,
}

export const putCSComInfo = (putCSComInfoParma: IputCSComInfoParmas) => (
    axiosInstance.put(
        `cscominfo/${putCSComInfoParma.comInfo_pk}/`,
        {
            siteInfo: putCSComInfoParma.siteInfo_pk,
            company_name: putCSComInfoParma.company_name,
            login_show_optin: putCSComInfoParma.login_show_optin,
            share_show_option: putCSComInfoParma.share_show_option,
            user_show_option: putCSComInfoParma.user_show_option,
            r_rate: putCSComInfoParma.r_rate,
            casino_login_rate: putCSComInfoParma.casino_login_rate,
            casino_share_rate: putCSComInfoParma.casino_share_rate,
            casino_activate: putCSComInfoParma.casino_activate,
            slot_login_rate: putCSComInfoParma.slot_login_rate,
            slot_share_rate: putCSComInfoParma.slot_share_rate,
            slot_activate: putCSComInfoParma.slot_activate,
            holdem_login_rate: putCSComInfoParma.holdem_login_rate,
            holdem_share_rate: putCSComInfoParma.holdem_share_rate,
            holdem_activate: putCSComInfoParma.holdem_activate
        },
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)

export const deleteCSComInfo = ({ comInfo_pk }: { comInfo_pk: number }) => (
    axiosInstance.delete(
        `cscominfo/${comInfo_pk}/`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)