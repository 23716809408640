
/*
    데이터 배열에서 숫자 타입의 값들을 문자열로 변환하는 함수
    data: 데이터 배열
*/

interface DataItem {
    Date: string;
    [key: string]: number | string; // 'Date' 외 다른 키들은 숫자 또는 문자열 가능
}

interface TransformedDataItem {
    Date: string;
    [key: string]: string; // 모든 키의 값은 문자열
}

export function transformNumbersToStrings(data: DataItem[]): TransformedDataItem[] {
    return data.map(item => {
        const transformedItem: TransformedDataItem = { Date: item.Date };
        for (const key in item) {
            if (key !== 'Date') {
                // 강제적으로 string 타입으로 변환
                transformedItem[key] = String(item[key]);
            }
        }
        return transformedItem;
    });
}


/*
    데이터 배열에서 석택된 날짜의 "합계"금액을 반환하는 함수. 선택된 날짜가 없으면 "0"을 반환
    items: 데이터 배열
    selectedDate: 선택된 날짜
*/
interface DataItem2 {
    Date: string;
    합계: string;
    [key: string]: string; // 다른 키-값 쌍도 허용
}

export const getTotalByDate = (items: DataItem2[], selectedDate: string): string => {

    // "2024-07-17" 형식의 날짜를 "07/17" 형식으로 변환
    const dateWithoutYear = selectedDate.slice(5).replace('-', '/');

    // 해당 날짜의 데이터를 찾음
    const item = items.find(i => i.Date === dateWithoutYear);

    // item과 item.합계가 정의되어 있는지 확인 후, toLocaleString 적용
    if (item && item.합계 !== undefined && item.합계 !== null) {

        const total = parseInt(item.합계.replace(/,/g, ''), 10);

        return isNaN(total) ? "0" : total.toLocaleString();
    }

    return "0";
};

/*
    데이터 배열에서 "합계"금액을 계산하는 함수
    (배열의 특정 키에 대한 숫자 값을 합산하여 총합을 문자열로 반환합니다.)
    items: 데이터 배열
    key: 합계를 계산할 키
*/
export const calculateTotal = (items: any[], key: string): string => {
    const total = items.reduce((acc, item) => {
        if (item && item[key]) {
            return acc + parseInt(item[key].replace(/,/g, ''), 10);
        }
        return acc;
    }, 0);
    return total.toLocaleString();
};


/*
    문자열을 숫자로 변환하는 함수
    (금액 단위 설정을 위해 사용)
    value: 변환할 값 
*/
export const convertToNumber = (value: any): number => {
    if (value === undefined) { return 0; }
    if (value === "") { return 0; }
    if (value === "-") { return 0; }
    if (value === "0") { return 0; }
    if (value === 0) { return 0; }
    return parseInt(value.replace(/,/g, ''), 10);
};


/*
    선택된 날짜가 입력되면 ex "2024-06-10" 그 날짜의 월의 첫날과 그 날짜를 반환한다 ['2024-06-01', '2024-06-10'] 
    date: 날짜  
*/
export const getMonthFirstAndLastDay = (date: string) => {
    const firstDay = new Date(date);
    firstDay.setDate(1);
    const lastDay = new Date(date);

    return [firstDay.toISOString().split('T')[0], lastDay.toISOString().split('T')[0]];
};

