import { Center, Flex, Grid, GridItem, Text, useColorModeValue } from "@chakra-ui/react";
import useSiteDailySettlementData from "../../../userMainContesnts/SiteDailySettlement/compnents/fetchData";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import { convertToNumber } from "../../../../lib/dashboardAndDalilySettlementUtil";


export default function MDailyContentsTable() {
    const contentData = useSiteDailySettlementData(); // 커스텀 훅 사용

    const mainBgColor = useColorModeValue(lightTheme_colors.mainBgColor, darkTheme_colors.mainBgColor);
    const mainFontColor = useColorModeValue(lightTheme_colors.mainFontColor, darkTheme_colors.mainFontColor);
    const titleColor = useColorModeValue(lightTheme_colors.titleColor, darkTheme_colors.titleColor);
    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);
    const bgHoverColor = useColorModeValue(lightTheme_colors.bgHoverColor, darkTheme_colors.bgHoverColor);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);

    const getAmountColor = (amount: string) => {
        return convertToNumber(amount) < 0 ? negativeColor : mainFontColor;
    }

    return (
        <Flex direction="column" padding="0.5rem" bg={mainBgColor} color={mainFontColor} borderRadius="md" w={"100%"} h={"100%"} >
            {/* Categories Table using Grid */}
            <Flex direction="column" mb="1" bg={bgColor} borderRadius="md" overflow="scroll">
                <Grid templateColumns="repeat(3, 1fr)" bg={titleColor} padding="0.1rem">
                    <GridItem color={mainFontColor} fontWeight="bold" fontSize="sm" >
                        <Center>종 목</Center>
                    </GridItem>
                    <GridItem color={mainFontColor} fontWeight="bold" fontSize="sm">
                        <Center>일별 손익</Center>
                    </GridItem>
                    <GridItem color={mainFontColor} fontWeight="bold" fontSize="sm">
                        <Center>당월 누적 손익</Center>
                    </GridItem>
                </Grid>
                {contentData.categories.map((category, index) => (
                    <Grid templateColumns="repeat(3, 1fr)" key={index} bg={category.isTotal ? bgHoverColor : 'transparent'} padding="0.1rem">
                        <GridItem fontWeight={category.isTotal ? 'bold' : 'normal'} fontSize="sm" >
                            <Center>{category.name}</Center>
                        </GridItem>
                        <GridItem fontWeight={category.isTotal ? 'bold' : 'normal'} fontSize="sm">
                            <Flex justify="flex-end" color={getAmountColor(category.dailyProfit)}>{category.dailyProfit}</Flex>
                        </GridItem>
                        <GridItem fontWeight={category.isTotal ? 'bold' : 'normal'} fontSize="sm">
                            <Flex justify="flex-end" color={getAmountColor(category.totalProfit)}>{category.totalProfit}</Flex>
                        </GridItem>
                    </Grid>
                ))}
            </Flex>

            {/* Transactions Table using Grid */}
            <Flex direction="column" mb="1" bg={bgColor} borderRadius="md" overflow="scroll">
                <Grid templateColumns="repeat(3, 1fr)" bg={titleColor} padding="0.1rem">
                    <GridItem color={mainFontColor} fontWeight="bold" fontSize="sm">
                        <Center>충전 환전</Center>
                    </GridItem>
                    <GridItem color={mainFontColor} fontWeight="bold" fontSize="sm">
                        <Center>일별 손익</Center>
                    </GridItem>
                    <GridItem color={mainFontColor} fontWeight="bold" fontSize="sm">
                        <Center>당월 누적 손익</Center>
                    </GridItem>
                </Grid>
                {contentData.transactions.map((transaction, index) => (
                    <Grid templateColumns="repeat(3, 1fr)" key={index} bg={transaction.isTotal ? bgHoverColor : 'transparent'} padding="0.1rem">
                        <GridItem fontWeight={transaction.isTotal ? 'bold' : 'normal'} fontSize="sm">
                            <Center>{transaction.name}</Center>
                        </GridItem>
                        <GridItem fontWeight={transaction.isTotal ? 'bold' : 'normal'} fontSize="sm">
                            <Flex justify="flex-end" color={getAmountColor(transaction.dailyAmount)}>{transaction.dailyAmount}</Flex>
                        </GridItem>
                        <GridItem fontWeight={transaction.isTotal ? 'bold' : 'normal'} fontSize="sm">
                            <Flex justify="flex-end" color={getAmountColor(transaction.totalAmount)}>{transaction.totalAmount}</Flex>
                        </GridItem>
                    </Grid>
                ))}
            </Flex>

            {/* User Money Table using Grid */}
            <Flex direction="column" mb="1" bg={bgColor} borderRadius="md" overflow="scroll">
                <Grid templateColumns="repeat(3, 1fr)" bg={titleColor} padding="0.1rem">
                    <GridItem color={mainFontColor} fontWeight="bold" fontSize="sm">
                        <Center>유저 머니</Center>
                    </GridItem>
                    <GridItem />
                    <GridItem color={mainFontColor} fontWeight="bold" fontSize="sm">
                        <Center>일별 잔액</Center>
                    </GridItem>
                </Grid>
                {contentData.userMoney.map((money, index) => (
                    <Grid templateColumns="repeat(3, 1fr)" key={index} bg={money.isTotal ? bgHoverColor : 'transparent'} padding="0.1rem">
                        <GridItem fontWeight={money.isTotal ? 'bold' : 'normal'} fontSize="sm">
                            <Center>{money.name}</Center>
                        </GridItem>
                        <GridItem />
                        <GridItem fontWeight={money.isTotal ? 'bold' : 'normal'} fontSize="sm">
                            <Flex justify="flex-end" color={getAmountColor(money.dailyAmount)}>{money.dailyAmount}</Flex>
                        </GridItem>
                    </Grid>
                ))}
            </Flex>

            {/* Banks Table using Grid */}
            <Flex direction="column" mb="1" bg={bgColor} borderRadius="md" overflow="scroll">
                <Grid templateColumns="repeat(3, 1fr)" bg={titleColor} padding="0.1rem">
                    <GridItem color={mainFontColor} fontWeight="bold" fontSize="sm">
                        <Center>뱅크</Center>
                    </GridItem>
                    <GridItem />
                    <GridItem color={mainFontColor} fontWeight="bold" fontSize="sm">
                        <Center>일별 잔액</Center>
                    </GridItem>
                </Grid>
                {contentData.banks.map((bank, index) => (
                    <Grid templateColumns="repeat(3, 1fr)" key={index} bg={bank.isTotal ? bgHoverColor : 'transparent'} padding="0.1rem">
                        <GridItem colSpan={2} fontWeight={bank.isTotal ? 'bold' : 'normal'} fontSize="sm"   >
                            <Text marginLeft={"1.5rem"}>{bank.name}</Text>
                        </GridItem>
                        <GridItem fontWeight={bank.isTotal ? 'bold' : 'normal'} fontSize="sm">
                            <Flex justify="flex-end" color={getAmountColor(bank.dailyAmount)}>{bank.dailyAmount}</Flex>
                        </GridItem>
                    </Grid>
                ))}
            </Flex>
        </Flex>
    );
};
