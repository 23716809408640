import React from 'react';
import {
    Box,
    Flex,
    Grid,
    GridItem,
    Text,
    Input,
    Button,
    VStack,
    HStack,
    Badge,
    Divider,
    useColorModeValue
} from '@chakra-ui/react';
import { darkTheme_colors, lightTheme_colors } from '../../theme';


export default function SiteSetting() {
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);

    const titleColor = useColorModeValue(lightTheme_colors.titleColor, darkTheme_colors.titleColor);

    const siteList = [
        { name: '멜론', status: '연동 확인', rate: '9%', date: '2024-04-22', active: true },
        { name: '체리', status: '연동 확인', rate: '8.5%', date: '2024-05-22', active: true },
        { name: '백두산', status: '연동 미확인', rate: '8.5%', date: '2024-05-29', active: false },
        { name: '벨 라', status: '연동 미확인', rate: '10%', date: '2024-06-01', active: false },
        { name: '와일드', status: '연동 미확인', rate: '11%', date: '2024-07-07', active: false }
    ];

    return (
        <Flex direction="column" p={8} bg={mainBgColor} color={mainFontColor} minHeight="100vh">
            <Grid templateColumns="repeat(2, 1fr)" gap={10}>
                <GridItem>
                    <Box bg={bgColor} borderRadius="md" boxShadow="md">
                        <Box bg={titleColor} p={4} borderTopRadius="md">
                            <Text fontSize="lg" fontWeight="bold" color={mainFontColor}>
                                사이트 연동 등록
                            </Text>
                        </Box>
                        <VStack spacing={4} p={4}>
                            <Input placeholder="사이트 명" />
                            <Input placeholder="어드민 사이트 url" />
                            <Input placeholder="어드민 아이디" />
                            <Input placeholder="관리자 패스워드" />
                            <Input placeholder="알값 %" />
                            <Button colorScheme={"telegram"} w="100%">
                                등록
                            </Button>
                        </VStack>
                        <Box p={4}>
                            <Text fontSize="sm">
                                운영하시는 어드민 사이트에서 본 프로그램에만 만들어온 새로 생성하여 등록해 주시기 바랍니다.
                            </Text>
                            <Text fontSize="sm" mt={2}>
                                어드민 접속을 위해서 이 사이트 등록이 필요할 경우, 아래 아이템을 등록해야 합니다.
                            </Text>
                            <Text fontSize="sm" mt={2}>
                                165.185.897.111
                            </Text>
                        </Box>
                    </Box>
                </GridItem>

                <GridItem>
                    <Box bg={bgColor} borderRadius="md" boxShadow="md">
                        <Box bg={titleColor} p={4} borderTopRadius="md">
                            <Text fontSize="lg" fontWeight="bold" color={mainFontColor}>
                                사이트 연동 리스트
                            </Text>
                        </Box>
                        <VStack spacing={4} p={4}>
                            {siteList.map((site, index) => (
                                <Box
                                    key={index}
                                    w="100%"
                                    p={4}
                                    borderRadius="md"
                                    bg={inputBgColor}
                                    boxShadow="sm"
                                    borderColor={mainFontColor}
                                    borderWidth="1px"
                                >
                                    <HStack justifyContent="space-between">
                                        <Text fontSize="md" fontWeight="bold">
                                            {site.name}
                                        </Text>
                                        <Badge colorScheme={site.active ? 'green' : 'red'}>
                                            {site.status}
                                        </Badge>
                                    </HStack>
                                    <Divider my={2} />
                                    <Flex justify="space-between">
                                        <Text fontSize="sm">알값: {site.rate}</Text>
                                        <Text fontSize="sm">등록일: {site.date}</Text>
                                    </Flex>
                                </Box>
                            ))}
                        </VStack>
                    </Box>
                </GridItem>
            </Grid >
        </Flex >
    );
};


