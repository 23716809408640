import { darkTheme_colors, lightTheme_colors } from "../../theme";
import React from 'react';
import { VStack, Link, Box, Text, useColorModeValue } from '@chakra-ui/react';
import { FaHome, FaCogs, FaCalendarDay, FaChartPie, FaRegCalendarAlt, FaExclamationCircle, FaArrowRight } from 'react-icons/fa';
import { useRecoilState } from "recoil";
import { SelectedMenuAtom } from "../../atoms/topSelect";

const menuItems = [
    { name: '대시보드', rank: 0, path: 'Dashboard', icon: FaHome },
    { name: '사이트설정', rank: 0, path: 'SiteSetting', icon: FaCogs },
    { name: '일일정산', rank: 0, path: 'SiteDailySettlement', icon: FaCalendarDay },
    { name: '사이트별 일일 정산', rank: 1, path: 'SiteDailySettlement', icon: FaArrowRight },
    { name: '사이트별 세부 정산', rank: 1, path: 'SiteDetailSettlement', icon: FaArrowRight },


    { name: '카지노 루징/쉐어정산', rank: 0, path: 'CasinoLosingShareCompany', icon: FaChartPie },
    { name: '정산 업체 등록', rank: 1, path: 'CasinoLosingShareCompanyRegistration', icon: FaArrowRight },
    { name: '정기 정산', rank: 1, path: 'CSLosingShreMontly', icon: FaArrowRight },
    { name: '정기 루징 내역', rank: 1, path: 'CSLosingMonTotal', icon: FaArrowRight },
    { name: '기간별 조회', rank: 1, path: 'CSLosingShreDaily', icon: FaArrowRight },

    { name: '파워볼 루징/쉐어정산', rank: 0, path: 'PBLosingShareCompany', icon: FaChartPie },
    { name: '정산 업체 등록', rank: 1, path: 'PBLosingShareCompanyRegistration', icon: FaArrowRight },
    { name: '정기 정산', rank: 1, path: 'PBLosingShreMontly', icon: FaArrowRight },
    { name: '정기 정산(유저)', rank: 1, path: 'PBLosingMontlyUserTree', icon: FaArrowRight },

    { name: '정기 루징 내역', rank: 1, path: 'PBLosingMonTotal', icon: FaArrowRight },
    { name: '기간별 조회', rank: 1, path: 'PBLosingShreDaily', icon: FaArrowRight },
    { name: '기간별 조회(유저)', rank: 1, path: 'PBLosingDailyUserTree', icon: FaArrowRight },


    { name: '월정산', rank: 0, path: 'MonthlyTotalSettlement', icon: FaRegCalendarAlt },
    { name: '사이트별 월 정산', rank: 1, path: 'SiteMonthlySettlement', icon: FaArrowRight },
    { name: '월별 종합 정산', rank: 1, path: 'MonthlyTotalSettlement', icon: FaArrowRight },

    { name: '사용 가이드', rank: 0, path: 'GuideInquiry', icon: FaExclamationCircle },

    // { name: '테스트페이지1', rank: 0, path: 'testpage1', icon: FaExclamationCircle },
    // { name: '테스트페이지2', rank: 0, path: 'testpage2', icon: FaExclamationCircle },
    { name: '테스트페이지3', rank: 0, path: 'testpage4', icon: FaExclamationCircle }



];

export default function LeftMenu() {

    // 사이트 선택 핸들러
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom);

    const handleSiteSelect = (path: string) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.pageSelect = path;
        setSelectedMenu(before_SelectedMenu);
    }

    // 테마 관련 코드
    const bgHoverColor = useColorModeValue(lightTheme_colors['bgHoverColor'], darkTheme_colors['bgHoverColor']);
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const bgSelectedColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);
    const fontSelectedColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);


    const transition = 'all 0.2s';

    return (
        <VStack
            w={"100%"}
            spacing={4}
            align="stretch"
            padding="1rem"
            borderRadius="xl"
            bg={bgColor}
        >
            {menuItems.map((item, index) => {
                const isSelected = SelectedMenu.pageSelect === item.path;
                return (
                    <Link
                        key={index}
                        _hover={{ textDecoration: 'none', bg: bgHoverColor, transform: 'scale(1.08)', }}
                        borderRadius="md"
                        padding="0"
                        transition={transition}
                        onClick={() => handleSiteSelect(item.path)}
                    >
                        {item.rank === 1 && (
                            <Box
                                display="flex"
                                alignItems="center"
                                marginLeft={"2rem"}
                                paddingY="0"
                                paddingX="0.2rem"
                                borderRadius="md"
                                marginBottom="0"
                                bg={isSelected ? bgSelectedColor : "transparent"}
                            >
                                <Box as={item.icon} marginRight="0.5rem" />
                                <Text fontSize="lg" >
                                    {item.name}
                                </Text>
                            </Box>
                        )}
                        {item.rank === 0 && (
                            <Box
                                display="flex"
                                alignItems="center"
                                paddingY="0"
                                paddingX="0.2rem"
                                borderRadius="md"
                                bg={isSelected ? bgSelectedColor : "transparent"}
                            >
                                <Box as={item.icon} marginRight="0.5rem" />
                                <Text fontSize="lg" >
                                    {item.name}
                                </Text>
                            </Box>

                        )}
                    </Link>
                )

            }
            )}
        </VStack>
    );
}



