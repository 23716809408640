import { Box, Button, Divider, Flex, HStack, Text, useColorModeValue, VStack, useDisclosure } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import PBNewRegModal from "../Modal/NewReg";
import { useEffect, useState } from "react";
import PBPutRegModal from "../Modal/PutReg";
import { useMutation } from "@tanstack/react-query";
import { getPBComInfo, IpbComInfo } from "../../../../api/pbcominfo";
import { useRecoilValue } from "recoil";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";

const formatDateTime = (datetime_str: string) => {
    const date = new Date(datetime_str);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더합니다.
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
};


export default function PBComRegList() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    // 서버에서 데이터 가져와서 처리하자. 
    const [PBCominfoList, setPBCominfoList] = useState([] as IpbComInfo[]);

    const getPBCominfoMutation = useMutation(getPBComInfo, {
        onSuccess: (data) => {
            setPBCominfoList(data);
        }
    });

    // 모달을 위한 상태 관리
    const {
        isOpen: NewRegIsOpen,
        onOpen: NewRegOnOpen,
        onClose: NewRegOnClose,
    } = useDisclosure();

    const {
        isOpen: PutRegisOpen,
        onOpen: PutRegonOpen,
        onClose: PutRegonClose,
    } = useDisclosure();

    // 사이트 설정이 바뀌거나. 모달창 상태가 변할때 데이터를 가져오도록 설정
    useEffect(() => {
        getPBCominfoMutation.mutate({ siteInfo_pk: SelectedMenu.site.pk });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [NewRegIsOpen, PutRegisOpen, SelectedMenu]);


    const [selectedPutPk, setSelectedPutPk] = useState(-1);
    const handlePutOpenModal = (comInfo_pk: number) => {
        setSelectedPutPk(comInfo_pk);  // 선택한 pk 값을 설정
        PutRegonOpen();            // 모달 열기
    };

    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const receSelectColor = useColorModeValue(lightTheme_colors['receSelectColor'], darkTheme_colors['receSelectColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);
    const titleColor = useColorModeValue(lightTheme_colors.titleColor, darkTheme_colors.titleColor);

    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);
    return (
        <Box bg={bgColor} borderRadius="md" boxShadow="md" w={"40rem"} h={"100%"}>
            <Flex justifyContent={"space-between"} alignItems={"center"} bg={titleColor} p={4} borderTopRadius="md">
                <Text marginLeft={"1rem"} fontSize="lg" fontWeight="bold" color={mainFontColor}>
                    파워볼 정산 업체 리스트
                </Text>
                <Button marginRight={"1rem"} colorScheme="blue" onClick={NewRegOnOpen}>
                    신규등록
                </Button>
            </Flex>
            <Box
                p={4}
                overflowY="auto"
                h="calc(100% - 80px)"
                css={{
                    '&::-webkit-scrollbar': {
                        width: '8px',
                        backgroundColor: `${scrollBarColor} `,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: `${scrollBarThumbColor} `,
                        borderRadius: '4px',
                    },
                    '&:hover::-webkit-scrollbar-thumb': {
                        backgroundColor: `${scrollBarThumbHoverColor} `,
                    },
                }}
            > {/* 전체 높이에서 상단 Flex 높이를 뺀 값을 사용 */}

                <VStack
                    spacing={4}
                    overflowY="auto"  // VStack 내부에 스크롤 강제 적용
                    overflowX="hidden"

                >
                    {PBCominfoList.length > 0 ? (
                        PBCominfoList.map((site, index) => (
                            <Box
                                key={index}
                                w="100%"
                                p={4}
                                borderRadius="md"
                                bg={inputBgColor}
                                boxShadow="sm"
                                borderColor={mainFontColor}
                                borderWidth="1px"
                            >
                                <HStack justifyContent="space-between">
                                    <Text fontSize="md" fontWeight="bold">
                                        {site.company_name}
                                    </Text>
                                    <Button marginRight={"1rem"} colorScheme="green" onClick={() => handlePutOpenModal(site.pk)} >
                                        수정
                                    </Button>
                                </HStack>
                                <Divider my={2} />
                                <Flex justify="space-between">
                                    <Text fontSize="sm">루징 : {site.login_rate}%</Text>
                                    <Text fontSize="sm">쉐어 : {site.share_rate}%</Text>
                                    <Text fontSize="sm">등록(수정) : {formatDateTime(site.updated_at)}</Text>
                                </Flex>
                            </Box>
                        ))
                    ) : (
                        <Text fontSize="2xl" color={receSelectColor} p={4}>
                            데이터가 없습니다.
                        </Text>
                    )}
                </VStack>
            </Box>

            {/* 분리된 신규등록 모달 사용 */}
            <PBNewRegModal isOpen={NewRegIsOpen} onClose={NewRegOnClose} />
            <PBPutRegModal isOpen={PutRegisOpen} onClose={PutRegonClose} comInfo_pk={selectedPutPk} />
        </Box>
    );
}


/**
[
    {
        "pk": 1,
        "distributor": {
            "pk": 1,
            "name": "관리자1"
        },
        "siteInfo": 2,
        "company_name": "asdf",
        "login_rate": 55,
        "selected_login_list": "[\"코인-3분_게임합\",\"코인-5분_게임합\"]",
        "share_rate": 3,
        "selected_share_list": "[\"코인-3분_게임합\",\"코인사다리-5분_게임합\"]",
        "created_at": "2024-08-27T11:25:02.656716",
        "updated_at": "2024-08-27T11:25:02.656716"
    },
    {
        "pk": 2,
        "distributor": {
            "pk": 1,
            "name": "관리자1"
        },
        "siteInfo": 2,
        "company_name": "asdf2",
        "login_rate": 2.3,
        "selected_login_list": "[\"코인-3분_게임합\",\"코인-5분_게임합\",\"PBG_게임합\",\"코인사다리-5분_게임합\",\"코인사다리-3분_게임합\"]",
        "share_rate": 3.4,
        "selected_share_list": "[\"코인-3분_게임합\",\"코인사다리-5분_게임합\",\"코인-5분_게임합\",\"PBG_게임합\",\"EOS-5분_게임합\"]",
        "created_at": "2024-08-27T11:26:01.456862",
        "updated_at": "2024-08-27T11:26:01.456862"
    },
    {
        "pk": 3,
        "distributor": {
            "pk": 1,
            "name": "관리자1"
        },
        "siteInfo": 2,
        "company_name": "asdf4",
        "login_rate": 2.5,
        "selected_login_list": "[\"코인-3분_게임합\",\"코인-5분_게임합\",\"PBG_게임합\",\"코인사다리-5분_게임합\",\"코인사다리-3분_게임합\",\"EOS-5분_게임합\",\"동행파워볼_게임합\",\"EOS-3분_게임합\"]",
        "share_rate": 6.6,
        "selected_share_list": "[\"코인-3분_게임합\",\"코인사다리-5분_게임합\",\"코인-5분_게임합\",\"PBG_게임합\",\"EOS-5분_게임합\",\"코인사다리-3분_게임합\",\"동행파워볼_게임합\",\"EOS-3분_게임합\"]",
        "created_at": "2024-08-27T11:29:30.783620",
        "updated_at": "2024-08-27T11:29:30.783620"
    },
    {
        "pk": 4,
        "distributor": {
            "pk": 1,
            "name": "관리자1"
        },
        "siteInfo": 2,
        "company_name": "wqd1231",
        "login_rate": 1.2,
        "selected_login_list": "[\"코인-5분_게임합\"]",
        "share_rate": 1.4,
        "selected_share_list": "[\"코인-3분_게임합\"]",
        "created_at": "2024-08-27T11:32:51.461205",
        "updated_at": "2024-08-27T11:32:51.461205"
    }
]
 */