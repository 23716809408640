import { Button, Flex, Grid, GridItem, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Stack, Switch, Text, useColorModeValue, useToast, VStack } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { get10RangeFromString, get15RangeFromString, getMonthRangeFromString } from "../../../../lib/util";
import { getCSIdbOption, IcsIdvOption, putCSIdbOption } from "../../../../api/csidvoption";

// Modal 컴포넌트의 Props 타입 정의
interface CSPutIdvRegModalProps {
    isOpen: boolean;
    onClose: () => void;
    option: string;
}

// 폼 입력 데이터 타입 정의
interface FormData {
    companyName: string;
    login_show_optin: string;
    share_show_option: string;
    user_show_option: string;
    r_rate: number;
    casino_login_rate: number;
    casino_share_rate: number;
    casino_activate: boolean;
    slot_login_rate: number;
    slot_share_rate: number;
    slot_activate: boolean;
    holdem_login_rate: number;
    holdem_share_rate: number;
    holdem_activate: boolean;
}

export default function CSPutIdvRegModal({ isOpen, onClose, option }: CSPutIdvRegModalProps) {
    // 사이트 옵션 목록 정보 가져오기

    const toast = useToast();
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)


    const CSIdvOption_day = useRef<IcsIdvOption>();

    const CSIdvOption_mon = useRef<IcsIdvOption>();
    const CSIdvOption_15_01 = useRef<IcsIdvOption>();
    const CSIdvOption_15_02 = useRef<IcsIdvOption>();
    const CSIdvOption_10_01 = useRef<IcsIdvOption>();
    const CSIdvOption_10_02 = useRef<IcsIdvOption>();
    const CSIdvOption_10_03 = useRef<IcsIdvOption>();


    // 개별옵션 불러온다. 
    const getCSIdvOptionMutation = useMutation(getCSIdbOption, {
        onSuccess: (data, variables) => {

            // 카지노 옵션 pk 저장
            CSIdvOption_day.current = data;

            setValue("companyName", data.company_name);
            setValue("login_show_optin", data.login_show_optin);
            setValue("share_show_option", data.share_show_option);
            setValue("user_show_option", data.user_show_option);
            setValue("r_rate", data.r_rate);
            setValue("casino_login_rate", data.casino_login_rate);
            setValue("casino_share_rate", data.casino_share_rate);
            setValue("casino_activate", data.casino_activate);
            setValue("slot_login_rate", data.slot_login_rate);
            setValue("slot_share_rate", data.slot_share_rate);
            setValue("slot_activate", data.slot_activate);
            setValue("holdem_login_rate", data.holdem_login_rate);
            setValue("holdem_share_rate", data.holdem_share_rate);
            setValue("holdem_activate", data.holdem_activate);

            // 옵션을 저장한다.
            if (variables.type === "day") {
                CSIdvOption_day.current = data;
            } else
                if (variables.type === "mon") {
                    CSIdvOption_mon.current = data;
                }
                else if (variables.type === "15_01") {
                    CSIdvOption_15_01.current = data;
                }
                else if (variables.type === "15_02") {
                    CSIdvOption_15_02.current = data;
                }
                else if (variables.type === "10_01") {
                    CSIdvOption_10_01.current = data;
                }
                else if (variables.type === "10_02") {
                    CSIdvOption_10_02.current = data;
                }
                else if (variables.type === "10_03") {
                    CSIdvOption_10_03.current = data;
                }
        }
    });

    useEffect(() => {
        if (isOpen) {
            if (option === "day") {
                let getCSIdvOptionParams = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: SelectedMenu.start_date,
                    end_date: SelectedMenu.end_date,
                    type: "day",
                }
                getCSIdvOptionMutation.mutate(getCSIdvOptionParams);
            }

            if (option === "month") {
                const date_list_mon = getMonthRangeFromString(SelectedMenu.Month);
                const date_list_15 = get15RangeFromString(SelectedMenu.Month);
                const date_list_10 = get10RangeFromString(SelectedMenu.Month);

                let getMonCSIdvOptionParams_mon = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list_mon[0],
                    end_date: date_list_mon[1],
                    type: "mon",
                }

                let getMonCSIdvOptionParams_15_01 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list_15[0],
                    end_date: date_list_15[1],
                    type: "15_01",
                }

                let getMonCSIdvOptionParams_15_02 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list_15[2],
                    end_date: date_list_15[3],
                    type: "15_02",
                }

                let getMonCSIdvOptionParams_10_01 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list_10[0],
                    end_date: date_list_10[1],
                    type: "10_01",
                }

                let getMonCSIdvOptionParams_10_02 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list_10[2],
                    end_date: date_list_10[3],
                    type: "10_02",
                }

                let getMonCSIdvOptionParams_10_03 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list_10[4],
                    end_date: date_list_10[5],
                    type: "10_03",
                }
                getCSIdvOptionMutation.mutate(getMonCSIdvOptionParams_mon);
                getCSIdvOptionMutation.mutate(getMonCSIdvOptionParams_15_01);
                getCSIdvOptionMutation.mutate(getMonCSIdvOptionParams_15_02);
                getCSIdvOptionMutation.mutate(getMonCSIdvOptionParams_10_01);
                getCSIdvOptionMutation.mutate(getMonCSIdvOptionParams_10_02);
                getCSIdvOptionMutation.mutate(getMonCSIdvOptionParams_10_03);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);


    // useForm 초기화
    const { register, handleSubmit, formState, reset, setValue, watch } = useForm<FormData>({
        mode: "onBlur",
        defaultValues: {

        },
    });



    // 입력값 수정 
    /*
        month 는  여러 입력값이 한번에 수정되어야 한다. ( 30일, 15일, 10일 때문에 개별 유저 개별저장때문에...)
        그래서 아래와 같은 구조로 수정한다.
    */
    const putCSIdvOptionMutation = useMutation(putCSIdbOption, {
        // `onSuccess`와 `onError` 대신 `mutateAsync`를 사용하여 Promise 기반 처리
    });



    // 폼 제출 핸들러 타입 정의
    const onSubmit: SubmitHandler<FormData> = async (data) => {
        const promises = [];

        if (option === "day") {
            if (!CSIdvOption_day.current) {
                console.log("옵션 정보가 없습니다.");
                return;
            }

            let putCSIdvOptionParams = {
                IdvOpthon_pk: CSIdvOption_day.current.pk,
                siteInfo_pk: CSIdvOption_day.current.siteInfo,
                company_name: CSIdvOption_day.current.company_name,
                start_date: CSIdvOption_day.current.start_date,
                end_date: CSIdvOption_day.current.end_date,
                login_show_optin: data.login_show_optin,
                share_show_option: data.share_show_option,
                user_show_option: data.user_show_option,
                r_rate: data.r_rate,
                casino_login_rate: data.casino_login_rate,
                casino_share_rate: data.casino_share_rate,
                casino_activate: data.casino_activate,
                slot_login_rate: data.slot_login_rate,
                slot_share_rate: data.slot_share_rate,
                slot_activate: data.slot_activate,
                holdem_login_rate: data.holdem_login_rate,
                holdem_share_rate: data.holdem_share_rate,
                holdem_activate: data.holdem_activate,
                idv_login_rates: CSIdvOption_day.current.idv_login_rates,
            };
            promises.push(putCSIdvOptionMutation.mutateAsync(putCSIdvOptionParams));
        }

        if (option === "month") {
            if (!(CSIdvOption_mon.current
                && CSIdvOption_15_01.current
                && CSIdvOption_15_02.current
                && CSIdvOption_10_01.current
                && CSIdvOption_10_02.current
                && CSIdvOption_10_03.current
            )) {
                console.log("옵션 정보가 없습니다.");
                return;
            }

            let putMonCSIdvOptionParams_mon = {
                IdvOpthon_pk: CSIdvOption_mon.current.pk,
                siteInfo_pk: CSIdvOption_mon.current.siteInfo,
                company_name: CSIdvOption_mon.current.company_name,
                start_date: CSIdvOption_mon.current.start_date,
                end_date: CSIdvOption_mon.current.end_date,
                login_show_optin: data.login_show_optin,
                share_show_option: data.share_show_option,
                user_show_option: data.user_show_option,
                r_rate: data.r_rate,
                casino_login_rate: data.casino_login_rate,
                casino_share_rate: data.casino_share_rate,
                casino_activate: data.casino_activate,
                slot_login_rate: data.slot_login_rate,
                slot_share_rate: data.slot_share_rate,
                slot_activate: data.slot_activate,
                holdem_login_rate: data.holdem_login_rate,
                holdem_share_rate: data.holdem_share_rate,
                holdem_activate: data.holdem_activate,
                idv_login_rates: CSIdvOption_mon.current.idv_login_rates,
            };
            promises.push(putCSIdvOptionMutation.mutateAsync(putMonCSIdvOptionParams_mon));

            let putMonCSIdvOptionParams_15_01 = {
                IdvOpthon_pk: CSIdvOption_15_01.current.pk,
                siteInfo_pk: CSIdvOption_15_01.current.siteInfo,
                company_name: CSIdvOption_15_01.current.company_name,
                start_date: CSIdvOption_15_01.current.start_date,
                end_date: CSIdvOption_15_01.current.end_date,
                login_show_optin: data.login_show_optin,
                share_show_option: data.share_show_option,
                user_show_option: data.user_show_option,
                r_rate: data.r_rate,
                casino_login_rate: data.casino_login_rate,
                casino_share_rate: data.casino_share_rate,
                casino_activate: data.casino_activate,
                slot_login_rate: data.slot_login_rate,
                slot_share_rate: data.slot_share_rate,
                slot_activate: data.slot_activate,
                holdem_login_rate: data.holdem_login_rate,
                holdem_share_rate: data.holdem_share_rate,
                holdem_activate: data.holdem_activate,
                idv_login_rates: CSIdvOption_15_01.current.idv_login_rates,
            };
            promises.push(putCSIdvOptionMutation.mutateAsync(putMonCSIdvOptionParams_15_01));

            let putMonCSIdvOptionParams_15_02 = {
                IdvOpthon_pk: CSIdvOption_15_02.current.pk,
                siteInfo_pk: CSIdvOption_15_02.current.siteInfo,
                company_name: CSIdvOption_15_02.current.company_name,
                start_date: CSIdvOption_15_02.current.start_date,
                end_date: CSIdvOption_15_02.current.end_date,
                login_show_optin: data.login_show_optin,
                share_show_option: data.share_show_option,
                user_show_option: data.user_show_option,
                r_rate: data.r_rate,
                casino_login_rate: data.casino_login_rate,
                casino_share_rate: data.casino_share_rate,
                casino_activate: data.casino_activate,
                slot_login_rate: data.slot_login_rate,
                slot_share_rate: data.slot_share_rate,
                slot_activate: data.slot_activate,
                holdem_login_rate: data.holdem_login_rate,
                holdem_share_rate: data.holdem_share_rate,
                holdem_activate: data.holdem_activate,
                idv_login_rates: CSIdvOption_15_02.current.idv_login_rates,
            };
            promises.push(putCSIdvOptionMutation.mutateAsync(putMonCSIdvOptionParams_15_02));

            let putMonCSIdvOptionParams_10_01 = {
                IdvOpthon_pk: CSIdvOption_10_01.current.pk,
                siteInfo_pk: CSIdvOption_10_01.current.siteInfo,
                company_name: CSIdvOption_10_01.current.company_name,
                start_date: CSIdvOption_10_01.current.start_date,
                end_date: CSIdvOption_10_01.current.end_date,
                login_show_optin: data.login_show_optin,
                share_show_option: data.share_show_option,
                user_show_option: data.user_show_option,
                r_rate: data.r_rate,
                casino_login_rate: data.casino_login_rate,
                casino_share_rate: data.casino_share_rate,
                casino_activate: data.casino_activate,
                slot_login_rate: data.slot_login_rate,
                slot_share_rate: data.slot_share_rate,
                slot_activate: data.slot_activate,
                holdem_login_rate: data.holdem_login_rate,
                holdem_share_rate: data.holdem_share_rate,
                holdem_activate: data.holdem_activate,
                idv_login_rates: CSIdvOption_10_01.current.idv_login_rates,
            };
            promises.push(putCSIdvOptionMutation.mutateAsync(putMonCSIdvOptionParams_10_01));

            let putMonCSIdvOptionParams_10_02 = {
                IdvOpthon_pk: CSIdvOption_10_02.current.pk,
                siteInfo_pk: CSIdvOption_10_02.current.siteInfo,
                company_name: CSIdvOption_10_02.current.company_name,
                start_date: CSIdvOption_10_02.current.start_date,
                end_date: CSIdvOption_10_02.current.end_date,
                login_show_optin: data.login_show_optin,
                share_show_option: data.share_show_option,
                user_show_option: data.user_show_option,
                r_rate: data.r_rate,
                casino_login_rate: data.casino_login_rate,
                casino_share_rate: data.casino_share_rate,
                casino_activate: data.casino_activate,
                slot_login_rate: data.slot_login_rate,
                slot_share_rate: data.slot_share_rate,
                slot_activate: data.slot_activate,
                holdem_login_rate: data.holdem_login_rate,
                holdem_share_rate: data.holdem_share_rate,
                holdem_activate: data.holdem_activate,
                idv_login_rates: CSIdvOption_10_02.current.idv_login_rates,
            };
            promises.push(putCSIdvOptionMutation.mutateAsync(putMonCSIdvOptionParams_10_02));

            let putMonCSIdvOptionParams_10_03 = {
                IdvOpthon_pk: CSIdvOption_10_03.current.pk,
                siteInfo_pk: CSIdvOption_10_03.current.siteInfo,
                company_name: CSIdvOption_10_03.current.company_name,
                start_date: CSIdvOption_10_03.current.start_date,
                end_date: CSIdvOption_10_03.current.end_date,
                login_show_optin: data.login_show_optin,
                share_show_option: data.share_show_option,
                user_show_option: data.user_show_option,
                r_rate: data.r_rate,
                casino_login_rate: data.casino_login_rate,
                casino_share_rate: data.casino_share_rate,
                casino_activate: data.casino_activate,
                slot_login_rate: data.slot_login_rate,
                slot_share_rate: data.slot_share_rate,
                slot_activate: data.slot_activate,
                holdem_login_rate: data.holdem_login_rate,
                holdem_share_rate: data.holdem_share_rate,
                holdem_activate: data.holdem_activate,
                idv_login_rates: CSIdvOption_10_03.current.idv_login_rates,
            };
            promises.push(putCSIdvOptionMutation.mutateAsync(putMonCSIdvOptionParams_10_03));
        }

        try {
            await Promise.all(promises);  // 모든 mutation이 완료될 때까지 대기
            // 모든 작업이 완료되었을 때 toast와 관련 동작 실행
            toast({
                position: 'top',
                title: "옵션 수정 성공!! ",
                description: `${SelectedMenu.CSCom.name} 업체옵션이 수정되었습니다.`,
                status: "info",
                isClosable: true,
            });

            setSelectedMenu({ ...SelectedMenu, CSreload_tag: new Date().getTime() });
            reset();
            onClose();  // 모달 닫기
        } catch (error) {
            toast({
                position: 'top',
                title: "옵션 수정 실패! ",
                description: "관리자에게 문의해 주세요.",
                status: "error",
                isClosable: true,
            });
            reset();
            onClose();  // 모달 닫기
        }
    }

    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent bg={inputBgColor}>
                <ModalHeader>{SelectedMenu.CSCom.name}   - {option === "month" ? (
                    <>{SelectedMenu.Month}</>
                ) : (
                    <>{SelectedMenu.start_date} ~ {SelectedMenu.end_date}</>
                )} - 옵션 수정</ModalHeader>
                <ModalCloseButton />
                <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
                    <VStack spacing={6} align="stretch">
                        <Grid templateColumns="repeat(2, 1fr)" gap={4}>

                            {/* 알값 이름 */}
                            <GridItem>
                                <VStack align="stretch" spacing={2}>
                                    <Text fontSize="md" fontWeight="bold">알값</Text>
                                    <Input
                                        placeholder="알값 %"
                                        type="number"
                                        step="0.1"
                                        min="0"  // 0보다 작은 값 입력 불가
                                        {...register("r_rate", { required: "알값%를 입력하세요" })}
                                    />
                                    {formState.errors.casino_login_rate && (
                                        <Text color="red.500">{formState.errors.r_rate?.message}</Text>
                                    )}
                                </VStack>
                            </GridItem>
                        </Grid>

                        {/* 루징 조건, 비율 입력 및 사용자 노출 타입 */}
                        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                            <GridItem>
                                <VStack align="stretch" spacing={4}>
                                    <Text fontSize="md" fontWeight="bold">루징 조건</Text>
                                    <RadioGroup
                                        onChange={(value) => setValue("login_show_optin", value)}
                                        value={watch("login_show_optin")}  // 서버에서 불러온 값 반영
                                    >
                                        <Stack direction="column">
                                            <Radio value="month">월말 루징 정산</Radio>
                                            <Radio value="month15">15일 루징 정산</Radio>
                                            <Radio value="month10">10일 루징 정산</Radio>
                                        </Stack>
                                    </RadioGroup>
                                    {formState.errors.login_show_optin && (
                                        <Text color="red.500">{formState.errors.login_show_optin.message}</Text>
                                    )}
                                </VStack>
                            </GridItem>

                            <GridItem>
                                <VStack align="stretch" spacing={4}>
                                    <Text fontSize="md" fontWeight="bold">카지노/슬롯/홀덤 비율 입력</Text>

                                    <HStack justifyContent="space-between">
                                        <Text flex="3">카지노 루징률 (%)</Text>
                                        <Input
                                            flex="2"
                                            placeholder="카지노 루징률 %"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            defaultValue={0}  // 기본값 0 설정
                                            {...register("casino_login_rate", { required: "카지노 루징률을 입력하세요" })}
                                        />
                                    </HStack>
                                    {formState.errors.casino_login_rate && (
                                        <Text color="red.500">{formState.errors.casino_login_rate.message}</Text>
                                    )}

                                    <HStack justifyContent="space-between">
                                        <Text flex="3">슬롯 루징률 (%)</Text>
                                        <Input
                                            flex="2"
                                            placeholder="슬롯 루징률 %"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            defaultValue={0}  // 기본값 0 설정
                                            {...register("slot_login_rate", { required: "슬롯 루징률을 입력하세요" })}
                                        />
                                    </HStack>
                                    {formState.errors.slot_login_rate && (
                                        <Text color="red.500">{formState.errors.slot_login_rate.message}</Text>
                                    )}

                                    <HStack justifyContent="space-between">
                                        <Text flex="3">홀덤 루징률 (%)</Text>
                                        <Input
                                            flex="2"
                                            placeholder="홀덤 루징률 %"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            defaultValue={0}  // 기본값 0 설정
                                            {...register("holdem_login_rate", { required: "홀덤 루징률을 입력하세요" })}
                                        />
                                    </HStack>
                                    {formState.errors.holdem_login_rate && (
                                        <Text color="red.500">{formState.errors.holdem_login_rate.message}</Text>
                                    )}
                                </VStack>
                            </GridItem>

                        </Grid>

                        {/* 사용자 노출 타입 */}
                        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                            <GridItem>
                                <VStack align="stretch" spacing={4}>
                                    <Text fontSize="md" fontWeight="bold">루징표 노출 타입</Text>
                                    <RadioGroup defaultValue="under">
                                        <Stack direction="column">
                                            <Radio value="only" {...register("user_show_option", { required: "노출 타입을 선택하세요" })}>
                                                종합 only
                                            </Radio>
                                            <Radio value="under" {...register("user_show_option")}>
                                                종합 본인 + 직하부 회원
                                            </Radio>
                                            <Radio value="all" {...register("user_show_option")}>
                                                종합 본인 + 하루 전체 회원
                                            </Radio>
                                        </Stack>
                                        {formState.errors.user_show_option && (
                                            <Text color="red.500">{formState.errors.user_show_option.message}</Text>
                                        )}
                                    </RadioGroup>
                                </VStack>
                            </GridItem>
                            <GridItem>
                                <VStack align="stretch" spacing={4}>
                                    <Text fontSize="md" fontWeight="bold">화면 활성화</Text>
                                    <Stack direction="column">
                                        <Flex justifyContent="space-around">
                                            <Text flex="2">카지노 활성화</Text>
                                            <Switch
                                                flex="1"
                                                colorScheme="blue"
                                                {...register("casino_activate")}
                                                isChecked={watch("casino_activate")}
                                            />
                                        </Flex>
                                        <Flex justifyContent="space-around">
                                            <Text flex="2">슬롯 활성화</Text>
                                            <Switch
                                                flex="1"
                                                colorScheme="blue"
                                                {...register("slot_activate")}
                                                isChecked={watch("slot_activate")}
                                            />
                                        </Flex>
                                        <Flex justifyContent="space-around">
                                            <Text flex="2">홀덤 활성화</Text>
                                            <Switch
                                                flex="1"
                                                colorScheme="blue"
                                                {...register("holdem_activate")}
                                                isChecked={watch("holdem_activate")}
                                            />
                                        </Flex>
                                    </Stack>
                                </VStack>
                            </GridItem>
                        </Grid>

                        {/* 쉐어 조건 및 비율 입력 */}
                        <Grid templateColumns="repeat(2, 1fr)" gap={4} pt={"2rem"}>
                            <GridItem>
                                <VStack align="stretch" spacing={4}>
                                    <Text fontSize="md" fontWeight="bold">쉐어 조건</Text>
                                    <RadioGroup
                                        onChange={(value) => setValue("share_show_option", value)}
                                        value={watch("share_show_option")}  // 서버에서 불러온 값 반영
                                    >
                                        <Stack direction="column">
                                            <Radio value="month">월말 쉐어 정산</Radio>
                                            <Radio value="month15">15일 쉐어 정산</Radio>
                                            <Radio value="month10">10일 쉐어 정산</Radio>
                                            <Radio value="disable">표시 안함</Radio>
                                        </Stack>
                                    </RadioGroup>
                                    {formState.errors.share_show_option && (
                                        <Text color="red.500">{formState.errors.share_show_option.message}</Text>
                                    )}
                                </VStack>
                            </GridItem>

                            <GridItem>
                                <VStack align="stretch" spacing={4}>
                                    <Text fontSize="md" fontWeight="bold">쉐어율 입력</Text>

                                    <HStack justifyContent="space-between">
                                        <Text flex="3">카지노 쉐어율 (%)</Text>
                                        <Input
                                            flex="2"
                                            placeholder="카지노 쉐어율 %"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            defaultValue={0}  // 기본값 0 설정
                                            {...register("casino_share_rate", { required: "카지노 쉐어율을 입력하세요" })}
                                        />
                                    </HStack>
                                    {formState.errors.casino_share_rate && (
                                        <Text color="red.500">{formState.errors.casino_share_rate.message}</Text>
                                    )}

                                    <HStack justifyContent="space-between">
                                        <Text flex="3">슬롯 쉐어율 (%)</Text>
                                        <Input
                                            flex="2"
                                            placeholder="슬롯 쉐어율 %"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            defaultValue={0}  // 기본값 0 설정
                                            {...register("slot_share_rate", { required: "슬롯 쉐어율을 입력하세요" })}
                                        />
                                    </HStack>
                                    {formState.errors.slot_share_rate && (
                                        <Text color="red.500">{formState.errors.slot_share_rate.message}</Text>
                                    )}

                                    <HStack justifyContent="space-between">
                                        <Text flex="3">홀덤 쉐어율 (%)</Text>
                                        <Input
                                            flex="2"
                                            placeholder="홀덤 쉐어율 %"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            defaultValue={0}  // 기본값 0 설정
                                            {...register("holdem_share_rate", { required: "홀덤 쉐어율을 입력하세요" })}
                                        />
                                    </HStack>
                                    {formState.errors.holdem_share_rate && (
                                        <Text color="red.500">{formState.errors.holdem_share_rate.message}</Text>
                                    )}
                                </VStack>
                            </GridItem>

                        </Grid>
                    </VStack>
                    <ModalFooter>
                        <Flex justifyContent="end" w="100%">
                            <Flex>
                                <Button colorScheme="blue" mr={3} type="submit">
                                    옵션 수정
                                </Button>
                                <Button variant="ghost" onClick={onClose}>취소</Button>
                            </Flex>
                        </Flex>
                    </ModalFooter>
                </ModalBody>
            </ModalContent>

        </Modal>
    );
}
