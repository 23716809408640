import { useChargeExchangeData } from '../../../userMainContesnts/SiteDetailSettlement/consponents/DepositAndWithdrawData';
import MBaseTable from '../../CommContents/Table/MBaseTable';

export default function MDepositAndWithdrawTable() {
    const chargeExchangeData = useChargeExchangeData();

    return (
        <MBaseTable headers={chargeExchangeData.headers} rows={chargeExchangeData.rows} />
    );
}
