import { Center, Flex, Grid, GridItem, useColorModeValue, useToast } from "@chakra-ui/react";
import SiteComDayDayComOptTopSelect from "../CommContents/TopSelect/SiteComDayDayComOptTopSelect";
import { useRecoilState } from "recoil";
import { SelectedMenuAtom } from "../../../atoms/topSelect";
import { useEffect, useRef, useState } from "react";
import UserTreeTable, { IUserTreeDataProps } from "../CSLosiongShreComm/Contents/UserTreeTable";
import { useMutation } from "@tanstack/react-query";
import { getCSIdbOption, putCSIdbOption } from "../../../api/csidvoption";
import { getSiteData } from "../../../api/sitedata";
import { getCombinedData } from "../../../lib/conversionData";
import { GetUserSharDataProps, getUserTreeDatas } from "../CSLosiongShreComm/util/util";
import UserSharTable from "../CSLosiongShreComm/Contents/UserSharTable";
import { darkTheme_colors, lightTheme_colors } from "../../../theme";
import UserTreeTableNoData from "../CSLosiongShreComm/Contents/UserTreeTableNoData";


export default function CSLosingShreDaily() {
    /**
    - 한달 데이터로 만들어서 보여준다. 
     */

    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)

    const UserTreeDataProps = useRef({} as IUserTreeDataProps);

    const [TreeDataProps, setTreeDataProps] = useState<IUserTreeDataProps>();

    const dataReady = useRef(false);

    useEffect(() => {
        const executeMutations = async () => {
            if (SelectedMenu.site.pk === -1 || SelectedMenu.CSCom.pk === -1) {
                return;
            }
            dataReady.current = false;

            // 날짜 범위 생성
            const start_date = SelectedMenu.start_date
            const end_date = SelectedMenu.end_date

            // Atom 데이터 가져오기(선택된 날짜 범위)
            const getAtomdataparams = {
                siteinfo: SelectedMenu.site.pk,
                datatype: "atom",
                start_date: start_date,
                end_date: end_date,
            };

            // 옵션데이터
            UserTreeDataProps.current.site_name = SelectedMenu.site.name;
            UserTreeDataProps.current.company_name = SelectedMenu.CSCom.name;
            UserTreeDataProps.current.start_date = start_date;
            UserTreeDataProps.current.end_date = end_date;

            const getMonCSidvoptionparams = {
                siteInfo_pk: SelectedMenu.site.pk,
                company_name: SelectedMenu.CSCom.name,
                start_date: start_date,
                end_date: end_date,
                type: "day",
            };


            try {
                // 옵션 데이터 가져온다.
                await getCSIdvOptionMutation.mutateAsync(getMonCSidvoptionparams);

                // 원자 데이터를 가져온다.
                await atomDataMutation.mutateAsync(getAtomdataparams);

                // 모든 mutation 완료 후 dataReady를 true로 변경(데이터가 모두 준비된 상태)          
                dataReady.current = true;


            } catch (error) {
                console.error("오류 발생:", error);
            }
        };

        // executeMutations 함수 호출 (서버에서 필요한 데이터는 다 가져온 상태)

        executeMutations();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedMenu]);


    // 개별옵션 불러온다. (기본옵션 처리는 서버에서 처리한다.)
    const getCSIdvOptionMutation = useMutation(getCSIdbOption, {
        onSuccess: (data, variables) => {
            const type = variables.type;
            // 옵션을 저장한다. 
            if (type === "day") {
                UserTreeDataProps.current.IvdOptin = data;
            }
        }
    });

    // 원자 데이터 불러온다.
    const atomDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            let target_atom_data = [] as any;

            data.forEach((item: any) => {
                // 날짜  데이터
                target_atom_data.push(JSON.parse(item.data));
            });
            //리스트를 역순으로 뒤집는다. 
            target_atom_data = target_atom_data.reverse();

            // 역순으로 뒤집어서 데이터를 합친다. 
            let target_combined_data = [] as any;
            for (const atom_data of target_atom_data) {
                target_combined_data = getCombinedData(target_combined_data, atom_data);
            }

            // 합친 데이터에서. user_id 가 SelectedMenu.company_name 인 데이터와 status_id_list 에 SelectedMenu.company_name 이 있는 데이터를 찾는다.
            let AtomUserTreeDatas = target_combined_data.filter((item: any) => item.user_id === SelectedMenu.CSCom.name || item.status_id_list.includes(SelectedMenu.CSCom.name));

            // 여기서 알값 데이터를 뽑아야한다. 
            UserTreeDataProps.current.UserAtomData = AtomUserTreeDatas;

            //한달 데이터를 저장한다.
            UserTreeDataProps.current.UserTreeDatas = getUserTreeDatas(AtomUserTreeDatas, UserTreeDataProps.current.IvdOptin);
            setTreeDataProps(UserTreeDataProps.current);

        }
    });

    // 개별 옵션 유저가 입력시 처리 
    const toast = useToast();
    const putCSIdvOptionMutation = useMutation(putCSIdbOption, {
        onSuccess: (data) => {
            toast({
                title: "성공적으로 수정되었습니다.",
                status: "success",
                duration: 9000,
                isClosable: true,
            });

            setSelectedMenu({ ...SelectedMenu, CSreload_tag: new Date().getTime() });
        },
        onError: (error) => {
            toast({
                title: "오류가 발생했습니다.",
                description: "다시 시도해 주세요.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
        }
    });

    const handleDataFromChild = (InputIvdUserOptin: any) => {
        // 수정데이터를 JSON 문자열로 변환
        const ivd_login_rates = JSON.stringify(InputIvdUserOptin.IvdUserOptin);

        let putCSIdvOptionParams = {
            IdvOpthon_pk: InputIvdUserOptin.targetIvdOptin.pk,
            siteInfo_pk: InputIvdUserOptin.targetIvdOptin.siteInfo,
            company_name: InputIvdUserOptin.targetIvdOptin.company_name,
            start_date: InputIvdUserOptin.targetIvdOptin.start_date,
            end_date: InputIvdUserOptin.targetIvdOptin.end_date,
            login_show_optin: InputIvdUserOptin.targetIvdOptin.login_show_optin,
            share_show_option: InputIvdUserOptin.targetIvdOptin.share_show_option,
            user_show_option: InputIvdUserOptin.targetIvdOptin.user_show_option,
            r_rate: InputIvdUserOptin.targetIvdOptin.r_rate,
            casino_login_rate: InputIvdUserOptin.targetIvdOptin.casino_login_rate,
            casino_share_rate: InputIvdUserOptin.targetIvdOptin.casino_share_rate,
            casino_activate: InputIvdUserOptin.targetIvdOptin.casino_activate,
            slot_login_rate: InputIvdUserOptin.targetIvdOptin.slot_login_rate,
            slot_share_rate: InputIvdUserOptin.targetIvdOptin.slot_share_rate,
            slot_activate: InputIvdUserOptin.targetIvdOptin.slot_activate,
            holdem_login_rate: InputIvdUserOptin.targetIvdOptin.holdem_login_rate,
            holdem_share_rate: InputIvdUserOptin.targetIvdOptin.holdem_share_rate,
            holdem_activate: InputIvdUserOptin.targetIvdOptin.holdem_activate,
            idv_login_rates: ivd_login_rates,
        }

        putCSIdvOptionMutation.mutateAsync(putCSIdvOptionParams);
    };

    // UserSharData 만든다.
    const [SharDataProps, setSharDataProps] = useState<any>();

    // const SharDataProps = useRef() as any;

    useEffect(() => {
        if (!dataReady.current) {
            return;
        }
        if (!TreeDataProps) {
            return;
        }

        setSharDataProps(GetUserSharDataProps(TreeDataProps, UserTreeDataProps.current.IvdOptin));

    }, [dataReady.current]);


    // 테마 관련 코드 
    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    return (
        <Grid padding={"0.2rem"}
            h='100%'
            templateColumns='repeat(10, 1fr)'
            templateRows='repeat(10, 1fr)'
            gap={1}
        >
            <GridItem borderRadius={"xl"} paddingX={"0.5rem"} colSpan={10} rowSpan={1}>
                <SiteComDayDayComOptTopSelect />
            </GridItem>

            {(SelectedMenu.site.pk === -1 || SelectedMenu.CSCom.pk === -1) && (
                <GridItem colSpan={10} rowSpan={10} alignItems={"center"} justifyContent={"center"}>
                    <Center margin={10}>업체를 등록하여 선택해 주세요.. </Center>
                </GridItem>
            )}


            {(SelectedMenu.site.pk !== -1 && SelectedMenu.CSCom.pk !== -1) && (
                <>
                    <GridItem paddingX={"0.5rem"} colSpan={7} rowSpan={9} alignItems={"start"} paddingTop={"0.5rem"}>
                        <Flex
                            flexDirection={'column'}
                            w={'100%'}
                            h={'100%'}
                            alignItems={'center'}
                            gap={8}
                        >
                            {TreeDataProps && (
                                (TreeDataProps.UserTreeDatas.length > 0) ?
                                    (
                                        <UserTreeTable
                                            site_name={TreeDataProps.site_name}
                                            company_name={TreeDataProps.company_name}
                                            start_date={TreeDataProps.start_date}
                                            end_date={TreeDataProps.end_date}
                                            UserTreeDatas={TreeDataProps.UserTreeDatas}
                                            UserAtomData={TreeDataProps.UserAtomData}
                                            IvdOptin={TreeDataProps.IvdOptin}
                                            KeyIvdOptin={TreeDataProps.KeyIvdOptin}
                                            onSendData={handleDataFromChild}
                                        />
                                    ) : (
                                        <UserTreeTableNoData
                                            site_name={TreeDataProps.site_name}
                                            company_name={TreeDataProps.company_name}
                                            start_date={TreeDataProps.start_date}
                                            end_date={TreeDataProps.end_date}
                                        />
                                    )
                            )}
                        </Flex>
                    </GridItem>
                    <GridItem paddingX={"0.5rem"} colSpan={3} rowSpan={9} alignItems={"start"} paddingTop={"0.5rem"}>
                        <Flex
                            flexDirection={'column'}
                            w={'100%'} h={'100%'}
                            alignItems={'center'}
                            gap={8}
                            overflowY={"auto"}
                            css={{
                                '&::-webkit-scrollbar': {
                                    width: '8px',
                                    backgroundColor: scrollBarColor,
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    backgroundColor: scrollBarThumbColor,
                                    borderRadius: '4px',
                                },
                                '&:hover::-webkit-scrollbar-thumb': {
                                    backgroundColor: scrollBarThumbHoverColor,
                                },
                            }}>
                            {SharDataProps && <UserSharTable UserSharData={SharDataProps.UserSharData} UserShartitle={SharDataProps.UserShartitle} IvdOptin={SharDataProps.IvdOptin} onSendData={handleDataFromChild} />}
                        </Flex>
                    </GridItem>

                </>
            )}


        </Grid>
    )
}