import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, Button, Center, Flex, Grid, GridItem, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Radio, RadioGroup, Stack, Switch, Text, useColorModeValue, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import { SubmitHandler, useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { deleteCSComInfo, getCSComInfoDetail, putCSComInfo } from "../../../../api/cscominfo";
import { useEffect, useRef } from "react";
import { set } from "lodash";

// Modal 컴포넌트의 Props 타입 정의
interface CSPutRegModalProps {
    isOpen: boolean;
    onClose: () => void;
    comInfo_pk: number;
}

// 폼 입력 데이터 타입 정의
interface FormData {
    companyName: string;
    login_show_optin: string;
    share_show_option: string;
    user_show_option: string;
    r_rate: number;
    casino_login_rate: number;
    casino_share_rate: number;
    casino_activate: boolean;
    slot_login_rate: number;
    slot_share_rate: number;
    slot_activate: boolean;
    holdem_login_rate: number;
    holdem_share_rate: number;
    holdem_activate: boolean;
}

export default function CSPutRegModal({ isOpen, onClose, comInfo_pk }: CSPutRegModalProps) {
    // 사이트 옵션 목록 정보 가져오기
    const toast = useToast();
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)


    // comInfo_pk 값으로 옵션 데이터 가져오기
    const getCSComInfoDetailMutation = useMutation(getCSComInfoDetail, {
        onSuccess: (data) => {
            setValue("companyName", data.company_name);
            setValue("login_show_optin", data.login_show_optin);
            setValue("share_show_option", data.share_show_option);
            setValue("user_show_option", data.user_show_option);
            setValue("r_rate", data.r_rate);
            setValue("casino_login_rate", data.casino_login_rate);
            setValue("casino_share_rate", data.casino_share_rate);
            setValue("casino_activate", data.casino_activate);
            setValue("slot_login_rate", data.slot_login_rate);
            setValue("slot_share_rate", data.slot_share_rate);
            setValue("slot_activate", data.slot_activate);
            setValue("holdem_login_rate", data.holdem_login_rate);
            setValue("holdem_share_rate", data.holdem_share_rate);
            setValue("holdem_activate", data.holdem_activate);
        }
    });

    useEffect(() => {
        if (comInfo_pk !== -1) {  // comInfo_pk가 -1이 아닌 경우에만 API 호출
            getCSComInfoDetailMutation.mutate({ comInfo_pk });
        }
    }, [isOpen, comInfo_pk]);


    // useForm 초기화
    const { register, handleSubmit, formState, reset, setValue, watch } = useForm<FormData>({
        mode: "onBlur",
        defaultValues: {

        },
    });

    // 입력값 수정
    const putCSIdvOptionMutation = useMutation(putCSComInfo, {
        onSuccess: (data: any) => {
            toast({
                position: 'top',
                title: "업체 등록 성공! ",
                description: `${data.company_name} 업체가 등록되었습니다.`,
                status: "info",
                isClosable: true,
            });

            setSelectedMenu({ ...SelectedMenu, reload_tag: new Date().getTime() });
            getCSComInfoDetailMutation.mutate({ comInfo_pk });

            reset();
            onClose();  // 모달 닫기
        },
        onError: (data: any) => {
            toast({
                position: 'top',
                title: "업체 등록 실패! ",
                description: "관리자에게 문의해 주세요.",
                status: "error",
                isClosable: true,
            });

            reset();
            onClose();  // 모달 닫기
        }
    });



    // 폼 제출 핸들러 타입 정의
    const onSubmit: SubmitHandler<FormData> = (data) => {

        const postPBComInfoParmas = {
            comInfo_pk: comInfo_pk,
            siteInfo_pk: SelectedMenu.site.pk,
            company_name: data.companyName,
            login_show_optin: data.login_show_optin,
            share_show_option: data.share_show_option,
            user_show_option: data.user_show_option,
            r_rate: data.r_rate,
            casino_login_rate: data.casino_login_rate,
            casino_share_rate: data.casino_share_rate,
            casino_activate: data.casino_activate,
            slot_login_rate: data.slot_login_rate,
            slot_share_rate: data.slot_share_rate,
            slot_activate: data.slot_activate,
            holdem_login_rate: data.holdem_login_rate,
            holdem_share_rate: data.holdem_share_rate,
            holdem_activate: data.holdem_activate,
        };


        putCSIdvOptionMutation.mutate(postPBComInfoParmas);
    };


    // 삭제 컨펌 창 관련
    const target_del_com_name = useRef<string>("");

    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose
    } = useDisclosure();

    const cancelRef = useRef<HTMLButtonElement>(null);

    const deleteCSIdvOptionMutation = useMutation(deleteCSComInfo, {
        onSuccess: (data: any) => {
            toast({
                position: 'top',
                title: "옵션 삭제 성공! ",
                description: `${target_del_com_name.current} 업체가 삭제되었습니다.`,
                status: "info",
                isClosable: true,
            });

            target_del_com_name.current = "";
            onDeleteClose();
            onClose();

        },
        onError: (data: any) => {
            toast({
                position: 'top',
                title: "옵션 삭제 실패! ",
                description: "관리자에게 문의해 주세요.",
                status: "error",
                isClosable: true,
            });

            onDeleteClose();
            onClose();
        }
    });

    const handleDelete = () => {

        target_del_com_name.current = watch("companyName");
        deleteCSIdvOptionMutation.mutate({ comInfo_pk });

    };


    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);
    const errorColor = useColorModeValue(lightTheme_colors['errorColor'], darkTheme_colors['errorColor']);
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent bg={inputBgColor}>
                <ModalHeader>업체 옵션 수정/삭제</ModalHeader>
                <ModalCloseButton />
                <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
                    <VStack spacing={6} align="stretch">
                        <Grid templateColumns="repeat(2, 1fr)" gap={4}>

                            {/* 업체 이름 */}
                            <GridItem>
                                <VStack align="stretch" spacing={2}>
                                    <Text fontSize="md" fontWeight="bold">업체 이름</Text>
                                    <Input
                                        placeholder="업체명 입력"
                                        {...register("companyName", { required: "업체명을 입력하세요" })}
                                    />
                                    {formState.errors.companyName && <Center color={errorColor}>{formState.errors.companyName?.message}</Center>}
                                </VStack>
                            </GridItem>
                            <GridItem>
                                <VStack align="stretch" spacing={2}>
                                    <Text fontSize="md" fontWeight="bold">알값</Text>
                                    <Input
                                        placeholder="알값 %"
                                        type="number"
                                        step="0.1"
                                        min="0"  // 0보다 작은 값 입력 불가
                                        {...register("r_rate", { required: "알값%를 입력하세요" })}
                                    />
                                    {formState.errors.casino_login_rate && (
                                        <Text color="red.500">{formState.errors.r_rate?.message}</Text>
                                    )}
                                </VStack>
                            </GridItem>
                        </Grid>

                        {/* 루징 조건, 비율 입력 및 사용자 노출 타입 */}
                        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                            <GridItem>
                                <VStack align="stretch" spacing={4}>
                                    <Text fontSize="md" fontWeight="bold">루징 조건</Text>
                                    <RadioGroup
                                        onChange={(value) => setValue("login_show_optin", value)}
                                        value={watch("login_show_optin")}  // 서버에서 불러온 값 반영
                                    >
                                        <Stack direction="column">
                                            <Radio value="month">월말 루징 정산</Radio>
                                            <Radio value="month15">15일 루징 정산</Radio>
                                            <Radio value="month10">10일 루징 정산</Radio>
                                        </Stack>
                                    </RadioGroup>
                                    {formState.errors.login_show_optin && (
                                        <Text color="red.500">{formState.errors.login_show_optin.message}</Text>
                                    )}
                                </VStack>
                            </GridItem>

                            <GridItem>
                                <VStack align="stretch" spacing={4}>
                                    <Text fontSize="md" fontWeight="bold">카지노/슬롯/홀덤 비율 입력</Text>

                                    <HStack justifyContent="space-between">
                                        <Text flex="3">카지노 루징률 (%)</Text>
                                        <Input
                                            flex="2"
                                            placeholder="카지노 루징률 %"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            defaultValue={0}  // 기본값 0 설정
                                            {...register("casino_login_rate", { required: "카지노 루징률을 입력하세요" })}
                                        />
                                    </HStack>
                                    {formState.errors.casino_login_rate && (
                                        <Text color="red.500">{formState.errors.casino_login_rate.message}</Text>
                                    )}

                                    <HStack justifyContent="space-between">
                                        <Text flex="3">슬롯 루징률 (%)</Text>
                                        <Input
                                            flex="2"
                                            placeholder="슬롯 루징률 %"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            defaultValue={0}  // 기본값 0 설정
                                            {...register("slot_login_rate", { required: "슬롯 루징률을 입력하세요" })}
                                        />
                                    </HStack>
                                    {formState.errors.slot_login_rate && (
                                        <Text color="red.500">{formState.errors.slot_login_rate.message}</Text>
                                    )}

                                    <HStack justifyContent="space-between">
                                        <Text flex="3">홀덤 루징률 (%)</Text>
                                        <Input
                                            flex="2"
                                            placeholder="홀덤 루징률 %"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            defaultValue={0}  // 기본값 0 설정
                                            {...register("holdem_login_rate", { required: "홀덤 루징률을 입력하세요" })}
                                        />
                                    </HStack>
                                    {formState.errors.holdem_login_rate && (
                                        <Text color="red.500">{formState.errors.holdem_login_rate.message}</Text>
                                    )}
                                </VStack>
                            </GridItem>

                        </Grid>

                        {/* 사용자 노출 타입 */}
                        <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                            <GridItem>
                                <VStack align="stretch" spacing={4}>
                                    <Text fontSize="md" fontWeight="bold">루징표 노출 타입</Text>
                                    <RadioGroup defaultValue="under">
                                        <Stack direction="column">
                                            <Radio value="only" {...register("user_show_option", { required: "노출 타입을 선택하세요" })}>
                                                종합 only
                                            </Radio>
                                            <Radio value="under" {...register("user_show_option")}>
                                                종합 본인 + 직하부 회원
                                            </Radio>
                                            <Radio value="all" {...register("user_show_option")}>
                                                종합 본인 + 하루 전체 회원
                                            </Radio>
                                        </Stack>
                                        {formState.errors.user_show_option && (
                                            <Text color="red.500">{formState.errors.user_show_option.message}</Text>
                                        )}
                                    </RadioGroup>
                                </VStack>
                            </GridItem>
                            <GridItem>
                                <VStack align="stretch" spacing={4}>
                                    <Text fontSize="md" fontWeight="bold">화면 활성화</Text>
                                    <Stack direction="column">
                                        <Flex justifyContent="space-around">
                                            <Text flex="2">카지노 활성화</Text>
                                            <Switch
                                                flex="1"
                                                colorScheme="blue"
                                                {...register("casino_activate")}
                                                isChecked={watch("casino_activate")}
                                            />
                                        </Flex>
                                        <Flex justifyContent="space-around">
                                            <Text flex="2">슬롯 활성화</Text>
                                            <Switch
                                                flex="1"
                                                colorScheme="blue"
                                                {...register("slot_activate")}
                                                isChecked={watch("slot_activate")}
                                            />
                                        </Flex>
                                        <Flex justifyContent="space-around">
                                            <Text flex="2">홀덤 활성화</Text>
                                            <Switch
                                                flex="1"
                                                colorScheme="blue"
                                                {...register("holdem_activate")}
                                                isChecked={watch("holdem_activate")}
                                            />
                                        </Flex>
                                    </Stack>
                                </VStack>
                            </GridItem>
                        </Grid>

                        {/* 쉐어 조건 및 비율 입력 */}
                        <Grid templateColumns="repeat(2, 1fr)" gap={4} pt={"2rem"}>
                            <GridItem>
                                <VStack align="stretch" spacing={4}>
                                    <Text fontSize="md" fontWeight="bold">쉐어 조건</Text>
                                    <RadioGroup
                                        onChange={(value) => setValue("share_show_option", value)}
                                        value={watch("share_show_option")}  // 서버에서 불러온 값 반영
                                    >
                                        <Stack direction="column">
                                            <Radio value="month">월말 쉐어 정산</Radio>
                                            <Radio value="month15">15일 쉐어 정산</Radio>
                                            <Radio value="month10">10일 쉐어 정산</Radio>
                                            <Radio value="disable">표시 안함</Radio>
                                        </Stack>
                                    </RadioGroup>
                                    {formState.errors.share_show_option && (
                                        <Text color="red.500">{formState.errors.share_show_option.message}</Text>
                                    )}
                                </VStack>
                            </GridItem>

                            <GridItem>
                                <VStack align="stretch" spacing={4}>
                                    <Text fontSize="md" fontWeight="bold">쉐어율 입력</Text>

                                    <HStack justifyContent="space-between">
                                        <Text flex="3">카지노 쉐어율 (%)</Text>
                                        <Input
                                            flex="2"
                                            placeholder="카지노 쉐어율 %"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            defaultValue={0}  // 기본값 0 설정
                                            {...register("casino_share_rate", { required: "카지노 쉐어율을 입력하세요" })}
                                        />
                                    </HStack>
                                    {formState.errors.casino_share_rate && (
                                        <Text color="red.500">{formState.errors.casino_share_rate.message}</Text>
                                    )}

                                    <HStack justifyContent="space-between">
                                        <Text flex="3">슬롯 쉐어율 (%)</Text>
                                        <Input
                                            flex="2"
                                            placeholder="슬롯 쉐어율 %"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            defaultValue={0}  // 기본값 0 설정
                                            {...register("slot_share_rate", { required: "슬롯 쉐어율을 입력하세요" })}
                                        />
                                    </HStack>
                                    {formState.errors.slot_share_rate && (
                                        <Text color="red.500">{formState.errors.slot_share_rate.message}</Text>
                                    )}

                                    <HStack justifyContent="space-between">
                                        <Text flex="3">홀덤 쉐어율 (%)</Text>
                                        <Input
                                            flex="2"
                                            placeholder="홀덤 쉐어율 %"
                                            type="number"
                                            step="0.01"
                                            min="0"
                                            defaultValue={0}  // 기본값 0 설정
                                            {...register("holdem_share_rate", { required: "홀덤 쉐어율을 입력하세요" })}
                                        />
                                    </HStack>
                                    {formState.errors.holdem_share_rate && (
                                        <Text color="red.500">{formState.errors.holdem_share_rate.message}</Text>
                                    )}
                                </VStack>
                            </GridItem>

                        </Grid>
                    </VStack>
                    <ModalFooter>
                        <Flex justifyContent="space-between" w="100%">
                            <Button colorScheme="red" onClick={onDeleteOpen}>삭제</Button>
                            <Flex>
                                <Button colorScheme="blue" mr={3} type="submit">
                                    옵션 수정
                                </Button>
                                <Button variant="ghost" onClick={onClose}>취소</Button>
                            </Flex>
                        </Flex>
                    </ModalFooter>
                </ModalBody>
            </ModalContent>
            <AlertDialog
                isOpen={isDeleteOpen}
                leastDestructiveRef={cancelRef}
                onClose={onDeleteClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            삭제 확인
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Center>정말로 삭제하시겠습니까?</Center>
                            <Center>삭제된 데이터는 복구할 수 없습니다.</Center>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onDeleteClose}>
                                취소
                            </Button>
                            <Button colorScheme="red" onClick={handleDelete} ml={3}>
                                삭제
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Modal>
    );
}
