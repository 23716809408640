import _ from 'lodash';

interface DataItem {
    user_id: string;
    user_nickname: string;
    status: string;
    status_list: string[];
    status_id_lenth: number;
    status_id_list: string[];
    [key: string]: number | string | string[];
}

export function getCombinedData(datalistNo1: DataItem[], datalistNo2: DataItem[]): DataItem[] {
    /*
    no2의 데이터를 no1에 합친다. 
    python 코드에서 변환함. 
    */
    if (datalistNo1.length === 0) {
        return _.cloneDeep(datalistNo2);
    }

    const returnCombinedDataList = _.cloneDeep(datalistNo1);
    const copyDictDataList2 = _.cloneDeep(datalistNo2);

    const dictDataList2KeyList = Object.keys(datalistNo2[0]);

    const statusIdLengthList = copyDictDataList2.map(x => x.status_id_lenth);
    const maxStatusIdLength = Math.max(...statusIdLengthList);

    for (let idLength = 0; idLength <= maxStatusIdLength; idLength++) {
        for (const targetData of copyDictDataList2) {
            if (targetData.status_id_lenth === idLength) {
                let breakTag = false;
                for (let index = 0; index < returnCombinedDataList.length; index++) {
                    const returnData = returnCombinedDataList[index];

                    if (returnData.user_id === targetData.user_id) {
                        for (let keyIndex = 6; keyIndex < dictDataList2KeyList.length; keyIndex++) {
                            const key = dictDataList2KeyList[keyIndex];
                            if (typeof returnData[key] === 'number' && typeof targetData[key] === 'number') {
                                returnCombinedDataList[index][key] = (returnCombinedDataList[index][key] as number) + (targetData[key] as number);
                            }
                        }
                        break;
                    }

                    if (index === returnCombinedDataList.length - 1) {
                        if (targetData.status_id_list.length === 0) {
                            returnCombinedDataList.push(targetData);
                            breakTag = true;
                            break;
                        } else {
                            for (let i = 0; i < returnCombinedDataList.length; i++) {
                                const targetCombinedData = returnCombinedDataList[i];
                                if (targetData.status_id_list[targetData.status_id_list.length - 1] === targetCombinedData.user_id) {
                                    returnCombinedDataList.splice(i + 1, 0, targetData);
                                    breakTag = true;
                                    break;
                                }
                            }
                        }
                    }

                    if (breakTag) {
                        break;
                    }
                }
            }
        }
    }

    return returnCombinedDataList;
}

export function getAtomCombinedDataTypeMon(dataList: any): any {
    /*
        전체 합산데이터를구한다.(month 데이터가 전체 합산데이터임)
    */
    let atom_combined_data_type_month = [] as DataItem[];
    let atom_combined_data_type_day_list = [];

    for (let i = 0; i < dataList.length; i++) {
        let atom_data = JSON.parse(dataList[i]['data']);

        atom_combined_data_type_month = getCombinedData(atom_combined_data_type_month, atom_data);
        atom_combined_data_type_day_list.push(dataList[i]['target_date']);
    }

    // console.log('atom_combined_data_type_day_list', atom_combined_data_type_day_list);
    // console.log('atom_combined_data_type_month', atom_combined_data_type_month);

    return {
        atom_combined_data_type_month: atom_combined_data_type_month,
        atom_combined_data_type_day_list: atom_combined_data_type_day_list
    }

}

export function getAtomCombinedDataType15AndFilenameList(atomDataList: any[]): {
    atom_combined_data_type_15_01: DataItem[],
    atom_combined_data_type_15_02: DataItem[],
    atom_combined_data_type_15_01_filename_list: string[],
    atom_combined_data_type_15_02_filename_list: string[]
} {
    let atom_combined_data_type_15_01: DataItem[] = [];
    let atom_combined_data_type_15_02: DataItem[] = [];
    let atom_combined_data_type_15_01_filename_list: string[] = [];
    let atom_combined_data_type_15_02_filename_list: string[] = [];

    if (atomDataList.length > 15) {
        for (let i = 0; i < 15; i++) {
            let atom_data = JSON.parse(atomDataList[i]['data']);
            atom_combined_data_type_15_01 = getCombinedData(atom_combined_data_type_15_01, atom_data);
            atom_combined_data_type_15_01_filename_list.push(atomDataList[i]['target_date']);
        }
        for (let i = 15; i < atomDataList.length; i++) {
            let atom_data = JSON.parse(atomDataList[i]['data']);
            atom_combined_data_type_15_02 = getCombinedData(atom_combined_data_type_15_02, atom_data);
            atom_combined_data_type_15_02_filename_list.push(atomDataList[i]['target_date']);
        }
    } else {
        for (let i = 0; i < atomDataList.length; i++) {
            let atom_data = JSON.parse(atomDataList[i]['data']);
            atom_combined_data_type_15_01 = getCombinedData(atom_combined_data_type_15_01, atom_data);
            atom_combined_data_type_15_01_filename_list.push(atomDataList[i]['target_date']);
        }
    }

    return {
        atom_combined_data_type_15_01: atom_combined_data_type_15_01,
        atom_combined_data_type_15_02: atom_combined_data_type_15_02,
        atom_combined_data_type_15_01_filename_list: atom_combined_data_type_15_01_filename_list,
        atom_combined_data_type_15_02_filename_list: atom_combined_data_type_15_02_filename_list
    };
}

export function getAtomCombinedDataType10AndFilenameList(atomDataList: any[]): {
    atom_combined_data_type_10_01: DataItem[],
    atom_combined_data_type_10_02: DataItem[],
    atom_combined_data_type_10_03: DataItem[],
    atom_combined_data_type_10_01_filename_list: string[],
    atom_combined_data_type_10_02_filename_list: string[],
    atom_combined_data_type_10_03_filename_list: string[]
} {
    let atom_combined_data_type_10_01: DataItem[] = [];
    let atom_combined_data_type_10_02: DataItem[] = [];
    let atom_combined_data_type_10_03: DataItem[] = [];
    let atom_combined_data_type_10_01_filename_list: string[] = [];
    let atom_combined_data_type_10_02_filename_list: string[] = [];
    let atom_combined_data_type_10_03_filename_list: string[] = [];

    if (atomDataList.length > 20) {
        for (let i = 0; i < 10; i++) {
            let atom_data = JSON.parse(atomDataList[i]['data']);
            atom_combined_data_type_10_01 = getCombinedData(atom_combined_data_type_10_01, atom_data);
            atom_combined_data_type_10_01_filename_list.push(atomDataList[i]['target_date']);
        }
        for (let i = 10; i < 20; i++) {
            let atom_data = JSON.parse(atomDataList[i]['data']);
            atom_combined_data_type_10_02 = getCombinedData(atom_combined_data_type_10_02, atom_data);
            atom_combined_data_type_10_02_filename_list.push(atomDataList[i]['target_date']);
        }
        for (let i = 20; i < atomDataList.length; i++) {
            let atom_data = JSON.parse(atomDataList[i]['data']);
            atom_combined_data_type_10_03 = getCombinedData(atom_combined_data_type_10_03, atom_data);
            atom_combined_data_type_10_03_filename_list.push(atomDataList[i]['target_date']);
        }
    } else if (atomDataList.length > 10) {
        for (let i = 0; i < 10; i++) {
            let atom_data = JSON.parse(atomDataList[i]['data']);
            atom_combined_data_type_10_01 = getCombinedData(atom_combined_data_type_10_01, atom_data);
            atom_combined_data_type_10_01_filename_list.push(atomDataList[i]['target_date']);
        }
        for (let i = 10; i < atomDataList.length; i++) {
            let atom_data = JSON.parse(atomDataList[i]['data']);
            atom_combined_data_type_10_02 = getCombinedData(atom_combined_data_type_10_02, atom_data);
            atom_combined_data_type_10_02_filename_list.push(atomDataList[i]['target_date']);
        }
    } else {
        for (let i = 0; i < atomDataList.length; i++) {
            let atom_data = JSON.parse(atomDataList[i]['data']);
            atom_combined_data_type_10_01 = getCombinedData(atom_combined_data_type_10_01, atom_data);
            atom_combined_data_type_10_01_filename_list.push(atomDataList[i]['target_date']);
        }
    }

    return {
        atom_combined_data_type_10_01: atom_combined_data_type_10_01,
        atom_combined_data_type_10_02: atom_combined_data_type_10_02,
        atom_combined_data_type_10_03: atom_combined_data_type_10_03,
        atom_combined_data_type_10_01_filename_list: atom_combined_data_type_10_01_filename_list,
        atom_combined_data_type_10_02_filename_list: atom_combined_data_type_10_02_filename_list,
        atom_combined_data_type_10_03_filename_list: atom_combined_data_type_10_03_filename_list
    };
}