import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getMonthRangeFromString, IApiData, transformData } from "../../../../lib/util";
import { useMutation } from "@tanstack/react-query";
import { getSiteData } from "../../../../api/sitedata";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";

export function useChargeExchangeData() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);
    const [chargeExchangeData, setChargeExchangeData] = useState<IApiData>({ headers: [], rows: [] });

    const MonthSiteDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                setChargeExchangeData({ headers: [], rows: [] });
                return;
            }

            const dataList = data.map((sitedata: any) => JSON.parse(sitedata.data));
            setChargeExchangeData(transformData(dataList));
        },
        onError: (error) => {
            console.error("사이트 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });

    useEffect(() => {
        const monthRange = getMonthRangeFromString(SelectedMenu.Month);
        if (SelectedMenu.site.pk !== -1 && SelectedMenu.category && SelectedMenu.Month) {
            MonthSiteDataMutation.mutate({
                siteinfo: SelectedMenu.site.pk,
                datatype: "charge_exchange",
                start_date: monthRange[0],
                end_date: monthRange[1],
            });
        }
    }, [SelectedMenu]);

    return chargeExchangeData;
}


const data = {
    headers: [
        'Date', '충전 금액', '환전 금액', '충합 차액'
    ],
    rows: [
        { date: '3/1', deposit: '78,950,000', withdrawal: '199,240,000', difference: '-120,290,000' },
        { date: '3/2', deposit: '85,280,000', withdrawal: '61,850,000', difference: '23,430,000' },
        { date: '3/3', deposit: '76,240,000', withdrawal: '37,250,000', difference: '38,990,000' },
        { date: '3/4', deposit: '44,680,000', withdrawal: '100,400,000', difference: '-55,720,000' },
        { date: '3/5', deposit: '98,700,000', withdrawal: '92,980,000', difference: '5,720,000' },
        { date: '3/6', deposit: '85,460,000', withdrawal: '94,140,000', difference: '-8,680,000' },
        { date: '3/7', deposit: '80,480,000', withdrawal: '125,320,000', difference: '-44,840,000' },
        { date: '3/8', deposit: '193,560,000', withdrawal: '147,000,000', difference: '46,560,000' },
        { date: '3/9', deposit: '130,450,000', withdrawal: '183,000,000', difference: '-52,550,000' },
        { date: '3/10', deposit: '57,290,000', withdrawal: '78,800,000', difference: '-21,510,000' },
        { date: '3/11', deposit: '95,120,000', withdrawal: '118,000,000', difference: '-22,880,000' },
        { date: '3/12', deposit: '139,780,000', withdrawal: '92,470,000', difference: '47,310,000' },
        { date: '3/13', deposit: '128,640,000', withdrawal: '150,000,000', difference: '-21,360,000' },
        { date: '3/14', deposit: '108,430,000', withdrawal: '61,000,000', difference: '47,430,000' },
        { date: '3/15', deposit: '72,380,000', withdrawal: '95,180,000', difference: '-22,800,000' },
        { date: '3/16', deposit: '116,800,000', withdrawal: '105,160,000', difference: '11,640,000' },
        { date: '3/17', deposit: '83,530,000', withdrawal: '114,640,000', difference: '-31,110,000' },
        { date: '3/18', deposit: '128,760,000', withdrawal: '86,120,000', difference: '42,640,000' },
        { date: '3/19', deposit: '41,910,000', withdrawal: '36,340,000', difference: '5,570,000' },
        { date: '3/20', deposit: '86,470,000', withdrawal: '62,070,000', difference: '24,400,000' },
        { date: '3/21', deposit: '68,910,000', withdrawal: '80,000,000', difference: '-11,090,000' },
        { date: '3/22', deposit: '47,380,000', withdrawal: '58,950,000', difference: '-11,570,000' },
        { date: '3/23', deposit: '43,840,000', withdrawal: '50,060,000', difference: '-6,220,000' },
        { date: '3/24', deposit: '64,040,000', withdrawal: '127,500,000', difference: '-63,460,000' },
        { date: '3/25', deposit: '62,430,000', withdrawal: '79,780,000', difference: '-17,350,000' },
        { date: '3/26', deposit: '60,470,000', withdrawal: '37,540,000', difference: '22,930,000' },
        { date: '3/27', deposit: '1,719,873', withdrawal: '1,719,873', difference: '0' },
        { date: '3/28', deposit: '0', withdrawal: '0', difference: '0' },
        { date: '3/29', deposit: '83,897', withdrawal: '30,997', difference: '52,900' },
        { date: '3/30', deposit: '63,934', withdrawal: '30,297', difference: '33,637' },
        { date: '3/31', deposit: '97,120,000', withdrawal: '67,280,000', difference: '29,840,000' },
        { date: 'TOTAL', deposit: '2,000,000,000', withdrawal: '1,800,000,000', difference: '200,000,000' },
    ]
};
