import { Grid, GridItem, Divider, Flex, Center } from "@chakra-ui/react";


import { useRecoilValue } from "recoil";
import { SelectedMenuAtom } from "../../../atoms/topSelect";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { getPBIdbOption } from "../../../api/pvidvoption";
import { getSiteData } from "../../../api/sitedata";
import { getCombinedData } from "../../../lib/conversionData";
import { getMonthRangeFromString } from "../../../lib/util";
import PBBaseTable, { IPBLosingShareData, PBLosingShareDefaultData } from "../CommContents/PBContents/PBTable";
import PBSttlementTable, { ISettlementShareData, ISettlementTableProps, SettlementTableDefaultData } from "../PBLosingShreComm/Contents/SettlementTable";
import SiteComMonComOptTopSelect from "../CommContents/TopSelect/SiteComMonComOptTopSelect";
import { filteredOptionList, getLoginShareValues, getRowAndColTypes, sumSelectedValues } from "../PBLosingShreComm/util/util";


export default function PBLosingShreMontly() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);


    const [LoginData, setLoginData] = useState<IPBLosingShareData>(PBLosingShareDefaultData);
    const [ShareData, setShareData] = useState<IPBLosingShareData>(PBLosingShareDefaultData);

    const [SettlementData, setSettlementData] = useState<ISettlementTableProps>(SettlementTableDefaultData);

    const SelectedLoginOption = useRef([] as string[]);
    const SelectedShareOption = useRef([] as string[]);


    // 개별옵션 불러온다. (기본옵션 처리는 서버에서 처리한다.)
    const getPBIdvOptionMutation = useMutation(getPBIdbOption, {
        onSuccess: (data) => {
            const login_rate = data.login_rate;
            SelectedLoginOption.current = JSON.parse(data.selected_login_list);

            const share_rate = data.share_rate;
            SelectedShareOption.current = JSON.parse(data.selected_share_list);

            setLoginData({
                site_name: SelectedMenu.site.name,
                company_name: SelectedMenu.PBCom.name,
                category: "루징금",
                start_date: data.start_date,
                end_date: data.end_date,
                rowTypes: LoginData.rowTypes,
                colTypes: LoginData.colTypes,
                rate: login_rate,
                selectedOptions: SelectedLoginOption.current,
                values: LoginData.values,
                up_total: false,
                total: LoginData.total
            });

            setShareData({
                site_name: SelectedMenu.site.name,
                company_name: SelectedMenu.PBCom.name,
                category: "쉐어금",
                start_date: data.start_date,
                end_date: data.end_date,
                rowTypes: ShareData.rowTypes,
                colTypes: ShareData.colTypes,
                rate: share_rate,
                selectedOptions: SelectedShareOption.current,
                values: ShareData.values,
                up_total: false,
                total: ShareData.total
            });

            setSettlementData({
                site_name: SelectedMenu.site.name,
                company_name: SelectedMenu.PBCom.name,
                start_date: data.start_date,
                end_date: data.end_date,
                profit_data: [],
                loss_data: [],
                profit_total: 0,
                loss_total: 0,
                finalAmount: 0
            });
        }
    });


    // 원자 데이터 불러온다.
    const atomDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            let re_atom_data_list = [] as any;
            data.forEach((item: any) => {
                re_atom_data_list.push(JSON.parse(item.data));
            });
            //re_atom_data_list  리스트를 역순으로 뒤집는다. 
            re_atom_data_list = re_atom_data_list.reverse();

            // 역순으로 뒤집어서 한달치 데이터를 합친다. 
            let atom_combined_data_type_month = [] as any;
            for (const atom_data of re_atom_data_list) {
                atom_combined_data_type_month = getCombinedData(atom_combined_data_type_month, atom_data);
            }

            // 합친 데이터에서. user_id 가 SelectedMenu.company_name 인 데이터와 status_id_list 에 SelectedMenu.company_name 이 있는 데이터를 찾는다.
            let targetData = atom_combined_data_type_month.filter((item: any) => item.user_id === SelectedMenu.PBCom.name || item.status_id_list.includes(SelectedMenu.PBCom.name));

            // 찾은 데이터를 이용하여 LoginShareValues 로 만든다. 
            const LoginShareValues = getLoginShareValues(targetData);

            // rowTypes, colTypes 를 구한다.
            const { rowTypes, colTypes } = getRowAndColTypes(LoginShareValues);

            // Total 값을 구한다.
            const LoginTotel = sumSelectedValues(LoginShareValues, LoginData.selectedOptions);
            const ShareTotal = sumSelectedValues(LoginShareValues, ShareData.selectedOptions);


            // 옵션을 없는거 선택된 옵션을 제거한다. ( 동행사다리 3조합 같은거.)
            const filteredLoginOptions = filteredOptionList(LoginShareValues, LoginData.selectedOptions);
            const filteredShareOptions = filteredOptionList(LoginShareValues, ShareData.selectedOptions);

            // 리무진 특별처리. up_fee 가 있으면 리무진 데이터이다. 
            let up_total: boolean | number = false;
            if (targetData.length > 0 && Object.keys(targetData[0]).some(key => key.includes("up_fee_"))) {
                const pb_bet_파워볼_게임합_sum = targetData.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.pb_bat_파워볼_게임합 || 0), 0);
                const pb_win_파워볼_게임합_sum = targetData.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.pb_win_파워볼_게임합 || 0), 0);
                const pb_fee_파워볼_게임합_sum = targetData.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.pb_fee_파워볼_게임합 || 0), 0);
                const up_fee_파워볼 = targetData.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.up_fee_파워볼_ || 0), 0);

                up_total = pb_bet_파워볼_게임합_sum - pb_win_파워볼_게임합_sum - pb_fee_파워볼_게임합_sum - up_fee_파워볼;
            }

            setLoginData({
                site_name: SelectedMenu.site.name,
                company_name: SelectedMenu.PBCom.name,
                start_date: LoginData.start_date,
                end_date: LoginData.end_date,
                category: LoginData.category,
                rowTypes: rowTypes,
                colTypes: colTypes,
                rate: LoginData.rate,
                selectedOptions: filteredLoginOptions,
                values: LoginShareValues,
                up_total: up_total,
                total: LoginTotel
            });

            setShareData({
                site_name: SelectedMenu.site.name,
                company_name: SelectedMenu.PBCom.name,
                start_date: ShareData.start_date,
                end_date: ShareData.end_date,
                category: ShareData.category,
                rowTypes: rowTypes,
                colTypes: colTypes,
                rate: ShareData.rate,
                selectedOptions: filteredShareOptions,
                values: LoginShareValues,
                up_total: up_total,
                total: ShareTotal
            });

            // 손익항목 만든다. 
            let PBShareLossData = {
                item: "파워볼 루징금",
                amount: Math.round(LoginTotel * (LoginData.rate / 100)),
                shareRate: ShareData.rate,
                shareAmount: Math.round(LoginTotel * (LoginData.rate / 100) * (ShareData.rate / 100)),
            } as ISettlementShareData;

            let PBShareProfitData = {
                item: "파워볼 손익금",
                amount: ShareTotal,
                shareRate: ShareData.rate,
                shareAmount: Math.round(ShareTotal * (ShareData.rate / 100)),
            } as ISettlementShareData;


            setSettlementData({
                site_name: SettlementData.site_name,
                company_name: SettlementData.company_name,
                start_date: SettlementData.start_date,
                end_date: SettlementData.end_date,
                profit_data: [PBShareLossData],
                loss_data: [PBShareProfitData],
                profit_total: PBShareProfitData.shareAmount,
                loss_total: PBShareLossData.shareAmount,
                finalAmount: PBShareProfitData.shareAmount - PBShareLossData.shareAmount
            });

        }
    });


    useEffect(() => {
        if (SelectedMenu.site.pk === -1 || SelectedMenu.PBCom.pk === -1) {
            return;
        }
        const date_list = getMonthRangeFromString(SelectedMenu.Month);

        const getPBidvoptionparams = {
            siteInfo_pk: SelectedMenu.site.pk,
            company_name: SelectedMenu.PBCom.name,
            start_date: date_list[0],
            end_date: date_list[1],
        }

        const getAtomdataparams = {
            siteinfo: SelectedMenu.site.pk,
            datatype: "atom",
            start_date: date_list[0],
            end_date: date_list[1],
        }

        getPBIdvOptionMutation.mutate(getPBidvoptionparams);

        atomDataMutation.mutate(getAtomdataparams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedMenu]);


    // 테마 관련 코드 

    return (
        <Grid padding={"0.2rem"}
            h='100%'
            templateColumns='repeat(10, 1fr)'
            templateRows='repeat(10, 1fr)'
            gap={1}
        >
            <GridItem borderRadius={"xl"} paddingX={"0.5rem"} colSpan={10} rowSpan={1}>
                <SiteComMonComOptTopSelect />
            </GridItem>

            {(SelectedMenu.site.pk === -1 || SelectedMenu.PBCom.pk === -1) && (
                <GridItem colSpan={10} rowSpan={10} alignItems={"center"} justifyContent={"center"}>
                    <Center margin={10}>업체를 등록하여 선택해 주세요.. </Center>
                </GridItem>
            )}

            {(SelectedMenu.site.pk !== -1 && SelectedMenu.PBCom.pk !== -1) && (
                <>
                    <GridItem paddingX={"0.5rem"} colSpan={7} rowSpan={9} alignItems={"start"} paddingTop={"0.5rem"}>
                        <Flex
                            flexDirection={'column'}
                            w={'100%'}
                            h={'100%'}
                            alignItems={'center'}
                            gap={8}
                        >
                            <PBBaseTable  {...LoginData} />

                            {SelectedShareOption.current.length > 0 && (
                                <Divider borderColor="gray.200" w="100%" />
                            )}

                            {SelectedShareOption.current.length > 0 && (
                                <PBBaseTable  {...ShareData} />
                            )}


                        </Flex>
                    </GridItem>

                    <GridItem paddingX={"0.5rem"} colSpan={3} rowSpan={9} alignItems={"start"} paddingTop={"0.5rem"}>

                        {SelectedShareOption.current.length > 0 && (
                            <PBSttlementTable {...SettlementData} />
                        )}
                    </GridItem>
                </>
            )}
        </Grid>
    )
}



/*
{
    "EOS-3분_단폴": 0,
    "EOS-3분_조합": 0,
    "EOS-3분_3조합": 0,
    "EOS-5분_단폴": -94611,
    "EOS-5분_조합": 0,
    "EOS-5분_3조합": 0,
    "코인-3분_단폴": -71490,
    "코인-3분_조합": 0,
    "코인-3분_3조합": 0,
    "코인-5분_단폴": 0,
    "코인-5분_조합": 0,
    "코인-5분_3조합": 0,
    "더블유-3분_단폴": 0,
    "더블유-3분_조합": 0,
    "더블유-3분_3조합": 0,
    "PBG파워볼_단폴": 0,
    "PBG파워볼_조합": 0,
    "PBG파워볼_3조합": 0,
    "동행파워볼_단폴": 0,
    "동행파워볼_조합": 0,
    "동행파워볼_3조합": 0,
    "EVO파워볼-2턴_단폴": 0,
    "EVO파워볼-2턴_조합": 0,
    "EVO파워볼-2턴_3조합": 0,
    "EVO파워볼-3턴_단폴": 0,
    "EVO파워볼-3턴_조합": 0,
    "EVO파워볼-3턴_3조합": 0,
    "코인사다리-3분_단폴": 0,
    "코인사다리-3분_조합": 0,
    "코인사다리-5분_단폴": 0,
    "코인사다리-5분_조합": 0,
    "동행사다리_단폴": 0,
    "동행사다리_조합": 0,
    "스피드키노_단폴": 0,
    "스피드키노_조합": 0
}
[
    "EOS-3분_단폴",
    "EOS-5분_단폴",
    "코인-3분_단폴",
    "코인-5분_단폴",
    "더블유-3분_단폴",
    "PBG파워볼_단폴",
    "동행파워볼_단폴",
    "EVO파워볼-2턴_단폴",
    "EVO파워볼-3턴_단폴",
    "코인사다리-3분_단폴",
    "코인사다리-5분_단폴",
    "동행사다리_단폴",
    "스피드키노_단폴",
    "스피드키노_조합",
    "동행사다리_조합",
    "코인사다리-5분_조합",
    "코인사다리-3분_조합",
    "EVO파워볼-3턴_조합",
    "EVO파워볼-2턴_조합",
    "동행파워볼_조합",
    "PBG파워볼_조합",
    "더블유-3분_조합",
    "코인-5분_조합",
    "코인-3분_조합",
    "EOS-5분_조합",
    "EOS-3분_조합",
    "EOS-3분_3조합",
    "EOS-5분_3조합",
    "코인-3분_3조합",
    "코인-5분_3조합",
    "더블유-3분_3조합",
    "PBG파워볼_3조합",
    "동행파워볼_3조합",
    "EVO파워볼-2턴_3조합",
    "EVO파워볼-3턴_3조합",
    "코인사다리-3분_3조합",
    "코인사다리-5분_3조합",
    "동행사다리_3조합",
    "스피드키노_3조합"
]
*/