import { Box, useColorModeValue } from "@chakra-ui/react";
import { useSwipeable } from "react-swipeable";
import { useState } from "react";
import { motion } from "framer-motion"; // Framer Motion import
import { useRecoilValue } from "recoil";
import { SelectedMenuAtom } from "../../../atoms/topSelect";
import { darkTheme_colors, lightTheme_colors } from "../../../theme";
import MDailyContentsTable from "./contents/MDailyContentsTable";
import MDailyTop5Table from "./contents/MDailyTop5Table";


export default function MSiteDailySettlement() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    const [page, setPage] = useState(0);

    const pages = [
        <MDailyContentsTable />,
        <MDailyTop5Table />,
    ];

    const handleSwipe = useSwipeable({
        onSwipedLeft: () => {
            if (page < pages.length - 1) {
                setPage((prev) => prev + 1);
            }
        },
        onSwipedRight: () => {
            if (page > 0) {
                setPage((prev) => prev - 1);
            }
        },
        trackMouse: true,
    });

    const bgColor = useColorModeValue(lightTheme_colors["bgColor"], darkTheme_colors["bgColor"]);
    const mainBgColor = useColorModeValue(lightTheme_colors.mainBgColor, darkTheme_colors.mainBgColor);
    return (
        <Box
            {...handleSwipe}
            display="flex"
            overflow="hidden" // 스크롤바 제거
            height="100%" // 부모 요소의 높이를 완전히 채움
            bg={mainBgColor}
            position="relative"
        >
            <motion.div
                style={{
                    display: "flex",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    left: 0,
                }}
                animate={{ x: -page * 100 + "%" }} // Framer Motion의 animate로 페이지 이동 애니메이션
                transition={{ duration: 0.5, ease: "easeInOut" }} // 애니메이션 속도와 이징
            >
                {pages.map((content, index) => (
                    <Box
                        key={index}
                        flex="none"
                        width="100vw"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"

                    >
                        {content}
                    </Box>
                ))}
            </motion.div>
        </Box>
    );
}
