import { Box, Center, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { IUserTreeDataProps } from "./UserTreeTable";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";

export interface IUserTreeTableNoDataProps {
    site_name: string;
    company_name: string;
    start_date: string;
    end_date: string;

}

export default function UserTreeTableNoData({
    site_name, company_name, start_date, end_date

}: IUserTreeTableNoDataProps) {

    //테마 관련
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const titleColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);

    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);
    return (
        <Box
            width={"100%"}
            overflowX="auto"
            bg={mainBgColor}
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: scrollBarColor,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbColor,
                    borderRadius: '4px',
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbHoverColor,
                },
            }}
        >

            {/* 상단 타이틀 */}
            <Flex bg={inputBgColor} color={mainFontColor} alignItems={"center"} justifyContent={"space-around"} py={2} mb={2} borderRadius={"md"}>
                <Box>
                    <Center>{site_name} {company_name}쉐어 정산표</Center>
                </Box>
                <Box>
                    <Center>{start_date} ~ {end_date}</Center>
                </Box>
            </Flex>
            {/* 데이터 없음 메시지 */}
            <Center borderRadius={"2xl"} bg={bgColor} py={10}>
                <Text fontSize="2xl" fontWeight="bold" color={negativeColor}>
                    데이터가 없습니다.
                </Text>
            </Center>
        </Box>
    )


}