import React, { useEffect, useState, useRef } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    VStack,
    Text,
    Grid,
    GridItem,
    Checkbox,
    useColorModeValue,
    Center,
    useToast,
    Flex,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure
} from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import { useMutation } from "@tanstack/react-query";
import { getPBgamelist } from "../../../../api/sitedata";
import { useRecoilState } from "recoil";

import { useForm, SubmitHandler } from "react-hook-form";
import { deletePBComInfo, getPBComInfoDetail, putPBComInfo } from "../../../../api/pbcominfo";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";



// Modal 컴포넌트의 Props 타입 정의
interface PBPutRegModalProps {
    isOpen: boolean;
    onClose: () => void;
    comInfo_pk: number;
}

// Option 타입 정의
interface Option {
    id: string;
    label: string;
}

// 폼 입력 데이터 타입 정의
interface FormData {
    companyName: string;
    losingPercentage: string;
    sharePercentage: string;
    selectedLosingOptions: string[];
    selectedShareOptions: string[];
}

// transformDataToOptions 함수 타입 정의
function transformDataToOptions(data: { colType: string[], rowType: string[] }): Option[] {
    const options: Option[] = [];

    data.colType.forEach((col) => {
        data.rowType.forEach((row) => {
            const id = `${col}_${row}`;
            const label = `${col}_${row}`;
            options.push({ id, label });
        });
    });

    return options;
}

export default function PBPutRegModal({ isOpen, onClose, comInfo_pk }: PBPutRegModalProps) {


    // 사이트 옵션 목록 정보 가져오기
    const toast = useToast();
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)
    const [loginOptions, setLoginOptions] = useState<Option[]>([]);
    const [shreOptions, setShreOptions] = useState<Option[]>([]);

    // 사이트 데이터에서 파워볼 게임 리스트를 가져오기
    const pbGameListMutation = useMutation(getPBgamelist, {
        onSuccess: (data: any) => {
            if ('detail' in data) {
                console.log("데이터가 없습니다.");
                return;
            }

            if ('pk' in data) {
                const json_data = JSON.parse(data.data);

                const _options = transformDataToOptions(json_data);

                setLoginOptions(_options);
                setShreOptions(_options);
            }
        }
    });

    // comInfo_pk 값으로 옵션 데이터 가져오기
    const getPBComInfoDetailMutation = useMutation(getPBComInfoDetail, {
        onSuccess: (data) => {


            // 기본값을 폼에 설정
            const losingOptions = JSON.parse(data.selected_login_list);
            const shareOptions = JSON.parse(data.selected_share_list);

            setValue("companyName", data.company_name);
            setValue("losingPercentage", `${data.login_rate}`); // 예: 기본 루징 퍼센트
            setValue("sharePercentage", `${data.share_rate}`);  // 예: 기본 쉐어 퍼센트
            setValue("selectedLosingOptions", losingOptions, { shouldValidate: true });
            setValue("selectedShareOptions", shareOptions, { shouldValidate: true });
        }
    });


    useEffect(() => {
        if (isOpen) {

            pbGameListMutation.mutate({ siteinfo: SelectedMenu.site.pk });
            getPBComInfoDetailMutation.mutate({ comInfo_pk });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    // useForm 초기화
    const { register, handleSubmit, formState, reset, setValue, watch } = useForm<FormData>({
        mode: "onBlur",
        defaultValues: {
            companyName: "",           // 기본값 설정
            losingPercentage: "",      // 기본값 설정
            sharePercentage: "",       // 기본값 설정
            selectedLosingOptions: [], // 기본값 설정
            selectedShareOptions: [],  // 기본값 설정
        },
    });

    // CheckBox를 useForm 상태로 관리
    const selectedLosingOptions = watch("selectedLosingOptions");
    const selectedShareOptions = watch("selectedShareOptions");

    const handleLosingOptionChange = (id: string) => {
        const updatedOptions = selectedLosingOptions.includes(id)
            ? selectedLosingOptions.filter(option => option !== id)
            : [...selectedLosingOptions, id];
        setValue("selectedLosingOptions", updatedOptions, { shouldValidate: true });
    };

    const handleShareOptionChange = (id: string) => {
        const updatedOptions = selectedShareOptions.includes(id)
            ? selectedShareOptions.filter(option => option !== id)
            : [...selectedShareOptions, id];
        setValue("selectedShareOptions", updatedOptions, { shouldValidate: true });
    };

    // 입력값 수정
    const putPBIdvOptionMutation = useMutation(putPBComInfo, {
        onSuccess: (data: any) => {
            toast({
                position: 'top',
                title: "옵션 수정 성공! ",
                description: `${data.company_name} 업체가 등록되었습니다.`,
                status: "info",
                isClosable: true,
            });

            setSelectedMenu({ ...SelectedMenu, reload_tag: new Date().getTime() });

            reset();
            onClose();  // 모달 닫기
        },
        onError: (data: any) => {
            toast({
                position: 'top',
                title: "옵션 수정 실패! ",
                description: "관리자에게 문의해 주세요.",
                status: "error",
                isClosable: true,
            });

            reset();
            onClose();  // 모달 닫기
        }
    });

    // 폼 제출 핸들러 타입 정의
    const onSubmit: SubmitHandler<FormData> = (data) => {
        const putPBComInfoParmas = {
            comInfo_pk: comInfo_pk,
            siteInfo_pk: SelectedMenu.site.pk,
            company_name: data.companyName,
            login_rate: parseFloat(data.losingPercentage),
            selected_login_list: JSON.stringify(data.selectedLosingOptions),
            share_rate: parseFloat(data.sharePercentage),
            selected_share_list: JSON.stringify(data.selectedShareOptions),
        };

        putPBIdvOptionMutation.mutate(putPBComInfoParmas);


    };

    // 삭제 컨펌 창 관련
    const target_del_com_name = useRef<string>("");

    const {
        isOpen: isDeleteOpen,
        onOpen: onDeleteOpen,
        onClose: onDeleteClose
    } = useDisclosure();

    const cancelRef = useRef<HTMLButtonElement>(null);

    const deletePBIdvOptionMutation = useMutation(deletePBComInfo, {
        onSuccess: (data: any) => {
            toast({
                position: 'top',
                title: "옵션 삭제 성공! ",
                description: `${target_del_com_name.current} 업체가 삭제되었습니다.`,
                status: "info",
                isClosable: true,
            });

            target_del_com_name.current = "";
            onDeleteClose();
            onClose();

        },
        onError: (data: any) => {
            toast({
                position: 'top',
                title: "옵션 삭제 실패! ",
                description: "관리자에게 문의해 주세요.",
                status: "error",
                isClosable: true,
            });

            onDeleteClose();
            onClose();
        }
    });

    const handleDelete = () => {

        target_del_com_name.current = watch("companyName");
        deletePBIdvOptionMutation.mutate({ comInfo_pk });

    };

    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);

    const errorColor = useColorModeValue(lightTheme_colors['errorColor'], darkTheme_colors['errorColor']);


    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent bg={inputBgColor}>
                <ModalHeader>업체 옵션 수정/삭제</ModalHeader>
                <ModalCloseButton />
                <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
                    <VStack spacing={10} align="stretch">
                        <VStack align="stretch" spacing={2}>
                            <Text fontSize="md" fontWeight="bold">파워볼 업체 이름</Text>
                            <Input
                                placeholder="파워볼 업체명"
                                {...register("companyName", { required: "업체명을 입력하세요" })}
                            />
                            {formState.errors.companyName && <Center color={errorColor}>{formState.errors.companyName?.message}</Center>}
                        </VStack>

                        {/* 파워볼 루징 조건 */}
                        <VStack align="stretch" spacing={4}>
                            <Text fontSize="md" fontWeight="bold">파워볼 루징 조건</Text>
                            <Input
                                placeholder="파워볼 루징 %"
                                type="number"
                                step="0.1"
                                min="0"
                                max="100"
                                {...register("losingPercentage", {
                                    required: "루징 퍼센트를 입력하세요",
                                    validate: (value) =>
                                        parseFloat(value) >= 0 || "0보다 큰 값을 입력하세요",
                                })}
                            />
                            {formState.errors.losingPercentage && <Center color={errorColor}>{formState.errors.losingPercentage?.message}</Center>}

                            <Text>파워볼 루징 종류 선택</Text>
                            <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                                {loginOptions.map((option) => (
                                    <GridItem key={option.id}>
                                        <Checkbox
                                            isChecked={selectedLosingOptions.includes(option.id)}
                                            onChange={() => handleLosingOptionChange(option.id)}
                                        >
                                            {option.label}
                                        </Checkbox>
                                    </GridItem>
                                ))}
                            </Grid>

                        </VStack>

                        {/* 파워볼 쉐어 조건 */}
                        <VStack align="stretch" spacing={4}>
                            <Text fontSize="md" fontWeight="bold">파워볼 쉐어 조건</Text>
                            <Input
                                placeholder="파워볼 쉐어 %"
                                type="number"
                                step="0.1"
                                min="0"
                                max="100"
                                {...register("sharePercentage", {
                                    required: "쉐어 퍼센트를 입력하세요",
                                    validate: (value) =>
                                        parseFloat(value) >= 0 || "0보다 큰 값을 입력하세요",
                                })}
                            />
                            {formState.errors.sharePercentage && <Center color={errorColor}>{formState.errors.sharePercentage?.message}</Center>}

                            <Text>파워볼 쉐어 종류 선택</Text>
                            <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                                {shreOptions.map((option) => (
                                    <GridItem key={option.id}>
                                        <Checkbox
                                            isChecked={selectedShareOptions.includes(option.id)}
                                            onChange={() => handleShareOptionChange(option.id)}
                                        >
                                            {option.label}
                                        </Checkbox>
                                    </GridItem>
                                ))}
                            </Grid>

                        </VStack>
                    </VStack>
                    <ModalFooter>
                        <Flex justifyContent="space-between" w="100%">
                            <Button colorScheme="red" onClick={onDeleteOpen}>삭제</Button>
                            <Flex>
                                <Button colorScheme="blue" mr={3} type="submit">
                                    옵션 수정
                                </Button>
                                <Button variant="ghost" onClick={onClose}>취소</Button>
                            </Flex>
                        </Flex>
                    </ModalFooter>
                </ModalBody>
            </ModalContent>

            <AlertDialog
                isOpen={isDeleteOpen}
                leastDestructiveRef={cancelRef}
                onClose={onDeleteClose}
            >
                <AlertDialogOverlay>
                    <AlertDialogContent>
                        <AlertDialogHeader fontSize="lg" fontWeight="bold">
                            삭제 확인
                        </AlertDialogHeader>

                        <AlertDialogBody>
                            <Center>정말로 삭제하시겠습니까?</Center>
                            <Center>삭제된 데이터는 복구할 수 없습니다.</Center>
                        </AlertDialogBody>

                        <AlertDialogFooter>
                            <Button ref={cancelRef} onClick={onDeleteClose}>
                                취소
                            </Button>
                            <Button colorScheme="red" onClick={handleDelete} ml={3}>
                                삭제
                            </Button>
                        </AlertDialogFooter>
                    </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Modal>
    );
}


/**
{
    "pk": 11,
    "distributor": {
        "pk": 1,
        "name": "관리자1"
    },
    "siteInfo": 2,
    "company_name": "체리2",
    "login_rate": 22,
    "selected_login_list": "[\"코인-3분_게임합\",\"코인-5분_게임합\"]",
    "share_rate": 22,
    "selected_share_list": "[\"코인-5분_게임합\",\"EOS-3분_게임합\"]",
    "created_at": "2024-08-27T12:58:27.791967",
    "updated_at": "2024-08-27T12:58:27.791967"
}
 */