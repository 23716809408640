import { useRecoilValue } from "recoil";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";
import { useMutation } from "@tanstack/react-query";
import { getPBIdbOption } from "../../../../api/pvidvoption";
import { getSiteData } from "../../../../api/sitedata";
import { getCombinedData } from "../../../../lib/conversionData";
import { getLoginShareValues, getRowAndColTypes, sumSelectedValues } from "../../PBLosingShreComm/util/util";


export interface IData {
    [key: string]: any;
}

export function useGetPBIdvOptionMutation(LoginDataRef: React.MutableRefObject<IData>, SelectedLoginOptionRef: React.MutableRefObject<string[]>) {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    return useMutation(getPBIdbOption, {
        onSuccess: (data) => {
            const login_rate = data.login_rate;
            SelectedLoginOptionRef.current = JSON.parse(data.selected_login_list);

            LoginDataRef.current = {
                site_name: SelectedMenu.site.name,
                company_name: SelectedMenu.PBCom.name,
                category: "루징금",
                start_date: data.start_date,
                end_date: data.end_date,
                rowTypes: LoginDataRef.current.rowTypes,
                colTypes: LoginDataRef.current.colTypes,
                rate: login_rate,
                selectedOptions: SelectedLoginOptionRef.current,
                values: LoginDataRef.current.values,
                up_total: false,
                total: LoginDataRef.current.total
            };
        }
    });
}

export function useAtomDataMutation(LoginDataRef: React.MutableRefObject<IData>, SelectedLoginOptionRef: React.MutableRefObject<string[]>) {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    return useMutation(getSiteData, {
        onSuccess: (data) => {
            let re_atom_data_list = [] as any;
            data.forEach((item: any) => {
                re_atom_data_list.push(JSON.parse(item.data));
            });

            re_atom_data_list = re_atom_data_list.reverse();

            let atom_combined_data_type_month = [] as any;
            for (const atom_data of re_atom_data_list) {
                atom_combined_data_type_month = getCombinedData(atom_combined_data_type_month, atom_data);
            }

            let targetData = atom_combined_data_type_month.filter(
                (item: any) =>
                    item.user_id === SelectedMenu.PBCom.name ||
                    item.status_id_list.includes(SelectedMenu.PBCom.name)
            );

            const LoginShareValues = getLoginShareValues(targetData);
            const { rowTypes, colTypes } = getRowAndColTypes(LoginShareValues);
            const LoginTotel = sumSelectedValues(LoginShareValues, SelectedLoginOptionRef.current);


            // 리무진 특별처리. up_fee 가 있으면 리무진 데이터이다. 
            let up_total: boolean | number = false;
            if (targetData.length > 0 && Object.keys(targetData[0]).some(key => key.includes("up_fee_"))) {
                const pb_bet_파워볼_게임합_sum = targetData.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.pb_bat_파워볼_게임합 || 0), 0);
                const pb_win_파워볼_게임합_sum = targetData.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.pb_win_파워볼_게임합 || 0), 0);
                const pb_fee_파워볼_게임합_sum = targetData.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.pb_fee_파워볼_게임합 || 0), 0);
                const up_fee_파워볼 = targetData.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.up_fee_파워볼_ || 0), 0);

                up_total = pb_bet_파워볼_게임합_sum - pb_win_파워볼_게임합_sum - pb_fee_파워볼_게임합_sum - up_fee_파워볼;
            }

            LoginDataRef.current = {
                site_name: SelectedMenu.site.name,
                company_name: SelectedMenu.PBCom.name,
                start_date: LoginDataRef.current.start_date,
                end_date: LoginDataRef.current.end_date,
                category: LoginDataRef.current.category,
                rowTypes: rowTypes,
                colTypes: colTypes,
                rate: LoginDataRef.current.rate,
                selectedOptions: SelectedLoginOptionRef.current,
                values: LoginShareValues,
                up_total: up_total,
                total: LoginTotel
            };
        }
    });
}