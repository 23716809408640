import { Input, Link, Td, Tr, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { IPBUserTreeData } from "./PBuserTreeTable";
import { calculateSums, findChildren } from "./PBUserTreeTableUtils";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";

interface IPBUserRowProps {
    UserTreeDatas: IPBUserTreeData[];
    UserRowData: IPBUserTreeData;
    level?: number;
    dataKeys: string[];
    // IvdOptin: any; // Define this type properly based on your usage    
}


const PBUserTreeTableRow: React.FC<IPBUserRowProps> = ({ UserTreeDatas, UserRowData, level = 0, dataKeys }) => {
    const [isOpen, setIsOpen] = useState<boolean>(level === 0 ? true : false);
    const children: IPBUserTreeData[] = findChildren(UserTreeDatas, UserRowData.user_id, UserRowData.status_id_list.length);
    const hasChildren = children.length > 0;

    const sums = useMemo(() => calculateSums(UserTreeDatas, UserRowData, dataKeys), [UserRowData, dataKeys]);


    // status_id_list의 길이에 따라 배경색을 지정하는 함수
    const rowLineColor0 = useColorModeValue(lightTheme_colors['rowLineColor0'], darkTheme_colors['rowLineColor0']);
    const rowLineColor1 = useColorModeValue(lightTheme_colors['rowLineColor1'], darkTheme_colors['rowLineColor1']);
    const rowLineColor2 = useColorModeValue(lightTheme_colors['rowLineColor2'], darkTheme_colors['rowLineColor2']);
    const rowLineColor3 = useColorModeValue(lightTheme_colors['rowLineColor3'], darkTheme_colors['rowLineColor3']);
    const rowLineColor4 = useColorModeValue(lightTheme_colors['rowLineColor4'], darkTheme_colors['rowLineColor4']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const receSelectColor = useColorModeValue(lightTheme_colors['receSelectColor'], darkTheme_colors['receSelectColor']);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);


    const getStatusColor = (statusIdLength: number) => {
        switch (statusIdLength) {
            case 0:
                return rowLineColor0;
            case 1:
                return rowLineColor1;
            case 2:
                return rowLineColor2;
            case 3:
                return rowLineColor3;
            default:
                return rowLineColor4;
        }
    };

    // 하위 유저 여부에 따라 텍스트 색상을 지정하는 함수
    const getTextColor = (hasChildren: boolean) => {
        return hasChildren ? receSelectColor : mainFontColor;
    };

    const rowBgColor = getStatusColor(UserRowData.status_id_list.length);
    const textColor = getTextColor(hasChildren);


    return (
        <>
            <Tr bg={rowBgColor} borderBottom={isOpen ? "1px solid" : undefined}>
                <Td pl={`${level * 2 + 1}rem`}>
                    <Link color={textColor} onClick={() => setIsOpen(!isOpen)} cursor="pointer">
                        {UserRowData.user_id} <br />({UserRowData.user_nickname})
                    </Link>
                </Td>
                <Td>{UserRowData.status}</Td>



                {/* 동적으로 dataKeys 렌더링 - casino_activate, slot_activate, holdem_activate 확인 */}

                {dataKeys.map((key) => {
                    const isNegative = isOpen ? UserRowData[key] < 0 : sums[key] < 0;
                    return (
                        <Td key={key} color={isNegative ? negativeColor : undefined} textAlign="right">
                            {isOpen ? (UserRowData[key].toLocaleString() || "-") : (sums[key].toLocaleString() || "-")}
                        </Td>
                    );
                })}
            </Tr>
            {isOpen && children.map((child) => (
                <PBUserTreeTableRow key={child.user_id} UserTreeDatas={UserTreeDatas} UserRowData={child} level={level + 1} dataKeys={dataKeys} />
            ))}
        </>
    );
};

export default PBUserTreeTableRow;
