// import { IBuyCart, IGetBuyOrder } from "../api/buy";


// 두개의 리스트가 같은지 확인(오브젝트 리스트)
export function areListsEqual(list1: any[], list2: any[]): boolean {
    if (list1.length !== list2.length) {
        return false;
    }
    for (let i = 0; i < list1.length; i++) {
        if (JSON.stringify(list1[i]) !== JSON.stringify(list2[i])) {
            return false;
        }
    }
    return true;
}


// 시간처리
export const formatDate = (dateStr: string) => {
    const date = new Date(dateStr);
    const month = date.getMonth() + 1; // getMonth()는 0부터 시작합니다.
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${month}/${day} ${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
}


// 날짜 처리  "2024년 06월" => ["2024-05-01", "2024-05-31"]
export const getMonthRangeFromString = (monthString: string): [string, string] => {
    // "2024년 06월" 형식의 문자열을 "2024"와 "06"으로 분리
    const [yearStr, monthStr] = monthString.replace("년", "").replace("월", "").trim().split(" ");
    const year = parseInt(yearStr);
    const month = parseInt(monthStr);

    // 해당 월의 시작 날짜와 끝 날짜 계산
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 0); // 다음 달의 0일은 해당 월의 마지막 날

    // 날짜를 yyyy-mm-dd 형식의 문자열로 변환
    const formatDate = (date: Date): string => {
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
        const dd = String(date.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    return [formatDate(startDate), formatDate(endDate)];
};

export const get15RangeFromString = (monthString: string): [string, string, string, string] => {
    // "2024년 06월" 형식의 문자열을 "2024"와 "06"으로 분리
    const [yearStr, monthStr] = monthString.replace("년", "").replace("월", "").trim().split(" ");
    const year = parseInt(yearStr);
    const month = parseInt(monthStr);

    // 해당 월의 시작 날짜와 끝 날짜 계산
    const _startMonth = new Date(year, month - 1, 1);
    const _15Month = new Date(year, month - 1, 15);
    const _16Month = new Date(year, month - 1, 16);
    const _endMonth = new Date(year, month, 0); // 다음 달의 0일은 해당 월의 마지막 날

    // 날짜를 yyyy-mm-dd 형식의 문자열로 변환
    const formatDate = (date: Date): string => {
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
        const dd = String(date.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    return [formatDate(_startMonth), formatDate(_15Month), formatDate(_16Month), formatDate(_endMonth)];
};

export const get10RangeFromString = (monthString: string): [string, string, string, string, string, string] => {
    // "2024년 06월" 형식의 문자열을 "2024"와 "06"으로 분리
    const [yearStr, monthStr] = monthString.replace("년", "").replace("월", "").trim().split(" ");
    const year = parseInt(yearStr);
    const month = parseInt(monthStr);

    // 해당 월의 시작 날짜와 끝 날짜 계산
    const _startMonth = new Date(year, month - 1, 1);
    const _10Month = new Date(year, month - 1, 10);
    const _11Month = new Date(year, month - 1, 11);
    const _20Month = new Date(year, month - 1, 20);
    const _21Month = new Date(year, month - 1, 21);
    const _endMonth = new Date(year, month, 0); // 다음 달의 0일은 해당 월의 마지막 날

    // 날짜를 yyyy-mm-dd 형식의 문자열로 변환
    const formatDate = (date: Date): string => {
        const yyyy = date.getFullYear();
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더함
        const dd = String(date.getDate()).padStart(2, '0');
        return `${yyyy}-${mm}-${dd}`;
    };

    return [formatDate(_startMonth), formatDate(_10Month), formatDate(_11Month), formatDate(_20Month), formatDate(_21Month), formatDate(_endMonth)];
};


// 월 처리
export const generateMonths = () => {
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth();

    const months = [];
    for (let i = 0; i < 12; i++) {
        const monthIndex = (currentMonth - i + 12) % 12;
        const yearOffset = Math.floor((currentMonth - i) / 12);
        const month = (monthIndex + 1).toString().padStart(2, '0');
        const year = currentYear + yearOffset;
        months.push(`${year}년 ${month}월`);
    }

    return months;
};

export const getLastThreeMonths = (SelectedMenu: any) => {
    // SelectedMenu.Month에서 연도와 월을 추출
    const [yearString, monthString] = SelectedMenu.Month.split("년 ");
    const selectedYear = parseInt(yearString);
    const selectedMonth = parseInt(monthString.replace("월", ""));

    const monthList = [];

    for (let i = 2; i >= 0; i--) {
        // i개월 전의 날짜 계산
        const date = new Date(selectedYear, selectedMonth - i - 1); // 기준 날짜로부터 계산
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월을 두 자리 숫자로 맞춤
        monthList.push(`${year}년 ${month}월`);
    }

    return monthList;
};


export const getCurrentMonth = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    return `${year}년 ${month}월`;
};

export const get_prev_month_str = (current_month: string) => {
    const [year, month] = current_month.replace('년 ', '-').replace('월', '').split('-').map(Number);
    const newDate = new Date(year, month - 2);
    const newYear = newDate.getFullYear();
    const newMonth = (newDate.getMonth() + 1).toString().padStart(2, '0');
    return `${newYear}년 ${newMonth}월`;
}

export const get_next_month_str = (current_month: string) => {
    const [year, month] = current_month.replace('년 ', '-').replace('월', '').split('-').map(Number);
    const newDate = new Date(year, month - 1 + 1); // 다음 달 계산
    const newYear = newDate.getFullYear();
    const newMonth = (newDate.getMonth() + 1).toString().padStart(2, '0');
    return `${newYear}년 ${newMonth}월`;
};


export const get_api_month_str = (input_year_month: string) => {
    // "2024년 11월" 을 받아서 "2024-11"을 반환
    const match = input_year_month.match(/(\d{4})년 (\d{1,2})월/);
    if (!match) {
        throw new Error("올바른 형식이 아닙니다. 예: '2024년 11월'");
    }

    const year = match[1];
    const month = match[2].padStart(2, '0'); // 월을 두 자리로 포맷팅

    return `${year}-${month}`;
}

export const post_api_month_str = (input_year_month: string) => {
    // "2024년 11월" 을 받아서 "2024-11-01"을 반환 월의 첫번째 날짜반환
    const match = input_year_month.match(/(\d{4})년 (\d{1,2})월/);
    if (!match) {
        throw new Error("올바른 형식이 아닙니다. 예: '2024년 11월'");
    }

    const year = match[1];
    const month = match[2].padStart(2, '0'); // 월을 두 자리로 포맷팅

    return `${year}-${month}-01`;
}


// 년 처리 
export const generateYears = (numYears = 5) => {
    const today = new Date();
    const currentYear = today.getFullYear();

    const years = [];
    for (let i = 0; i < numYears; i++) {
        years.push(`${currentYear - i}`);
    }

    return years;
};

export const getCurrentYear = () => {
    const today = new Date();
    const year = today.getFullYear();
    return `${year}`;
};

export const get_prev_year_str = (current_year: string) => {
    const year = parseInt(current_year.replace('년', '').trim(), 10);
    return `${year - 1}`;
}

export const get_next_year_str = (current_year: string) => {
    const year = parseInt(current_year.replace('년', '').trim(), 10);
    return `${year + 1}`;
}

export function generateMonthList(yearStr: string) {
    /*
    년도를 입력받으면 "2024" 
    ['2024년 01월","2024년 02월", ....... ] 이런리스트를 반환해주는 함수
    */
    const months = [];
    for (let i = 1; i <= 12; i++) {
        const month = i.toString().padStart(2, '0'); // 2자리 숫자로 맞춤 (01, 02, ... 12)
        months.push(`${yearStr}년 ${month}월`);
    }
    return months;
}




// 데이터 변형
// todo : 추가 삭제가 동시에 일어날경우 문제가 생길수 있다. 대응해둘것
export interface IApiData {
    headers: string[];
    rows: { [key: string]: string }[];
}

export const transformData = (data: { [key: string]: string }[]): IApiData => {
    // 가장 많은 키를 가진 항목을 찾기
    const headers = data.reduce<string[]>((longest, current) =>
        Object.keys(current).length > longest.length ? Object.keys(current) : longest, []
    );

    // 모든 rows에 대해 누락된 필드를 0으로 채우기
    const filledRows = data.map(row => {
        const filledRow: { [key: string]: string } = {};
        headers.forEach(header => {
            filledRow[header] = row[header] ?? '0';
        });
        return filledRow;
    });

    // 합계 계산
    const totalRow = filledRows.reduce((acc, row) => {
        headers.forEach(header => {
            if (header !== 'Date') {
                const currentValue = acc[header] ? acc[header].replace(/,/g, '') : '0';
                const rowValue = row[header] ? row[header].replace(/,/g, '') : '0';
                acc[header] = (parseInt(currentValue) + parseInt(rowValue)).toLocaleString();
            }
        });
        return acc;
    }, { Date: 'TOTAL', });

    return {
        headers,
        rows: [...filledRows, totalRow]
    };
}

type OutputRow = {
    Date: string;
    유저머니: string;
    유저포인트: string;
    합계: string;
    전일대비변동: string;
};

export const transformData2 = (data: { [key: string]: string }[]): IApiData => {
    const headers = ['Date', '유저 머니', '유저 포인트', '합계', '전일 대비 변동'];
    const rows: OutputRow[] = data.map((row, index) => {
        const previousTotal = index === 0 ? 0 : parseInt(data[index - 1].합계.replace(/,/g, ''), 10);
        const currentTotal = parseInt(row.합계.replace(/,/g, ''), 10);
        const difference = index === 0 ? 0 : currentTotal - previousTotal;

        return {
            Date: row.Date,
            유저머니: row.유저머니,
            유저포인트: row.유저포인트,
            합계: row.합계,
            전일대비변동: difference.toLocaleString()
        };
    });

    const totalRow = rows.reduce((acc, row) => {
        acc.유저머니 = (parseInt(acc.유저머니.replace(/,/g, ''), 10) + parseInt(row.유저머니.replace(/,/g, ''), 10)).toLocaleString();
        acc.유저포인트 = (parseInt(acc.유저포인트.replace(/,/g, ''), 10) + parseInt(row.유저포인트.replace(/,/g, ''), 10)).toLocaleString();
        acc.합계 = (parseInt(acc.합계.replace(/,/g, ''), 10) + parseInt(row.합계.replace(/,/g, ''), 10)).toLocaleString();
        return acc;
    }, { Date: 'TOTAL', 유저머니: '0', 유저포인트: '0', 합계: '0', 전일대비변동: '0' });

    rows.push(totalRow);

    return { headers, rows };
};




/*
[
    {
        "Date": "07/01",
        "EOS_3분": "-35,082",
        "EOS_5분": "11,755",
        "코인_3분": "-290,155",
        "코인_5분": "1,692,161",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "27,411",
        "코사_3분": "0",
        "코사_5분": "1,767",
        "합계": "1,407,857"
    },
    {
        "Date": "07/02",
        "EOS_3분": "216",
        "EOS_5분": "-24,608",
        "코인_3분": "-356,663",
        "코인_5분": "461,517",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "959,181",
        "코사_3분": "0",
        "코사_5분": "1,880",
        "합계": "1,041,523"
    },
    {
        "Date": "07/03",
        "EOS_3분": "929",
        "EOS_5분": "-18,612",
        "코인_3분": "-665,664",
        "코인_5분": "588,950",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "701,449",
        "코사_3분": "0",
        "코사_5분": "1,016",
        "합계": "608,068"
    },
    {
        "Date": "07/04",
        "EOS_3분": "1,109",
        "EOS_5분": "13,967",
        "코인_3분": "955,086",
        "코인_5분": "-543,968",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "455,611",
        "코사_3분": "0",
        "코사_5분": "673",
        "합계": "882,478"
    },
    {
        "Date": "07/05",
        "EOS_3분": "111",
        "EOS_5분": "21,210",
        "코인_3분": "356,105",
        "코인_5분": "-402,564",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "1,979,083",
        "코사_3분": "0",
        "코사_5분": "0",
        "합계": "1,953,945"
    },
    {
        "Date": "07/06",
        "EOS_3분": "-2,332,201",
        "EOS_5분": "36,470",
        "코인_3분": "-621,027",
        "코인_5분": "921,096",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "91,996",
        "코사_3분": "2",
        "코사_5분": "316",
        "합계": "-1,903,348"
    },
    {
        "Date": "07/07",
        "EOS_3분": "-8,954",
        "EOS_5분": "87,943",
        "코인_3분": "451,582",
        "코인_5분": "-341,440",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "14,090",
        "코사_3분": "0",
        "코사_5분": "279",
        "합계": "203,500"
    },
    {
        "Date": "07/08",
        "EOS_3분": "0",
        "EOS_5분": "-24,985",
        "코인_3분": "-25,792",
        "코인_5분": "114,572",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "25,405",
        "코사_3분": "0",
        "코사_5분": "0",
        "합계": "89,200"
    },
    {
        "Date": "07/09",
        "EOS_3분": "-14,635",
        "EOS_5분": "-197,693",
        "코인_3분": "58,623",
        "코인_5분": "127,627",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "31,338",
        "코사_3분": "0",
        "코사_5분": "0",
        "합계": "5,260"
    },
    {
        "Date": "07/10",
        "EOS_3분": "-5,785",
        "EOS_5분": "44,168",
        "코인_3분": "-497,338",
        "코인_5분": "-944,759",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "19,204",
        "코사_3분": "0",
        "코사_5분": "3,479",
        "합계": "-1,381,031"
    },
    {
        "Date": "07/11",
        "EOS_3분": "35",
        "EOS_5분": "13,382",
        "코인_3분": "-168,484",
        "코인_5분": "-2,296,501",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "10,695",
        "코사_3분": "0",
        "코사_5분": "71",
        "합계": "-2,440,802"
    },
    {
        "Date": "07/12",
        "EOS_3분": "-8,135",
        "EOS_5분": "238,569",
        "코인_3분": "-397,444",
        "코인_5분": "-1,153,129",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "97,740",
        "코사_3분": "0",
        "코사_5분": "0",
        "합계": "-1,222,399"
    },
    {
        "Date": "07/13",
        "EOS_3분": "-228,479",
        "EOS_5분": "1,767,887",
        "코인_3분": "1,794,455",
        "코인_5분": "546,864",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "198,323",
        "코사_3분": "4",
        "코사_5분": "0",
        "합계": "4,079,054"
    },
    {
        "Date": "07/14",
        "EOS_3분": "0",
        "EOS_5분": "-320,965",
        "코인_3분": "-77,349",
        "코인_5분": "759,860",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "61,862",
        "코사_3분": "0",
        "코사_5분": "775",
        "합계": "424,183"
    },
    {
        "Date": "07/15",
        "EOS_3분": "-86,542",
        "EOS_5분": "-250,243",
        "코인_3분": "261,564",
        "코인_5분": "-1,961,554",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "398,879",
        "코사_3분": "0",
        "코사_5분": "993",
        "합계": "-1,636,903"
    },
    {
        "Date": "07/26",
        "EOS_3분": "110,420",
        "EOS_5분": "-134,930",
        "코인_3분": "-425,452",
        "코인_5분": "803,151",
        "더블유_3분": "0",
        "동행_파워볼": "-11,977",
        "PBG_파워볼": "1,091,746",
        "코사_3분": "20",
        "코사_5분": "101",
        "동행사다리": "-11,977",
        "스피드키노": "1,091,746",
        "합계": "2,512,848"
    },
    {
        "Date": "07/27",
        "EOS_3분": "0",
        "EOS_5분": "-94,399",
        "코인_3분": "143,791",
        "코인_5분": "-571,826",
        "더블유_3분": "0",
        "동행_파워볼": "149,791",
        "PBG_파워볼": "447,445",
        "코사_3분": "0",
        "코사_5분": "0",
        "동행사다리": "149,791",
        "스피드키노": "447,445",
        "합계": "672,038"
    },
    {
        "Date": "07/28",
        "EOS_3분": "405",
        "EOS_5분": "1,611",
        "코인_3분": "-205,174",
        "코인_5분": "-206,103",
        "더블유_3분": "0",
        "동행_파워볼": "-46,716",
        "PBG_파워볼": "-1,094,577",
        "코사_3분": "565",
        "코사_5분": "219",
        "동행사다리": "-46,716",
        "스피드키노": "-1,094,577",
        "합계": "-2,691,063"
    },
    {
        "Date": "07/29",
        "EOS_3분": "215",
        "EOS_5분": "-259,273",
        "코인_3분": "2,432,116",
        "코인_5분": "-9,778",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "61,453",
        "코사_3분": "229",
        "코사_5분": "-13",
        "동행사다리": "0",
        "스피드키노": "61,453",
        "합계": "2,286,402"
    },
    {
        "Date": "07/30",
        "EOS_3분": "303,552",
        "EOS_5분": "-115,242",
        "코인_3분": "-887,190",
        "코인_5분": "348,706",
        "더블유_3분": "0",
        "동행_파워볼": "294,000",
        "PBG_파워볼": "-235,639",
        "코사_3분": "0",
        "코사_5분": "-5",
        "동행사다리": "294,000",
        "스피드키노": "-235,639",
        "합계": "-233,457"
    }
]
*/


/*
{
    "headers": [
        "Date",
        "EOS_3분",
        "EOS_5분",
        "코인_3분",
        "코인_5분",
        "더블유_3분",
        "동행_파워볼",
        "PBG_파워볼",
        "코사_3분",
        "코사_5분",
        "동행사다리",
        "스피드키노",
        "합계"
    ],
    "rows": [
        {
            "Date": "07/01",
            "EOS_3분": "-35,082",
            "EOS_5분": "11,755",
            "코인_3분": "-290,155",
            "코인_5분": "1,692,161",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "27,411",
            "코사_3분": "0",
            "코사_5분": "1,767",
            "합계": "1,407,857"
        },
        {
            "Date": "07/02",
            "EOS_3분": "216",
            "EOS_5분": "-24,608",
            "코인_3분": "-356,663",
            "코인_5분": "461,517",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "959,181",
            "코사_3분": "0",
            "코사_5분": "1,880",
            "합계": "1,041,523"
        },
        {
            "Date": "07/03",
            "EOS_3분": "929",
            "EOS_5분": "-18,612",
            "코인_3분": "-665,664",
            "코인_5분": "588,950",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "701,449",
            "코사_3분": "0",
            "코사_5분": "1,016",
            "합계": "608,068"
        },
        {
            "Date": "07/04",
            "EOS_3분": "1,109",
            "EOS_5분": "13,967",
            "코인_3분": "955,086",
            "코인_5분": "-543,968",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "455,611",
            "코사_3분": "0",
            "코사_5분": "673",
            "합계": "882,478"
        },
        {
            "Date": "07/05",
            "EOS_3분": "111",
            "EOS_5분": "21,210",
            "코인_3분": "356,105",
            "코인_5분": "-402,564",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "1,979,083",
            "코사_3분": "0",
            "코사_5분": "0",
            "합계": "1,953,945"
        },
        {
            "Date": "07/06",
            "EOS_3분": "-2,332,201",
            "EOS_5분": "36,470",
            "코인_3분": "-621,027",
            "코인_5분": "921,096",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "91,996",
            "코사_3분": "2",
            "코사_5분": "316",
            "합계": "-1,903,348"
        },
        {
            "Date": "07/07",
            "EOS_3분": "-8,954",
            "EOS_5분": "87,943",
            "코인_3분": "451,582",
            "코인_5분": "-341,440",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "14,090",
            "코사_3분": "0",
            "코사_5분": "279",
            "합계": "203,500"
        },
        {
            "Date": "07/08",
            "EOS_3분": "0",
            "EOS_5분": "-24,985",
            "코인_3분": "-25,792",
            "코인_5분": "114,572",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "25,405",
            "코사_3분": "0",
            "코사_5분": "0",
            "합계": "89,200"
        },
        {
            "Date": "07/09",
            "EOS_3분": "-14,635",
            "EOS_5분": "-197,693",
            "코인_3분": "58,623",
            "코인_5분": "127,627",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "31,338",
            "코사_3분": "0",
            "코사_5분": "0",
            "합계": "5,260"
        },
        {
            "Date": "07/10",
            "EOS_3분": "-5,785",
            "EOS_5분": "44,168",
            "코인_3분": "-497,338",
            "코인_5분": "-944,759",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "19,204",
            "코사_3분": "0",
            "코사_5분": "3,479",
            "합계": "-1,381,031"
        },
        {
            "Date": "07/11",
            "EOS_3분": "35",
            "EOS_5분": "13,382",
            "코인_3분": "-168,484",
            "코인_5분": "-2,296,501",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "10,695",
            "코사_3분": "0",
            "코사_5분": "71",
            "합계": "-2,440,802"
        },
        {
            "Date": "07/12",
            "EOS_3분": "-8,135",
            "EOS_5분": "238,569",
            "코인_3분": "-397,444",
            "코인_5분": "-1,153,129",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "97,740",
            "코사_3분": "0",
            "코사_5분": "0",
            "합계": "-1,222,399"
        },
        {
            "Date": "07/13",
            "EOS_3분": "-228,479",
            "EOS_5분": "1,767,887",
            "코인_3분": "1,794,455",
            "코인_5분": "546,864",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "198,323",
            "코사_3분": "4",
            "코사_5분": "0",
            "합계": "4,079,054"
        },
        {
            "Date": "07/14",
            "EOS_3분": "0",
            "EOS_5분": "-320,965",
            "코인_3분": "-77,349",
            "코인_5분": "759,860",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "61,862",
            "코사_3분": "0",
            "코사_5분": "775",
            "합계": "424,183"
        },
        {
            "Date": "07/15",
            "EOS_3분": "-86,542",
            "EOS_5분": "-250,243",
            "코인_3분": "261,564",
            "코인_5분": "-1,961,554",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "398,879",
            "코사_3분": "0",
            "코사_5분": "993",
            "합계": "-1,636,903"
        },
        {
            "Date": "07/26",
            "EOS_3분": "110,420",
            "EOS_5분": "-134,930",
            "코인_3분": "-425,452",
            "코인_5분": "803,151",
            "더블유_3분": "0",
            "동행_파워볼": "-11,977",
            "PBG_파워볼": "1,091,746",
            "코사_3분": "20",
            "코사_5분": "101",
            "동행사다리": "-11,977",
            "스피드키노": "1,091,746",
            "합계": "2,512,848"
        },
        {
            "Date": "07/27",
            "EOS_3분": "0",
            "EOS_5분": "-94,399",
            "코인_3분": "143,791",
            "코인_5분": "-571,826",
            "더블유_3분": "0",
            "동행_파워볼": "149,791",
            "PBG_파워볼": "447,445",
            "코사_3분": "0",
            "코사_5분": "0",
            "동행사다리": "149,791",
            "스피드키노": "447,445",
            "합계": "672,038"
        },
        {
            "Date": "07/28",
            "EOS_3분": "405",
            "EOS_5분": "1,611",
            "코인_3분": "-205,174",
            "코인_5분": "-206,103",
            "더블유_3분": "0",
            "동행_파워볼": "-46,716",
            "PBG_파워볼": "-1,094,577",
            "코사_3분": "565",
            "코사_5분": "219",
            "동행사다리": "-46,716",
            "스피드키노": "-1,094,577",
            "합계": "-2,691,063"
        },
        {
            "Date": "07/29",
            "EOS_3분": "215",
            "EOS_5분": "-259,273",
            "코인_3분": "2,432,116",
            "코인_5분": "-9,778",
            "더블유_3분": "0",
            "동행_파워볼": "0",
            "PBG_파워볼": "61,453",
            "코사_3분": "229",
            "코사_5분": "-13",
            "동행사다리": "0",
            "스피드키노": "61,453",
            "합계": "2,286,402"
        },
        {
            "Date": "07/30",
            "EOS_3분": "303,552",
            "EOS_5분": "-115,242",
            "코인_3분": "-887,190",
            "코인_5분": "348,706",
            "더블유_3분": "0",
            "동행_파워볼": "294,000",
            "PBG_파워볼": "-235,639",
            "코사_3분": "0",
            "코사_5분": "-5",
            "동행사다리": "294,000",
            "스피드키노": "-235,639",
            "합계": "-233,457"
        },
        {
            "Date": "TOTAL",
            "EOS_3분": "-2,302,821",
            "EOS_5분": "796,012",
            "코인_3분": "1,835,590",
            "코인_5분": "-2,067,118",
            "더블유_3분": "0",
            "동행_파워볼": "385,098",
            "PBG_파워볼": "5,342,695",
            "코사_3분": "820",
            "코사_5분": "11,551",
            "동행사다리": "385,098",
            "스피드키노": "270,428",
            "합계": "4,657,353"
        }
    ]
}
*/
