import { Grid, GridItem, VStack } from "@chakra-ui/react";

import { lightTheme_colors, darkTheme_colors } from "../../../theme";
import { useColorModeValue } from "@chakra-ui/react";
import SiteDailySettlementContentTable from "./Contents/ContentTable";
import SiteDayTopSelect from "../CommContents/TopSelect/SiteDayTopSelect";
import SiteDailySettlementTop5Table from "./Contents/Top5Table";


export default function SiteDailySettlement() {


    // 테마 관련 코드 
    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    return (
        <Grid padding={"0.2rem"}
            h='100%'
            templateColumns='repeat(4, 1fr)'
            templateRows='repeat(10, 1fr)'
            gap={1}
        >
            <GridItem paddingX={"0.5rem"} colSpan={4} rowSpan={1}>
                <SiteDayTopSelect />
            </GridItem>
            <GridItem paddingX={"0.5rem"} colSpan={1} rowSpan={9} alignItems={"start"}>
                <VStack
                    height={"100%"}
                    width={"100%"}
                    overflowY="auto"
                    overflowX="hidden"
                    align={"start"}
                    css={{
                        '&::-webkit-scrollbar': {
                            width: '8px',
                            backgroundColor: scrollBarColor,
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: scrollBarThumbColor,
                            borderRadius: '4px',
                        },
                        '&:hover::-webkit-scrollbar-thumb': {
                            backgroundColor: scrollBarThumbHoverColor,
                        },
                    }}
                >
                    <SiteDailySettlementContentTable />
                </VStack>
            </GridItem>
            <GridItem paddingX={"0.5rem"} colSpan={1} rowSpan={9} alignItems={"start"}>
                <VStack
                    height={"100%"}
                    width={"100%"}
                    overflowY="auto"
                    overflowX="hidden"
                    align={"start"}
                    css={{
                        '&::-webkit-scrollbar': {
                            width: '8px',
                            backgroundColor: scrollBarColor,
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: scrollBarThumbColor,
                            borderRadius: '4px',
                        },
                        '&:hover::-webkit-scrollbar-thumb': {
                            backgroundColor: scrollBarThumbHoverColor,
                        },
                    }}
                >
                    <SiteDailySettlementTop5Table />
                </VStack>
            </GridItem>
        </Grid>
    );
}
