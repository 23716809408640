import { Box, Center, Flex, Popover, PopoverBody, PopoverContent, PopoverTrigger, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import { useMemo } from "react";

import { calculateSums, findChildren } from "./PBUserTreeTableUtils";
import PBUserTreeTableRow from "./PBuserTreeTableRow";
import { useFetchPBLosing3MonData } from "../../PBLosingMonTotal/contents/fetchDada";
import { useRecoilValue } from "recoil";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";
import BaseTable from "../../CommContents/Table/BaseTable";

// UserTreeData 타입 정의
export interface IPBUserTreeData {
    user_id: string;
    user_nickname: string;
    status: string;
    status_id_list: string[];
    [key: string]: any;
}

export interface IPBUserAtomData {
    user_id: string;
    user_nickname: string;
    status: string;
    status_id_list: string[];
    status_list: string[];
    [key: string]: any;
}



export interface IPBUserTreeDataProps {
    site_name: string;
    company_name: string;
    start_date: string;
    end_date: string;
    PBUserTreeDatas: IPBUserTreeData[];
    LoginRate: number;
    // UserAtomData: IPBUserAtomData[];
    // IvdOptin: IcsIdvOption;
    // KeyIvdOptin: IPBkIvdOptin;

}

// 유저 데이터에서 동적으로 key 추출
const PBextractKeys = (UserTreeDatas: IPBUserTreeData[]): string[] => {
    const allKeys = new Set<string>();
    UserTreeDatas.forEach((userData) => {
        Object.keys(userData).forEach((key) => {
            if (
                !["user_id", "user_nickname", "status", "status_id_list"].includes(key)
            ) {
                // const re_key = key.replace("_", " ");
                allKeys.add(key);
            }
        });
    });

    // 정렬에서 제외할 항목들 리무진 특수처리  
    let excludeKeys = [] as string[];
    if (allKeys.has("상위 수수료")) {
        excludeKeys = ["손익금 합계", "상위 수수료", "루징금 합계"];
    } else {
        excludeKeys = ["손익금 합계", "루징금 합계"];
    }

    // 필터링된 키들을 첫 번째 단어 기준으로 정렬
    const sortedKeys = Array.from(allKeys)
        .filter(key => !excludeKeys.includes(key))  // 제외할 키들은 필터링
        .sort((a, b) => {
            const firstWordA = a.split("_")[0]; // 첫 번째 단어 추출
            const firstWordB = b.split("_")[0]; // 첫 번째 단어 추출
            return firstWordA.localeCompare(firstWordB, 'en'); // 첫 번째 단어를 기준으로 정렬
        });

    // 제외된 항목들을 정렬된 리스트의 마지막에 추가
    return [...sortedKeys, ...excludeKeys];
};

// 하위 유저 포함한 숫자 데이터 합산 함수 (루징율은 합산하지 않음)
export const PBcalculateSums = (UserTreeData: IPBUserTreeData[], user: IPBUserTreeData, dataKeys: string[]): Record<string, number> => {
    const children = findChildren(UserTreeData, user.user_id, user.status_id_list.length);
    const initialSums = dataKeys.reduce((acc, key) => {
        acc[key] = user[key] || 0;
        return acc;
    }, {} as Record<string, number>);

    children.forEach((child) => {
        const childSums = calculateSums(UserTreeData, child, dataKeys);
        dataKeys.forEach((key) => {
            initialSums[key] += childSums[key];
        });
    });

    return initialSums;
};


export default function PBUserTreeTable({
    site_name, company_name, start_date, end_date,
    PBUserTreeDatas, LoginRate,
}: IPBUserTreeDataProps) {

    const topUsers: IPBUserTreeData[] = [PBUserTreeDatas[0]];
    const dataKeys = PBextractKeys(PBUserTreeDatas);

    const totalSums = useMemo(() => {
        return PBcalculateSums(PBUserTreeDatas, topUsers[0], dataKeys);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topUsers, dataKeys]);

    const show_up_fee = (PBUserTreeDatas.length > 0 && Object.keys(PBUserTreeDatas[0]).some(key => key.includes("상위 수수료")))
        ? PBUserTreeDatas.reduce((sum, user) => sum + (user["상위 수수료"] || 0), 0)
        : false;


    // "손익금 합계"와 "루징금 합계" 총합을 계산
    const totalProfitSum = totalSums["손익금 합계"] || 0;
    const totalLosingSum = totalSums["루징금 합계"] || 0;

    const SelectedMenu = useRecoilValue(SelectedMenuAtom);
    const { PBlosing3MonTableData, loading } = useFetchPBLosing3MonData(SelectedMenu);


    //테마 관련
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const titleColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);

    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    const formatValueWithColor = (value: number) => {
        const formattedValue = value.toLocaleString();
        const isNegative = value < 0;
        return (
            <Text as="span" color={isNegative ? negativeColor : undefined}>
                {formattedValue}
            </Text>
        );
    };

    return (
        <Box
            width={"100%"}
            overflowX="auto"
            bg={mainBgColor}
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: scrollBarColor,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbColor,
                    borderRadius: '4px',
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbHoverColor,
                },
            }}
        >
            {/* 상단 타이틀 */}
            <Flex bg={inputBgColor} color={mainFontColor} alignItems={"center"} justifyContent={"space-around"} py={2} mb={2} borderRadius={"md"}>
                <Box>
                    <Center>{site_name} {company_name} 루징 정산표</Center>
                </Box>
                <Box>
                    <Center>{start_date} ~ {end_date}</Center>
                </Box>
            </Flex>

            <Table bg={bgColor} colorScheme="gray" size="sm">
                <Thead bg={titleColor}>
                    <Tr>
                        <Th>유저 ID<br />(닉네임)</Th>
                        <Th>상태</Th>

                        {/* 동적으로 dataKeys 헤더 렌더링 - casino_activate, slot_activate, holdem_activate 확인 */}
                        {dataKeys.map((key) => {
                            return (
                                <Th key={key} textAlign="right">
                                    {key.split('_')[0]}<br />{key.split('_')[1]}
                                </Th>
                            );
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {topUsers.map((user) => (
                        <PBUserTreeTableRow key={user.user_id} UserTreeDatas={PBUserTreeDatas} UserRowData={user} dataKeys={dataKeys} />
                    ))}
                </Tbody>

                <Tbody bg={titleColor}>
                    <Tr>
                        <Td></Td>
                        <Td>TOTAL</Td>

                        {/* 동적으로 총합 렌더링 - casino_activate, slot_activate, holdem_activate 확인 */}
                        {dataKeys.map((key) => {
                            const isNegative = totalSums[key] < 0;
                            return (
                                <Td key={key} color={isNegative ? negativeColor : undefined} textAlign="right">
                                    {totalSums[key]?.toLocaleString() || "-"}
                                </Td>
                            );
                        })}
                    </Tr>
                </Tbody>

            </Table>
            {typeof show_up_fee === "number" ? (
                <Flex bg={inputBgColor} color={mainFontColor} alignItems={"center"} justifyContent={"space-around"} py={2} my={2} borderRadius={"md"}>
                    <Box>
                        <Center>Total</Center>
                        <Center>{formatValueWithColor(totalProfitSum)}</Center> {/* 손익금 합계 표시 */}
                    </Box>
                    <Box>
                        <Center>루징율</Center>
                        <Center>{LoginRate}%</Center>
                    </Box>
                    <Box>
                        <Center>상위 수수료</Center>
                        <Center>{formatValueWithColor(show_up_fee)}</Center>
                    </Box>
                    <Box>
                        <Center>정산 금액</Center>
                        <Center>
                            <Popover placement="auto-end">
                                <PopoverTrigger>
                                    <Box cursor="pointer" _hover={{ textDecoration: "underline" }}>
                                        {formatValueWithColor(totalLosingSum)}
                                    </Box>
                                </PopoverTrigger>
                                <PopoverContent borderRadius="md" boxShadow="md" zIndex={4} width="auto" maxWidth="100%">
                                    <PopoverBody>
                                        {loading ? (
                                            <Center flexDirection="column">
                                                <Spinner size="lg" />  {/* 스피너 추가 */}
                                                <Text mt={2}>로딩 중...</Text>  {/* 로딩 메시지 표시 */}
                                            </Center>
                                        ) : PBlosing3MonTableData ? (
                                            <BaseTable tableData={PBlosing3MonTableData} />
                                        ) : (
                                            <Text>데이터를 불러오지 못했습니다.</Text>
                                        )}
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </Center> {/* 루징금 합계 표시 */}
                    </Box>
                </Flex>

            ) : (
                <Flex bg={inputBgColor} color={mainFontColor} alignItems={"center"} justifyContent={"space-around"} py={2} my={2} borderRadius={"md"}>
                    <Box>
                        <Center>Total</Center>
                        <Center>{formatValueWithColor(totalProfitSum)}</Center> {/* 손익금 합계 표시 */}
                    </Box>
                    <Box>
                        <Center>루징율</Center>
                        <Center>{LoginRate}% </Center>
                    </Box>
                    <Box>
                        <Center>정산 금액</Center>
                        <Center>
                            <Popover placement="auto-end">
                                <PopoverTrigger>
                                    <Box cursor="pointer" _hover={{ textDecoration: "underline" }}>
                                        {formatValueWithColor(totalLosingSum)}
                                    </Box>
                                </PopoverTrigger>
                                <PopoverContent borderRadius="md" boxShadow="md" zIndex={4} width="auto" maxWidth="100%">
                                    <PopoverBody>
                                        {loading ? (
                                            <Center flexDirection="column">
                                                <Spinner size="lg" />  {/* 스피너 추가 */}
                                                <Text mt={2}>로딩 중...</Text>  {/* 로딩 메시지 표시 */}
                                            </Center>
                                        ) : PBlosing3MonTableData ? (
                                            <BaseTable tableData={PBlosing3MonTableData} />
                                        ) : (
                                            <Text>데이터를 불러오지 못했습니다.</Text>
                                        )}
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>

                        </Center> {/* 루징금 합계 표시 */}
                    </Box>
                </Flex>
            )}


        </Box>
    );

}


