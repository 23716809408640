interface Data {
    [key: string]: any;
}



export function getLoginShareValues(data: Data[]): { [key: string]: number } {
    const profitSummary: { [key: string]: number } = {};

    data.forEach(record => {
        Object.keys(record).forEach(key => {
            if (key.startsWith('pb_profit')) {
                const gameType = key.replace('pb_profit_', '');
                if (profitSummary[gameType] !== undefined) {
                    profitSummary[gameType] += record[key];
                } else {
                    profitSummary[gameType] = record[key];
                }
            }
        });
    });

    return profitSummary;
}



export function getRowAndColTypes(data: { [key: string]: number }): { rowTypes: string[], colTypes: string[] } {
    const rowTypesSet = new Set<string>();
    const colTypesSet = new Set<string>();

    Object.keys(data).forEach(key => {
        const [colType, rowType] = key.split('_');
        if (rowType) {
            rowTypesSet.add(rowType);
        }
        if (colType) {
            colTypesSet.add(colType);
        }
    });

    return {
        rowTypes: Array.from(rowTypesSet),
        colTypes: Array.from(colTypesSet)
    };
}


export function sumSelectedValues(dataObject: Data, keysArray: string[]) {

    if (keysArray === undefined) {
        return 0;
    }
    if (keysArray.length === 0) {
        return 0;
    }

    let sum = 0;

    keysArray.forEach(key => {
        if (dataObject.hasOwnProperty(key)) {
            sum += dataObject[key];
        }
    });


    return sum;
}

export function filteredOptionList(data: Record<string, number>, list: string[]): string[] {
    /**
     * 옵션을 없는거 선택된 옵션을 제거한다. ( 동행사다리 3조합 같은거.)
     주어진 data 객체와 list 배열을 기반으로, list에서 data에 존재하지 않는 key를 제거
     */
    return list.filter(item => data.hasOwnProperty(item));
}