import { axiosInstance } from "./base";
// signup
export interface ISignup {
    username: string;
    password: string;
    re_password: string;
    distributor: number;
}

export const userSignup = ({ username, password, re_password, distributor }: ISignup) => (
    axiosInstance.post(
        `users/signup/`,
        { username, password, re_password, distributor },
    ).then((response) => response.data)
)

//user login
export interface IUser {
    pk: number;
    username: string;
    authority: "manager" | "distributor" | "user" | "muser";
    distributor: number;
    login_verified: boolean;
}

export interface IUserLogin {
    username: string;
    password: string;
}

export interface IuserLoginReturn {
    expiry: Date;
    token: string;
    user: {
        username: string
    }
}

export const userLogIn = ({ username, password }: IUserLogin) => (
    axiosInstance.post(
        `users/login/`,
        { username, password },

    ).then((response) => response.data)
)

// setPassword
export interface ISetPassword {
    old_password: string;
    new_password: string;
    re_password: string;
}

export const userPutPassword = ({ old_password, new_password, re_password }: ISetPassword) => (
    axiosInstance.put(
        `users/change_password/`,
        { old_password, new_password, re_password },
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }

    ).then((response) => response.data)
)


// user logout
export const userLogout = () => (
    axiosInstance.post(
        `users/logout/`,
        null,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)

// user info
export interface IUserInfo {
    "pk": number;
    "username": string;
    "nickname": string;
    "authority": "manager" | "distributor" | "user" | "muser";
    "distributor": string;
    "login_verified": boolean;
    "available_date": string;
    "login_ip": string;
    "login_datetime": string;
    "logout_datetime": string;
    "created_at": string;
}

export const getUserInfo = () => (
    axiosInstance.get(
        `users/user/`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)


// Distributor's users (구매로 연결된 유저 목록 가져온다.)
export interface IDistributorUser {
    "pk": number;
    "username": string;
    "nickname": string;
    "authority": "manager" | "distributor" | "user" | "muser";
    "distributor": {
        "pk": number;
        "name": string;
    },
    "is_process": boolean;
    "is_client_activate": boolean;
    "is_connection": boolean;
    "balance": string;
    "battery": string;
    "buy_count": number;
    "login_ip": string;
    "login_datetime": string;
    "logout_datetime": string;
    "created_at": string;
}

export const getDistributorUsers = () => (
    axiosInstance.get(
        `users/distributor/?userType=user`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)

export const getDistributorMusers = () => (
    axiosInstance.get(
        `users/distributor/?userType=muser`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)