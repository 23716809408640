import { useRef, useState } from "react";
import { IBankType } from "../../../../api/banktype";
import { InitialData, useBankInfoData, useBankTypeData } from "../../../userMainContesnts/SiteDetailSettlement/consponents/BankData";
import MBaseTable from "../../CommContents/Table/MBaseTable";

export default function MBankTable() {
    // 뱅크 타입 데이터 가져오기
    const bankTypeRef = useRef<IBankType[]>([]);
    useBankTypeData({ bankTypeRef });

    // 뱅크 정보 데이터 가져와서 테이블데이터 구성
    const [tableData, setTableData] = useState<InitialData>({ headers: [], rows: [] });
    useBankInfoData({ bankTypeRef, setTableData });

    console.log(tableData);

    return (
        <MBaseTable headers={tableData.headers} rows={tableData.rows} />
    );
}