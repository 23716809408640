import { useRecoilState } from "recoil";

import { useRef, useState } from "react";
import { Box, Flex, Button, Menu, MenuButton, useColorModeValue, MenuList, MenuItem, IconButton, Spinner, Input } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { generateYears, get_next_year_str, get_prev_year_str, getCurrentYear } from "../../../../lib/util"; // 연도 관련 함수들로 수정
import { ISelectMenu, SelectedMenuAtom } from "../../../../atoms/topSelect";

export default function SiteComYearTopSelect() {

    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom);

    // 사이트 선택
    const handleSiteSelect = (site: ISelectMenu) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.site = site;
        const reload_tag = new Date().getTime();
        before_SelectedMenu.reload_tag = reload_tag
        setSelectedMenu(before_SelectedMenu);
    };


    // 카지노 업체 선택
    const [searchCSCom, setSearchCSCom] = useState(""); // 검색어 상태
    const inputCSComRef = useRef<HTMLInputElement>(null); // Input 필드를 참조할 ref 생성

    const handleCSComSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchCSCom(e.target.value); // 검색어 업데이트
    };
    const handleCSComInputFocus = () => {
        // 검색어가 있으면 전체 선택
        if (inputCSComRef.current) {
            inputCSComRef.current.select();
        }
    };
    const handleCSComSelect = (CSCom: ISelectMenu) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.CSCom = CSCom;
        setSelectedMenu(before_SelectedMenu);
    };
    const filteredCSComList = SelectedMenu.CSComList.filter((site) =>
        site.name.toLowerCase().includes(searchCSCom.toLowerCase())
        // site.name.toLowerCase().startsWith(searchCSCom.toLowerCase())
    );

    // 파워볼 업체 선택 
    const [searchPBCom, setSearchPBCom] = useState(""); // 검색어 상태
    const inputPBComRef = useRef<HTMLInputElement>(null); // Input 필드를 참조할 ref 생성

    const handlePBComSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchPBCom(e.target.value); // 검색어 업데이트
    };
    const handlePBComInputFocus = () => {
        // 검색어가 있으면 전체 선택
        if (inputPBComRef.current) {
            inputPBComRef.current.select();
        }
    };
    const handlePBComSelectd = (PBCom: ISelectMenu) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.PBCom = PBCom;
        setSelectedMenu(before_SelectedMenu);
    };
    const filteredPBComList = SelectedMenu.PBComList.filter((site) =>
        site.name.toLowerCase().includes(searchPBCom.toLowerCase())
        // site.name.toLowerCase().startsWith(searchPBCom.toLowerCase())
    );


    // 년 선택
    const years = generateYears(); // 연도 리스트 생성
    const [yearIsLoading, setYearIsLoading] = useState(false);

    const handleYearSelect = (year: string) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.Year = year; // 연도 선택을 반영

        setSelectedMenu(before_SelectedMenu);
    };

    const handlePrevYear = () => {
        setYearIsLoading(true);
        setTimeout(() => {
            let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
            before_SelectedMenu.Year = get_prev_year_str(before_SelectedMenu.Year);
            setSelectedMenu(before_SelectedMenu);
            setYearIsLoading(false);
        }, 100); // simulate a delay
    };

    const handleNextYear = () => {
        setYearIsLoading(true);
        setTimeout(() => {
            let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
            before_SelectedMenu.Year = get_next_year_str(before_SelectedMenu.Year);
            setSelectedMenu(before_SelectedMenu);
            setYearIsLoading(false);
        }, 100); // simulate a delay
    };

    const formattedYear = `${SelectedMenu.Year}`; // 선택된 연도를 포맷팅

    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);

    return (
        <Flex
            h={"100%"}
            alignItems={"center"}
            direction="row"
            bg={bgColor}
            borderRadius={"md"}
        >
            <Box padding={"1rem"}>
                <Menu>
                    <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                        {SelectedMenu.site.name}
                    </MenuButton>
                    <MenuList maxHeight="30rem" overflowY="auto">
                        {SelectedMenu.sitelist.map((site, index) => (
                            <MenuItem key={site.pk} onClick={() => handleSiteSelect(site)}>
                                {site.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>

            {SelectedMenu.pageSelect === "PBLosingMonTotal" && (
                <Box padding={"1rem"}>
                    <Menu closeOnSelect={false}>
                        <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                            {SelectedMenu.PBCom.name}
                        </MenuButton>
                        <MenuList maxHeight="30rem" overflowY="auto">

                            {/* 검색 입력 필드 추가 */}
                            <Box padding="1rem">
                                <Input
                                    ref={inputPBComRef} // Input 필드를 참조
                                    placeholder="업체 이름 검색"
                                    value={searchPBCom}
                                    onChange={handlePBComSearchChange}
                                    onFocus={handlePBComInputFocus} // 포커스될 때 전체 선택
                                />
                            </Box>

                            {/* 필터링된 목록 출력 */}
                            {filteredPBComList.map((site) => (
                                <MenuItem key={site.pk} onClick={() => handlePBComSelectd(site)}>
                                    {site.name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </Box>
            )}

            {SelectedMenu.pageSelect === "CSLosingMonTotal" && (
                <Box padding={"1rem"}>
                    <Menu closeOnSelect={false}>
                        <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                            {SelectedMenu.CSCom.name}
                        </MenuButton>
                        <MenuList maxHeight="30rem" overflowY="auto">

                            {/* 검색 입력 필드 추가 */}
                            <Box padding="1rem">
                                <Input
                                    ref={inputCSComRef} // Input 필드를 참조
                                    placeholder="업체 이름 검색"
                                    value={searchCSCom}
                                    onChange={handleCSComSearchChange}
                                    onFocus={handleCSComInputFocus} // 포커스될 때 전체 선택
                                />
                            </Box>
                            {/* 필터링된 목록 출력 */}
                            {filteredCSComList.map((site) => (
                                <MenuItem key={site.pk} onClick={() => handleCSComSelect(site)}>
                                    {site.name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </Box>
            )}


            <Box padding={"1rem"}>
                <IconButton
                    aria-label="Previous Year"
                    icon={yearIsLoading ? <Spinner size="sm" /> : <FaChevronLeft />}
                    onClick={handlePrevYear}
                    isDisabled={yearIsLoading}
                />
            </Box>

            <Box paddingY={"1rem"}>
                <Menu>
                    <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                        {formattedYear} {/* 선택된 연도 표시 */}
                    </MenuButton>
                    <MenuList>
                        {years.map((year, index) => (
                            <MenuItem key={index} onClick={() => handleYearSelect(year)}>
                                {year}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>

            <Box padding={"1rem"}>
                <IconButton
                    aria-label="Next Year"
                    icon={yearIsLoading ? <Spinner size="sm" /> : <FaChevronRight />}
                    onClick={handleNextYear}
                    isDisabled={yearIsLoading || SelectedMenu.Year === getCurrentYear()}
                />
            </Box>

        </Flex>
    );
}
