import { useRecoilState } from "recoil";
import { useState } from "react";
import { Box, Button, Flex, Menu, MenuButton, MenuItem, MenuList, Portal, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { FaArrowRight, FaChevronDown } from "react-icons/fa";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import { generateMonths } from "../../../../lib/util";

const categories = ['파워볼', '카지노/슬롯', '홀덤', '스포츠', '기타', '충전/환전', '유저머니', '뱅크'];

const FootSelectmenuItems = [
    { name: '일일정산', rank: 1, path: 'SiteDailySettlement', icon: FaArrowRight },
    { name: '세부정산', rank: 1, path: 'SiteDetailSettlement', icon: FaArrowRight },
];

export default function MonthComOptMenuFootSelect() {
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selectedFootMenu, setSelectedFootMenu] = useState(FootSelectmenuItems[1]); // 선택된 메뉴 항목 상태

    // 월 선택 
    const months = generateMonths();
    const handleMonthSelect = (month: string) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.Month = month;
        setSelectedMenu(before_SelectedMenu);
    };
    const formattedMonth = `${SelectedMenu.Month}`;

    // 사이트 선택    
    const handleSiteSelect = (site: any) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.site = site;
        const reload_tag = new Date().getTime();
        before_SelectedMenu.reload_tag = reload_tag
        setSelectedMenu(before_SelectedMenu);
    };

    // 종목 선택
    const handleCategorySelect = (category: string) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.category = category;
        setSelectedMenu(before_SelectedMenu);
    }

    const handleFootMenuSelect = (item: any) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.pageSelect = item.path;
        setSelectedMenu(before_SelectedMenu);
        setSelectedFootMenu(item); // 선택된 메뉴 항목 업데이트
        onClose(); // 메뉴를 닫음
    };

    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);

    return (
        <Flex
            h={"3rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
            direction="row"
            bg={bgColor}
            borderRadius={"md"}
            zIndex={3} // zIndex 값을 설정하여 테이블의 고정된 DATE와 합계 위에 위치하지 않도록 함
        >


            <Box paddingY={"0.2rem"}>
                <Menu>
                    <MenuButton
                        as={Button}
                        size={"sm"}
                    >
                        {formattedMonth}
                    </MenuButton>
                    <Portal>
                        <MenuList>
                            {months.map((month, index) => (
                                <MenuItem key={index} onClick={() => handleMonthSelect(month)}>
                                    {month}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Portal>
                </Menu>
            </Box>

            <Box padding={"0.2rem"}>
                <Menu>
                    <MenuButton
                        as={Button}
                        size={"sm"}
                        rightIcon={<FaChevronDown />}
                    >
                        {SelectedMenu.site.name}
                    </MenuButton>
                    <Portal>
                        <MenuList maxHeight="30rem" overflowY="auto">
                            {SelectedMenu.sitelist.map((site, index) => (
                                <MenuItem key={site.pk} onClick={() => handleSiteSelect(site)}>
                                    {site.name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Portal>
                </Menu>
            </Box>

            <Box padding={"0.2rem"}>
                <Menu>
                    <MenuButton as={Button} size={"sm"} rightIcon={<FaChevronDown />}>
                        {SelectedMenu.category}
                    </MenuButton>
                    <Portal>

                        <MenuList>
                            {categories.map((category, index) => (
                                <MenuItem key={index} onClick={() => handleCategorySelect(category)}>
                                    {category}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Portal>
                </Menu>
            </Box>

            <Box padding={"0.2rem"}>
                <Menu>
                    <MenuButton as={Button} size={"sm"} rightIcon={<FaChevronDown />}>
                        {selectedFootMenu.name}
                    </MenuButton>
                    <Portal>

                        <MenuList>
                            {FootSelectmenuItems.map((item, index) => (
                                <MenuItem key={index} onClick={() => handleFootMenuSelect(item)}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Portal>
                </Menu>
            </Box>

        </Flex>
    );
}