import { Grid, GridItem, useColorModeValue, VStack } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../theme";

import PBComRegList from "./Contents/ComRegList";
import RegTopSelect from "../CommContents/TopSelect/RegTopSelect";


export default function PBLogingShreCompanyRegistration() {


    // 테마 관련 코드 
    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);
    return (
        <Grid padding={"0.2rem"}
            h='100%'
            templateColumns='repeat(1, 1fr)'
            templateRows='repeat(10, 1fr)'
            gap={1}
        >
            <GridItem borderRadius={"xl"} paddingX={"0.5rem"} colSpan={1} rowSpan={1}>
                <RegTopSelect />
            </GridItem>
            <GridItem paddingX={"0.5rem"} colSpan={1} rowSpan={9} alignItems={"start"} >
                <VStack
                    height={"100%"}
                    width={"100%"}
                    overflowY="auto"
                    overflowX="hidden"
                    align={"start"}
                    css={{
                        '&::-webkit-scrollbar': {
                            width: '8px',
                            backgroundColor: scrollBarColor,
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: scrollBarThumbColor,
                            borderRadius: '4px',
                        },
                        '&:hover::-webkit-scrollbar-thumb': {
                            backgroundColor: scrollBarThumbHoverColor,
                        },
                    }}
                >
                    <PBComRegList />

                </VStack>
            </GridItem>

        </Grid>
    )
}

