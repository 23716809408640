import React, { useState } from 'react';
import {
    Box, Button, Menu, MenuButton, MenuList, MenuItem, useColorModeValue, IconButton,
    Flex, Spinner,
    useDisclosure
} from '@chakra-ui/react';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useRecoilState } from 'recoil';
import { SelectedMenuAtom } from '../../../../atoms/topSelect';
import { darkTheme_colors, lightTheme_colors } from '../../../../theme';



const compareDates = (date1: string, date2: Date) => {
    // 시간 정보를 제외한 날짜만 비교
    const d1 = new Date(date1);
    const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    return d1 >= d2;
};

export default function SiteDayTopSelect() {
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)
    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    // 사이트 선택    
    const handleSiteSelect = (site: any) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.site = site;
        const reload_tag = new Date().getTime();
        before_SelectedMenu.reload_tag = reload_tag
        setSelectedMenu(before_SelectedMenu);
    };

    // 날짜 선택 
    const handlePrevDay = () => {
        setIsLoading(true);
        setTimeout(() => {
            let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
            const newDate = new Date(before_SelectedMenu.Date);
            newDate.setDate(newDate.getDate() - 1);
            before_SelectedMenu.Date = newDate.toISOString().split('T')[0];
            setSelectedMenu(before_SelectedMenu);
            setIsLoading(false);
        }, 200); // 스피너가 도는 시간을 조정할 수 있습니다.
    };

    const handleNextDay = () => {
        setIsLoading(true);
        setTimeout(() => {
            let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
            const newDate = new Date(before_SelectedMenu.Date);
            newDate.setDate(newDate.getDate() + 1);
            before_SelectedMenu.Date = newDate.toISOString().split('T')[0];
            setSelectedMenu(before_SelectedMenu);
            setIsLoading(false);
        }, 200); // 스피너가 도는 시간을 조정할 수 있습니다.
    };

    const handleDateChange = (date: Date | null) => {
        if (date) {
            let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
            before_SelectedMenu.Date = date.toISOString().split('T')[0];
            setSelectedMenu(before_SelectedMenu);
            onClose(); // 날짜를 선택한 후 메뉴를 닫음
        }
    };

    const handleTargetDateClick = () => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        const targetDay = new Date();
        targetDay.setDate(targetDay.getDate() - 1); // 날짜를 하루 전으로 설정
        before_SelectedMenu.Date = targetDay.toISOString().split('T')[0];
        setSelectedMenu(before_SelectedMenu);
    };

    const formattedDate = new Date(SelectedMenu.Date).toLocaleDateString('ko-KR', { year: 'numeric', month: 'long', day: 'numeric' });
    const isNextDayDisabled = compareDates(SelectedMenu.Date, new Date());
    const isToday = SelectedMenu.Date === new Date().toISOString().split('T')[0];

    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);

    return (
        <Flex
            h={"100%"}
            alignItems={"center"}
            direction="row"
            bg={bgColor}
            borderRadius={"md"}>
            <Box padding={"1rem"}>
                <Menu>
                    <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                        {SelectedMenu.site.name}
                    </MenuButton>
                    <MenuList maxHeight="30rem" overflowY="auto">
                        {SelectedMenu.sitelist.map((site, index) => (
                            <MenuItem key={site.pk} onClick={() => handleSiteSelect(site)}>
                                {site.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>

            <Box padding={"1rem"}>
                <IconButton
                    aria-label="Previous Day"
                    icon={isLoading ? <Spinner size="sm" /> : <FaChevronLeft />}
                    onClick={handlePrevDay}
                    isDisabled={isLoading}
                />
            </Box>

            <Box paddingY={"1rem"}>
                <Menu isOpen={isOpen} onClose={onClose}>
                    <MenuButton as={Button} rightIcon={<FaChevronDown />} onClick={onOpen}>
                        {formattedDate}
                    </MenuButton>
                    <MenuList>
                        <Box padding="1rem">
                            <DatePicker
                                selected={new Date(SelectedMenu.Date)} // selectedDate를 Date 객체로 변환
                                onChange={handleDateChange}
                                inline
                                dateFormat="yyyy/MM/dd"
                                maxDate={new Date()} // 오늘 날짜보다 큰 날짜는 선택 불가능
                            />
                        </Box>
                    </MenuList>
                </Menu>
            </Box>

            <Box padding={"1rem"}>
                <IconButton
                    aria-label="Next Day"
                    icon={isLoading ? <Spinner size="sm" /> : <FaChevronRight />}
                    onClick={handleNextDay}
                    isDisabled={isNextDayDisabled}
                />
            </Box>

            {!isToday && (
                <Box padding={"1rem"}>
                    <Button onClick={handleTargetDateClick}>기준 날짜로</Button>
                </Box>
            )}
        </Flex>
    );
}
