
import { IPBUserTreeData } from "./PBuserTreeTable";


// 특정 유저의 하위 유저를 찾는 함수
export const findChildren = (UserTreeData: IPBUserTreeData[], userId: string, userLevel: number) => {
    return UserTreeData.filter(
        (user) =>
            user.status_id_list.length === userLevel + 1 &&
            user.status_id_list.includes(userId)
    );
};

// 유저 데이터에서 동적으로 key 추출
export const extractKeys = (UserTreeDatas: IPBUserTreeData[]): string[] => {
    const allKeys = new Set<string>();
    UserTreeDatas.forEach((userData) => {
        Object.keys(userData).forEach((key) => {
            if (
                !["user_id", "user_nickname", "status", "status_id_list", "카지노 루징율", "슬롯 루징율", "홀덤 루징율"].includes(key)
            ) {
                allKeys.add(key);
            }
        });
    });
    return Array.from(allKeys);
};

// 하위 유저 포함한 숫자 데이터 합산 함수 (루징율은 합산하지 않음)
export const calculateSums = (UserTreeData: IPBUserTreeData[], user: IPBUserTreeData, dataKeys: string[]): Record<string, number> => {
    const children = findChildren(UserTreeData, user.user_id, user.status_id_list.length);
    const initialSums = dataKeys.reduce((acc, key) => {
        acc[key] = user[key] || 0;
        return acc;
    }, {} as Record<string, number>);

    children.forEach((child) => {
        const childSums = calculateSums(UserTreeData, child, dataKeys);
        dataKeys.forEach((key) => {
            initialSums[key] += childSums[key];
        });
    });

    return initialSums;
};


