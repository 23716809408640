import { useSportData } from '../../../userMainContesnts/SiteDetailSettlement/consponents/SoprtData';
import MBaseTable from '../../CommContents/Table/MBaseTable';

export default function MSportTable() {
    const SportData = useSportData();

    return (
        <MBaseTable headers={SportData.headers} rows={SportData.rows} />
    );
}
