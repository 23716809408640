import { createBrowserRouter } from "react-router-dom";

import { isMobile } from 'react-device-detect';

import UserMain from "./contents/UserMain";
import LoginFirst from "./contents/LoginFirst";

import Root from "./contents/Root";
import MobileUserMain from "./contents/MobileUserMain";



const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        children: [
            {
                path: "/",
                element: isMobile ? <MobileUserMain /> : <UserMain />
                // element: <TestPage />
            },
            {
                path: "loginfirst",
                element: <LoginFirst />,
            },
        ],

    }
]);
export default router;