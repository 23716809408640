import { Box, Center, Flex, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { BaseTableProps } from "../../../userMainContesnts/CommContents/Table/BaseTable";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";

export default function MBaseTop5Table({ tableData }: BaseTableProps) {
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const titleColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);

    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    // tableData가 undefined일 경우 기본값으로 설정
    const effectiveTableData = tableData || {
        headers: [],
        rows: []
    };

    const getColumnWidth = (index: number) => {
        if (index === 0) return "15%";
        if (index === 1) return "25%";
        return `${30 / (effectiveTableData.headers.length - 2)}%`;
    };

    return (
        <Box
            width={"100%"}
            overflowX="auto"
            bg={mainBgColor}
            color={mainFontColor}
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: scrollBarColor,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbColor,
                    borderRadius: '4px',
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbHoverColor,
                },
            }}
        >
            <Table variant="striped" bg={bgColor} colorScheme="gray" size="sm">
                <Thead bg={titleColor}>
                    <Tr>
                        {effectiveTableData.headers.length > 0 ? (
                            effectiveTableData.headers.map((header: string, index: number) => (
                                <Th
                                    key={index}
                                    textAlign="center"
                                    padding="0.2rem"
                                    fontSize={"2xs"}
                                    width={getColumnWidth(index)} // 열 너비 설정
                                >
                                    {header}
                                </Th>
                            ))
                        ) : (
                            <Th textAlign="center" width="100%">---</Th>
                        )}
                    </Tr>
                </Thead>
                <Tbody>
                    {effectiveTableData.rows.length === 0 ? (
                        <Tr>
                            <Td colSpan={effectiveTableData.headers.length || 1} textAlign="center" padding="0.2rem">
                                <Center color={mainFontColor}>데이터가 없습니다.</Center>
                            </Td>
                        </Tr>
                    ) : (
                        effectiveTableData.rows.map((row: any, rowIndex: number) => (
                            <Tr key={rowIndex}>
                                {effectiveTableData.headers.map((header: string, colIndex: number) => {
                                    const cellValue = row[header];
                                    const numericValue = parseFloat(cellValue.toString().replace(/,/g, ''));
                                    const isNegative = !isNaN(numericValue) && numericValue < 0;

                                    return (
                                        <Td
                                            key={colIndex}
                                            textAlign={colIndex === 0 ? "center" : "right"}
                                            padding="0.2rem"
                                            color={isNegative ? negativeColor : undefined}
                                            width={getColumnWidth(colIndex)} // 열 너비 설정                                            
                                        >
                                            <Flex
                                                justify={colIndex === 0 ? "center" : "flex-end"}
                                                fontSize={colIndex === 1 ? "xs" : "sm"} // 글자 크기 조건부 설정
                                            >
                                                {typeof cellValue === 'number' ? Math.round(cellValue / 10000) : cellValue}
                                            </Flex>
                                        </Td>
                                    );
                                })}
                            </Tr>
                        ))
                    )}
                </Tbody>
            </Table>
        </Box>
    );
}
