import React, { useEffect, useRef, useState } from 'react';
import { Fade, Flex, useColorModeValue } from '@chakra-ui/react';
import { darkTheme_colors, lightTheme_colors } from '../../../theme';

import { useRecoilValue } from 'recoil';

import { useMutation } from '@tanstack/react-query';
import { getSiteData } from '../../../api/sitedata';
import { calculateTotal, convertToNumber, getMonthFirstAndLastDay, getTotalByDate, transformNumbersToStrings } from '../../../lib/dashboardAndDalilySettlementUtil';
import DashBoardTable from './Contents/BoardTable';
import { SelectedMenuAtom } from '../../../atoms/topSelect';


interface Category {
    name: string;
    dailyProfit: string;
    totalProfit: string;
    isTotal?: boolean;
}

interface Transaction {
    name: string;
    dailyAmount: string;
    totalAmount: string;
    isTotal?: boolean;
}

interface Data {
    siteName: string;
    categories: Category[];
    transactions: Transaction[];
}

function sumData(datalist: Data[], siteNameStr: string): Data {
    if (!Array.isArray(datalist)) {
        console.error("datalist is not an array");
        return { siteName: siteNameStr, categories: [], transactions: [] }; // 빈 데이터를 반환
    }

    if (datalist.length === 0) {
        console.error("datalist is empty");
        return { siteName: siteNameStr, categories: [], transactions: [] }; // 빈 데이터를 반환
    }

    const categoriesMap: { [key: string]: { dailyProfit: number, totalProfit: number } } = {};
    const transactionsMap: { [key: string]: { dailyAmount: number, totalAmount: number } } = {};

    datalist.forEach(site => {
        // Process categories
        site.categories.forEach(category => {
            if (!categoriesMap[category.name]) {
                categoriesMap[category.name] = { dailyProfit: 0, totalProfit: 0 };
            }
            const dailyProfit = parseInt(category.dailyProfit.replace(/,/g, ''), 10) || 0;
            const totalProfit = parseInt(category.totalProfit.replace(/,/g, ''), 10) || 0;
            categoriesMap[category.name].dailyProfit += dailyProfit;
            categoriesMap[category.name].totalProfit += totalProfit;
        });

        // Process transactions
        site.transactions.forEach(transaction => {
            if (!transactionsMap[transaction.name]) {
                transactionsMap[transaction.name] = { dailyAmount: 0, totalAmount: 0 };
            }
            const dailyAmount = parseInt(transaction.dailyAmount.replace(/,/g, ''), 10) || 0;
            const totalAmount = parseInt(transaction.totalAmount.replace(/,/g, ''), 10) || 0;
            transactionsMap[transaction.name].dailyAmount += dailyAmount;
            transactionsMap[transaction.name].totalAmount += totalAmount;
        });
    });

    const aggregatedCategories = Object.keys(categoriesMap).map(name => ({
        name,
        dailyProfit: categoriesMap[name].dailyProfit.toLocaleString(),
        totalProfit: categoriesMap[name].totalProfit.toLocaleString(),
        isTotal: name === 'TOTAL'
    }));

    const aggregatedTransactions = Object.keys(transactionsMap).map(name => ({
        name,
        dailyAmount: transactionsMap[name].dailyAmount.toLocaleString(),
        totalAmount: transactionsMap[name].totalAmount.toLocaleString(),
        isTotal: name === '충환 손익'
    }));

    return {
        siteName: siteNameStr,
        categories: aggregatedCategories,
        transactions: aggregatedTransactions
    };
}


const defaultdata: Data = {
    siteName: '로딩중',
    categories: [
        { name: '파워볼', dailyProfit: '0', totalProfit: '0' },
        { name: '카지노', dailyProfit: '0', totalProfit: '0' },
        { name: '슬롯', dailyProfit: '0', totalProfit: '0' },
        { name: '홀덤', dailyProfit: '0', totalProfit: '0' },
        { name: 'TOTAL', dailyProfit: '0', totalProfit: '0', isTotal: true }
    ],
    transactions: [
        { name: '충전', dailyAmount: '0', totalAmount: '0' },
        { name: '환전', dailyAmount: '0', totalAmount: '0' },
        { name: '충환 손익', dailyAmount: '0', totalAmount: '0', isTotal: true }
    ],
};

// const data2 = {
//     siteName: '멜론',
//     categories: [
//         { name: '파워볼', dailyProfit: '8,000,671', totalProfit: '21,000,835' },
//         { name: '카지노', dailyProfit: '18,000,542', totalProfit: '82,000,061' },
//         { name: '슬롯', dailyProfit: '1,000,047', totalProfit: '77,000,826' },
//         { name: '홀덤', dailyProfit: '1,000,000', totalProfit: '3,500,000' },
//         { name: 'TOTAL', dailyProfit: '28,000,260', totalProfit: '181,000,722', isTotal: true }
//     ],
//     transactions: [
//         { name: '충전', dailyAmount: '18,000,542', totalAmount: '82,000,061' },
//         { name: '환전', dailyAmount: '25,000,047', totalAmount: '77,000,826' },
//         { name: '충환 손익', dailyAmount: '-7,000,505', totalAmount: '4,000,235', isTotal: true }
//     ],
// };

export default function DashBoard() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    // 데이터 가져오기.
    const [totelData, setTotalData] = useState<Data>(defaultdata);
    const [selectedSumData, setSelectedSumData] = useState<Data>(defaultdata);
    const [dataList, setDataList] = useState<Data[]>([defaultdata,]);

    // 데이터 임시보관.
    const pbdataRef = useRef<any>([{ name: '파워볼', dailyProfit: '0', totalProfit: '0' }]);
    const casinoslotDataRef = useRef<any>([{ name: '카지노', dailyProfit: '0', totalProfit: '0' },
    { name: '슬롯', dailyProfit: '0', totalProfit: '0' },]);
    const holdemDataRef = useRef<any>([{ name: '홀덤', dailyProfit: '0', totalProfit: '0' },]);

    const charge_exchangeDataRef = useRef<any>([{ name: '충전 금액', dailyAmount: '0', totalAmount: '0' },
    { name: '환전 금액', dailyAmount: '0', totalAmount: '0' },]);

    // 어제 날짜로를 구한다.
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayStr = yesterday.toISOString().split('T')[0];

    useEffect(() => {
        // 어제 날짜로 dateRange 를 구한다.    
        const dateRange = getMonthFirstAndLastDay(yesterdayStr);

        let sitedatalist = [] as Data[];


        SelectedMenu.sitelist.forEach((site) => {
            const pbParams = {
                siteinfo: site.pk,
                datatype: "powerball",
                start_date: dateRange[0],
                end_date: dateRange[1],
            }

            const casinoParams = {
                siteinfo: site.pk,
                datatype: "casinoslot",
                start_date: dateRange[0],
                end_date: dateRange[1],
            }

            const holdemParams = {
                siteinfo: site.pk,
                datatype: "holdem",
                start_date: dateRange[0],
                end_date: dateRange[1],
            }

            const charge_exchangeParams = {
                siteinfo: site.pk,
                datatype: "charge_exchange",
                start_date: dateRange[0],
                end_date: dateRange[1],
            }

            Promise.all([
                powerballDataMutation.mutateAsync(pbParams),
                casinoslotDataMutation.mutateAsync(casinoParams),
                holdemDataMutation.mutateAsync(holdemParams),
                charge_exchangeDataMutation.mutateAsync(charge_exchangeParams),
            ]).then(() => {
                const finaldata = {
                    siteName: site.name,
                    categories: [
                        ...pbdataRef.current,
                        ...casinoslotDataRef.current,
                        ...holdemDataRef.current,
                        {
                            name: 'TOTAL',
                            dailyProfit: calculateTotal([...pbdataRef.current, ...casinoslotDataRef.current, ...holdemDataRef.current], 'dailyProfit'),
                            totalProfit: calculateTotal([...pbdataRef.current, ...casinoslotDataRef.current, ...holdemDataRef.current], 'totalProfit'),
                            isTotal: true
                        }
                    ],
                    transactions: [
                        ...charge_exchangeDataRef.current,
                        {
                            name: '충환 손익',
                            dailyAmount: (parseInt(charge_exchangeDataRef.current[0].dailyAmount.replace(/,/g, ''), 10) - parseInt(charge_exchangeDataRef.current[1].dailyAmount.replace(/,/g, ''), 10)).toLocaleString(),
                            totalAmount: (parseInt(charge_exchangeDataRef.current[0].totalAmount.replace(/,/g, ''), 10) - parseInt(charge_exchangeDataRef.current[1].totalAmount.replace(/,/g, ''), 10)).toLocaleString(),
                            isTotal: true
                        }
                    ],
                };
                sitedatalist.push(finaldata);
                setDataList([...sitedatalist]); // React state를 업데이트
            }).then(() => {
                // 합산 데이터 생성       
                setTotalData(sumData(sitedatalist, "합 산"));
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const powerballDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                pbdataRef.current = [{ name: '파워볼', dailyProfit: '0', totalProfit: '0' }];
                return;
            }
            let data_list = [] as any;
            data.forEach((sitedata: any) => {
                const json_data = JSON.parse(sitedata.data);
                data_list.push(json_data);
            })
            data_list = transformNumbersToStrings(data_list);

            const pbdata = [{
                name: '파워볼',
                dailyProfit: getTotalByDate(data_list, yesterdayStr),
                totalProfit: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.합계), 0).toLocaleString()
            }];

            pbdataRef.current = pbdata;
        },
        onError: (error) => {
            console.error("파워볼 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });

    const casinoslotDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                casinoslotDataRef.current = [{ name: '카지노', dailyProfit: '0', totalProfit: '0' },
                { name: '슬롯', dailyProfit: '0', totalProfit: '0' }];
                return;
            }
            let data_list = [] as any;
            data.forEach((sitedata: any) => {
                const json_data = JSON.parse(sitedata.data);
                data_list.push(json_data);
            })
            data_list = transformNumbersToStrings(data_list);

            const casinoslotData = [
                {
                    name: '카지노',
                    dailyProfit: getTotalByDate(data_list, yesterdayStr),
                    totalProfit: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.카지노), 0).toLocaleString()
                },
                {
                    name: '슬롯',
                    dailyProfit: getTotalByDate(data_list, yesterdayStr),
                    totalProfit: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.슬롯), 0).toLocaleString()
                }
            ];
            ;
            casinoslotDataRef.current = casinoslotData;
        },
        onError: (error) => {
            console.error("카지슬롯 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });

    const holdemDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {

                holdemDataRef.current = [{ name: '홀덤', dailyProfit: '0', totalProfit: '0' }];
                return;
            }
            let data_list = [] as any;
            data.forEach((sitedata: any) => {
                const json_data = JSON.parse(sitedata.data);
                data_list.push(json_data);
            })
            data_list = transformNumbersToStrings(data_list);

            const holdemData = [{
                name: '홀덤',
                dailyProfit: getTotalByDate(data_list, yesterdayStr),
                totalProfit: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.합계), 0).toLocaleString()
            }];

            holdemDataRef.current = holdemData;
        },
        onError: (error) => {
            console.error("홀덤 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });


    const charge_exchangeDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                charge_exchangeDataRef.current = [{ name: '충전 금액', dailyAmount: '0', totalAmount: '0' },
                { name: '환전 금액', dailyAmount: '0', totalAmount: '0' }];
                return;
            }
            let data_list = [] as any;
            data.forEach((sitedata: any) => {
                const json_data = JSON.parse(sitedata.data);
                data_list.push(json_data);
            })
            data_list = transformNumbersToStrings(data_list);

            const charge_exchangeData = [
                {
                    name: '충전 금액',
                    dailyAmount: getTotalByDate(data_list, yesterdayStr),
                    totalAmount: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.충전), 0).toLocaleString()
                },
                {
                    name: '환전 금액',
                    dailyAmount: getTotalByDate(data_list, yesterdayStr),
                    totalAmount: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.환전), 0).toLocaleString()
                }
            ];

            charge_exchangeDataRef.current = charge_exchangeData;
        },
        onError: (error) => {
            console.error("충환 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });

    // 선택된 사이트 이름들을 관리하기 위한 상태 (초기값은 빈 배열)
    const [selectedSiteNames, setSelectedSiteNames] = useState<string[]>([]);
    const [isVisible, setIsVisible] = useState(false);
    const [shouldRender, setShouldRender] = useState(false);

    // DashBoardTable 클릭 핸들러
    const handleTableClick = (siteName: string) => {
        setSelectedSiteNames(prevSelected => {
            if (prevSelected.includes(siteName)) {
                // 이미 선택된 경우, 해당 siteName을 목록에서 제거
                return prevSelected.filter(name => name !== siteName);
            } else {
                // 선택되지 않은 경우, siteName을 목록에 추가
                return [...prevSelected, siteName];
            }
        });
    };

    // 사이트가 선택된경우 처리, 선택된 싸이트 이름으로 dataList 데이터리스트에서  siteName이 같은 데이터를 찾아서 리스트로 만든다.  
    useEffect(() => {
        if (selectedSiteNames.length === 0) {
            setIsVisible(false); // Trigger fade out
            return;
        }
        const selectedDataList = dataList.filter(data => selectedSiteNames.includes(data.siteName));
        setSelectedSumData(sumData(selectedDataList, "선택된 사이트 합"));
        setShouldRender(true); // Ensure the component is rendered
        setIsVisible(true); // Trigger fade in
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSiteNames]);


    // 테마
    const mainBgColor = useColorModeValue(lightTheme_colors.mainBgColor, darkTheme_colors.mainBgColor);
    const mainFontColor = useColorModeValue(lightTheme_colors.mainFontColor, darkTheme_colors.mainFontColor);
    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);

    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    return (
        <Flex
            direction="column"
            padding="1rem"
            bg={mainBgColor}
            color={mainFontColor}
            borderRadius="md"
            w="100%"
            minWidth="80%"
            h="100%" // 높이 100% 설정
            overflowY="auto" // DashBoard 컴포넌트 내에서만 스크롤이 가능하도록 설정
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: scrollBarColor,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbColor,
                    borderRadius: '4px',
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbHoverColor,
                },
            }}

        >
            {/* Categories Table */}
            <DashBoardTable data1={totelData} isSelected={false} />

            {/* Animated Transactions Table */}
            {shouldRender && (
                <Fade
                    in={isVisible}
                    transition={{ enter: { duration: 0.5 }, exit: { duration: 0.4 } }}
                    onAnimationComplete={() => {
                        if (!isVisible) {
                            setShouldRender(false); // Remove the component from DOM
                            setSelectedSumData(defaultdata); // Reset data if needed
                        }
                    }}
                >
                    <DashBoardTable data1={selectedSumData} isSelected={false} />
                </Fade>
            )}

            {/* Categories Table */}
            {dataList.map((data) => (
                <div key={data.siteName} onClick={() => handleTableClick(data.siteName)}>
                    <DashBoardTable
                        data1={data}
                        isSelected={selectedSiteNames.includes(data.siteName)}
                    />
                </div>
            ))}
        </Flex>
    );
};



