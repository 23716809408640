import { Box, Center, Flex, Popover, PopoverBody, PopoverContent, PopoverTrigger, Spinner, Table, Tbody, Td, Text, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import { useMemo } from "react";
import { IcsIdvOption } from "../../../../api/csidvoption";
import UserTreeTableRow from "./UserTreeTableRow";
import { calculateSums, extractKeys, findChildren } from "./UserTreeTableUtils";
import { useRecoilValue } from "recoil";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";
import { useFetchCSLosing3MonData } from "../../CSLosingMonTotal/conpnents/fetchData";
import BaseTable from "../../CommContents/Table/BaseTable";

// UserTreeData 타입 정의
export interface IUserTreeData {
    user_id: string;
    user_nickname: string;
    status: string;
    status_id_list: string[];
    [key: string]: any;
}

export interface IUserAtomData {
    user_id: string;
    user_nickname: string;
    status: string;
    status_id_list: string[];
    status_list: string[];
    [key: string]: any;
}

// IdvBaseOptin idv 정의
interface IkIvdOptin {
    [userId: string]: {
        casino_login_rate?: number,
        slot_login_rate?: number,
        holdem_login_rate?: number
    }
}

export interface IUserTreeDataProps {
    site_name: string;
    company_name: string;
    start_date: string;
    end_date: string;
    UserTreeDatas: IUserTreeData[];
    UserAtomData: IUserAtomData[];
    IvdOptin: IcsIdvOption;
    KeyIvdOptin: IkIvdOptin;
    onSendData: (data: any) => void;
}


export default function UserTreeTable({
    site_name, company_name, start_date, end_date,
    UserTreeDatas, IvdOptin, onSendData
}: IUserTreeDataProps) {
    const topUsers: IUserTreeData[] = [UserTreeDatas[0]];
    const dataKeys = extractKeys(UserTreeDatas);

    const totalSums = useMemo(() => {
        return calculateSums(UserTreeDatas, topUsers[0], dataKeys);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topUsers, dataKeys]);

    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    const { CSlosingMonTableData, loading } = useFetchCSLosing3MonData(SelectedMenu);  // 커스텀 훅 사용 


    const handleRateChange = (userId: string, rateType: string, value: number) => {
        /*
        input :  popo333  casino_login_rate   7
    
        라인의 개별 유저의 루징율 수정데이터가 오면. 
        그걸로 그 유저의 하위 유저 전부를 수정해야한다. 

        cost_adjust 일때는 하위유저를 수정하지 않는다.
        */
        // 입력된 데이터 리스트만든다.  
        let inputOptionList = [[userId, rateType, value]];

        // 하위 유저를 수정하지 않아야 하는 경우를 체크
        if (rateType !== "cost_adjust") {
            // 입력된 유저의 유저레벨을 찾는다.
            const userLevel = UserTreeDatas.find((user) => user.user_id === userId)?.status_id_list.length || 0;

            // 입력 데이터 유저의 하위 유저를 찾아서 리스트에 추가
            const findChild = (userId: string, userLevel: number) => {
                const children = findChildren(UserTreeDatas, userId, userLevel);
                children.forEach((child) => {
                    inputOptionList.push([child.user_id, rateType, value]);
                    findChild(child.user_id, userLevel + 1);
                });
            };

            findChild(userId, userLevel);
        }

        // 수정할 데이터
        const before_IvdUserOptin = JSON.parse(IvdOptin.idv_login_rates);
        let after_IvdUserOptin = before_IvdUserOptin;

        // 수정할 데이터에 입력된 데이터를 반영
        inputOptionList.forEach((inputData) => (
            after_IvdUserOptin = {
                ...after_IvdUserOptin,
                [inputData[0]]: {   // userId
                    ...after_IvdUserOptin[inputData[0]], // userId의 데이터
                    [inputData[1]]: inputData[2], // rateType , value
                },
            }
        ));

        // 수정할 데이터를 보낸다.   
        const InputIvdUserOptin = {
            targetIvdOptin: IvdOptin,
            IvdUserOptin: after_IvdUserOptin,
        }

        onSendData(InputIvdUserOptin)
    };


    //테마 관련
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const titleColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);

    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    return (
        <Box
            width={"100%"}
            overflowX="auto"
            bg={mainBgColor}
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: scrollBarColor,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbColor,
                    borderRadius: '4px',
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbHoverColor,
                },
            }}
        >

            {/* 상단 타이틀 */}
            <Flex bg={inputBgColor} color={mainFontColor} alignItems={"center"} justifyContent={"space-around"} py={2} mb={2} borderRadius={"md"}>
                <Box>
                    <Center>{site_name} {company_name} 루징 정산표</Center>
                </Box>
                <Box>
                    <Center>{start_date} ~ {end_date}</Center>
                </Box>
            </Flex>
            <Table bg={bgColor} colorScheme="gray" size="sm">
                <Thead bg={titleColor}>
                    <Tr>
                        <Th>유저 ID<br />(닉네임)</Th>
                        <Th>상태</Th>

                        {/* casino_activate가 true일 때만 카지노 관련 헤더를 렌더링 */}
                        {IvdOptin.casino_activate && <Th>카지노<br />루징율</Th>}

                        {/* slot_activate가 true일 때만 슬롯 관련 헤더를 렌더링 */}
                        {IvdOptin.slot_activate && <Th>슬롯<br />루징율</Th>}

                        {/* holdem_activate가 true일 때만 홀덤 관련 헤더를 렌더링 */}
                        {IvdOptin.holdem_activate && <Th>홀덤<br />루징율</Th>}

                        {/* 동적으로 dataKeys 헤더 렌더링 - casino_activate, slot_activate, holdem_activate 확인 */}
                        {dataKeys.map((key) => {
                            // 카지노 관련 헤더 제외
                            if (key.includes("카지노") && !IvdOptin.casino_activate) return null;
                            // 슬롯 관련 헤더 제외
                            if (key.includes("슬롯") && !IvdOptin.slot_activate) return null;
                            // 홀덤 관련 헤더 제외
                            if (key.includes("홀덤") && !IvdOptin.holdem_activate) return null;

                            return (
                                <Th key={key}>
                                    {key.split(' ')[0]}<br />{key.split(' ')[1]}
                                </Th>
                            );
                        })}
                    </Tr>
                </Thead>
                <Tbody>
                    {topUsers.map((user) => (
                        <UserTreeTableRow key={user.user_id} UserTreeDatas={UserTreeDatas} UserRowData={user} dataKeys={dataKeys} IvdOptin={IvdOptin} onRateChange={handleRateChange} />
                    ))}
                </Tbody>
                <Tbody bg={titleColor}>
                    <Tr>
                        <Td></Td>
                        <Td>TOTAL</Td>

                        {/* casino_activate가 true일 때만 카지노 관련 총합을 렌더링 */}
                        {IvdOptin.casino_activate && <Td></Td>}

                        {/* slot_activate가 true일 때만 슬롯 관련 총합을 렌더링 */}
                        {IvdOptin.slot_activate && <Td></Td>}

                        {/* holdem_activate가 true일 때만 홀덤 관련 총합을 렌더링 */}
                        {IvdOptin.holdem_activate && <Td></Td>}

                        {/* 동적으로 총합 렌더링 - casino_activate, slot_activate, holdem_activate 확인 */}
                        {dataKeys.map((key) => {
                            // 카지노 관련 열 제외
                            if (key.includes("카지노") && !IvdOptin.casino_activate) return null;
                            // 슬롯 관련 열 제외
                            if (key.includes("슬롯") && !IvdOptin.slot_activate) return null;
                            // 홀덤 관련 열 제외
                            if (key.includes("홀덤") && !IvdOptin.holdem_activate) return null;

                            const isNegative = totalSums[key] < 0;

                            // "루징금 합계" 항목 클릭 시 팝오버 표시
                            if (key === "루징금 합계") {
                                return (
                                    <Td key={key} textAlign="right" color={isNegative ? negativeColor : undefined}>
                                        <Popover placement="auto-end">
                                            <PopoverTrigger>
                                                <Box cursor="pointer" _hover={{ textDecoration: "underline" }}>
                                                    {totalSums[key]?.toLocaleString() || "-"}
                                                </Box>
                                            </PopoverTrigger>
                                            <PopoverContent borderRadius="md" boxShadow="md" zIndex={4} width="auto" maxWidth="100%">


                                                <PopoverBody>
                                                    {loading ? (
                                                        <Center flexDirection="column">
                                                            <Spinner size="lg" />  {/* 스피너 추가 */}
                                                            <Text mt={2}>로딩 중...</Text>  {/* 로딩 메시지 표시 */}
                                                        </Center>
                                                    ) : CSlosingMonTableData ? (
                                                        <BaseTable tableData={CSlosingMonTableData.tableData} />
                                                    ) : (
                                                        <Text>데이터를 불러오지 못했습니다.</Text>
                                                    )}
                                                </PopoverBody>
                                            </PopoverContent>
                                        </Popover>
                                    </Td>
                                );
                            }
                            return (
                                <Td key={key} color={isNegative ? negativeColor : undefined} textAlign="right">
                                    {totalSums[key]?.toLocaleString() || "-"}
                                </Td>
                            );
                        })}
                    </Tr>
                </Tbody>
            </Table>
        </Box>
    );

}


/*
IdvBaseOptin_mon idv {
    "tkwk": {
        "casino_login_rate": 10,
        "slot_login_rate": 20,
        "holdem_login_rate": 30
    }
}
*/