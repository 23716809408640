import { useRecoilState } from "recoil";

import { useRef, useState } from "react";
import { Box, Flex, Button, Menu, MenuButton, useColorModeValue, MenuList, MenuItem, IconButton, Spinner, useDisclosure, Input } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import { FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { generateMonths, get_next_month_str, get_prev_month_str, getCurrentMonth } from "../../../../lib/util";
import { ISelectMenu, SelectedMenuAtom } from "../../../../atoms/topSelect";
import PBPutIdvRegModal from "../../PBLosingShreComm/Modal/PutIdvReg";
import CSPutIdvRegModal from "../../CSLosiongShreComm/Modal/PutIdvReg";



export default function SiteComMonComOptTopSelect() {
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)


    // 사이트 선택 ( 사이트 선택이 돠면 업체 리스트를 불러와야 한다. )
    const handleSiteSelect = (site: ISelectMenu) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.site = site;
        const reload_tag = new Date().getTime();
        before_SelectedMenu.reload_tag = reload_tag
        setSelectedMenu(before_SelectedMenu);
    };

    // 카지노 업체 선택
    const [searchCSCom, setSearchCSCom] = useState(""); // 검색어 상태
    const inputCSComRef = useRef<HTMLInputElement>(null); // Input 필드를 참조할 ref 생성

    const handleCSComSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchCSCom(e.target.value); // 검색어 업데이트
    };
    const handleCSComInputFocus = () => {
        // 검색어가 있으면 전체 선택
        if (inputCSComRef.current) {
            inputCSComRef.current.select();
        }
    };
    const handleCSComSelect = (CSCom: ISelectMenu) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.CSCom = CSCom;
        setSelectedMenu(before_SelectedMenu);
    };
    const filteredCSComList = SelectedMenu.CSComList.filter((site) =>
        site.name.toLowerCase().includes(searchCSCom.toLowerCase())
        // site.name.toLowerCase().startsWith(searchCSCom.toLowerCase())
    );

    // 파워볼 업체 선택 
    const [searchPBCom, setSearchPBCom] = useState(""); // 검색어 상태
    const inputPBComRef = useRef<HTMLInputElement>(null); // Input 필드를 참조할 ref 생성

    const handlePBComSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchPBCom(e.target.value); // 검색어 업데이트
    };
    const handlePBComInputFocus = () => {
        // 검색어가 있으면 전체 선택
        if (inputPBComRef.current) {
            inputPBComRef.current.select();
        }
    };
    const handlePBComSelectd = (PBCom: ISelectMenu) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.PBCom = PBCom;
        setSelectedMenu(before_SelectedMenu);
    };
    const filteredPBComList = SelectedMenu.PBComList.filter((site) =>
        site.name.toLowerCase().includes(searchPBCom.toLowerCase())
        // site.name.toLowerCase().startsWith(searchPBCom.toLowerCase())
    );


    // 월 선택
    const months = generateMonths();
    const [monthIsLoading, setMonthIsLoading] = useState(false);

    const handleMonthSelect = (month: string) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.Month = month;
        setSelectedMenu(before_SelectedMenu);
    };

    const handlePrevMonth = () => {
        setMonthIsLoading(true);
        setTimeout(() => {
            let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
            before_SelectedMenu.Month = get_prev_month_str(before_SelectedMenu.Month);
            setSelectedMenu(before_SelectedMenu);
            setMonthIsLoading(false);
        }, 100); // simulate a delay
    };

    const handleNextMonth = () => {
        setMonthIsLoading(true);
        setTimeout(() => {
            let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
            before_SelectedMenu.Month = get_next_month_str(before_SelectedMenu.Month);
            setSelectedMenu(before_SelectedMenu);
            setMonthIsLoading(false);
        }, 100); // simulate a delay
    };

    const formattedMonth = `${SelectedMenu.Month}`;

    // 옵션 수정 모달
    const {
        isOpen: PBPutRegisOpen,
        onOpen: PBPutRegonOpen,
        onClose: PBPutRegonClose,
    } = useDisclosure();


    const handlePBPutOpenModal = () => {
        PBPutRegonOpen();            // 모달 열기
    };


    const {
        isOpen: CSPutRegisOpen,
        onOpen: CSPutRegonOpen,
        onClose: CSPutRegonClose,
    } = useDisclosure();


    const handleCSPutOpenModal = () => {
        CSPutRegonOpen();            // 모달 열기
    };



    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);

    return (
        <Flex
            h={"100%"}
            alignItems={"center"}
            direction="row"
            bg={bgColor}
            borderRadius={"md"}
        >
            <Box padding={"1rem"}>
                <Menu>
                    <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                        {SelectedMenu.site.name}
                    </MenuButton>
                    <MenuList>
                        {SelectedMenu.sitelist.map((site, index) => (
                            <MenuItem key={site.pk} onClick={() => handleSiteSelect(site)}>
                                {site.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>

            {(SelectedMenu.pageSelect === "PBLosingShreMontly" || SelectedMenu.pageSelect === "PBLosingMontlyUserTree") && (
                <Box padding={"1rem"}>
                    <Menu closeOnSelect={false}>
                        <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                            {SelectedMenu.PBCom.name}
                        </MenuButton>
                        <MenuList maxHeight="30rem" overflowY="auto">

                            {/* 검색 입력 필드 추가 */}
                            <Box padding="1rem">
                                <Input
                                    ref={inputPBComRef} // Input 필드를 참조
                                    placeholder="업체 이름 검색"
                                    value={searchPBCom}
                                    onChange={handlePBComSearchChange}
                                    onFocus={handlePBComInputFocus} // 포커스될 때 전체 선택
                                />
                            </Box>

                            {/* 필터링된 목록 출력 */}
                            {filteredPBComList.map((site) => (
                                <MenuItem key={site.pk} onClick={() => handlePBComSelectd(site)}>
                                    {site.name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </Box>
            )}

            {SelectedMenu.pageSelect === "CSLosingShreMontly" && (
                <Box padding={"1rem"}>
                    <Menu closeOnSelect={false}>
                        <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                            {SelectedMenu.CSCom.name}
                        </MenuButton>
                        <MenuList maxHeight="30rem" overflowY="auto">

                            {/* 검색 입력 필드 추가 */}
                            <Box padding="1rem">
                                <Input
                                    ref={inputCSComRef} // Input 필드를 참조
                                    placeholder="업체 이름 검색"
                                    value={searchCSCom}
                                    onChange={handleCSComSearchChange}
                                    onFocus={handleCSComInputFocus} // 포커스될 때 전체 선택
                                />
                            </Box>
                            {/* 필터링된 목록 출력 */}
                            {filteredCSComList.map((site) => (
                                <MenuItem key={site.pk} onClick={() => handleCSComSelect(site)}>
                                    {site.name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Menu>
                </Box>
            )}


            <Box padding={"1rem"}>
                <IconButton
                    aria-label="Previous Month"
                    icon={monthIsLoading ? <Spinner size="sm" /> : <FaChevronLeft />}
                    onClick={handlePrevMonth}
                    isDisabled={monthIsLoading}
                />
            </Box>

            <Box paddingY={"1rem"}>
                <Menu>
                    <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                        {formattedMonth}
                    </MenuButton>
                    <MenuList>
                        {months.map((month, index) => (
                            <MenuItem key={index} onClick={() => handleMonthSelect(month)}>
                                {month}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>

            <Box padding={"1rem"}>
                <IconButton
                    aria-label="Next Month"
                    icon={monthIsLoading ? <Spinner size="sm" /> : <FaChevronRight />}
                    onClick={handleNextMonth}
                    isDisabled={monthIsLoading || SelectedMenu.Month === getCurrentMonth()}
                />
            </Box>

            {(SelectedMenu.pageSelect === "PBLosingShreMontly" || SelectedMenu.pageSelect === "PBLosingMontlyUserTree") && SelectedMenu.PBCom.name !== "등록된 업체 없슴" && (
                <Box padding="1rem" display="flex" alignItems="center" ml="auto">
                    <Button marginRight="1rem" colorScheme="green" onClick={handlePBPutOpenModal}>
                        {SelectedMenu.PBCom.name} 옵션 수정
                    </Button>
                </Box>
            )}

            {SelectedMenu.pageSelect === "CSLosingShreMontly" && SelectedMenu.CSCom.name !== "등록된 업체 없슴" && (
                <Box padding="1rem" display="flex" alignItems="center" ml="auto">
                    <Button marginRight="1rem" colorScheme="orange" onClick={handleCSPutOpenModal}>
                        {SelectedMenu.CSCom.name} 옵션 수정
                    </Button>
                </Box>
            )}

            <PBPutIdvRegModal isOpen={PBPutRegisOpen} onClose={PBPutRegonClose} option="month" />
            <CSPutIdvRegModal isOpen={CSPutRegisOpen} onClose={CSPutRegonClose} option="month" />


        </Flex>


    );
}
