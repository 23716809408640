import { useRecoilState } from "recoil";
import { useState } from "react";
import { Box, Button, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Portal, Spinner, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import { FaArrowRight, FaChevronDown, FaChevronLeft, FaChevronRight } from "react-icons/fa";
import DatePicker from "react-datepicker";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";


const compareDates = (date1: string, date2: Date) => {
    // 시간 정보를 제외한 날짜만 비교
    const d1 = new Date(date1);
    const d2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());
    return d1 >= d2;
};


const FootSelectmenuItems = [
    { name: '일일정산', rank: 1, path: 'SiteDailySettlement', icon: FaArrowRight },
    { name: '세부정산', rank: 1, path: 'SiteDetailSettlement', icon: FaArrowRight },
];

export default function DateComMenuFootSelect() {
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)

    const [isLoading, setIsLoading] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [selectedFootMenu, setSelectedFootMenu] = useState(FootSelectmenuItems[0]); // 선택된 메뉴 항목 상태

    // 사이트 선택    
    const handleSiteSelect = (site: any) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.site = site;
        const reload_tag = new Date().getTime();
        before_SelectedMenu.reload_tag = reload_tag
        setSelectedMenu(before_SelectedMenu);
    };

    // 날짜 선택 
    const handlePrevDay = () => {
        setIsLoading(true);
        setTimeout(() => {
            let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
            const newDate = new Date(before_SelectedMenu.Date);
            newDate.setDate(newDate.getDate() - 1);
            before_SelectedMenu.Date = newDate.toISOString().split('T')[0];
            setSelectedMenu(before_SelectedMenu);
            setIsLoading(false);
        }, 200); // 스피너가 도는 시간을 조정할 수 있습니다.
    };

    const handleNextDay = () => {
        setIsLoading(true);
        setTimeout(() => {
            let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
            const newDate = new Date(before_SelectedMenu.Date);
            newDate.setDate(newDate.getDate() + 1);
            before_SelectedMenu.Date = newDate.toISOString().split('T')[0];
            setSelectedMenu(before_SelectedMenu);
            setIsLoading(false);
        }, 200); // 스피너가 도는 시간을 조정할 수 있습니다.
    };

    const handleDateChange = (date: Date | null) => {
        if (date) {
            let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
            before_SelectedMenu.Date = date.toISOString().split('T')[0];
            setSelectedMenu(before_SelectedMenu);
            onClose(); // 날짜를 선택한 후 메뉴를 닫음
        }
    };


    const handleFootMenuSelect = (item: any) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.pageSelect = item.path;
        setSelectedMenu(before_SelectedMenu);
        setSelectedFootMenu(item); // 선택된 메뉴 항목 업데이트
        onClose(); // 메뉴를 닫음
    };

    const formattedDate = new Date(SelectedMenu.Date).toLocaleDateString('ko-KR', { year: 'numeric', month: 'long', day: 'numeric' });
    const isNextDayDisabled = compareDates(SelectedMenu.Date, new Date());
    const isToday = SelectedMenu.Date === new Date().toISOString().split('T')[0];

    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);

    return (
        <Flex
            h={"3rem"}
            alignItems={"center"}
            justifyContent={"space-between"}
            direction="row"
            bg={bgColor}
            borderRadius={"md"}>


            <Box padding={"0.2rem"}>
                <IconButton
                    size={"sm"}
                    aria-label="Previous Day"
                    icon={isLoading ? <Spinner size="sm" /> : <FaChevronLeft />}
                    onClick={handlePrevDay}
                    isDisabled={isLoading}
                />
            </Box>

            <Box paddingY={"0.2rem"}>
                <Menu isOpen={isOpen} onClose={onClose}>
                    <MenuButton size={"sm"} width={"8rem"} as={Button} onClick={onOpen}>
                        {formattedDate.slice(2, 12)}
                    </MenuButton>
                    <Portal>
                        <MenuList>
                            <Box padding="0.2rem">
                                <DatePicker
                                    selected={new Date(SelectedMenu.Date)} // selectedDate를 Date 객체로 변환
                                    onChange={handleDateChange}
                                    inline
                                    dateFormat="yyyy/MM/dd"
                                    maxDate={new Date()} // 오늘 날짜보다 큰 날짜는 선택 불가능
                                />
                            </Box>
                        </MenuList>
                    </Portal>
                </Menu>
            </Box>

            <Box padding={"0.2rem"}>
                <IconButton
                    size={"sm"}
                    aria-label="Next Day"
                    icon={isLoading ? <Spinner size="sm" /> : <FaChevronRight />}
                    onClick={handleNextDay}
                    isDisabled={isNextDayDisabled}
                />
            </Box>

            <Box padding={"0.2rem"}>
                <Menu>
                    <MenuButton
                        as={Button}
                        size={"sm"}
                        rightIcon={<FaChevronDown />}
                    >
                        {SelectedMenu.site.name}
                    </MenuButton>
                    <Portal>

                        <MenuList maxHeight="30rem" overflowY="auto">
                            {SelectedMenu.sitelist.map((site, index) => (
                                <MenuItem key={site.pk} onClick={() => handleSiteSelect(site)}>
                                    {site.name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Portal>
                </Menu>
            </Box>

            <Box padding={"0.2rem"}>
                <Menu>
                    <MenuButton as={Button} size={"sm"} rightIcon={<FaChevronDown />}>
                        {selectedFootMenu.name}
                    </MenuButton>
                    <Portal>

                        <MenuList>
                            {FootSelectmenuItems.map((item, index) => (
                                <MenuItem key={index} onClick={() => handleFootMenuSelect(item)}>
                                    {item.name}
                                </MenuItem>
                            ))}
                        </MenuList>
                    </Portal>
                </Menu>
            </Box>

        </Flex>
    );
}