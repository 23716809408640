import { Box, Flex, Input, Popover, PopoverArrow, PopoverBody, PopoverCloseButton, PopoverContent, PopoverHeader, PopoverTrigger, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useMemo, useRef, useState } from "react";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";

// 타입 정의
interface IUserSharDataItem {
    lossProfit: number;
    losing: number;
    eggprice: number;
    shareRate: number;
    bat: number;
    win: number;
    r_rate: number;
    disable: boolean;
}

interface IUserShartitle {
    site_name: string;
    company_name: string;
    start_date: string;
    end_date: string;
    idv_show_optin: string;
}

interface IUserSharData {
    casino: IUserSharDataItem;
    slot: IUserSharDataItem;
    holdem: IUserSharDataItem;
}

interface ISharTableOption {
    casino_losing?: number;
    casino_eggprice?: number;
    slot_losing?: number;
    slot_eggprice?: number;
    holdem_losing?: number;
    holdem_eggprice?: number;
    price_adjust?: number;
}


// 더미 데이터 예시
const UserSharData: IUserSharData = {
    casino: {
        lossProfit: 5782963,
        losing: 1156592,
        eggprice: 1000000,
        shareRate: 50,
        bat: 0,
        win: 0,
        r_rate: 8.3,
        disable: false,
    },
    slot: {
        lossProfit: 744671,
        losing: 148934,
        eggprice: 600000,
        shareRate: 20,
        bat: 0,
        win: 0,
        r_rate: 8.3,
        disable: false,
    },
    holdem: {
        lossProfit: 0,
        losing: 1000000,
        eggprice: 0,
        shareRate: 10,
        bat: 0,
        win: 0,
        r_rate: 8.3,
        disable: true,
    },
};

// 팝업 창에서 보여줄 데이터를 테이블로 생성하는 함수
const renderPopoverContent = (data: IUserSharDataItem) => {
    const calculatedLossProfit = data.bat - data.win;
    const calculatedEggValue = Math.round((calculatedLossProfit * data.r_rate) / 100);

    return (
        <Table size="sm" variant="simple">
            <Tbody>
                <Tr>
                    <Td>배팅금액</Td>
                    <Td isNumeric>{data.bat.toLocaleString()}</Td>
                </Tr>
                <Tr>
                    <Td>당첨금액</Td>
                    <Td isNumeric>{data.win.toLocaleString()}</Td>
                </Tr>
                <Tr>
                    <Td>손익 금액</Td>
                    <Td isNumeric>{calculatedLossProfit.toLocaleString()}</Td>
                </Tr>
                <Tr>
                    <Td>알값 기준</Td>
                    <Td isNumeric>{data.r_rate.toLocaleString()}%</Td>
                </Tr>
                <Tr>
                    <Td>알값 계산</Td>
                    <Td isNumeric>{calculatedEggValue.toLocaleString()}</Td>
                </Tr>
            </Tbody>
        </Table>
    );
};

export interface IUserSharDataProps {
    UserShartitle: IUserShartitle;
    UserSharData: IUserSharData;
    IvdOptin: any;
    onSendData: (data: any) => void;
}

export default function UserSharTable({ UserShartitle, UserSharData, IvdOptin, onSendData }: IUserSharDataProps) {
    /**
      SharTableOption = {
        "casino_losing": 0,
        "casino_eggprice" : 0,
        "slot_losing": 0,
        "slot_eggprice": 0,
        "holdem_losing": 0,
        "holdem_eggprice": 0,
        "price_adjust": 50000,
      }       
    */

    const [SharTableOption, setSharTableOption] = useState<ISharTableOption>({});

    // 입력값이 변경되었을때만 onRateChange 를 호출하기 위해 state로 관리
    const [casinoLosing, setCasinoLosing] = useState(SharTableOption["casino_losing"]?.toLocaleString() || "");
    const [casinoEggprice, setCasinoEggprice] = useState(SharTableOption["casino_eggprice"]?.toLocaleString() || "");
    const [slotLosing, setSlotLosing] = useState(SharTableOption["slot_losing"]?.toLocaleString() || "");
    const [slotEggprice, setSlotEggprice] = useState(SharTableOption["slot_eggprice"]?.toLocaleString() || "");
    const [holdemLosing, setHoldemLosing] = useState(SharTableOption["holdem_losing"]?.toLocaleString() || "");
    const [holdemEggprice, setHoldemEggprice] = useState(SharTableOption["holdem_eggprice"]?.toLocaleString() || "");

    const [priceAdjust, setPriceAdjust] = useState(SharTableOption["price_adjust"]?.toLocaleString() || "");

    useEffect(() => {
        const IdvUserOption = JSON.parse(IvdOptin.idv_login_rates)
        const updatedSharTableOption = IdvUserOption['SharTableOption'] === undefined ? {} : IdvUserOption['SharTableOption'];

        // SharTableOption 상태 업데이트
        setSharTableOption(updatedSharTableOption);

        setCasinoLosing(UserSharData.casino.losing.toLocaleString());
        setCasinoEggprice(UserSharData.casino.eggprice.toLocaleString());
        setSlotLosing(UserSharData.slot.losing.toLocaleString());
        setSlotEggprice(UserSharData.slot.eggprice.toLocaleString());
        setHoldemLosing(UserSharData.holdem.losing.toLocaleString());
        setHoldemEggprice(UserSharData.holdem.eggprice.toLocaleString());

        // 바로 updatedSharTableOption 사용
        setPriceAdjust(updatedSharTableOption["price_adjust"]?.toLocaleString() || "0");

    }, [UserSharData, UserShartitle, IvdOptin]);



    // 각 항목에 맞는 상태와 이벤트 핸들러를 설정합니다.
    const getValue = (key: string) => {
        switch (key) {
            case "casino":
                return casinoLosing;
            case "slot":
                return slotLosing;
            case "holdem":
                return holdemLosing;
            default:
                return "";
        }
    };
    const setValue = (key: string, value: string) => {
        switch (key) {
            case "casino":
                setCasinoLosing(value);
                break;
            case "slot":
                setSlotLosing(value);
                break;
            case "holdem":
                setHoldemLosing(value);
                break;
            default:
                break;
        }
    };
    const setBgColor = (key: string) => {
        switch (key) {
            case "casino":
                return SharTableOption.casino_losing === undefined ? inputBgColor : deactivationColor
            case "slot":
                return SharTableOption.slot_losing === undefined ? inputBgColor : deactivationColor
            case "holdem":
                return SharTableOption.holdem_losing === undefined ? inputBgColor : deactivationColor
            default:
                return "";
        }
    }

    const handleInputBlur = (key: string, value: string) => {
        const numericValue = value === "-" ? 0 : parseFloat(value.replace(/,/g, ""));
        const localizedValue = isNaN(numericValue) ? "0" : numericValue.toLocaleString();

        // 상태 업데이트 및 기타 처리 로직
        setValue(key, localizedValue);
        handleBlur(`${key}_losing`, numericValue);
    };

    // 각 "알값"에 맞는 상태와 이벤트 핸들러를 설정합니다.
    const getEggpriceValue = (key: string) => {
        switch (key) {
            case "casino":
                return casinoEggprice;
            case "slot":
                return slotEggprice;
            case "holdem":
                return holdemEggprice;
            default:
                return "";
        }
    };

    const setEggpriceValue = (key: string, value: string) => {
        switch (key) {
            case "casino":
                setCasinoEggprice(value);
                break;
            case "slot":
                setSlotEggprice(value);
                break;
            case "holdem":
                setHoldemEggprice(value);
                break;
            default:
                break;
        }
    };
    const setEggpriceBgColor = (key: string) => {
        switch (key) {
            case "casino":
                return SharTableOption.casino_eggprice === undefined ? inputBgColor : deactivationColor
            case "slot":
                return SharTableOption.slot_eggprice === undefined ? inputBgColor : deactivationColor
            case "holdem":
                return SharTableOption.holdem_eggprice === undefined ? inputBgColor : deactivationColor
            default:
                return "";
        }
    }

    const handleEggpriceBlur = (key: string, value: string) => {
        const numericValue = value === "-" ? 0 : parseFloat(value.replace(/,/g, ""));
        const localizedValue = isNaN(numericValue) ? "0" : numericValue.toLocaleString();

        // 상태 업데이트 및 기타 처리 로직
        setEggpriceValue(key, localizedValue);
        handleBlur(`${key}_eggprice`, numericValue);
    };


    // "금액 조정"의 Input 이벤트 처리 함수
    const handlePriceAdjustBlur = () => {
        const numericValue = priceAdjust === "-" ? 0 : parseFloat(priceAdjust.replace(/,/g, ""));
        const localizedValue = isNaN(numericValue) ? "0" : numericValue.toLocaleString();

        // 상태 업데이트
        setPriceAdjust(localizedValue);

        // 필요 시 onSendData로 변경된 값 전송
        handleBlur("price_adjust", numericValue);
    };


    // Blur 공통 처리 함수
    const handleBlur = (key: string, value: number) => {
        /**         
        SharTableOption = {
            "casino_losing": 0,
            "casino_eggprice": 0,
            "slot_losing": 0,
            "slot_eggprice": 0,
            "holdem_losing": 0,
            "holdem_eggprice": 0,
            "price_adjust": 50000,
        }
        */


        // 수정할 데이터
        const before_IvdUserOptin = JSON.parse(IvdOptin.idv_login_rates);
        let after_IvdUserOptin = before_IvdUserOptin;

        // 수정할 데이터에 입력된 데이터를 반영
        // SharTableOption이 없으면 생성 후 slot_eggprice를 추가하거나 업데이트
        after_IvdUserOptin = {
            ...after_IvdUserOptin,
            SharTableOption: {
                ...after_IvdUserOptin.SharTableOption, // 기존 SharTableOption을 유지
                [key]: value, // slot_eggprice 추가 또는 업데이트
            }
        };

        // 수정할 데이터를 보낸다.   
        const InputIvdUserOptin = {
            targetIvdOptin: IvdOptin,
            IvdUserOptin: after_IvdUserOptin,
        }

        onSendData(InputIvdUserOptin)

    };

    // 손익 데이터 계산
    const totalLossProfit = useMemo(() => {
        return Object.values(UserSharData)
            .filter((item) => !item.disable)
            .reduce((acc, curr) => acc + curr.lossProfit, 0);
    }, [UserSharData]);

    const totalShareAmount = useMemo(() => {
        return Object.values(UserSharData)
            .filter((item) => !item.disable)
            .reduce((acc, curr) => acc + (curr.lossProfit * (curr.shareRate / 100)), 0);
    }, [UserSharData]);

    const totalLosing = useMemo(() => {
        return Object.values(UserSharData)
            .filter((item) => !item.disable)
            .reduce((acc, curr) => acc + curr.losing, 0);
    }, [UserSharData]);

    const totalSpendShareAmount = useMemo(() => {
        return Object.values(UserSharData)
            .filter((item) => !item.disable)
            .reduce((acc, curr) => acc + (curr.losing * (curr.shareRate / 100)), 0);
    }, [UserSharData]);

    const totalEggPrice = useMemo(() => {
        return Object.values(UserSharData)
            .filter((item) => !item.disable)
            .reduce((acc, curr) => acc + curr.eggprice, 0);
    }, [UserSharData]);

    const totalEggPriceShareAmount = useMemo(() => {
        return Object.values(UserSharData)
            .filter((item) => !item.disable)
            .reduce((acc, curr) => acc + (curr.eggprice * (curr.shareRate / 100)), 0);
    }, [UserSharData]);

    // "정산 금액" 계산에 "금액 조정"을 반영
    const finalTotalAmount = useMemo(() => {
        const priceAdjustValue = parseFloat(priceAdjust.replace(/,/g, "")) || 0;
        return totalShareAmount - (totalSpendShareAmount + totalEggPriceShareAmount) + priceAdjustValue;
    }, [totalShareAmount, totalSpendShareAmount, totalEggPriceShareAmount, priceAdjust]);


    // 테마 관련
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const titleColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);
    const deactivationColor = useColorModeValue(lightTheme_colors['deactivationColor'], darkTheme_colors['deactivationColor']);

    const priceAdjustBgColor = SharTableOption.price_adjust === undefined ? inputBgColor : deactivationColor;

    return (
        <Box borderRadius="md" boxShadow="md" w={"100%"} >
            {/* 상단 타이틀 */}
            <Flex bg={inputBgColor} color={mainFontColor} alignItems={"center"} justifyContent={"space-around"} py={2} mb={2} borderRadius={"md"}>
                <Box>
                    {UserShartitle.site_name} {UserShartitle.company_name} 쉐어 정산표
                </Box>
                <Box>
                    {UserShartitle.start_date} ~ {UserShartitle.end_date}
                </Box>
            </Flex>

            {/* 손익 정산 */}
            <Table bg={bgColor} colorScheme="gray" size="sm">
                <Thead bg={titleColor}>
                    <Tr>
                        <Th width="25%">손익 항목</Th>
                        <Th width="25%" isNumeric>손익 금액</Th>
                        <Th width="25%" isNumeric>쉐어율</Th>
                        <Th width="25%" isNumeric>쉐어 금액</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {Object.keys(UserSharData).map((key) => {
                        const item = UserSharData[key as keyof IUserSharData];

                        if (item.disable) return null;

                        return (
                            <Tr key={key}>
                                <Td>{key === "casino" ? "카지노" : key === "slot" ? "슬롯" : "홀덤"}</Td>
                                <Td isNumeric color={item.lossProfit < 0 ? negativeColor : undefined}>
                                    {item.lossProfit.toLocaleString()}
                                </Td>
                                <Td isNumeric>{item.shareRate}%</Td>
                                <Td isNumeric color={(item.lossProfit * (item.shareRate / 100)) < 0 ? negativeColor : undefined}>
                                    {(Math.round(item.lossProfit * (item.shareRate / 100))).toLocaleString()}
                                </Td>
                            </Tr>
                        );
                    })}
                    <Tr fontWeight="bold" bg={titleColor}>
                        <Td>TOTAL</Td>
                        <Td isNumeric color={totalLossProfit < 0 ? negativeColor : undefined}>
                            {(Math.round(totalLossProfit)).toLocaleString()}
                        </Td>
                        <Td></Td>
                        <Td isNumeric color={totalShareAmount < 0 ? negativeColor : undefined}>
                            {(Math.round(totalShareAmount)).toLocaleString()}
                        </Td>
                    </Tr>
                </Tbody>
            </Table>

            {/* 지출 알값 정산 */}
            <Table bg={bgColor} colorScheme="gray" size="sm" mt={4}>
                <Thead bg={titleColor}>
                    <Tr>
                        <Th width="25%">지출 항목</Th>
                        <Th width="25%" isNumeric>지출 금액</Th>
                        <Th width="25%" isNumeric>쉐어율</Th>
                        <Th width="25%" isNumeric>쉐어 지출 금액</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {Object.keys(UserSharData).map((key) => {
                        const item = UserSharData[key as keyof IUserSharData];

                        if (item.disable) return null;

                        return (
                            <Tr key={key}>
                                <Td>{key === "casino" ? "카지노 루징금" : key === "slot" ? "슬롯 루징금" : "홀덤 루징금"}</Td>

                                <Td py="0.2rem">
                                    <Input
                                        size="sm"
                                        type="text"
                                        width="6.5rem"
                                        borderRadius={"lg"}
                                        value={getValue(key)} // 입력 시 해당 상태값을 표시
                                        textAlign="right"
                                        bg={setBgColor(key)}
                                        color={parseFloat(getValue(key).replace(/,/g, "")) < 0 ? negativeColor : undefined} // 음수일 경우 negativeColor 적용
                                        onFocus={(e) => e.target.select()} // Focus 시 전체 선택
                                        onChange={(e) => {
                                            const inputValue = e.target.value.replace(/,/g, "");
                                            // 입력이 "-"이거나 숫자로 변환 가능한 경우만 처리
                                            if (inputValue === "-" || !isNaN(parseFloat(inputValue))) {
                                                setValue(key, inputValue); // 해당 상태값 업데이트
                                            }
                                        }}
                                        onBlur={() => handleInputBlur(key, getValue(key))}
                                    />
                                </Td>
                                <Td isNumeric>{item.shareRate}%</Td>
                                <Td isNumeric color={(item.losing * (item.shareRate / 100)) < 0 ? negativeColor : undefined}>
                                    {(Math.round((item.losing * (item.shareRate / 100)))).toLocaleString()}
                                </Td>
                            </Tr>
                        );
                    })}

                    {Object.keys(UserSharData).map((key) => {
                        const item = UserSharData[key as keyof IUserSharData];

                        if (item.disable) return null;

                        return (
                            <Tr key={key}>
                                <Td>
                                    {/* 팝오버로 해당 항목의 정보를 보여줌 */}
                                    <Popover>
                                        <PopoverTrigger>
                                            <Box cursor="pointer" _hover={{ textDecoration: "underline" }}>
                                                {key === "casino" ? "카지노 알값" : key === "slot" ? "슬롯 알값" : "홀덤 알값"}
                                            </Box>
                                        </PopoverTrigger>
                                        <PopoverContent>
                                            <PopoverArrow />
                                            <PopoverCloseButton />
                                            <PopoverHeader>{`${key === "casino" ? "카지노" : key === "slot" ? "슬롯" : "홀덤"} 상세 정보`}</PopoverHeader>
                                            <PopoverBody>
                                                {renderPopoverContent(item)}
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </Td>
                                {/* 알값 Input */}
                                <Td py="0.2rem">
                                    <Input
                                        size="sm"
                                        type="text"
                                        width="6.5rem"
                                        borderRadius={"lg"}
                                        value={getEggpriceValue(key)} // 입력 시 해당 상태값을 표시
                                        textAlign="right"
                                        bg={setEggpriceBgColor(key)}
                                        color={parseFloat(getEggpriceValue(key).replace(/,/g, "")) < 0 ? negativeColor : undefined} // 음수일 경우 negativeColor 적용
                                        onFocus={(e) => e.target.select()} // Focus 시 전체 선택
                                        onChange={(e) => {
                                            const inputValue = e.target.value.replace(/,/g, "");
                                            // 입력이 "-"이거나 숫자로 변환 가능한 경우만 처리
                                            if (inputValue === "-" || !isNaN(parseFloat(inputValue))) {
                                                setEggpriceValue(key, inputValue); // 해당 상태값 업데이트
                                            }
                                        }}
                                        onBlur={() => handleEggpriceBlur(key, getEggpriceValue(key))}
                                    />
                                </Td>
                                <Td isNumeric>{item.shareRate}%</Td>
                                <Td isNumeric color={(item.eggprice * (item.shareRate / 100)) < 0 ? negativeColor : undefined}>
                                    {(Math.round((item.eggprice * (item.shareRate / 100)))).toLocaleString()}
                                </Td>
                            </Tr>
                        );
                    })}

                    <Tr fontWeight="bold" bg={titleColor}>
                        <Td>TOTAL</Td>
                        <Td isNumeric color={(totalLosing + totalEggPrice) < 0 ? negativeColor : undefined}>
                            {(totalLosing + totalEggPrice).toLocaleString()}
                        </Td>
                        <Td></Td>
                        <Td isNumeric color={(totalSpendShareAmount + totalEggPriceShareAmount) < 0 ? negativeColor : undefined}>
                            {(Math.round((totalSpendShareAmount + totalEggPriceShareAmount))).toLocaleString()}
                        </Td>
                    </Tr>
                </Tbody>
            </Table>

            {/* 정산 항목 테이블 */}
            <Table bg={bgColor} colorScheme="gray" size="sm" mt={4}>
                <Thead bg={titleColor}>
                    <Tr>
                        <Th>정산 항목</Th>
                        <Th isNumeric>정산 금액</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td>쉐어 금액</Td>
                        <Td isNumeric color={totalShareAmount < 0 ? negativeColor : undefined}>
                            {(Math.round(totalShareAmount)).toLocaleString()}
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>지출 금액</Td>
                        <Td isNumeric color={totalSpendShareAmount + totalEggPriceShareAmount < 0 ? negativeColor : undefined}>
                            {(Math.round((totalSpendShareAmount + totalEggPriceShareAmount))).toLocaleString()}
                        </Td>
                    </Tr>
                    {/* 금액 조정 항목 */}
                    <Tr>
                        <Td>금액 조정</Td>
                        <Td isNumeric py="0.2rem">
                            <Input
                                size="sm"
                                type="text"
                                width="6.5rem"
                                borderRadius="lg"
                                value={priceAdjust}
                                textAlign="right"
                                bg={priceAdjustBgColor}
                                color={parseFloat(priceAdjust.replace(/,/g, "")) < 0 ? negativeColor : undefined} // 음수일 경우 색상 처리
                                onFocus={(e) => e.target.select()} // Focus 시 전체 선택
                                onChange={(e) => {
                                    const inputValue = e.target.value.replace(/,/g, "");
                                    // "-" 또는 숫자만 허용
                                    if (inputValue === "-" || !isNaN(parseFloat(inputValue))) {
                                        setPriceAdjust(inputValue);
                                    }
                                }}
                                onBlur={handlePriceAdjustBlur} // 입력이 끝나면 로컬 서식 적용 및 상태 업데이트
                            />
                        </Td>
                    </Tr>
                    {/* 최종 정산 금액 */}
                    <Tr fontWeight="bold" bg={titleColor}>
                        <Td>정산 금액</Td>
                        <Td isNumeric color={finalTotalAmount < 0 ? negativeColor : undefined}>
                            {(Math.round(finalTotalAmount)).toLocaleString()}
                        </Td>
                    </Tr>
                </Tbody>
            </Table>
        </Box>
    );
}
