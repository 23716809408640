import useLoginProtectedPage from "../components/LoginProtectedPage";
import { Box, VStack, useColorModeValue } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../theme";
import LeftMenu from "./userMainContesnts/LeftMenu";
import { useRecoilValue } from "recoil";
import SiteDetailSettlement from "./userMainContesnts/SiteDetailSettlement/SiteDetailSettlement";
import SiteSetting from "./userMainContesnts/SiteSetting";

import DashBoard from "./userMainContesnts/DashBoard/DashBoard";
import SiteDailySettlement from "./userMainContesnts/SiteDailySettlement/SiteDailySettlement";
import PBLogingShreCompanyRegistration from "./userMainContesnts/PBCompanyRegistration/companyRegistration";

// 추가된 임포트
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css";
import PBLosingShreMontly from "./userMainContesnts/PBLosingShreMontly/PBLosingShreMontly";
import PBLosingMonTotal from "./userMainContesnts/PBLosingMonTotal/PBLosingMonTotal";
import PBLosingShreDaily from "./userMainContesnts/PBLosingShreDaily/PBLosingShreDaily";
import CSLogingShreCompanyRegistration from "./userMainContesnts/CSCompanyRegistration/companyRegistration";
import CSLosingShreMontly from "./userMainContesnts/CSLosingShreMontly/CSLosingShreMontly";
import CSLosingShreDaily from "./userMainContesnts/CSLosingShreDaily/CSLosingShreDaily";
import { SelectedMenuAtom } from "../atoms/topSelect";

import CSLosingMonTotal from "./userMainContesnts/CSLosingMonTotal/CSLosingMonTotal";
import PBLosingDailyUserTree from "./userMainContesnts/PBLosingDailyUserTree/PBLosingDailyUserTree";
import PBLosingMontlyUserTree from "./userMainContesnts/PBLosingMontlyUserTree/PBLosingMontlyUserTree";

export default function UserMain() {
    useLoginProtectedPage();

    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);

    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    return (
        <Box
            display="flex"
            height="95vh" // 화면 전체를 차지하도록 설정
            bg={bgColor}
        >
            <ResizableBox
                width={300} // 기본 폭
                height={Infinity} // 높이를 무한대로 설정하여 전체 높이를 차지
                minConstraints={[200, Infinity]} // 최소 폭, 높이는 고정
                maxConstraints={[500, Infinity]} // 최대 폭, 높이는 고정
                resizeHandles={['e']} // 오른쪽에서 조절 가능
            >
                <Box
                    height="100%"
                    bg={mainBgColor}
                    color={mainFontColor}
                    marginX={"0.5rem"}
                    padding={"0.5rem"}
                    borderRadius={"xl"}
                >
                    <VStack
                        height="100%"
                        width="100%"
                        overflowY="auto"
                        overflowX="hidden"
                        css={{
                            '&::-webkit-scrollbar': {
                                width: '8px',
                                backgroundColor: scrollBarColor,
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: scrollBarThumbColor,
                                borderRadius: '4px',
                            },
                            '&:hover::-webkit-scrollbar-thumb': {
                                backgroundColor: scrollBarThumbHoverColor,
                            },
                        }}
                    >
                        <LeftMenu />
                    </VStack>
                </Box>
            </ResizableBox>

            <Box
                flex="1"
                height="100%"
                bg={mainBgColor}
                color={mainFontColor}
                padding={"0.5rem"}
                borderRadius={"xl"}
                overflow="hidden"
            >
                {SelectedMenu.pageSelect === "Dashboard" && <DashBoard />}
                {SelectedMenu.pageSelect === "SiteSetting" && <SiteSetting />}
                {SelectedMenu.pageSelect === "SiteDailySettlement" && <SiteDailySettlement />}
                {SelectedMenu.pageSelect === "SiteDetailSettlement" && <SiteDetailSettlement />}

                {SelectedMenu.pageSelect === "CasinoLosingShareCompanyRegistration" && <CSLogingShreCompanyRegistration />}
                {SelectedMenu.pageSelect === "CSLosingShreMontly" && <CSLosingShreMontly />}
                {SelectedMenu.pageSelect === "CSLosingMonTotal" && <CSLosingMonTotal />}


                {SelectedMenu.pageSelect === "CSLosingShreDaily" && <CSLosingShreDaily />}



                {SelectedMenu.pageSelect === "PBLosingShareCompanyRegistration" && <PBLogingShreCompanyRegistration />}
                {SelectedMenu.pageSelect === "PBLosingShreMontly" && <PBLosingShreMontly />}
                {SelectedMenu.pageSelect === "PBLosingMontlyUserTree" && <PBLosingMontlyUserTree />}

                {SelectedMenu.pageSelect === "PBLosingMonTotal" && <PBLosingMonTotal />}
                {SelectedMenu.pageSelect === "PBLosingShreDaily" && <PBLosingShreDaily />}
                {SelectedMenu.pageSelect === "PBLosingDailyUserTree" && <PBLosingDailyUserTree />}





                {/* {SelectedMenu.pageSelect === "testpage1" && <TestPage />} */}
                {/* {SelectedMenu.pageSelect === "testpage2" && <TestPage2 />} */}
                {/* {SelectedMenu.pageSelect === "testpage3" && <TestPage3 />} */}
                {/* {SelectedMenu.pageSelect === "testpage4" && <TestPage5 />} */}

            </Box>
        </Box>
    );
}
