
import { Box, Center, Flex, Table, Thead, Tbody, Tr, Th, Td, useColorModeValue, Text } from '@chakra-ui/react';
import { darkTheme_colors, lightTheme_colors } from '../../../../theme';
import { usePowerBallData } from '../consponents/PowerBallData';


export default function PowerBallTable() {
    const pbdata = usePowerBallData();

    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const titleColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);

    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    return (
        <Box
            width={"100%"}
            overflowX="auto"
            bg={mainBgColor}
            color={mainFontColor}
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: scrollBarColor,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbColor,
                    borderRadius: '4px',
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbHoverColor,
                },
            }}
        >
            {pbdata.headers.length === 0 || pbdata.rows.length === 0 ? (
                <Center h="100%">
                    <Text fontSize="2xl" color={negativeColor}>데이터가 없습니다!</Text>
                </Center>
            ) : (
                <Table variant="striped" bg={bgColor} colorScheme="gray" size="sm">
                    <Thead bg={titleColor}>
                        <Tr>
                            {pbdata.headers.map((header, index) => (
                                <Th key={index} textAlign="center" padding="0.2rem">{header}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {pbdata.rows.map((row, rowIndex) => (
                            <Tr
                                key={rowIndex}
                                sx={{
                                    bg: rowIndex === pbdata.rows.length - 1 ? titleColor : undefined,
                                    '& > td': {
                                        bg: rowIndex === pbdata.rows.length - 1 ? `${titleColor} !important` : undefined,
                                    }
                                }}
                            >
                                {Object.keys(row).map((key, colIndex) => {
                                    const cellValue = row[key];
                                    const isNegative = typeof cellValue === 'string' && parseFloat(cellValue.replace(/,/g, '')) < 0;

                                    return (
                                        <Td
                                            key={colIndex}
                                            textAlign={colIndex === 0 ? "center" : "right"}
                                            padding="0.2rem"
                                            color={isNegative ? negativeColor : undefined}
                                        >
                                            <Flex justify={colIndex === 0 ? "center" : "flex-end"}>
                                                {cellValue}
                                            </Flex>
                                        </Td>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </Box>
    );
};
