import { Grid, GridItem, Flex, Center } from "@chakra-ui/react";

import { useRecoilValue } from "recoil";
import { SelectedMenuAtom } from "../../../atoms/topSelect";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { getPBIdbOption } from "../../../api/pvidvoption";
import { getSiteData } from "../../../api/sitedata";
import { getCombinedData } from "../../../lib/conversionData";

import SiteComDayDayComOptTopSelect from "../CommContents/TopSelect/SiteComDayDayComOptTopSelect";
import PBUserTreeTable from "../PBLosingShreComm/Contents/PBuserTreeTable";


interface UserData {
    user_id: string;
    user_nickname: string;
    status: string;
    status_id_list: string[];
    [key: string]: any;
}

const getLosinTreeData = (
    data: UserData[],
    loginSelected: string[],
    loginRate: number
): UserData[] => {
    if (data.length > 0 && Object.keys(data[0]).some(key => key.includes("up_fee_"))) {
        // 리무진 특별처리. up_fee 가 있으면 리무진 데이터이다.  
        return data.map(user => {
            // 손익금 합계 계산
            const totalProfit = loginSelected.reduce((acc, key) => {
                const profitKey = `pb_profit_${key}`;
                if (user.hasOwnProperty(profitKey)) {
                    return acc + user[profitKey];
                }
                return acc;
            }, 0);


            let up_fee = 0;
            if (user.status === "회원") {
                up_fee = user.pb_bat_파워볼_게임합 - user.pb_win_파워볼_게임합 - user.pb_fee_파워볼_게임합 - user.up_fee_파워볼_;
            }

            // 루징금 합계 계산( 리무진은 합계를 뒤에서 게산한다. )
            const totalLosing = Math.round(up_fee * (loginRate / 100));

            const show_up_fee = totalLosing - Math.round(totalProfit * (loginRate / 100));

            // 새로운 데이터 반환
            return {
                "user_id": user.user_id,
                "user_nickname": user.user_nickname,
                "status": user.status,
                "status_id_list": user.status_id_list,
                ...loginSelected.reduce((acc, key) => {
                    const profitKey = `pb_profit_${key}`;
                    acc[key] = user[profitKey] || 0;
                    return acc;
                }, {} as { [key: string]: number }),
                "손익금 합계": totalProfit,
                "상위 수수료": show_up_fee,
                // "루징율": loginRate,
                "루징금 합계": totalLosing,
            };
        });
    } else {
        return data.map(user => {
            // 손익금 합계 계산
            const totalProfit = loginSelected.reduce((acc, key) => {
                const profitKey = `pb_profit_${key}`;
                if (user.hasOwnProperty(profitKey)) {
                    return acc + user[profitKey];
                }
                return acc;
            }, 0);

            // 루징금 합계 계산
            const totalLosing = Math.round(totalProfit * (loginRate / 100));

            // 새로운 데이터 반환
            return {
                "user_id": user.user_id,
                "user_nickname": user.user_nickname,
                "status": user.status,
                "status_id_list": user.status_id_list,
                ...loginSelected.reduce((acc, key) => {
                    const profitKey = `pb_profit_${key}`;
                    acc[key] = user[profitKey] || 0;
                    return acc;
                }, {} as { [key: string]: number }),
                "손익금 합계": totalProfit,
                // "루징율": loginRate,
                "루징금 합계": totalLosing,

            };
        });
    }
};

export default function PBLosingDailyUserTree() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    const SelectedLoginOption = useRef([] as string[]);

    const LoginRate = useRef(0);

    const [PBLosingData, setPBLosingData] = useState([] as any[]);


    // 개별옵션 불러온다. (기본옵션 처리는 서버에서 처리한다.)
    const getPBIdvOptionMutation = useMutation(getPBIdbOption, {
        onSuccess: (data) => {
            LoginRate.current = data.login_rate;
            SelectedLoginOption.current = JSON.parse(data.selected_login_list);
        }
    });


    // 원자 데이터 불러온다.
    const atomDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            let re_atom_data_list = [] as any;
            data.forEach((item: any) => {
                re_atom_data_list.push(JSON.parse(item.data));
            });
            //re_atom_data_list  리스트를 역순으로 뒤집는다. 
            re_atom_data_list = re_atom_data_list.reverse();

            // 역순으로 뒤집어서 한달치 데이터를 합친다. 
            let atom_combined_data_type_month = [] as any;
            for (const atom_data of re_atom_data_list) {
                atom_combined_data_type_month = getCombinedData(atom_combined_data_type_month, atom_data);
            }

            // 합친 데이터에서. user_id 가 SelectedMenu.company_name 인 데이터와 status_id_list 에 SelectedMenu.company_name 이 있는 데이터를 찾는다.
            let targetData = atom_combined_data_type_month.filter((item: any) => item.user_id === SelectedMenu.PBCom.name || item.status_id_list.includes(SelectedMenu.PBCom.name));

            // 일단 필요데이터만 있는 트리데이터를 만든다.
            let targetLoginTreeData = getLosinTreeData(targetData, SelectedLoginOption.current, LoginRate.current);

            setPBLosingData(targetLoginTreeData);
        }
    });


    useEffect(() => {
        if (SelectedMenu.site.pk === -1 || SelectedMenu.PBCom.pk === -1) {
            return;
        }

        const getPBidvoptionparams = {
            siteInfo_pk: SelectedMenu.site.pk,
            company_name: SelectedMenu.PBCom.name,
            start_date: SelectedMenu.start_date,
            end_date: SelectedMenu.end_date,
        }

        const getAtomdataparams = {
            siteinfo: SelectedMenu.site.pk,
            datatype: "atom",
            start_date: SelectedMenu.start_date,
            end_date: SelectedMenu.end_date,
        }

        getPBIdvOptionMutation.mutate(getPBidvoptionparams);

        atomDataMutation.mutate(getAtomdataparams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedMenu]);

    return (
        <Grid padding={"0.2rem"}
            h='100%'
            templateColumns='repeat(10, 1fr)'
            templateRows='repeat(10, 1fr)'
            gap={1}
        >
            <GridItem borderRadius={"xl"} paddingX={"0.5rem"} colSpan={10} rowSpan={1}>
                <SiteComDayDayComOptTopSelect />
            </GridItem>

            {(SelectedMenu.site.pk === -1 || SelectedMenu.CSCom.pk === -1) && (
                <GridItem colSpan={10} rowSpan={10} alignItems={"center"} justifyContent={"center"}>
                    <Center margin={10}>업체를 등록하여 선택해 주세요.. </Center>
                </GridItem>
            )}

            {(SelectedMenu.site.pk !== -1 && SelectedMenu.CSCom.pk !== -1) && (
                <GridItem paddingX={"0.5rem"} colSpan={10} rowSpan={9} alignItems={"start"} paddingTop={"0.5rem"}>
                    <Flex
                        flexDirection={'column'}
                        w={'100%'}
                        h={'100%'}
                        alignItems={'center'}
                        gap={8}
                    >
                        {(PBLosingData.length > 0) && (
                            <PBUserTreeTable
                                site_name={SelectedMenu.site.name}
                                company_name={SelectedMenu.PBCom.name}
                                start_date={SelectedMenu.start_date}
                                end_date={SelectedMenu.end_date}
                                PBUserTreeDatas={PBLosingData}
                                LoginRate={LoginRate.current}
                            />
                        )}

                    </Flex>
                </GridItem>
            )}
        </Grid>
    );

}

