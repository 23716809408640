import { Button, Center, Input, InputGroup, InputLeftElement, InputRightElement, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Stack, useColorModeValue, useToast } from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { ISetPassword, userPutPassword } from "../api/user";
import { IseverDetailReturn } from "../api/base";
import { darkTheme_colors, lightTheme_colors } from "../theme";
import { FaLock } from "react-icons/fa";

interface SetPasswordModelModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export default function SetPasswordModel({ isOpen, onClose }: SetPasswordModelModalProps) {
    /*
    {
        "old_password":"",
        "new_password":"",
        "re_password":"aa12341234",
    }
    */
    const toast = useToast();
    const { register, handleSubmit, formState, reset } = useForm<ISetPassword>();

    const [serverdetailmessage, setserverdetailmessage] = useState("")

    // 패스워드 보이기 숨기기
    const [oldPasswordshow, setOldPasswordshow] = useState(false)
    const oldPasswordshowHandleClick = () => setOldPasswordshow(!oldPasswordshow)
    const [newPasswordshow, setNewPasswordshow] = useState(false)
    const newPasswordshowHandleClick = () => setNewPasswordshow(!newPasswordshow)
    const [repasswordshow, setrePasswordshow] = useState(false)
    const repasswordshowHandleClick = () => setrePasswordshow(!repasswordshow)

    // 변경 버튼 눌럿을때 동작
    const onSubmit = ({ old_password, new_password, re_password }: ISetPassword) => {
        // setDupliactePrevant()
        mutation.mutate({ old_password, new_password, re_password })
    }

    const mutation = useMutation(userPutPassword, {
        onSuccess: (data: IseverDetailReturn) => {
            toast({
                position: 'top',
                title: "패스워드 변경 성공! ",
                description: "패스워드가 변경되었습니다. ",
                status: "info",
                isClosable: true,
            });

            reset();
            onClose();
        },
        onError: (data: IseverDetailReturn) => {
            setserverdetailmessage(data.response.data.detail)
        }
    })

    // 테마 관련 코드
    const btnBgColor = useColorModeValue(lightTheme_colors['btnBgColor'], darkTheme_colors['btnBgColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);
    return (
        <Modal isOpen={isOpen} onClose={onClose} >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>패스워드 변경</ModalHeader>
                <ModalCloseButton />
                <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
                    <Stack spacing={"4"}>
                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<FaLock color='gray.300' />}
                            />
                            <Input
                                {...register("old_password", { required: "기존 패스워드가 입력되지 않았습니다.", })}
                                type={oldPasswordshow ? 'text' : "password"}
                                placeholder='기존 비번을 입력하세요'
                                bgColor={inputBgColor}
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={oldPasswordshowHandleClick}>
                                    {oldPasswordshow ? '숨기기' : '보이기'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        {formState.errors.old_password ?
                            <Center textColor={"red"}>{formState.errors.old_password?.message}</Center> : ""
                        }

                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<FaLock color='gray.300' />}
                            />
                            <Input
                                {...register("new_password", { required: "신규비번이 입력되지 않았습니다.", })}
                                type={newPasswordshow ? 'text' : "password"}
                                placeholder='변경할 비번을 입력하세요'
                                bgColor={inputBgColor}
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={newPasswordshowHandleClick}>
                                    {newPasswordshow ? '숨기기' : '보이기'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        {formState.errors.new_password ?
                            <Center textColor={"red"}>{formState.errors.new_password?.message}</Center> : ""
                        }

                        <InputGroup>
                            <InputLeftElement
                                pointerEvents='none'
                                children={<FaLock color='gray.300' />}
                            />
                            <Input
                                {...register("re_password", { required: "패스워드 확인이 입력되지 않았습니다.", })}
                                type={repasswordshow ? 'text' : "password"}
                                placeholder='변경할 비번을 다시 입력하세요'
                                bgColor={inputBgColor}
                            />
                            <InputRightElement width='4.5rem'>
                                <Button h='1.75rem' size='sm' onClick={repasswordshowHandleClick}>
                                    {repasswordshow ? '숨기기' : '보이기'}
                                </Button>
                            </InputRightElement>
                        </InputGroup>
                        {formState.errors.re_password ?
                            <Center textColor={"red"}>{formState.errors.re_password?.message}</Center> : ""
                        }

                        {mutation.isError ?
                            <Center textColor={"red"}>{serverdetailmessage}</Center> : ""
                        }
                        <Button isLoading={mutation.isLoading} type="submit" bgColor={btnBgColor} w="100%" >
                            패스워드 변경
                        </Button>
                    </Stack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}