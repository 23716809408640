import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { getMonthRangeFromString, IApiData, transformData } from "../../../../lib/util";
import { getSiteData } from "../../../../api/sitedata";
import { useRecoilValue } from "recoil";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";

export function useGitaData() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);
    const [gitadata, setGitaData] = useState<IApiData>({ headers: [], rows: [] });

    const MonthSiteDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                setGitaData({ headers: [], rows: [] });
                return;
            }

            const dataList = data.map((sitedata: any) => JSON.parse(sitedata.data));
            setGitaData(transformData(dataList));
        },
        onError: (error) => {
            console.error("사이트 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });

    useEffect(() => {
        const monthRange = getMonthRangeFromString(SelectedMenu.Month);
        if (SelectedMenu.site.pk !== -1 && SelectedMenu.category && SelectedMenu.Month) {
            MonthSiteDataMutation.mutate({
                siteinfo: SelectedMenu.site.pk,
                datatype: "gita",
                start_date: monthRange[0],
                end_date: monthRange[1],
            });
        }
    }, [SelectedMenu]);

    return gitadata;
}
