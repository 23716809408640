import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useMutation } from '@tanstack/react-query';
import { getMonthRangeFromString, IApiData, transformData } from '../../../../lib/util';
import { getSiteData } from '../../../../api/sitedata';
import { SelectedMenuAtom } from '../../../../atoms/topSelect';


export function usePowerBallData() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);
    const [pbdata, setPbData] = useState<IApiData>({ headers: [], rows: [] });

    const MonthSiteDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                setPbData({ headers: [], rows: [] });
                return;
            }

            const data_list = data.map((sitedata: any) => JSON.parse(sitedata.data));
            const transformedData = transformData(data_list);
            setPbData(transformedData);
        },
        onError: (error) => {
            console.error("사이트 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });

    useEffect(() => {
        const monthRange = getMonthRangeFromString(SelectedMenu.Month);
        if (SelectedMenu.site.pk !== -1 && SelectedMenu.category !== "" && SelectedMenu.Month !== "") {
            MonthSiteDataMutation.mutate({
                siteinfo: SelectedMenu.site.pk,
                datatype: "powerball",
                start_date: monthRange[0],
                end_date: monthRange[1],
            });
        }
    }, [SelectedMenu.site, SelectedMenu.category, SelectedMenu.Month]);

    return pbdata;
}


const ex_data = {
    headers: [
        'Date', 'EOS 3분', 'EOS 5분', '코인 3분', '코인 5분', '더블유 파워볼', 'PBG 파워볼', '코사 3분', '코사 5분', '합계'
    ],
    rows: [
        { date: '3/1', eos3min: '265,904', eos5min: '-5,879,688', coin3min: '619,749', coin5min: '1,739,466', doubleup: '29,415', pbg: '8,614', kosa3min: '2,813', kosa5min: '2,813', total: '-3,214,325' },
        { date: '3/2', eos3min: '26,494', eos5min: '-10,244,553', coin3min: '485,869', coin5min: '-4,008,638', doubleup: '0', pbg: '13,972', kosa3min: '1,893', kosa5min: '1,893', total: '-13,771,737' },
        { date: '3/3', eos3min: '-132,672', eos5min: '-723,819', coin3min: '1,541,676', coin5min: '1,453,220', doubleup: '0', pbg: '10,662', kosa3min: '469', kosa5min: '469', total: '-938,907' },
        { date: '3/4', eos3min: '-1,372,602', eos5min: '-718,317', coin3min: '1,541,676', coin5min: '1,453,220', doubleup: '0', pbg: '0', kosa3min: '216', kosa5min: '216', total: '-1,896,407' },
        { date: '3/5', eos3min: '2,072,442', eos5min: '-724,406', coin3min: '1,541,676', coin5min: '1,454,317', doubleup: '0', pbg: '-128,845', kosa3min: '10', kosa5min: '45', total: '-215,839' },
        { date: '3/6', eos3min: '-444,101', eos5min: '-1,240,595', coin3min: '0', coin5min: '1,133,520', doubleup: '117', pbg: '0', kosa3min: '1,642', kosa5min: '1,642', total: '-1,550,080' },

        { date: 'TOTAL', eos3min: '4,266,664', eos5min: '-21,252,830', coin3min: '8,000,815', coin5min: '4,108,242', doubleup: '29,610', pbg: '12,550,315', kosa3min: '-5,292', kosa5min: '43,576', total: '7,741,100' }
    ]
};