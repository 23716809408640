import { IUserTreeDataProps } from "../Contents/UserTreeTable";

export function getUserTreeDatas(AtomUserTreeDatas: any, IvdOptin: any): any {
    // console.log("CSLosingShreMontly IvdOptin_mon!!: ", IvdOptin);
    // ivd 옵션과 데이터를 조합한다. 

    const optioned_AtomUserTreedata = _comabne_AtomUserTreeDatas_IvdOptin(AtomUserTreeDatas, IvdOptin)
    // console.log("optioned_AtomUserTreedata : ", optioned_AtomUserTreedata);

    // idv 옵션에서 개별 유저 옵션을 가져온다. 
    const IdvUserOption = JSON.parse(IvdOptin.idv_login_rates)
    // console.log("idvUserOption: ", IdvUserOption);

    // 개별 유저 옵션을 적용한다. 
    const IdvUseroptined_optioned_AtomUserTreedata = _combne_optioned_AtomUserTreedata_IdvUseroptin(optioned_AtomUserTreedata, IdvUserOption);
    // console.log("IdvUseroptined_optioned_AtomUserTreedata : ", IdvUseroptined_optioned_AtomUserTreedata);

    // 샘플 유저 데이터를 만들자. ( 이걸로 합계 데이터를 만들면 된다. )    
    let UserTreeDatas = [];

    // 리무진 특별처리. up_fee 가 있으면 리무진 데이터이다. 
    if (AtomUserTreeDatas.length > 0 && Object.keys(AtomUserTreeDatas[0]).some(key => key.includes("up_fee_"))) {
        UserTreeDatas = _sp_UserTreeDatas(AtomUserTreeDatas, IdvUseroptined_optioned_AtomUserTreedata);

    } else {
        UserTreeDatas = _trance_UserTreeDatas(IdvUseroptined_optioned_AtomUserTreedata);

    }

    return UserTreeDatas;
}

function _sp_UserTreeDatas(AtomUserTreeDatas: any[], IdvUseroptined_optioned_AtomUserTreedata: any[]): any[] {
    /**
     리무진 특별 데이터 만든다. 
     IdvUseroptined_optioned_AtomUserTreedata : 맨위의 유저(대상유저데이터. )login_rate, activate 정보가 있다.
     UserTreeDatas : up_fee_ 정보가 있다. 
     AtomUserTreeDatas: 여기에 합성시켜서 내보낸다. 

     */

    const casislot_bet_카지노_sum = AtomUserTreeDatas.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.casislot_bet_카지노_ || 0), 0);
    const casislot_bet_슬롯_sum = AtomUserTreeDatas.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.casislot_bet_슬롯_ || 0), 0);

    const pb_bet_파워볼_게임합_sum = AtomUserTreeDatas.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.pb_bat_파워볼_게임합 || 0), 0);


    const casislot_win_카지노_sum = AtomUserTreeDatas.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.casislot_win_카지노_ || 0), 0);
    const casislot_win_슬롯_sum = AtomUserTreeDatas.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.casislot_win_슬롯_ || 0), 0);

    const pb_win_파워볼_게임합_sum = AtomUserTreeDatas.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.pb_win_파워볼_게임합 || 0), 0);



    const casislot_fee_카지노_sum = AtomUserTreeDatas.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.casislot_fee_카지노_ || 0), 0);
    const casislot_fee_슬롯_sum = AtomUserTreeDatas.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.casislot_fee_슬롯_ || 0), 0);

    const pb_fee_파워볼_게임합_sum = AtomUserTreeDatas.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.pb_fee_파워볼_게임합 || 0), 0);


    const up_fee_카지노_ = AtomUserTreeDatas.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.up_fee_카지노_ || 0), 0);
    const up_fee_슬롯 = AtomUserTreeDatas.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.up_fee_슬롯_ || 0), 0);

    const up_fee_파워볼 = AtomUserTreeDatas.filter((user: any) => user.status === "회원").reduce((sum: any, user: any) => sum + (user.up_fee_파워볼_ || 0), 0);



    const casino_sp = IdvUseroptined_optioned_AtomUserTreedata[0]['casino_activate'] ? Math.round((casislot_bet_카지노_sum - casislot_win_카지노_sum - casislot_fee_카지노_sum - up_fee_카지노_) * (IdvUseroptined_optioned_AtomUserTreedata[0]['casino_login_rate'] / 100)) : 0;
    const slot_sp = IdvUseroptined_optioned_AtomUserTreedata[0]['slot_activate'] ? Math.round((casislot_bet_슬롯_sum - casislot_win_슬롯_sum - casislot_fee_슬롯_sum - up_fee_슬롯) * (IdvUseroptined_optioned_AtomUserTreedata[0]['slot_login_rate'] / 100)) : 0;


    // 합산 데이터 구하자. (합산데이터를 따로 구해야한다. 안그러면 cost_adjust가 더해져서 파국!!)
    // const imsi_UserTreeDatas = _trance_UserTreeDatas(IdvUseroptined_optioned_AtomUserTreedata);
    const imsi_UserTreeDatas = IdvUseroptined_optioned_AtomUserTreedata.map((user, index) => {
        // 손익금 계산
        const casino_profit = user.casino_activate ? (user.casislot_bet_카지노_ - user.casislot_win_카지노_ - user.casislot_fee_카지노_) : 0;
        const slot_profit = user.slot_activate ? (user.casislot_bet_슬롯_ - user.casislot_win_슬롯_ - user.casislot_fee_슬롯_) : 0;
        const holdem_profit = user.holdem_activate ? (user.holdem_bet_홀덤_ - user.holdem_win_홀덤_ - user.holdem_fee_홀덤_) : 0;

        // 루징금 계산
        const casino_loss = user.casino_activate ? Math.round(casino_profit * user.casino_login_rate / 100) : 0;
        const slot_loss = user.slot_activate ? Math.round(slot_profit * user.slot_login_rate / 100) : 0;
        const holdem_loss = user.holdem_activate ? Math.round(holdem_profit * user.holdem_login_rate / 100) : 0;

        const total_loss = casino_loss + slot_loss + holdem_loss;
        return {
            "루징금 합계": total_loss
        };
    });


    // 합산데이터로. 상위수수료 자체를 구한다. 
    const total_profit = imsi_UserTreeDatas.reduce((acc, curr) => acc + (curr["루징금 합계"] || 0), 0);
    const sp_data = (casino_sp + slot_sp) - total_profit;


    const sp_UserTreeDatas = IdvUseroptined_optioned_AtomUserTreedata.map((user, index) => {

        if (index === 0) {
            // 손익금 계산
            const casino_profit = user.casino_activate ? (user.casislot_bet_카지노_ - user.casislot_win_카지노_ - user.casislot_fee_카지노_) : 0;
            const slot_profit = user.slot_activate ? (user.casislot_bet_슬롯_ - user.casislot_win_슬롯_ - user.casislot_fee_슬롯_) : 0;
            const holdem_profit = user.holdem_activate ? (user.holdem_bet_홀덤_ - user.holdem_win_홀덤_ - user.holdem_fee_홀덤_) : 0;
            const total_profit = casino_profit + slot_profit + holdem_profit;

            // 루징금 계산
            const casino_loss = user.casino_activate ? Math.round(casino_profit * user.casino_login_rate / 100) : 0;
            const slot_loss = user.slot_activate ? Math.round(slot_profit * user.slot_login_rate / 100) : 0;
            const holdem_loss = user.holdem_activate ? Math.round(holdem_profit * user.holdem_login_rate / 100) : 0;

            const cost_adjust = user.cost_adjust;  // 코스트 조정
            const up_fee = sp_data
            const total_loss = casino_loss + slot_loss + holdem_loss + cost_adjust + up_fee;

            return {
                user_id: user.user_id,
                user_nickname: user.user_nickname,
                status: user.status,
                // status_list: user.status_list,
                status_id_list: user.status_id_list,

                "카지노 루징율": user.casino_activate ? user.casino_login_rate : 0,
                "슬롯 루징율": user.slot_activate ? user.slot_login_rate : 0,
                "홀덤 루징율": user.holdem_activate ? user.holdem_login_rate : 0,

                "카지노 손익금": casino_profit,
                "슬롯 손익금": slot_profit,
                "홀덤 손익금": holdem_profit,
                "손익금 합계": total_profit,

                "카지노 루징금": casino_loss,
                "슬롯 루징금": slot_loss,
                "홀덤 루징금": holdem_loss,

                "상위 수수료": up_fee,

                "금액 조정": cost_adjust,
                "루징금 합계": total_loss
            };
        } else {
            // 손익금 계산
            const casino_profit = user.casino_activate ? (user.casislot_bet_카지노_ - user.casislot_win_카지노_ - user.casislot_fee_카지노_) : 0;
            const slot_profit = user.slot_activate ? (user.casislot_bet_슬롯_ - user.casislot_win_슬롯_ - user.casislot_fee_슬롯_) : 0;
            const holdem_profit = user.holdem_activate ? (user.holdem_bet_홀덤_ - user.holdem_win_홀덤_ - user.holdem_fee_홀덤_) : 0;
            const total_profit = casino_profit + slot_profit + holdem_profit;

            // 루징금 계산
            const casino_loss = user.casino_activate ? Math.round(casino_profit * user.casino_login_rate / 100) : 0;
            const slot_loss = user.slot_activate ? Math.round(slot_profit * user.slot_login_rate / 100) : 0;
            const holdem_loss = user.holdem_activate ? Math.round(holdem_profit * user.holdem_login_rate / 100) : 0;


            const cost_adjust = user.cost_adjust;  // 코스트 조정
            const total_loss = casino_loss + slot_loss + holdem_loss + cost_adjust;

            return {
                user_id: user.user_id,
                user_nickname: user.user_nickname,
                status: user.status,
                // status_list: user.status_list,
                status_id_list: user.status_id_list,

                "카지노 루징율": user.casino_activate ? user.casino_login_rate : 0,
                "슬롯 루징율": user.slot_activate ? user.slot_login_rate : 0,
                "홀덤 루징율": user.holdem_activate ? user.holdem_login_rate : 0,

                "카지노 손익금": casino_profit,
                "슬롯 손익금": slot_profit,
                "홀덤 손익금": holdem_profit,
                "손익금 합계": total_profit,

                "카지노 루징금": casino_loss,
                "슬롯 루징금": slot_loss,
                "홀덤 루징금": holdem_loss,

                "상위 수수료": 0,

                "금액 조정": cost_adjust,
                "루징금 합계": total_loss
            };
        }
    });

    // 우선 그대로 반환.
    return sp_UserTreeDatas;
}

function _comabne_AtomUserTreeDatas_IvdOptin(AtomUserTreeDatas: any[], IvdOptin: any): any[] {
    /*
     ivd 옵션과 AtomUserTreeDatas 데이터를 조합한다.  
    */
    return AtomUserTreeDatas.map(TargetData => {
        return {
            // 사용자 정보 부분
            user_id: TargetData.user_id,
            user_nickname: TargetData.user_nickname,
            status: TargetData.status,
            status_list: TargetData.status_list,
            status_id_list: TargetData.status_id_list,
            status_id_lenth: TargetData.status_id_lenth,

            // 게임별 활성화 상태 및 루징율 추가
            casino_activate: IvdOptin.casino_activate,
            slot_activate: IvdOptin.slot_activate,
            holdem_activate: IvdOptin.holdem_activate,

            casino_login_rate: IvdOptin.casino_login_rate,
            slot_login_rate: IvdOptin.slot_login_rate,
            holdem_login_rate: IvdOptin.holdem_login_rate,

            cost_adjust: 0,  // 코스트 조정

            // 게임별 베팅, 승리, 수수료, 손익 정보
            casislot_bet_카지노_: TargetData.casislot_bet_카지노_ !== undefined ? TargetData.casislot_bet_카지노_ : 0,
            casislot_win_카지노_: TargetData.casislot_win_카지노_ !== undefined ? TargetData.casislot_win_카지노_ : 0,
            casislot_fee_카지노_: TargetData.casislot_fee_카지노_ !== undefined ? TargetData.casislot_fee_카지노_ : 0,
            casislot_profit_카지노_: TargetData.casislot_profit_카지노_ !== undefined ? TargetData.casislot_profit_카지노_ : 0,
            casislot_bet_슬롯_: TargetData.casislot_bet_슬롯_ !== undefined ? TargetData.casislot_bet_슬롯_ : 0,
            casislot_win_슬롯_: TargetData.casislot_win_슬롯_ !== undefined ? TargetData.casislot_win_슬롯_ : 0,
            casislot_fee_슬롯_: TargetData.casislot_fee_슬롯_ !== undefined ? TargetData.casislot_fee_슬롯_ : 0,
            casislot_profit_슬롯_: TargetData.casislot_profit_슬롯_ !== undefined ? TargetData.casislot_profit_슬롯_ : 0,
            holdem_bet_홀덤_: TargetData.holdem_bet_홀덤_ !== undefined ? TargetData.holdem_bet_홀덤_ : 0,
            holdem_win_홀덤_: TargetData.holdem_win_홀덤_ !== undefined ? TargetData.holdem_win_홀덤_ : 0,
            holdem_fee_홀덤_: TargetData.holdem_fee_홀덤_ !== undefined ? TargetData.holdem_fee_홀덤_ : 0,
            holdem_profit_홀덤_: TargetData.holdem_profit_홀덤_ !== undefined ? TargetData.holdem_profit_홀덤_ : 0,
        };
    });
}

function _combne_optioned_AtomUserTreedata_IdvUseroptin(optioned_AtomUserTreedata: any[], IdvUserOption: any): any[] {
    /**
     개별 유저 옵션을 적용한다.  !!! 여기여기 
         {"rokmc0117":{"casino_login_rate":10},"cjsska34":{"casino_login_rate":10},"ncm119":{"casino_login_rate":10}}
     */

    return optioned_AtomUserTreedata.map(user => {
        // user_id가 TargetIdvOptin의 키 값과 같은지 확인
        if (IdvUserOption[user.user_id]) {
            const optinData = IdvUserOption[user.user_id];
            // casino_login_rate, slot_login_rate, holdem_login_rate 덮어쓰기
            return {
                ...user,
                casino_login_rate: optinData.casino_login_rate !== undefined ? optinData.casino_login_rate : user.casino_login_rate,
                slot_login_rate: optinData.slot_login_rate !== undefined ? optinData.slot_login_rate : user.slot_login_rate,
                holdem_login_rate: optinData.holdem_login_rate !== undefined ? optinData.holdem_login_rate : user.holdem_login_rate,

                cost_adjust: optinData.cost_adjust !== undefined ? optinData.cost_adjust : 0,  // 코스트 조정
            };
        }
        // user_id가 없으면 원래 데이터를 반환
        return user;
    });
}


function _trance_UserTreeDatas(users: any[]): any[] {
    return users.map(user => {
        // 손익금 계산
        const casino_profit = user.casino_activate ? (user.casislot_bet_카지노_ - user.casislot_win_카지노_ - user.casislot_fee_카지노_) : 0;
        const slot_profit = user.slot_activate ? (user.casislot_bet_슬롯_ - user.casislot_win_슬롯_ - user.casislot_fee_슬롯_) : 0;
        const holdem_profit = user.holdem_activate ? (user.holdem_bet_홀덤_ - user.holdem_win_홀덤_ - user.holdem_fee_홀덤_) : 0;
        const total_profit = casino_profit + slot_profit + holdem_profit;

        // 루징금 계산
        const casino_loss = user.casino_activate ? Math.round(casino_profit * user.casino_login_rate / 100) : 0;
        const slot_loss = user.slot_activate ? Math.round(slot_profit * user.slot_login_rate / 100) : 0;
        const holdem_loss = user.holdem_activate ? Math.round(holdem_profit * user.holdem_login_rate / 100) : 0;


        const cost_adjust = user.cost_adjust;  // 코스트 조정
        const total_loss = casino_loss + slot_loss + holdem_loss + cost_adjust;

        return {
            user_id: user.user_id,
            user_nickname: user.user_nickname,
            status: user.status,
            // status_list: user.status_list,
            status_id_list: user.status_id_list,

            "카지노 루징율": user.casino_activate ? user.casino_login_rate : 0,
            "슬롯 루징율": user.slot_activate ? user.slot_login_rate : 0,
            "홀덤 루징율": user.holdem_activate ? user.holdem_login_rate : 0,

            "카지노 손익금": casino_profit,
            "슬롯 손익금": slot_profit,
            "홀덤 손익금": holdem_profit,
            "손익금 합계": total_profit,

            "카지노 루징금": casino_loss,
            "슬롯 루징금": slot_loss,
            "홀덤 루징금": holdem_loss,

            "금액 조정": cost_adjust,
            "루징금 합계": total_loss
        };
    });
}


// UserSharDataProps 을 만든다. eggprice가 0보다 작으면 0을 반환한다. 
export function GetUserSharDataProps(treeDataProps: IUserTreeDataProps, IvdOptin: any) {

    // idv 옵션에서 개별 유저 옵션을 가져온다. 
    const IdvUserOption = JSON.parse(IvdOptin.idv_login_rates)
    const SharTableOption = IdvUserOption['SharTableOption'] === undefined ? {} : IdvUserOption['SharTableOption'];


    /**
     {
            "holdem_losing": 10
        }
     */
    const retrun_data = {
        UserShartitle: {
            site_name: treeDataProps.site_name,
            company_name: treeDataProps.company_name,
            start_date: treeDataProps.start_date,
            end_date: treeDataProps.end_date,
            idv_show_optin: treeDataProps.IvdOptin.share_show_option,
        },
        UserSharData: {
            casino: {
                lossProfit: treeDataProps.UserTreeDatas.reduce(
                    (acc, curr) => acc + (curr["카지노 손익금"] || 0),
                    0
                ),
                losing: SharTableOption["casino_losing"] !== undefined ?
                    SharTableOption["casino_losing"] :
                    treeDataProps.UserTreeDatas.reduce(
                        (acc, curr) => acc + (curr["카지노 루징금"] || 0),
                        0
                    ),
                eggprice: SharTableOption["casino_eggprice"] !== undefined ?
                    SharTableOption["casino_eggprice"] : Math.round(
                        (treeDataProps.UserAtomData.reduce(
                            (acc, curr) => acc + (curr["casislot_bet_카지노_"] || 0),
                            0
                        ) -
                            treeDataProps.UserAtomData.reduce(
                                (acc, curr) => acc + (curr["casislot_win_카지노_"] || 0),
                                0
                            )) *
                        treeDataProps.IvdOptin.r_rate / 100
                    ),

                bat: treeDataProps.UserAtomData.reduce((acc, curr) => acc + (curr["casislot_bet_카지노_"] || 0), 0),
                win: treeDataProps.UserAtomData.reduce((acc, curr) => acc + (curr["casislot_win_카지노_"] || 0), 0),
                r_rate: treeDataProps.IvdOptin.r_rate,
                shareRate: treeDataProps.IvdOptin.casino_share_rate,
                disable: !treeDataProps.IvdOptin.casino_activate,
            },
            slot: {
                lossProfit: treeDataProps.UserTreeDatas.reduce(
                    (acc, curr) => acc + (curr["슬롯 손익금"] || 0),
                    0
                ),
                losing: SharTableOption["slot_losing"] !== undefined ?
                    SharTableOption["slot_losing"] :
                    treeDataProps.UserTreeDatas.reduce(
                        (acc, curr) => acc + (curr["슬롯 루징금"] || 0),
                        0
                    ),
                eggprice: SharTableOption["slot_eggprice"] !== undefined ?
                    SharTableOption["slot_eggprice"] : Math.round(
                        (treeDataProps.UserAtomData.reduce(
                            (acc, curr) => acc + (curr["casislot_bet_슬롯_"] || 0),
                            0
                        ) -
                            treeDataProps.UserAtomData.reduce(
                                (acc, curr) => acc + (curr["casislot_win_슬롯_"] || 0),
                                0
                            )) *
                        treeDataProps.IvdOptin.r_rate / 100
                    ),

                bat: treeDataProps.UserAtomData.reduce((acc, curr) => acc + (curr["casislot_bet_슬롯_"] || 0), 0),
                win: treeDataProps.UserAtomData.reduce((acc, curr) => acc + (curr["casislot_win_슬롯_"] || 0), 0),
                r_rate: treeDataProps.IvdOptin.r_rate,
                shareRate: treeDataProps.IvdOptin.slot_share_rate,
                disable: !treeDataProps.IvdOptin.slot_activate,
            },
            holdem: {
                lossProfit: treeDataProps.UserTreeDatas.reduce(
                    (acc, curr) => acc + (curr["홀덤 손익금"] || 0),
                    0
                ),
                losing: SharTableOption["holdem_losing"] !== undefined ?
                    SharTableOption["holdem_losing"] :
                    treeDataProps.UserTreeDatas.reduce(
                        (acc, curr) => acc + (curr["홀덤 루징금"] || 0),
                        0
                    ),
                eggprice: SharTableOption["holdem_eggprice"] !== undefined ?
                    SharTableOption["holdem_eggprice"] : Math.round(
                        (treeDataProps.UserAtomData.reduce(
                            (acc, curr) => acc + (curr["holdem_bet_홀덤_"] || 0),
                            0
                        ) -
                            treeDataProps.UserAtomData.reduce(
                                (acc, curr) => acc + (curr["holdem_win_홀덤_"] || 0),
                                0
                            )) *
                        treeDataProps.IvdOptin.r_rate / 100
                    ),

                bat: treeDataProps.UserAtomData.reduce((acc, curr) => acc + (curr["holdem_bet_홀덤_"] || 0), 0),
                win: treeDataProps.UserAtomData.reduce((acc, curr) => acc + (curr["holdem_win_홀덤_"] || 0), 0),
                r_rate: treeDataProps.IvdOptin.r_rate,
                shareRate: treeDataProps.IvdOptin.holdem_share_rate,
                disable: !treeDataProps.IvdOptin.holdem_activate,
            },
        },
        IvdOptin: IvdOptin
    };



    return retrun_data;
}


/**
 
        const UserSharDataProps_mon_imsi = {
            UserShartitle: {
                site_name: TreeDataProps_mon.site_name,
                company_name: TreeDataProps_mon.company_name,
                start_date: TreeDataProps_mon.start_date,
                end_date: TreeDataProps_mon.end_date,
                idv_show_optin: TreeDataProps_mon.IvdOptin.share_show_option,
            },
            UserSharData: {
                casino: {
                    lossProfit: TreeDataProps_mon.UserTreeDatas.reduce((acc, curr) => { return acc + (curr["카지노 손익금"] || 0); }, 0),
                    losing: TreeDataProps_mon.UserTreeDatas.reduce((acc, curr) => { return acc + (curr["카지노 루징금"] || 0); }, 0),

                    eggprice: Math.round((TreeDataProps_mon.UserAtomData.reduce((acc, curr) => { return acc + (curr["casislot_bet_카지노_"] || 0); }, 0) - TreeDataProps_mon.UserAtomData.reduce((acc, curr) => { return acc + (curr["casislot_win_카지노_"] || 0); }, 0)) * TreeDataProps_mon.IvdOptin.r_rate / 100),

                    shareRate: TreeDataProps_mon.IvdOptin.casino_share_rate,
                    disable: !TreeDataProps_mon.IvdOptin.casino_activate,
                },
                slot: {
                    lossProfit: TreeDataProps_mon.UserTreeDatas.reduce((acc, curr) => { return acc + (curr["슬롯 손익금"] || 0); }, 0),
                    losing: TreeDataProps_mon.UserTreeDatas.reduce((acc, curr) => { return acc + (curr["슬롯 루징금"] || 0); }, 0),

                    eggprice: Math.round((TreeDataProps_mon.UserAtomData.reduce((acc, curr) => { return acc + (curr["casislot_bet_슬롯_"] || 0); }, 0) - TreeDataProps_mon.UserAtomData.reduce((acc, curr) => { return acc + (curr["casislot_win_슬롯_"] || 0); }, 0)) * TreeDataProps_mon.IvdOptin.r_rate / 100),

                    shareRate: TreeDataProps_mon.IvdOptin.slot_share_rate,
                    disable: !TreeDataProps_mon.IvdOptin.slot_activate,
                },
                holdem: {
                    lossProfit: TreeDataProps_mon.UserTreeDatas.reduce((acc, curr) => { return acc + (curr["홀덤 손익금"] || 0); }, 0),
                    losing: TreeDataProps_mon.UserTreeDatas.reduce((acc, curr) => { return acc + (curr["홀덤 루징금"] || 0); }, 0),

                    eggprice: Math.round((TreeDataProps_mon.UserAtomData.reduce((acc, curr) => { return acc + (curr["holdem_bet_홀덤_"] || 0); }, 0) - TreeDataProps_mon.UserAtomData.reduce((acc, curr) => { return acc + (curr["holdem_win_홀덤_"] || 0); }, 0)) * TreeDataProps_mon.IvdOptin.r_rate / 100),

                    shareRate: TreeDataProps_mon.IvdOptin.holdem_share_rate,
                    disable: !TreeDataProps_mon.IvdOptin.holdem_activate,
                },
            }
        }
        setSharDataProps_mon(UserSharDataProps_mon_imsi);
        이걸 함수화 한것임.
 */

