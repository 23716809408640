import { Center, Grid, GridItem, Spinner, Text, VStack } from "@chakra-ui/react";
import { useRecoilValue } from "recoil";
import { SelectedMenuAtom } from "../../../atoms/topSelect";
import SiteComYearTopSelect from "../CommContents/TopSelect/SiteComYearTopSelect";
import { useFetchPBLosingMonData } from "./contents/fetchDada";
import BaseTable from "../CommContents/Table/BaseTable";

export default function PBLosingMonTotal() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    const { PBlosingMonTableData, loading } = useFetchPBLosingMonData(SelectedMenu);
    return (
        <Grid padding={"0.2rem"}
            h='100%'
            templateColumns='repeat(10, 1fr)'
            templateRows='repeat(10, 1fr)'
            gap={1}
        >
            <GridItem borderRadius={"xl"} paddingX={"0.5rem"} colSpan={10} rowSpan={1}>
                <SiteComYearTopSelect />
            </GridItem>
            <GridItem borderRadius={"xl"} paddingX={"0.5rem"} colSpan={10} rowSpan={9} >
                {loading ? (
                    <Center h="100%">
                        <VStack spacing={4}>
                            <Spinner size="xl" />
                            <Text fontSize="lg">로딩 중...</Text>
                        </VStack>
                    </Center>
                ) : (
                    PBlosingMonTableData && <BaseTable tableData={PBlosingMonTableData} />
                )}
            </GridItem>
        </Grid>
    );
}
