import { Flat } from "lodash";
import { axiosInstance } from "./base";

export interface ISiteData {
    pk: number;
    user: {
        pk: number;
        username: string;
        authority: string;
        distributor: number;
        login_verified: boolean;
    };
    sitetype: {
        pk: number;
        name: string;
    };
    site_id: string;
    site_name: string;
    target_date: string;
    site_url: string;
    site_R_rate: number;

}

interface ISiteDataParmas {
    siteinfo: number;
    datatype: string;
    start_date: string;
    end_date: string;
}

// sitedata/?sitetype=1&datatype=atom&start_date=2024-05-10&end_date=2024-06-06
export const getSiteData = ({ siteinfo, datatype, start_date, end_date }: ISiteDataParmas) => (
    axiosInstance.get(
        `sitedata/`,
        {
            params: {
                siteinfo: siteinfo,
                datatype: datatype,
                start_date: start_date,
                end_date: end_date,
            },
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)




// 파워볼 게임리스트 데이터를 나타내는 인터페이스
interface PBgamelistResponse {
    pk: number;
    distributor: {
        pk: number;
        name: string;
    };
    siteinfo: {
        pk: number;
        sitetype: {
            pk: number;
            name: string;
        };
        site_name: string;
    };
    target_date: string;
    data_type: string;
    data: string;
    memo: string;
}

// 데이터가 없는 경우의 인터페이스
interface NoDataResponse {
    detail: string;
}

// 두 가지 응답을 처리할 수 있는 유니언 타입
type GetPBgamelistResponse = PBgamelistResponse | NoDataResponse;

export const getPBgamelist = ({ siteinfo }: { siteinfo: number }) => (
    axiosInstance.get(
        `sitedata/pbgamelist/`,
        {
            params: {
                siteinfo: siteinfo,
            },
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data as GetPBgamelistResponse)
)