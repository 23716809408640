import { axiosInstance } from "./base";

// 카지노 정산 업체 옵션
export interface IcsIdvOption {
    pk: number;
    distributor: {
        pk: number;
        name: string;
    };
    siteInfo: number;
    company_name: string;
    start_date: string;
    end_date: string;
    login_show_optin: string;
    share_show_option: string;
    user_show_option: string;
    r_rate: number;
    casino_login_rate: number;
    casino_share_rate: number;
    casino_activate: boolean;
    slot_login_rate: number;
    slot_share_rate: number;
    slot_activate: boolean;
    holdem_login_rate: number;
    holdem_share_rate: number;
    holdem_activate: boolean;
    idv_login_rates: string;
    created_at: string;
    updated_at: string;
}

export interface IpostCSIdbOptionParams {
    'siteInfo_pk': number,
    'company_name': string,
    'start_date': string,
    'end_date': string,
    'type': string,
}

export const getCSIdbOption = (params: IpostCSIdbOptionParams) => (
    axiosInstance.get(
        `csidvoption/`,
        {
            params: params,
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data as IcsIdvOption)
)

export const getCSIdbOptionDetail = ({ pk }: { pk: number; }) => (
    axiosInstance.get(
        `csidvoption/${pk}/`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data as IcsIdvOption)
)

interface IputCSIdbOptionParams {
    'IdvOpthon_pk': number,
    'siteInfo_pk': number,
    'company_name': string,
    'start_date': string,
    'end_date': string,
    'login_show_optin': string,
    'share_show_option': string,
    'user_show_option': string,
    'r_rate': number,
    'casino_login_rate': number,
    'casino_share_rate': number,
    'casino_activate': boolean,
    'slot_login_rate': number,
    'slot_share_rate': number,
    'slot_activate': boolean,
    'holdem_login_rate': number,
    'holdem_share_rate': number,
    'holdem_activate': boolean,
    "idv_login_rates": string,
}

export const putCSIdbOption = (params: IputCSIdbOptionParams) => (
    axiosInstance.put(
        `csidvoption/${params.IdvOpthon_pk}/`,
        {
            siteInfo_pk: params.siteInfo_pk,
            company_name: params.company_name,
            start_date: params.start_date,
            end_date: params.end_date,
            login_show_optin: params.login_show_optin,
            share_show_option: params.share_show_option,
            user_show_option: params.user_show_option,
            r_rate: params.r_rate,
            casino_login_rate: params.casino_login_rate,
            casino_share_rate: params.casino_share_rate,
            casino_activate: params.casino_activate,
            slot_login_rate: params.slot_login_rate,
            slot_share_rate: params.slot_share_rate,
            slot_activate: params.slot_activate,
            holdem_login_rate: params.holdem_login_rate,
            holdem_share_rate: params.holdem_share_rate,
            holdem_activate: params.holdem_activate,
            idv_login_rates: params.idv_login_rates,
        },
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)