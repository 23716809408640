import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { getMonthRangeFromString, IApiData, transformData2 } from "../../../../lib/util";
import { useMutation } from "@tanstack/react-query";
import { getSiteData } from "../../../../api/sitedata";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";

export function useUserMoneyData() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);
    const [userMoneyData, setUserMoneyData] = useState<IApiData>({ headers: [], rows: [] });

    const MonthSiteDataMutation = useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                setUserMoneyData({ headers: [], rows: [] });
                return;
            }
            const dataList = data.map((sitedata: any) => JSON.parse(sitedata.data));
            setUserMoneyData(transformData2(dataList));
        },
        onError: (error) => {
            console.error("사이트 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });

    useEffect(() => {
        const monthRange = getMonthRangeFromString(SelectedMenu.Month);
        if (SelectedMenu.site.pk !== -1 && SelectedMenu.category && SelectedMenu.Month) {
            MonthSiteDataMutation.mutate({
                siteinfo: SelectedMenu.site.pk,
                datatype: "usermoney",
                start_date: monthRange[0],
                end_date: monthRange[1],
            });
        }
    }, [SelectedMenu]);

    return userMoneyData;
}


/**
const data = {
    headers: [
        'Date', '유저 머니', '유저 포인트', 'TOTAL', '전일 대비 변동'
    ],
    rows: [
        { date: '3/1', userMoney: '589,115,596', userPoints: '13,984,078', total: '603,099,674', change: '-24,187,984' },
        { date: '3/2', userMoney: '564,952,369', userPoints: '12,253,720', total: '577,206,089', change: '-25,893,585' },
        { date: '3/3', userMoney: '551,580,896', userPoints: '10,479,408', total: '561,986,415', change: '-15,219,674' },
        { date: '3/4', userMoney: '573,886,927', userPoints: '11,422,018', total: '585,308,945', change: '23,308,532' },
        { date: '3/5', userMoney: '573,886,927', userPoints: '11,422,018', total: '585,308,945', change: '0' },
        { date: '3/6', userMoney: '569,179,159', userPoints: '10,729,557', total: '579,908,716', change: '-5,400,229' },
        { date: '3/7', userMoney: '569,179,159', userPoints: '10,729,557', total: '579,908,716', change: '0' },
        { date: '3/8', userMoney: '566,424,679', userPoints: '11,372,117', total: '577,796,796', change: '-2,111,920' },
        { date: '3/9', userMoney: '565,416,919', userPoints: '11,437,659', total: '576,854,578', change: '-942,218' },
        { date: '3/10', userMoney: '566,846,753', userPoints: '11,493,659', total: '578,340,412', change: '1,485,834' },
        { date: '3/11', userMoney: '565,812,784', userPoints: '11,336,001', total: '577,148,785', change: '-1,191,627' },
        { date: '3/12', userMoney: '564,846,753', userPoints: '11,493,659', total: '576,340,412', change: '-808,373' },
        { date: '3/13', userMoney: '563,123,201', userPoints: '12,553,243', total: '575,676,444', change: '-663,968' },
        { date: '3/14', userMoney: '566,424,679', userPoints: '11,372,117', total: '577,796,796', change: '2,120,352' },
        { date: '3/15', userMoney: '564,846,753', userPoints: '11,493,659', total: '576,340,412', change: '-1,456,384' },
        { date: '3/16', userMoney: '562,319,123', userPoints: '11,793,211', total: '574,112,334', change: '-2,228,078' },
        { date: '3/17', userMoney: '563,726,284', userPoints: '11,987,211', total: '575,713,495', change: '1,601,161' },
        { date: '3/18', userMoney: '562,315,123', userPoints: '12,124,433', total: '574,439,556', change: '-1,273,939' },
        { date: '3/19', userMoney: '561,942,938', userPoints: '12,003,394', total: '573,946,332', change: '-493,224' },
        { date: '3/20', userMoney: '561,121,203', userPoints: '12,343,232', total: '573,464,435', change: '-481,897' },
        { date: '3/21', userMoney: '561,121,203', userPoints: '12,343,232', total: '573,464,435', change: '0' },
        { date: '3/22', userMoney: '561,708,002', userPoints: '12,640,029', total: '574,348,031', change: '883,596' },
        { date: '3/23', userMoney: '561,121,203', userPoints: '12,343,232', total: '573,464,435', change: '-883,596' },
        { date: '3/24', userMoney: '560,946,938', userPoints: '12,324,123', total: '573,271,061', change: '-193,374' },
        { date: '3/25', userMoney: '561,121,203', userPoints: '12,343,232', total: '573,464,435', change: '193,374' },
        { date: '3/26', userMoney: '560,946,938', userPoints: '12,324,123', total: '573,271,061', change: '-193,374' },
        { date: '3/27', userMoney: '561,707,596', userPoints: '12,650,485', total: '574,358,081', change: '1,087,020' },
        { date: '3/28', userMoney: '561,823,897', userPoints: '12,789,789', total: '574,613,686', change: '255,605' },
        { date: '3/29', userMoney: '562,943,897', userPoints: '12,909,789', total: '575,853,686', change: '1,239,000' },
        { date: '3/30', userMoney: '562,943,897', userPoints: '12,909,789', total: '575,853,686', change: '0' },
        { date: '3/31', userMoney: '599,761,911', userPoints: '8,577,387', total: '608,339,298', change: '32,485,612' },
        { date: 'TOTAL', userMoney: '18,827,291', userPoints: '1,167,056', total: '20,243,999', change: '0' }
    ]
};
 */