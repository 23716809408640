import { useRecoilValue } from "recoil";
import { SelectedMenuAtom } from "../../../atoms/topSelect";
import { Flex } from "@chakra-ui/react";
import MPowerBallTable from "./contents/PowerBallTable";
import MCasinoSlotTable from "./contents/CasinoSlotTable";
import MHoldemTable from "./contents/HoldemTable";
import MSportTable from "./contents/SportTable";
import MGitaTable from "./contents/GitaTable";
import MDepositAndWithdrawTable from "./contents/DepositAndWidrawTable";
import MUserMoneyTable from "./contents/UserMoneyTable";
import MBankTable from "./contents/BankTable";

export default function MSiteDetailSettlement() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    return (
        <Flex overflow={"scroll"}>
            {SelectedMenu.category === "파워볼" && <MPowerBallTable />}
            {SelectedMenu.category === "카지노/슬롯" && <MCasinoSlotTable />}
            {SelectedMenu.category === "홀덤" && <MHoldemTable />}
            {SelectedMenu.category === "스포츠" && <MSportTable />}
            {SelectedMenu.category === "기타" && <MGitaTable />}
            {SelectedMenu.category === "충전/환전" && <MDepositAndWithdrawTable />}
            {SelectedMenu.category === "유저머니" && <MUserMoneyTable />}
            {SelectedMenu.category === "뱅크" && <MBankTable />}

        </Flex>
    );
}