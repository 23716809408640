import { useHoldemData } from '../../../userMainContesnts/SiteDetailSettlement/consponents/HoldomData';
import MBaseTable from '../../CommContents/Table/MBaseTable';

export default function MHoldemTable() {
    const hodemData = useHoldemData();

    return (
        <MBaseTable headers={hodemData.headers} rows={hodemData.rows} />
    );
}
