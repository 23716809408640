import { Box, useColorModeValue, Flex } from "@chakra-ui/react";
import useLoginProtectedPage from "../components/LoginProtectedPage";
import { darkTheme_colors, lightTheme_colors } from "../theme";
import { useRecoilState } from "recoil";
import { SelectedMenuAtom } from "../atoms/topSelect";
import DateComMenuFootSelect from "./mobileUserMainContents/CommContents/FootSelect/DateComMenuFootSelect";
import MonthComOptMenuFootSelect from "./mobileUserMainContents/CommContents/FootSelect/MonthComOptMenuFootSelect";
import { useEffect } from "react";
import MSiteDailySettlement from "./mobileUserMainContents/DailySettlement/SiteDailySettlement";
import MSiteDetailSettlement from "./mobileUserMainContents/DetaileSettlement/DetaileSettlement";

export default function MobileUserMain() {
    useLoginProtectedPage();
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)

    useEffect(() => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.pageSelect = "SiteDailySettlement";
        setSelectedMenu(before_SelectedMenu);
    }, []);

    const bgColor = useColorModeValue(lightTheme_colors["bgColor"], darkTheme_colors["bgColor"]);

    return (
        <Flex
            direction="column"
            height="100%" // 화면을 완전히 채움
            overflow="hidden" // 스크롤바 제거
            bg={bgColor}
        >
            <Box flex="1" overflow="scroll"> {/* 스와이프 영역 */}
                {SelectedMenu.pageSelect === "SiteDailySettlement" && <MSiteDailySettlement />}
                {SelectedMenu.pageSelect === "SiteDetailSettlement" && <MSiteDetailSettlement />}
            </Box>
            <Box height="3rem" position="sticky" bottom="0"> {/* 하단에 고정된 FootSelect */}
                {SelectedMenu.pageSelect === "SiteDailySettlement" && <DateComMenuFootSelect />}
                {SelectedMenu.pageSelect === "SiteDetailSettlement" && <MonthComOptMenuFootSelect />}
            </Box>
        </Flex>
    );
}
