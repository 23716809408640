import { Input, Link, Td, Tr, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { IUserTreeData } from "./UserTreeTable";
import { calculateSums, findChildren } from "./UserTreeTableUtils";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";

interface IUserRowProps {
    UserTreeDatas: IUserTreeData[];
    UserRowData: IUserTreeData;
    level?: number;
    dataKeys: string[];
    IvdOptin: any; // Define this type properly based on your usage
    onRateChange: (userId: string, rateType: string, value: number) => void;
}


const UserTreeTableRow: React.FC<IUserRowProps> = ({ UserTreeDatas, UserRowData, level = 0, dataKeys, IvdOptin, onRateChange }) => {
    const [isOpen, setIsOpen] = useState<boolean>(level === 0 ? true : false);
    const children: IUserTreeData[] = findChildren(UserTreeDatas, UserRowData.user_id, UserRowData.status_id_list.length);
    const hasChildren = children.length > 0;

    const sums = useMemo(() => calculateSums(UserTreeDatas, UserRowData, dataKeys), [UserRowData, dataKeys]);


    // 입력값이 변경되었을때만 onRateChange 를 호출하기 위해 state로 관리
    const [casinoRate, setCasinoRate] = useState(UserRowData["카지노 루징율"] || 0);
    const [slotRate, setSlotRate] = useState(UserRowData["슬롯 루징율"] || 0);
    const [holdemRate, setHoldemRate] = useState(UserRowData["홀덤 루징율"] || 0);

    const [adjustmentInput, setAdjustmentInput] = useState(UserRowData["금액 조정"]?.toLocaleString() || "");

    useEffect(() => {
        setCasinoRate(UserRowData["카지노 루징율"] || 0);
        setSlotRate(UserRowData["슬롯 루징율"] || 0);
        setHoldemRate(UserRowData["홀덤 루징율"] || 0);
    }, [UserRowData]);

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select();
    };

    // 카지노, 슬롯, 홀덤 입력을 다룰 때
    const handleBlur = (currentValue: number | string, initialValue: number, userId: string, rateType: string) => {
        // NaN 또는 "-"일 경우 0으로 처리
        const numericValue = isNaN(parseFloat(currentValue.toString().replace(/,/g, ""))) ? 0 : parseFloat(currentValue.toString().replace(/,/g, ""));

        // 화면에 0으로 업데이트
        if (isNaN(numericValue) || currentValue === "-") {
            switch (rateType) {
                case "casino_login_rate":
                    setCasinoRate(0);
                    break;
                case "slot_login_rate":
                    setSlotRate(0);
                    break;
                case "holdem_login_rate":
                    setHoldemRate(0);
                    break;
                default:
                    break;
            }
        } else {
            // 화면에 올바른 수치를 업데이트
            switch (rateType) {
                case "casino_login_rate":
                    setCasinoRate(numericValue);
                    break;
                case "slot_login_rate":
                    setSlotRate(numericValue);
                    break;
                case "holdem_login_rate":
                    setHoldemRate(numericValue);
                    break;
                default:
                    break;
            }
        }

        if (!isNaN(numericValue) && numericValue !== initialValue) {
            onRateChange(userId, rateType, numericValue);
        }
    };

    const handleAdjustmentBlur = () => {
        // 입력 값이 유효한 경우에만 숫자로 업데이트 및 로컬 서식 적용
        const numericValue = adjustmentInput === "-" ? 0 : parseFloat(adjustmentInput.replace(/,/g, ""));
        if (!isNaN(numericValue)) {
            setAdjustmentInput(numericValue.toLocaleString()); // 로컬 서식으로 변환하여 입력 필드 업데이트
            handleBlur(numericValue, UserRowData["금액 조정"], UserRowData.user_id, "cost_adjust");
        }
        // 빈 문자열 또는 "-"만 입력된 경우 0으로 업데이트
        else {
            setAdjustmentInput("0");
            handleBlur(0, UserRowData["금액 조정"], UserRowData.user_id, "cost_adjust");
        }
    };

    // status_id_list의 길이에 따라 배경색을 지정하는 함수
    const rowLineColor0 = useColorModeValue(lightTheme_colors['rowLineColor0'], darkTheme_colors['rowLineColor0']);
    const rowLineColor1 = useColorModeValue(lightTheme_colors['rowLineColor1'], darkTheme_colors['rowLineColor1']);
    const rowLineColor2 = useColorModeValue(lightTheme_colors['rowLineColor2'], darkTheme_colors['rowLineColor2']);
    const rowLineColor3 = useColorModeValue(lightTheme_colors['rowLineColor3'], darkTheme_colors['rowLineColor3']);
    const rowLineColor4 = useColorModeValue(lightTheme_colors['rowLineColor4'], darkTheme_colors['rowLineColor4']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const receSelectColor = useColorModeValue(lightTheme_colors['receSelectColor'], darkTheme_colors['receSelectColor']);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);

    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);
    const deactivationColor = useColorModeValue(lightTheme_colors['deactivationColor'], darkTheme_colors['deactivationColor']);



    const getStatusColor = (statusIdLength: number) => {
        switch (statusIdLength) {
            case 0:
                return rowLineColor0;
            case 1:
                return rowLineColor1;
            case 2:
                return rowLineColor2;
            case 3:
                return rowLineColor3;
            default:
                return rowLineColor4;
        }
    };

    // 하위 유저 여부에 따라 텍스트 색상을 지정하는 함수
    const getTextColor = (hasChildren: boolean) => {
        return hasChildren ? receSelectColor : mainFontColor;
    };

    const rowBgColor = getStatusColor(UserRowData.status_id_list.length);
    const textColor = getTextColor(hasChildren);


    // idv_login_rates에서 현재 유저의 rate 가져오기
    const loginRates = JSON.parse(IvdOptin.idv_login_rates)[UserRowData.user_id] || {};
    // 백그라운드 색상 처리 (존재하면 색상 변경, 없으면 기본)
    const casinoInputBg = loginRates.casino_login_rate !== undefined ? deactivationColor : inputBgColor;
    const slotInputBg = loginRates.slot_login_rate !== undefined ? deactivationColor : inputBgColor;
    const holdemInputBg = loginRates.holdem_login_rate !== undefined ? deactivationColor : inputBgColor;
    const cost_adjustBg = loginRates.cost_adjust !== undefined ? deactivationColor : inputBgColor;


    return (
        <>
            <Tr bg={rowBgColor} borderBottom={isOpen ? "1px solid" : undefined}>
                <Td pl={`${level * 2 + 1}rem`}>
                    <Link color={textColor} onClick={() => setIsOpen(!isOpen)} cursor="pointer">
                        {UserRowData.user_id} <br />({UserRowData.user_nickname})
                    </Link>
                </Td>
                <Td>{UserRowData.status}</Td>

                {/* casino_activate가 true일 때만 카지노 관련 열을 렌더링 */}
                {IvdOptin.casino_activate && (
                    <Td>
                        <Input
                            size="sm"
                            type="number"
                            width="4rem"
                            borderRadius={"lg"}
                            value={casinoRate}
                            textAlign="right" // 숫자 오른쪽 정렬
                            bg={casinoInputBg}
                            onFocus={handleFocus}
                            onChange={(e) => setCasinoRate(parseFloat(e.target.value))}
                            onBlur={() =>
                                handleBlur(casinoRate, UserRowData["카지노 루징율"], UserRowData.user_id, "casino_login_rate")
                            }
                        />
                    </Td>
                )}
                {/* slot_activate가 true일 때만 슬롯 관련 열을 렌더링 */}
                {IvdOptin.slot_activate && (
                    <Td>
                        <Input
                            size="sm"
                            type="number"
                            width="4rem"
                            borderRadius={"lg"}
                            value={slotRate}
                            textAlign="right" // 숫자 오른쪽 정렬
                            bg={slotInputBg}
                            onFocus={handleFocus}
                            onChange={(e) => setSlotRate(parseFloat(e.target.value))}
                            onBlur={() =>
                                handleBlur(slotRate, UserRowData["슬롯 루징율"], UserRowData.user_id, "slot_login_rate")
                            }
                        />
                    </Td>
                )}

                {/* holdem_activate가 true일 때만 홀덤 관련 열을 렌더링 */}
                {IvdOptin.holdem_activate && (
                    <Td>
                        <Input
                            size="sm"
                            type="number"
                            width="4rem"
                            borderRadius={"lg"}
                            value={holdemRate}
                            textAlign="right" // 숫자 오른쪽 정렬
                            bg={holdemInputBg}
                            onFocus={handleFocus}
                            onChange={(e) => setHoldemRate(parseFloat(e.target.value))}
                            onBlur={() =>
                                handleBlur(holdemRate, UserRowData["홀덤 루징율"], UserRowData.user_id, "holdem_login_rate")
                            }
                        />
                    </Td>
                )}

                {/* 동적으로 dataKeys 렌더링 - casino_activate, slot_activate, holdem_activate 확인 */}

                {(dataKeys.filter((key) => key !== "금액 조정")).map((key) => {  // 금액조정을 필터링 한다. 
                    // 카지노 관련 열 제외
                    if (key.includes("카지노") && !IvdOptin.casino_activate) return null;
                    // 슬롯 관련 열 제외
                    if (key.includes("슬롯") && !IvdOptin.slot_activate) return null;
                    // 홀덤 관련 열 제외
                    if (key.includes("홀덤") && !IvdOptin.holdem_activate) return null;

                    // 금액 조정 input을 "루징금 합계" 바로 앞에 렌더링
                    if (key === "루징금 합계") {
                        return (
                            <>
                                {/* 금액 조정 관련 열 렌더링 */}
                                <Td key="금액 조정">
                                    <Input
                                        size="sm"
                                        type="text" // 음수 및 로컬 서식 처리를 위해 "text"로 설정
                                        width="6.5rem"
                                        borderRadius={"lg"}
                                        value={adjustmentInput} // 입력 시 그대로 표시
                                        textAlign="right" // 숫자 오른쪽 정렬
                                        bg={cost_adjustBg}
                                        color={parseFloat(adjustmentInput.replace(/,/g, "")) < 0 ? negativeColor : undefined} // 음수일 경우 negativeColor 적용
                                        onFocus={(e) => e.target.select()} // Focus 시 전체 선택
                                        onChange={(e) => {
                                            // - 및 숫자를 허용하고 로컬 서식 제거
                                            const inputValue = e.target.value.replace(/,/g, "");
                                            // 입력이 "-"이거나 숫자로 변환 가능한 경우만 처리
                                            if (inputValue === "-" || !isNaN(parseFloat(inputValue))) {
                                                setAdjustmentInput(inputValue); // 입력 상태를 그대로 유지
                                            }
                                        }}
                                        onBlur={handleAdjustmentBlur} // onBlur에서 "-" 입력 시 0 처리
                                    />
                                </Td>


                                <Td key={key} color={sums[key] < 0 ? negativeColor : undefined} textAlign="right">
                                    {isOpen ? (UserRowData[key].toLocaleString() || "-") : (sums[key].toLocaleString() || "-")}
                                </Td>
                            </>
                        );
                    }

                    const isNegative = isOpen ? UserRowData[key] < 0 : sums[key] < 0;
                    return (
                        <Td key={key} color={isNegative ? negativeColor : undefined} textAlign="right">
                            {isOpen ? (UserRowData[key].toLocaleString() || "-") : (sums[key].toLocaleString() || "-")}
                        </Td>
                    );
                })}
            </Tr>
            {isOpen && children.map((child) => (
                <UserTreeTableRow key={child.user_id} UserTreeDatas={UserTreeDatas} UserRowData={child} level={level + 1} dataKeys={dataKeys} IvdOptin={IvdOptin} onRateChange={onRateChange} />
            ))}
        </>
    );
};

export default UserTreeTableRow;
