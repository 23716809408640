import { axiosInstance } from "./base";

export interface ISiteInfo {
    pk: number;
    site_id: string;
    distributor: number;
    is_activate: boolean;
    memo: string;
    sitetype: {
        pk: number;
        name: string;
    };
    user: {
        pk: number;
        username: string;
        authority: string;
        distributor: number;
        login_verified: boolean;
    };
}

export const getSiteinfo = () => (
    axiosInstance.get(
        `siteinfo/`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)


