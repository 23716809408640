import { axiosInstance } from "./base";

// 파워볼 정산 업체 옵션
export interface IpbComInfo {
    pk: number;
    distributor: {
        pk: number;
        name: string;
    };
    siteInfo: number;
    company_name: string;
    login_rate: number;
    selected_login_list: string;
    share_rate: number;
    selected_share_list: string;
    created_at: string;
    updated_at: string;
}

interface IpostPBComInfoParmas {
    'siteInfo_pk': number,
    'company_name': string,
    'login_rate': number,
    'selected_login_list': string,
    'share_rate': number,
    'selected_share_list': string,
}

export const getPBComInfo = ({ siteInfo_pk }: { siteInfo_pk: number; }) => (
    axiosInstance.get(
        `pbcominfo/`,
        {
            params: {
                siteInfo: siteInfo_pk,
            },
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data as IpbComInfo[])
)

export const postPBComInfo = ({ siteInfo_pk, company_name, login_rate, selected_login_list, share_rate, selected_share_list }: IpostPBComInfoParmas) => (
    axiosInstance.post(
        `pbcominfo/`,
        {
            siteInfo_pk: siteInfo_pk,
            company_name: company_name,
            login_rate: login_rate,
            selected_login_list: selected_login_list,
            share_rate: share_rate,
            selected_share_list: selected_share_list,
        },
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)


export const getPBComInfoDetail = ({ comInfo_pk }: { comInfo_pk: number }) => (
    axiosInstance.get(
        `pbcominfo/${comInfo_pk}/`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data as IpbComInfo)
)

interface IputPBComInfoParmas {
    'comInfo_pk': number,
    'siteInfo_pk': number,
    'company_name': string,
    'login_rate': number,
    'selected_login_list': string,
    'share_rate': number,
    'selected_share_list': string,
}

export const putPBComInfo = (putPBComInfoParma: IputPBComInfoParmas) => (
    axiosInstance.put(
        `pbcominfo/${putPBComInfoParma.comInfo_pk}/`,
        {
            siteInfo: putPBComInfoParma.siteInfo_pk,
            company_name: putPBComInfoParma.company_name,
            login_rate: putPBComInfoParma.login_rate,
            selected_login_list: putPBComInfoParma.selected_login_list,
            share_rate: putPBComInfoParma.share_rate,
            selected_share_list: putPBComInfoParma.selected_share_list,
        },
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)

export const deletePBComInfo = ({ comInfo_pk }: { comInfo_pk: number }) => (
    axiosInstance.delete(
        `pbcominfo/${comInfo_pk}/`,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)