import { useEffect, useRef, useState } from "react";
import { IData, useAtomDataMutation, useGetPBIdvOptionMutation } from "./costommutaions";
import { generateMonthList, getLastThreeMonths, getMonthRangeFromString } from "../../../../lib/util";


interface SiteData {
    site_name: string;          // 사이트 이름
    company_name: string;       // 회사 이름
    start_date: string;         // 시작 날짜 (YYYY-MM-DD)
    end_date: string;           // 종료 날짜 (YYYY-MM-DD)
    category: string;           // 카테고리 (예: 루징금)
    rowTypes: string[];         // 행 타입 (단폴, 조합, 3조합 등)
    colTypes: string[];         // 열 타입 (EOS-3분, 코인-5분 등)
    rate: number;               // 비율 (숫자)
    selectedOptions: string[];  // 선택된 옵션 (각 조합 및 단폴 등)
    values: {                   // 값 (각 옵션에 대한 값)
        [key: string]: number;  // 옵션 이름을 키로 하고, 값은 number
    };
    up_total: boolean | number;       // 업토탈 
    total: number;              // 총계 (루징금 총계)
}

function transformData(inputData: SiteData[]) {
    let totalAmount = 0;

    const rows = inputData.map((item) => {
        const period = `${item.start_date} ~ ${item.end_date}`;

        // selectedOptions에 있는 값의 values 합산
        const amount = item.selectedOptions.reduce((acc, option) => {
            const value = item.values[option] || 0; // values가 없으면 0으로 처리
            return acc + value;
        }, 0);


        // 리무진 특별처리
        const adjustedAmount = typeof item.up_total === "number" ? Math.round(item.up_total * (item.rate / 100)) : Math.round(amount * item.rate / 100)

        totalAmount += adjustedAmount;

        // 금액을 천단위 콤마로 포맷팅
        return {
            "기간": period,
            "파워볼 루징금": adjustedAmount.toLocaleString(),
        };
    });


    // 전체 합계 행 추가
    rows.push({
        "기간": 'TOTAL',
        "파워볼 루징금": totalAmount.toLocaleString(),
    });

    return {
        headers: ['기간', '파워볼 루징금'],
        rows,
    };
}



export const useFetchPBLosingMonData = (SelectedMenu: any) => {
    const [PBlosingMonTableData, setPBlosingMonTableData] = useState<any>(null); // 상태로 관리
    const [loading, setLoading] = useState(false);

    const LoginData = useRef({} as IData);
    const SelectedLoginOption = useRef([] as string[]);

    // 개별옵션 불러온다. (기본옵션 처리는 서버에서 처리한다.)
    const getPBIdvOptionMutation = useGetPBIdvOptionMutation(LoginData, SelectedLoginOption);
    // 원자 데이터 불러온다.
    const atomDataMutation = useAtomDataMutation(LoginData, SelectedLoginOption);



    const fetchData = async () => {
        setLoading(true); // 로딩 시작
        let data_list = [] as any;

        const MonsList = generateMonthList(SelectedMenu.Year);

        for (const month of MonsList) {
            const date_list = getMonthRangeFromString(month);

            const getPBidvoptionparams = {
                siteInfo_pk: SelectedMenu.site.pk,
                company_name: SelectedMenu.PBCom.name,
                start_date: date_list[0],
                end_date: date_list[1],
            };

            const getAtomdataparams = {
                siteinfo: SelectedMenu.site.pk,
                datatype: "atom",
                start_date: date_list[0],
                end_date: date_list[1],
            };

            // 두 가지 mutation을 동시에 실행하고, 모두 완료된 후에 다음 코드가 실행되도록 한다.
            await Promise.all([
                getPBIdvOptionMutation.mutateAsync(getPBidvoptionparams),
                atomDataMutation.mutateAsync(getAtomdataparams)
            ]);

            // 두 mutation이 끝난 후 LoginData.current를 data_list에 추가
            data_list.push(LoginData.current);

        }
        const transformed = transformData(data_list);

        setPBlosingMonTableData(transformed);
        setLoading(false); // 로딩 종료
    };

    useEffect(() => {
        if (SelectedMenu.site.pk !== -1 || SelectedMenu.PBCom.pk !== -1) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedMenu]);

    return { PBlosingMonTableData, loading };
}


export const useFetchPBLosing3MonData = (SelectedMenu: any) => {
    const [PBlosing3MonTableData, setPBlosing3MonTableData] = useState<any>(null); // 상태로 관리
    const [loading, setLoading] = useState(false);

    const LoginData = useRef({} as IData);
    const SelectedLoginOption = useRef([] as string[]);

    // 개별옵션 불러온다. (기본옵션 처리는 서버에서 처리한다.)
    const getPBIdvOptionMutation = useGetPBIdvOptionMutation(LoginData, SelectedLoginOption);
    // 원자 데이터 불러온다.
    const atomDataMutation = useAtomDataMutation(LoginData, SelectedLoginOption);

    const fetchData = async () => {
        setLoading(true); // 로딩 시작
        let data_list = [] as any;

        const MonsList = getLastThreeMonths(SelectedMenu);

        for (const month of MonsList) {
            const date_list = getMonthRangeFromString(month);

            const getPBidvoptionparams = {
                siteInfo_pk: SelectedMenu.site.pk,
                company_name: SelectedMenu.PBCom.name,
                start_date: date_list[0],
                end_date: date_list[1],
            };

            const getAtomdataparams = {
                siteinfo: SelectedMenu.site.pk,
                datatype: "atom",
                start_date: date_list[0],
                end_date: date_list[1],
            };

            // 두 가지 mutation을 동시에 실행하고, 모두 완료된 후에 다음 코드가 실행되도록 한다.
            await Promise.all([
                getPBIdvOptionMutation.mutateAsync(getPBidvoptionparams),
                atomDataMutation.mutateAsync(getAtomdataparams)
            ]);

            // 두 mutation이 끝난 후 LoginData.current를 data_list에 추가
            data_list.push(LoginData.current);

        }
        const transformed = transformData(data_list);

        console.log("transformed: ", transformed);

        setPBlosing3MonTableData(transformed);
        setLoading(false); // 로딩 종료
    };

    useEffect(() => {
        if (SelectedMenu.site.pk !== -1 || SelectedMenu.PBCom.pk !== -1) {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedMenu]);

    return { PBlosing3MonTableData, loading };

}