import React, { useEffect, useState, useRef } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    VStack,
    Text,
    Grid,
    GridItem,
    Checkbox,
    useColorModeValue,
    Center,
    useToast,
    Flex,
} from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import { useMutation } from "@tanstack/react-query";
import { getPBgamelist } from "../../../../api/sitedata";
import { useRecoilState } from "recoil";

import { useForm, SubmitHandler } from "react-hook-form";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";
import { getPBIdbOption, putPBIdbOption } from "../../../../api/pvidvoption";
import { getMonthRangeFromString } from "../../../../lib/util";



// Modal 컴포넌트의 Props 타입 정의
interface PBPutIdvRegModalProps {
    isOpen: boolean;
    onClose: () => void;
    option: string;
}

// Option 타입 정의
interface Option {
    id: string;
    label: string;
}

// 폼 입력 데이터 타입 정의
interface FormData {
    companyName: string;
    losingPercentage: string;
    sharePercentage: string;
    selectedLosingOptions: string[];
    selectedShareOptions: string[];
}

// transformDataToOptions 함수 타입 정의
function transformDataToOptions(data: { colType: string[], rowType: string[] }): Option[] {
    const options: Option[] = [];

    data.colType.forEach((col) => {
        data.rowType.forEach((row) => {
            const id = `${col}_${row}`;
            const label = `${col}_${row}`;
            options.push({ id, label });
        });
    });

    return options;
}

export default function PBPutIdvRegModal({ isOpen, onClose, option }: PBPutIdvRegModalProps) {

    // 사이트 옵션 목록 정보 가져오기
    const toast = useToast();
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)
    const [loginOptions, setLoginOptions] = useState<Option[]>([]);
    const [shreOptions, setShreOptions] = useState<Option[]>([]);

    const PBIdvOption_pk = useRef<number>(-1);

    // 사이트 데이터에서 파워볼 게임 리스트를 가져오기
    const pbGameListMutation = useMutation(getPBgamelist, {
        onSuccess: (data: any) => {
            if ('detail' in data) {
                console.log("데이터가 없습니다.");
                return;
            }

            if ('pk' in data) {
                const json_data = JSON.parse(data.data);

                const _options = transformDataToOptions(json_data);

                setLoginOptions(_options);
                setShreOptions(_options);
            }
        }
    });

    // 개별옵션 불러온다. (기본옵션 처리는 서버에서 처리한다.)
    const getPBIdvOptionMutation = useMutation(getPBIdbOption, {
        onSuccess: (data) => {
            // 파워볼 옵션 pk 저장
            PBIdvOption_pk.current = data.pk;

            // 기본값을 폼에 설정
            const losingOptions = JSON.parse(data.selected_login_list);
            const shareOptions = JSON.parse(data.selected_share_list);

            setValue("companyName", data.company_name);
            setValue("losingPercentage", `${data.login_rate}`); // 예: 기본 루징 퍼센트
            setValue("sharePercentage", `${data.share_rate}`);  // 예: 기본 쉐어 퍼센트
            setValue("selectedLosingOptions", losingOptions, { shouldValidate: true });
            setValue("selectedShareOptions", shareOptions, { shouldValidate: true });
        }
    });


    useEffect(() => {
        if (isOpen) {
            let getPBidvoptionparams = {
                siteInfo_pk: SelectedMenu.site.pk,
                company_name: SelectedMenu.PBCom.name,
                start_date: SelectedMenu.start_date,
                end_date: SelectedMenu.end_date,
            }

            if (option === "month") {
                const date_list = getMonthRangeFromString(SelectedMenu.Month);

                getPBidvoptionparams = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.PBCom.name,
                    start_date: date_list[0],
                    end_date: date_list[1],
                }
            }



            pbGameListMutation.mutate({ siteinfo: SelectedMenu.site.pk });

            getPBIdvOptionMutation.mutate(getPBidvoptionparams);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    // useForm 초기화
    const { register, handleSubmit, formState, reset, setValue, watch } = useForm<FormData>({
        mode: "onBlur",
        defaultValues: {
            companyName: "",           // 기본값 설정
            losingPercentage: "",      // 기본값 설정
            sharePercentage: "",       // 기본값 설정
            selectedLosingOptions: [], // 기본값 설정
            selectedShareOptions: [],  // 기본값 설정
        },
    });

    // CheckBox를 useForm 상태로 관리
    const selectedLosingOptions = watch("selectedLosingOptions");
    const selectedShareOptions = watch("selectedShareOptions");

    const handleLosingOptionChange = (id: string) => {
        const updatedOptions = selectedLosingOptions.includes(id)
            ? selectedLosingOptions.filter(option => option !== id)
            : [...selectedLosingOptions, id];
        setValue("selectedLosingOptions", updatedOptions, { shouldValidate: true });
    };

    const handleShareOptionChange = (id: string) => {
        const updatedOptions = selectedShareOptions.includes(id)
            ? selectedShareOptions.filter(option => option !== id)
            : [...selectedShareOptions, id];
        setValue("selectedShareOptions", updatedOptions, { shouldValidate: true });
    };

    // 입력값 수정
    const putPBIdvOptionMutation = useMutation(putPBIdbOption, {
        onSuccess: (data: any) => {
            toast({
                position: 'top',
                title: "옵션 수정 성공! ",
                description: `${data.company_name} 업체가 등록되었습니다.`,
                status: "info",
                isClosable: true,
            });

            setSelectedMenu({ ...SelectedMenu, reload_tag: new Date().getTime() });

            reset();
            onClose();  // 모달 닫기
        },
        onError: (data: any) => {
            toast({
                position: 'top',
                title: "옵션 수정 실패! ",
                description: "관리자에게 문의해 주세요.",
                status: "error",
                isClosable: true,
            });

            reset();
            onClose();  // 모달 닫기
        }
    });

    // 폼 제출 핸들러 타입 정의
    const onSubmit: SubmitHandler<FormData> = (data) => {
        let putPBIdbOptionParams = {
            IdvOpthon_pk: PBIdvOption_pk.current,
            siteInfo_pk: SelectedMenu.site.pk,
            company_name: data.companyName,
            start_date: SelectedMenu.start_date,
            end_date: SelectedMenu.end_date,
            login_rate: parseFloat(data.losingPercentage),
            selected_login_list: JSON.stringify(data.selectedLosingOptions),
            share_rate: parseFloat(data.sharePercentage),
            selected_share_list: JSON.stringify(data.selectedShareOptions),
        }

        if (option === "month") {
            const date_list = getMonthRangeFromString(SelectedMenu.Month);

            putPBIdbOptionParams = {
                IdvOpthon_pk: PBIdvOption_pk.current,
                siteInfo_pk: SelectedMenu.site.pk,
                company_name: data.companyName,
                start_date: date_list[0],
                end_date: date_list[1],
                login_rate: parseFloat(data.losingPercentage),
                selected_login_list: JSON.stringify(data.selectedLosingOptions),
                share_rate: parseFloat(data.sharePercentage),
                selected_share_list: JSON.stringify(data.selectedShareOptions),
            };
        }

        putPBIdvOptionMutation.mutate(putPBIdbOptionParams);
    };

    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);

    const errorColor = useColorModeValue(lightTheme_colors['errorColor'], darkTheme_colors['errorColor']);


    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent bg={inputBgColor}>
                <ModalHeader>{SelectedMenu.PBCom.name}   - {option === "month" ? (
                    <>{SelectedMenu.Month}</>
                ) : (
                    <>{SelectedMenu.start_date} ~ {SelectedMenu.end_date}</>
                )} - 옵션 수정</ModalHeader>

                <ModalCloseButton />
                <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
                    <VStack spacing={2} align="stretch">
                        <VStack align="stretch" spacing={2} display={"none"}>
                            <Text fontSize="md" fontWeight="bold">파워볼 업체 이름</Text>
                            <Input
                                placeholder="파워볼 업체명"
                                {...register("companyName", { required: "업체명을 입력하세요" })}
                            />
                            {formState.errors.companyName && <Center color={errorColor}>{formState.errors.companyName?.message}</Center>}
                        </VStack>

                        {/* 파워볼 루징 조건 */}
                        <VStack align="stretch" spacing={4}>
                            <Text fontSize="md" fontWeight="bold">파워볼 루징 조건</Text>
                            <Input
                                placeholder="파워볼 루징 %"
                                type="number"
                                step="0.1"
                                min="0"
                                max="100"
                                {...register("losingPercentage", {
                                    required: "루징 퍼센트를 입력하세요",
                                    validate: (value) =>
                                        parseFloat(value) >= 0 || "0보다 큰 값을 입력하세요",
                                })}
                            />
                            {formState.errors.losingPercentage && <Center color={errorColor}>{formState.errors.losingPercentage?.message}</Center>}

                            <Text>파워볼 루징 종류 선택</Text>
                            <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                                {loginOptions.map((option) => (
                                    <GridItem key={option.id}>
                                        <Checkbox
                                            isChecked={selectedLosingOptions.includes(option.id)}
                                            onChange={() => handleLosingOptionChange(option.id)}
                                        >
                                            {option.label}
                                        </Checkbox>
                                    </GridItem>
                                ))}
                            </Grid>

                        </VStack>

                        {/* 파워볼 쉐어 조건 */}
                        <VStack align="stretch" spacing={4}>
                            <Text fontSize="md" fontWeight="bold">파워볼 쉐어 조건</Text>
                            <Input
                                placeholder="파워볼 쉐어 %"
                                type="number"
                                step="0.1"
                                min="0"
                                max="100"
                                {...register("sharePercentage", {
                                    required: "쉐어 퍼센트를 입력하세요",
                                    validate: (value) =>
                                        parseFloat(value) >= 0 || "0보다 큰 값을 입력하세요",
                                })}
                            />
                            {formState.errors.sharePercentage && <Center color={errorColor}>{formState.errors.sharePercentage?.message}</Center>}

                            <Text>파워볼 쉐어 종류 선택</Text>
                            <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                                {shreOptions.map((option) => (
                                    <GridItem key={option.id}>
                                        <Checkbox
                                            isChecked={selectedShareOptions.includes(option.id)}
                                            onChange={() => handleShareOptionChange(option.id)}
                                        >
                                            {option.label}
                                        </Checkbox>
                                    </GridItem>
                                ))}
                            </Grid>

                        </VStack>
                    </VStack>
                    <ModalFooter>
                        <Flex justifyContent="end" w="100%">
                            <Flex>
                                <Button colorScheme="blue" mr={3} type="submit">
                                    옵션 수정
                                </Button>
                                <Button variant="ghost" onClick={onClose}>취소</Button>
                            </Flex>
                        </Flex>
                    </ModalFooter>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}

