// hooks/useFetchCSLosingMonData.ts
import { useState, useRef, useEffect } from "react";
import { BaseTableProps } from "../../CommContents/Table/BaseTable";
import { useGetAtomData, useGetCSIdvOption } from "./costommutation";
import { generateMonthList, getLastThreeMonths, getMonthRangeFromString } from "../../../../lib/util";


export const useFetchCSLosingMonData = (SelectedMenu: any) => {
    const [CSlosingMonTableData, setCSlosingMonTableData] = useState<BaseTableProps>();
    const [loading, setLoading] = useState(false);

    const UserTreeDataProps = useRef({} as any);
    const LoginData = useRef([] as any[]);

    const getCSIdvOptionMutation = useGetCSIdvOption(UserTreeDataProps);  // 커스텀 훅 안으로 이동
    const atomDataMutation = useGetAtomData(SelectedMenu, UserTreeDataProps, LoginData);  // 커스텀 훅 안으로 이동

    const fetchData = async () => {
        setLoading(true); // 로딩 시작
        LoginData.current = [];

        const MonsList = generateMonthList(SelectedMenu.Year);

        for (const month of MonsList) {
            const date_list = getMonthRangeFromString(month);

            // 옵션값을 불러오기 위해 현제 옵션이 뭔지 확인먼저 한다. 
            const getCSidvoptionparams = {
                siteInfo_pk: SelectedMenu.site.pk,
                company_name: SelectedMenu.CSCom.name,
                start_date: date_list[0],
                end_date: date_list[1],
                type: "mon",
            };
            // 옵션 데이터 가져온다.
            await getCSIdvOptionMutation.mutateAsync(getCSidvoptionparams);

            if (UserTreeDataProps.current.IvdOptin.login_show_optin === "month") {

                const getAtomDataparams = {
                    siteinfo: SelectedMenu.site.pk,
                    datatype: "atom",
                    start_date: date_list[0],
                    end_date: date_list[1],
                };
                await atomDataMutation.mutateAsync(getAtomDataparams);
            } else if (UserTreeDataProps.current.IvdOptin.login_show_optin === "month15") {
                // 옵션 데이터 가져온다.
                const getCSidvoptionparams_15_01 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list[0],
                    end_date: date_list[1].slice(0, -2) + "15",
                    type: "15_01",
                };
                await getCSIdvOptionMutation.mutateAsync(getCSidvoptionparams_15_01);

                const getAtomDataparams_15_01 = {
                    siteinfo: SelectedMenu.site.pk,
                    datatype: "atom",
                    start_date: date_list[0],
                    end_date: date_list[1].slice(0, -2) + "15",
                };
                await atomDataMutation.mutateAsync(getAtomDataparams_15_01);

                // 옵션 데이터 가져온다.
                const getCSidvoptionparams_15_02 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list[0].slice(0, -2) + "16",
                    end_date: date_list[1],
                    type: "15_02",
                };
                await getCSIdvOptionMutation.mutateAsync(getCSidvoptionparams_15_02);

                const getAtomDataparams_15_02 = {
                    siteinfo: SelectedMenu.site.pk,
                    datatype: "atom",
                    start_date: date_list[0].slice(0, -2) + "16",
                    end_date: date_list[1],
                };
                await atomDataMutation.mutateAsync(getAtomDataparams_15_02);

            } else if (UserTreeDataProps.current.IvdOptin.login_show_optin === "month10") {

                const getCSidvoptionparams_10_01 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list[0],
                    end_date: date_list[1].slice(0, -2) + "10",
                    type: "10_01",
                };
                await getCSIdvOptionMutation.mutateAsync(getCSidvoptionparams_10_01);

                const getAtomDataparams_10_01 = {
                    siteinfo: SelectedMenu.site.pk,
                    datatype: "atom",
                    start_date: date_list[0],
                    end_date: date_list[1].slice(0, -2) + "10",
                };
                await atomDataMutation.mutateAsync(getAtomDataparams_10_01);

                const getCSidvoptionparams_10_02 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list[0].slice(0, -2) + "11",
                    end_date: date_list[1].slice(0, -2) + "20",
                    type: "10_02",
                };
                await getCSIdvOptionMutation.mutateAsync(getCSidvoptionparams_10_02);

                const getAtomDataparams_10_02 = {
                    siteinfo: SelectedMenu.site.pk,
                    datatype: "atom",
                    start_date: date_list[0].slice(0, -2) + "11",
                    end_date: date_list[1].slice(0, -2) + "20",
                };
                await atomDataMutation.mutateAsync(getAtomDataparams_10_02);

                const getCSidvoptionparams_10_03 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list[0].slice(0, -2) + "21",
                    end_date: date_list[1],
                    type: "10_03",
                };
                await getCSIdvOptionMutation.mutateAsync(getCSidvoptionparams_10_03);

                const getAtomDataparams_10_03 = {
                    siteinfo: SelectedMenu.site.pk,
                    datatype: "atom",
                    start_date: date_list[0].slice(0, -2) + "21",
                    end_date: date_list[1],
                };
                await atomDataMutation.mutateAsync(getAtomDataparams_10_03);
            }
        }

        const tbdata: BaseTableProps = {
            tableData: {
                headers: ["기간", "카지노 루징금", "슬롯 루징금", "홀덤 루징금", "금액 조정", "루징금 합계"],
                rows: [
                    ...LoginData.current,
                    {
                        "기간": "TOTAL",
                        "카지노 루징금": LoginData.current.reduce((acc: number, cur: any) => acc + cur["카지노 루징금"], 0),
                        "슬롯 루징금": LoginData.current.reduce((acc: number, cur: any) => acc + cur["슬롯 루징금"], 0),
                        "홀덤 루징금": LoginData.current.reduce((acc: number, cur: any) => acc + cur["홀덤 루징금"], 0),
                        "금액 조정": LoginData.current.reduce((acc: number, cur: any) => acc + cur["금액 조정"], 0),
                        "루징금 합계": LoginData.current.reduce((acc: number, cur: any) => acc + cur["루징금 합계"], 0),
                    },
                ],
            },
        };

        setCSlosingMonTableData(tbdata);
        setLoading(false); // 로딩 종료
    };

    useEffect(() => {
        if (SelectedMenu.site.pk !== -1 && SelectedMenu.CSCom.pk !== -1) {
            fetchData();  // SelectedMenu가 업데이트될 때마다 fetchData 호출
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedMenu]);

    return { CSlosingMonTableData, loading };
};





export const useFetchCSLosing3MonData = (SelectedMenu: any) => {
    const [CSlosingMonTableData, setCSlosingMonTableData] = useState<BaseTableProps>();
    const [loading, setLoading] = useState(false);

    const UserTreeDataProps = useRef({} as any);
    const LoginData = useRef([] as any[]);

    const getCSIdvOptionMutation = useGetCSIdvOption(UserTreeDataProps);
    const atomDataMutation = useGetAtomData(SelectedMenu, UserTreeDataProps, LoginData);

    const fetchData = async () => {
        setLoading(true);
        LoginData.current = [];

        const MonsList = getLastThreeMonths(SelectedMenu);

        for (const month of MonsList) {
            const date_list = getMonthRangeFromString(month);

            // 옵션값을 불러오기 위해 현제 옵션이 뭔지 확인먼저 한다. 
            const getCSidvoptionparams = {
                siteInfo_pk: SelectedMenu.site.pk,
                company_name: SelectedMenu.CSCom.name,
                start_date: date_list[0],
                end_date: date_list[1],
                type: "mon",
            };
            // 옵션 데이터 가져온다.
            await getCSIdvOptionMutation.mutateAsync(getCSidvoptionparams);

            if (UserTreeDataProps.current.IvdOptin.login_show_optin === "month") {

                const getAtomDataparams = {
                    siteinfo: SelectedMenu.site.pk,
                    datatype: "atom",
                    start_date: date_list[0],
                    end_date: date_list[1],
                };
                await atomDataMutation.mutateAsync(getAtomDataparams);
            } else if (UserTreeDataProps.current.IvdOptin.login_show_optin === "month15") {
                // 옵션 데이터 가져온다.
                const getCSidvoptionparams_15_01 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list[0],
                    end_date: date_list[1].slice(0, -2) + "15",
                    type: "15_01",
                };
                await getCSIdvOptionMutation.mutateAsync(getCSidvoptionparams_15_01);

                const getAtomDataparams_15_01 = {
                    siteinfo: SelectedMenu.site.pk,
                    datatype: "atom",
                    start_date: date_list[0],
                    end_date: date_list[1].slice(0, -2) + "15",
                };
                await atomDataMutation.mutateAsync(getAtomDataparams_15_01);

                // 옵션 데이터 가져온다.
                const getCSidvoptionparams_15_02 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list[0].slice(0, -2) + "16",
                    end_date: date_list[1],
                    type: "15_02",
                };
                await getCSIdvOptionMutation.mutateAsync(getCSidvoptionparams_15_02);

                const getAtomDataparams_15_02 = {
                    siteinfo: SelectedMenu.site.pk,
                    datatype: "atom",
                    start_date: date_list[0].slice(0, -2) + "16",
                    end_date: date_list[1],
                };
                await atomDataMutation.mutateAsync(getAtomDataparams_15_02);

            } else if (UserTreeDataProps.current.IvdOptin.login_show_optin === "month10") {

                const getCSidvoptionparams_10_01 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list[0],
                    end_date: date_list[1].slice(0, -2) + "10",
                    type: "10_01",
                };
                await getCSIdvOptionMutation.mutateAsync(getCSidvoptionparams_10_01);

                const getAtomDataparams_10_01 = {
                    siteinfo: SelectedMenu.site.pk,
                    datatype: "atom",
                    start_date: date_list[0],
                    end_date: date_list[1].slice(0, -2) + "10",
                };
                await atomDataMutation.mutateAsync(getAtomDataparams_10_01);

                const getCSidvoptionparams_10_02 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list[0].slice(0, -2) + "11",
                    end_date: date_list[1].slice(0, -2) + "20",
                    type: "10_02",
                };
                await getCSIdvOptionMutation.mutateAsync(getCSidvoptionparams_10_02);

                const getAtomDataparams_10_02 = {
                    siteinfo: SelectedMenu.site.pk,
                    datatype: "atom",
                    start_date: date_list[0].slice(0, -2) + "11",
                    end_date: date_list[1].slice(0, -2) + "20",
                };
                await atomDataMutation.mutateAsync(getAtomDataparams_10_02);

                const getCSidvoptionparams_10_03 = {
                    siteInfo_pk: SelectedMenu.site.pk,
                    company_name: SelectedMenu.CSCom.name,
                    start_date: date_list[0].slice(0, -2) + "21",
                    end_date: date_list[1],
                    type: "10_03",
                };
                await getCSIdvOptionMutation.mutateAsync(getCSidvoptionparams_10_03);

                const getAtomDataparams_10_03 = {
                    siteinfo: SelectedMenu.site.pk,
                    datatype: "atom",
                    start_date: date_list[0].slice(0, -2) + "21",
                    end_date: date_list[1],
                };
                await atomDataMutation.mutateAsync(getAtomDataparams_10_03);
            }
        }

        const tbdata: BaseTableProps = {
            tableData: {
                headers: ["기간", "카지노 루징금", "슬롯 루징금", "홀덤 루징금", "금액 조정", "루징금 합계"],
                rows: [
                    ...LoginData.current,
                    {
                        "기간": "TOTAL",
                        "카지노 루징금": LoginData.current.reduce((acc: number, cur: any) => acc + cur["카지노 루징금"], 0),
                        "슬롯 루징금": LoginData.current.reduce((acc: number, cur: any) => acc + cur["슬롯 루징금"], 0),
                        "홀덤 루징금": LoginData.current.reduce((acc: number, cur: any) => acc + cur["홀덤 루징금"], 0),
                        "금액 조정": LoginData.current.reduce((acc: number, cur: any) => acc + cur["금액 조정"], 0),
                        "루징금 합계": LoginData.current.reduce((acc: number, cur: any) => acc + cur["루징금 합계"], 0),
                    },
                ],
            },
        };

        setCSlosingMonTableData(tbdata);
        setLoading(false);
    };

    useEffect(() => {
        if (SelectedMenu.site.pk !== -1 && SelectedMenu.CSCom.pk !== -1) {
            fetchData(); // SelectedMenu가 변경될 때마다 fetchData 호출
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedMenu]);

    return { CSlosingMonTableData, loading };
};