import React from 'react';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useColorModeValue,
    Text,
    Center,
    Flex,
} from '@chakra-ui/react';
import { darkTheme_colors, lightTheme_colors } from '../../../../theme';

export interface ISettlementShareData {
    item: string;
    amount: number;
    shareRate: number;
    shareAmount: number;
}

export interface ISettlementTableProps {
    site_name: string;
    company_name: string;
    start_date: string;
    end_date: string;
    profit_data: ISettlementShareData[];
    loss_data: ISettlementShareData[];
    profit_total: number;
    loss_total: number;
    finalAmount: number;
}

export const SettlementTableDefaultData: ISettlementTableProps = {
    site_name: "",
    company_name: "",
    start_date: "",
    end_date: "",
    profit_data: [],
    loss_data: [],
    profit_total: 0,
    loss_total: 0,
    finalAmount: 0
}


export default function PBSettlementTable({
    site_name,
    company_name,
    start_date,
    end_date,
    profit_data,
    loss_data,
    profit_total,
    loss_total,
    finalAmount
}: ISettlementTableProps) {

    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const titleColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);

    const formatValueWithColor = (value: number) => {
        const formattedValue = value.toLocaleString();
        const isNegative = value < 0;
        return (
            <Text as="span" color={isNegative ? negativeColor : undefined}>
                {formattedValue}
            </Text>
        );
    };

    return (
        <Box
            width="100%"
            overflowX="auto"
            bg={mainBgColor}
            color={mainFontColor}
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: inputBgColor,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: titleColor,
                    borderRadius: '4px',
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: titleColor,
                },
            }}
        >
            {/* 상단 타이틀 */}
            <Flex bg={inputBgColor} color={mainFontColor} alignItems={"center"} justifyContent={"space-around"} py={2} mb={2} borderRadius={"md"}>
                <Box>
                    <Center>{site_name} {company_name}쉐어 정산표</Center>
                </Box>
                <Box>
                    <Center>{start_date} ~ {end_date}</Center>
                </Box>
            </Flex>

            {/* 손익 항목 테이블 */}
            <Table variant="simple" bg={bgColor} colorScheme="gray" size="sm">
                <Thead bg={titleColor}>
                    <Tr>
                        <Th textAlign="center" padding="0.5rem" width="25%">손익 항목</Th>
                        <Th textAlign="center" padding="0.5rem" width="25%">손익 금액</Th>
                        <Th textAlign="center" padding="0.5rem" width="25%">쉐어율</Th>
                        <Th textAlign="center" padding="0.5rem" width="25%">쉐어 금액</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {loss_data.map((value, idx) => (
                        <Tr key={idx}>
                            <Td textAlign="center" padding="0.5rem" width="25%">{value.item}</Td>
                            <Td textAlign="right" padding="0.5rem" width="25%">{formatValueWithColor(value.amount)}</Td>
                            <Td textAlign="center" padding="0.5rem" width="25%">{value.shareRate}%</Td>
                            <Td textAlign="right" padding="0.5rem" width="25%">{formatValueWithColor(value.shareAmount)}</Td>
                        </Tr>
                    ))}

                </Tbody>
            </Table>

            {/* 지출항목 테이블 */}
            <Table variant="simple" bg={bgColor} colorScheme="gray" size="sm" mt={4}>
                <Thead bg={titleColor}>
                    <Tr>
                        <Th textAlign="center" padding="0.5rem" width="25%">지출 항목</Th>
                        <Th textAlign="center" padding="0.5rem" width="25%">지출 금액</Th>
                        <Th textAlign="center" padding="0.5rem" width="25%">쉐어율</Th>
                        <Th textAlign="center" padding="0.5rem" width="25%">쉐어 지출 금액</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {profit_data.map((value, idx) => (
                        <Tr key={idx}>
                            <Td textAlign="center" padding="0.5rem" width="25%">{value.item}</Td>
                            <Td textAlign="right" padding="0.5rem" width="25%">{formatValueWithColor(value.amount)}</Td>
                            <Td textAlign="center" padding="0.5rem" width="25%">{value.shareRate}%</Td>
                            <Td textAlign="right" padding="0.5rem" width="25%">{formatValueWithColor(value.shareAmount)}</Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>


            {/* 하단 합계 및 정산 금액 테이블 */}
            <Table variant="simple" bg={bgColor} colorScheme="gray" size="sm" mt={4}>
                <Thead bg={titleColor}>
                    <Tr>
                        <Th textAlign="center" padding="0.2rem">정산 항목</Th>
                        <Th textAlign="center" padding="0.2rem">정산 금액</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    <Tr>
                        <Td textAlign="center" padding="0.2rem">쉐어 금액</Td>
                        <Td textAlign="right" padding="0.2rem">{formatValueWithColor(profit_total)}</Td>
                    </Tr>
                    <Tr>
                        <Td textAlign="center" padding="0.2rem">지출 금액</Td>
                        <Td textAlign="right" padding="0.2rem">{formatValueWithColor(loss_total)}</Td>
                    </Tr>
                    <Tr>
                        <Td textAlign="center" padding="0.2rem">정산 금액</Td>
                        <Td textAlign="right" padding="0.2rem">{formatValueWithColor(finalAmount)}</Td>
                    </Tr>
                </Tbody>
            </Table>
        </Box>
    );
}
