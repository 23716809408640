import React, { useEffect, useState } from "react";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    Input,
    VStack,
    Text,
    Grid,
    GridItem,
    Checkbox,
    useColorModeValue,
    Center,
    useToast
} from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import { useMutation } from "@tanstack/react-query";
import { getPBgamelist } from "../../../../api/sitedata";
import { useRecoilState } from "recoil";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";
import { useForm, SubmitHandler } from "react-hook-form";
import { postPBComInfo } from "../../../../api/pbcominfo";


// Option 타입 정의
interface Option {
    id: string;
    label: string;
}

// 폼 입력 데이터 타입 정의
interface FormData {
    companyName: string;
    losingPercentage: string;
    sharePercentage: string;
    selectedLosingOptions: string[];
    selectedShareOptions: string[];
}

// transformDataToOptions 함수 타입 정의
function transformDataToOptions(data: { colType: string[], rowType: string[] }): Option[] {
    const options: Option[] = [];

    data.colType.forEach((col) => {
        data.rowType.forEach((row) => {
            const id = `${col}_${row}`;
            const label = `${col}_${row}`;
            options.push({ id, label });
        });
    });

    return options;
}

// Modal 컴포넌트의 Props 타입 정의
interface PBNewRegModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export default function PBNewRegModal({ isOpen, onClose }: PBNewRegModalProps) {
    // 사이트 옵션 목록 정보 가져오기
    const toast = useToast();
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)
    const [loginOptions, setLoginOptions] = useState<Option[]>([]);
    const [shreOptions, setShreOptions] = useState<Option[]>([]);

    // 사이트 데이터에서 파워볼 게임 리스트를 가져오기
    const pbGameListMutation = useMutation(getPBgamelist, {
        onSuccess: (data: any) => {
            if ('detail' in data) {
                console.log("데이터가 없습니다.");
                return;
            }

            if ('pk' in data) {
                const json_data = JSON.parse(data.data);

                const _options = transformDataToOptions(json_data);

                setLoginOptions(_options);
                setShreOptions(_options);
            }
        }
    });

    useEffect(() => {
        pbGameListMutation.mutate({ siteinfo: SelectedMenu.site.pk });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedMenu]);

    // 입력값 등록
    const postPBIdvOptionMutation = useMutation(postPBComInfo, {
        onSuccess: (data: any) => {
            toast({
                position: 'top',
                title: "업체 등록 성공! ",
                description: `${data.company_name} 업체가 등록되었습니다.`,
                status: "info",
                isClosable: true,
            });

            setSelectedMenu({ ...SelectedMenu, reload_tag: new Date().getTime() });

            reset();
            onClose();  // 모달 닫기
        },
        onError: (data: any) => {
            toast({
                position: 'top',
                title: "업체 등록 실패! ",
                description: "관리자에게 문의해 주세요.",
                status: "error",
                isClosable: true,
            });

            reset();
            onClose();  // 모달 닫기
        }
    });


    // useForm 초기화
    const { register, handleSubmit, formState, reset, setValue, watch } = useForm<FormData>({
        mode: "onBlur",
        defaultValues: {
            selectedLosingOptions: [],
            selectedShareOptions: [],
        },
    });

    // CheckBox를 useForm 상태로 관리
    const selectedLosingOptions = watch("selectedLosingOptions");
    const selectedShareOptions = watch("selectedShareOptions");

    const handleLosingOptionChange = (id: string) => {
        const updatedOptions = selectedLosingOptions.includes(id)
            ? selectedLosingOptions.filter(option => option !== id)
            : [...selectedLosingOptions, id];
        setValue("selectedLosingOptions", updatedOptions, { shouldValidate: true });
    };

    const handleShareOptionChange = (id: string) => {
        const updatedOptions = selectedShareOptions.includes(id)
            ? selectedShareOptions.filter(option => option !== id)
            : [...selectedShareOptions, id];
        setValue("selectedShareOptions", updatedOptions, { shouldValidate: true });
    };

    // 폼 제출 핸들러 타입 정의
    const onSubmit: SubmitHandler<FormData> = (data) => {
        const postPBComInfoParmas = {
            siteInfo_pk: SelectedMenu.site.pk,
            company_name: data.companyName,
            login_rate: parseFloat(data.losingPercentage),
            selected_login_list: JSON.stringify(data.selectedLosingOptions),
            share_rate: parseFloat(data.sharePercentage),
            selected_share_list: JSON.stringify(data.selectedShareOptions),
        };

        postPBIdvOptionMutation.mutate(postPBComInfoParmas);


    };

    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const errorColor = useColorModeValue(lightTheme_colors['errorColor'], darkTheme_colors['errorColor']);


    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent bg={bgColor}>
                <ModalHeader>신규 업체 등록</ModalHeader>
                <ModalCloseButton />
                <ModalBody as="form" onSubmit={handleSubmit(onSubmit)}>
                    <VStack spacing={10} align="stretch">
                        <VStack align="stretch" spacing={2}>
                            <Text fontSize="md" fontWeight="bold">파워볼 업체 이름</Text>
                            <Input
                                placeholder="파워볼 업체명"
                                {...register("companyName", { required: "업체명을 입력하세요" })}
                            />
                            {formState.errors.companyName && <Center color={errorColor}>{formState.errors.companyName?.message}</Center>}
                        </VStack>

                        {/* 파워볼 루징 조건 */}
                        <VStack align="stretch" spacing={4}>
                            <Text fontSize="md" fontWeight="bold">파워볼 루징 조건</Text>
                            <Input
                                placeholder="파워볼 루징 %"
                                type="number"
                                step="0.1"
                                min="0"
                                max="100"
                                {...register("losingPercentage", {
                                    required: "루징 퍼센트를 입력하세요",
                                    validate: (value) =>
                                        parseFloat(value) >= 0 || "0보다 큰 값을 입력하세요",
                                })}
                            />
                            {formState.errors.losingPercentage && <Center color={errorColor}>{formState.errors.losingPercentage?.message}</Center>}

                            <Text>파워볼 루징 종류 선택</Text>
                            <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                                {loginOptions.map((option) => (
                                    <GridItem key={option.id}>
                                        <Checkbox
                                            isChecked={selectedLosingOptions.includes(option.id)}
                                            onChange={() => handleLosingOptionChange(option.id)}
                                        >
                                            {option.label}
                                        </Checkbox>
                                    </GridItem>
                                ))}
                            </Grid>

                        </VStack>

                        {/* 파워볼 쉐어 조건 */}
                        <VStack align="stretch" spacing={4}>
                            <Text fontSize="md" fontWeight="bold">파워볼 쉐어 조건</Text>
                            <Input
                                placeholder="파워볼 쉐어 %"
                                type="number"
                                step="0.1"
                                min="0"
                                max="100"
                                {...register("sharePercentage", {
                                    required: "쉐어 퍼센트를 입력하세요",
                                    validate: (value) =>
                                        parseFloat(value) >= 0 || "0보다 큰 값을 입력하세요",
                                })}
                            />
                            {formState.errors.sharePercentage && <Center color={errorColor}>{formState.errors.sharePercentage?.message}</Center>}

                            <Text>파워볼 쉐어 종류 선택</Text>
                            <Grid templateColumns="repeat(3, 1fr)" gap={2}>
                                {shreOptions.map((option) => (
                                    <GridItem key={option.id}>
                                        <Checkbox
                                            isChecked={selectedShareOptions.includes(option.id)}
                                            onChange={() => handleShareOptionChange(option.id)}
                                        >
                                            {option.label}
                                        </Checkbox>
                                    </GridItem>
                                ))}
                            </Grid>

                        </VStack>
                    </VStack>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} type="submit">
                            신규 업체 등록
                        </Button>
                        <Button variant="ghost" onClick={onClose}>취소</Button>
                    </ModalFooter>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
}
