import { useMutation } from '@tanstack/react-query';
import { getSiteData } from '../../../../api/sitedata';
import { getBankType, IBankType } from '../../../../api/banktype';
import { getBankInfo, IBankInfo } from '../../../../api/bankinfo';
import { convertToNumber, getTotalByDate, transformNumbersToStrings } from '../../../../lib/dashboardAndDalilySettlementUtil';



interface DataItem {
    Date: string;
    합계: string;
    [key: string]: string; // 다른 키-값 쌍도 허용
}

const getCasinoSlotDataByDate = (items: DataItem[], selectedDate: string): any => {
    /**
    items : [
        {        "Date": "09/01",        "카지노": "25,630,268",        "슬롯": "3,180,547",        "합계": "28,810,815"    },
        {        "Date": "09/02",        "카지노": "-3,986,662",        "슬롯": "470,344",        "합계": "-3,516,318"    }
    ]
    selectedDate : "2024-09-02"

    날짜가 있으면 그 날짜의 데이터를
    없으면 
       {
            "Date": "09/01",            "카지노": "0",            "슬롯": "0",            "합계": "0"
        },
    을 반환한다.
    */
    // "2024-07-17" 형식의 날짜를 "07/17" 형식으로 변환
    const dateWithoutYear = selectedDate.slice(5).replace('-', '/');

    // 해당 날짜의 데이터를 찾음
    const item = items.find(i => i.Date === dateWithoutYear);

    if (item === undefined) {
        return {
            "Date": dateWithoutYear, "카지노": "0", "슬롯": "0", "합계": "0"
        };
    }
    return item;
}

const getDepasitAndWithdrawDataByDate = (items: DataItem[], selectedDate: string): any => {
    /**
    items : [
        {
            "Date": "09/01",            "충전": "129,490,000",            "환전": "172,720,000",            "충환_손익": "-43,230,000"
        },
        {
            "Date": "09/02",            "충전": "141,610,000",            "환전": "120,680,000",            "충환_손익": "20,930,000"
        }
    ]
    selectedDate : "2024-09-02"

    날짜가 있으면 그 날짜의 데이터를
    없으면 
       {
            "Date": "09/01",            "충전": "0",            "환전": "0",            "충환_손익": "0"
        },
    을 반환한다.
    */
    // "2024-07-17" 형식의 날짜를 "07/17" 형식으로 변환
    const dateWithoutYear = selectedDate.slice(5).replace('-', '/');

    // 해당 날짜의 데이터를 찾음
    const item = items.find(i => i.Date === dateWithoutYear);

    if (item === undefined) {
        return {
            "Date": dateWithoutYear, "충전": "0", "환전": "0", "충환_손익": "0"
        };
    }
    return item;
}

const getbankInfoData = (banklist: IBankType[], bankData: IBankInfo[]): any => {
    const result = banklist.filter(bank => bank.isactivate).map(bank => {

        const data = bankData.find(bd => bd.banktype.name === bank.name);
        return {
            name: bank.name,
            dailyAmount: data ? data.balance.toLocaleString() : '0'
        };
    });
    return result;
};

// 파워볼 데이터 Mutation
export function useGetPBDataMutation(pbdataRef: React.MutableRefObject<any>, SelectedMenu: any) {
    return useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                pbdataRef.current = [{ name: '파워볼', dailyProfit: '0', totalProfit: '0' }];
                return;
            }
            let data_list = data.map((sitedata: any) => JSON.parse(sitedata.data));
            data_list = transformNumbersToStrings(data_list);
            pbdataRef.current = [{
                name: '파워볼',
                dailyProfit: getTotalByDate(data_list, SelectedMenu.Date),
                totalProfit: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.합계), 0).toLocaleString()
            }];
        },
        onError: (error) => {
            console.error("파워볼 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });
}

// 카지노 및 슬롯 데이터 Mutation
export function useGetCasinoSlotDataMutation(casinoslotDataRef: React.MutableRefObject<any>, SelectedMenu: any) {
    return useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                casinoslotDataRef.current = [
                    { name: '카지노', dailyProfit: '0', totalProfit: '0' },
                    { name: '슬롯', dailyProfit: '0', totalProfit: '0' }
                ];
                return;
            }
            let data_list = data.map((sitedata: any) => JSON.parse(sitedata.data));
            data_list = transformNumbersToStrings(data_list);

            casinoslotDataRef.current = [
                {
                    name: '카지노',
                    dailyProfit: getCasinoSlotDataByDate(data_list, SelectedMenu.Date).카지노,
                    totalProfit: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.카지노), 0).toLocaleString()
                },
                {
                    name: '슬롯',
                    dailyProfit: getCasinoSlotDataByDate(data_list, SelectedMenu.Date).슬롯,
                    totalProfit: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.슬롯), 0).toLocaleString()
                }
            ];
        },
        onError: (error) => {
            console.error("카지노/슬롯 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });
}

// 홀덤 데이터 Mutation
export function useGetHoldemDataMutation(holdemDataRef: React.MutableRefObject<any>, SelectedMenu: any) {
    return useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                holdemDataRef.current = [{ name: '홀덤', dailyProfit: '0', totalProfit: '0' }];
                return;
            }
            let data_list = data.map((sitedata: any) => JSON.parse(sitedata.data));
            data_list = transformNumbersToStrings(data_list);
            holdemDataRef.current = [{
                name: '홀덤',
                dailyProfit: getTotalByDate(data_list, SelectedMenu.Date),
                totalProfit: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.합계), 0).toLocaleString()
            }];
        },
        onError: (error) => {
            console.error("홀덤 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });
}

// 스포츠 데이터 Mutation
export function useGetSportDataMutation(sportDataRef: React.MutableRefObject<any>, SelectedMenu: any) {
    return useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                sportDataRef.current = [{ name: '스포츠', dailyProfit: '0', totalProfit: '0' }];
                return;
            }
            let data_list = [] as any;
            data.forEach((sitedata: any) => {
                const json_data = JSON.parse(sitedata.data);
                data_list.push(json_data);
            })
            data_list = transformNumbersToStrings(data_list);

            const spdata = [{
                name: '스포츠',
                dailyProfit: getTotalByDate(data_list, SelectedMenu.Date),
                totalProfit: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.합계), 0).toLocaleString()
            }];
            sportDataRef.current = spdata;
        },
        onError: (error) => {
            console.error("스포츠 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });
}

// 기타 데이터 Mutation
export function useGetGitaDataMutation(gitaDataRef: React.MutableRefObject<any>, SelectedMenu: any) {
    return useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                gitaDataRef.current = [{ name: '기타', dailyProfit: '0', totalProfit: '0' }];
                return;
            }
            let data_list = [] as any;
            data.forEach((sitedata: any) => {
                const json_data = JSON.parse(sitedata.data);
                data_list.push(json_data);
            })
            data_list = transformNumbersToStrings(data_list);

            const gitadata = [{
                name: '기타',
                dailyProfit: getTotalByDate(data_list, SelectedMenu.Date),
                totalProfit: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.합계), 0).toLocaleString()
            }];
            gitaDataRef.current = gitadata;
        },
        onError: (error) => {
            console.error("기타 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });
}

// 충환 데이터 Mutation
export function useGetChargeExchangeDataMutation(charge_exchangeDataRef: React.MutableRefObject<any>, SelectedMenu: any) {
    return useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                charge_exchangeDataRef.current = [{ name: '충전 금액', dailyAmount: '0', totalAmount: '0' },
                { name: '환전 금액', dailyAmount: '0', totalAmount: '0' }];
                return;
            }
            let data_list = [] as any;
            data.forEach((sitedata: any) => {
                const json_data = JSON.parse(sitedata.data);
                data_list.push(json_data);
            })
            data_list = transformNumbersToStrings(data_list);

            const charge_exchangeData = [
                {
                    name: '충전 금액',
                    dailyAmount: getDepasitAndWithdrawDataByDate(data_list, SelectedMenu.Date).충전,
                    totalAmount: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.충전), 0).toLocaleString()
                },
                {
                    name: '환전 금액',
                    dailyAmount: getDepasitAndWithdrawDataByDate(data_list, SelectedMenu.Date).환전,
                    totalAmount: data_list.reduce((acc: number, cur: any) => acc + convertToNumber(cur.환전), 0).toLocaleString()
                }
            ];

            charge_exchangeDataRef.current = charge_exchangeData;
        },
        onError: (error) => {
            console.error("충환 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });
}

// 유저 머니 데이터 Mutation
export function useGeusermoneyDataMutation(usermoneyDataRef: React.MutableRefObject<any>, SelectedMenu: any) {
    return useMutation(getSiteData, {
        onSuccess: (data) => {
            if (data.length === 0) {
                usermoneyDataRef.current = [{ name: '보유 머니', dailyAmount: '0' },
                { name: '보유 포인트', dailyAmount: '0' }];
                return;
            }
            let data_list = [] as any;
            data.forEach((sitedata: any) => {
                const json_data = JSON.parse(sitedata.data);
                data_list.push(json_data);
            })
            data_list = transformNumbersToStrings(data_list);

            const usermoneyData = [
                {
                    name: '보유 머니',
                    dailyAmount: convertToNumber(data_list[data_list.length - 1].유저머니).toLocaleString()
                },
                {
                    name: '보유 포인트',
                    dailyAmount: convertToNumber(data_list[data_list.length - 1].유저포인트).toLocaleString()
                }
            ];
            usermoneyDataRef.current = usermoneyData;
        },
        onError: (error) => {
            console.error("사이트 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });
}

// 뱅크 타입 데이터 Mutation
export function useGetBankTypeDataMutation(bankTypeRef: React.MutableRefObject<IBankType[]>) {
    return useMutation(getBankType, {
        onSuccess: (data) => {
            bankTypeRef.current = data;
        },
        onError: (error) => {
            console.error("뱅크타입을 가져오는 중 오류가 발생했습니다:", error);
        },
    });
}

// 뱅크 정보 데이터 Mutation
export function useGetBankInfoDataMutation(bankTypeRef: React.MutableRefObject<IBankType[]>, bankInfoRef: React.MutableRefObject<IBankInfo[]>, bankInfoDataRef: React.MutableRefObject<any>) {
    return useMutation(getBankInfo, {
        onSuccess: (data: IBankInfo[]) => {
            bankInfoRef.current = data;

            const bankInfoData = getbankInfoData(bankTypeRef.current, bankInfoRef.current);
            bankInfoDataRef.current = bankInfoData;
        },
        onError: (error) => {
            console.error("뱅크정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });
}


// 충환수익 데이터 Mutation (atom 데이터에서 가져온다. ) baseTable에서 사용



interface IUserChargeExchangeData {
    최상위: string;
    유저: string;
    충전금액: number;
    환전금액: number;
    충환손익: number;
}

function transformUserChargeExchangeData(data: any): IUserChargeExchangeData[] {
    // 데이터를 변환하고 필요한 항목만 추출
    const UserChargeExchangeData: IUserChargeExchangeData[] = data.map((item: any) => ({
        최상위: item.status_id_list.length > 0 ? item.status_id_list[0] : item.user_id,
        유저: `${item.user_id} (${item.user_nickname})`,
        충전금액: item.charge,
        환전금액: item.exchange,
        충환손익: item.charge_exchange
    }));

    // 충환손익을 기준으로 내림차순 정렬
    UserChargeExchangeData.sort((a, b) => b.충환손익 - a.충환손익);

    return UserChargeExchangeData;
}

interface IUserCPSData {
    최상위: string;
    유저: string;
    파워볼: number;
    카지노: number;
    슬롯: number;
    배팅합: number;
}

function transformUserCPSData(data: any): IUserCPSData[] {
    // 데이터를 변환하고 필요한 항목만 추출
    const UserCPSData: IUserCPSData[] = data.map((item: any) => {
        const powerballBetSum = Object.keys(item)
            .filter((key) => key.startsWith("pb_bat_"))
            .reduce((sum, key) => sum + item[key], 0);

        const casinoBet = item["casislot_bet_카지노_"] || 0;
        const slotBet = item["casislot_bet_슬롯_"] || 0;
        const totalBet = powerballBetSum + casinoBet + slotBet;

        return {
            최상위: item.status_id_list.length > 0 ? item.status_id_list[0] : item.user_id,
            유저: `${item.user_id} (${item.user_nickname})`,
            파워볼: powerballBetSum,
            카지노: casinoBet,
            슬롯: slotBet,
            배팅합: totalBet,
        };
    }).sort((a: IUserCPSData, b: IUserCPSData) => b.배팅합 - a.배팅합);

    return UserCPSData;
}


export function useGetTop5DataMutation(
    setTop5Data: React.Dispatch<React.SetStateAction<any>>,
    setTop5RverseData: React.Dispatch<React.SetStateAction<any>>,
    setTopCPSTopData: React.Dispatch<React.SetStateAction<any>>

) {
    return useMutation(getSiteData, {
        onSuccess: (data) => {
            const top5headers = [
                "최상위",
                "유저",
                "충전금액",
                "환전금액",
                "충환손익"
            ]
            const CPSTopHeaders = [
                "최상위",
                "유저",
                "파워볼",
                "카지노",
                "슬롯",
                "배팅합"
            ]

            // 데이터가 없을수도 있슴
            if (data.length === 0) {
                const emtyData = {
                    headers: [],
                    rows: []
                };
                setTop5Data(emtyData);
                setTop5RverseData(emtyData);
                setTopCPSTopData(emtyData);
                return;
            }

            const target_atom_data = JSON.parse(data[0].data);

            const UserChargeExchangeData = transformUserChargeExchangeData(target_atom_data);

            const UserCPSData = transformUserCPSData(target_atom_data);

            setTop5Data({
                headers: top5headers,
                rows: UserChargeExchangeData.slice(0, 5)

            });
            setTop5RverseData({
                headers: top5headers,
                rows: UserChargeExchangeData.reverse().slice(0, 5)
            });
            setTopCPSTopData({
                headers: CPSTopHeaders,
                rows: UserCPSData.slice(0, 10)
            });

        },
        onError: (error) => {
            console.error("충환수익 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });
}
