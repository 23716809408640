import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { SelectedMenuAtom } from '../../../../atoms/topSelect';
import { IBankType } from '../../../../api/banktype';
import { IBankInfo } from '../../../../api/bankinfo';
import {
    calculateTotal,
    getMonthFirstAndLastDay,
} from '../../../../lib/dashboardAndDalilySettlementUtil';
import { useGetBankInfoDataMutation, useGetBankTypeDataMutation, useGetCasinoSlotDataMutation, useGetChargeExchangeDataMutation, useGetGitaDataMutation, useGetHoldemDataMutation, useGetPBDataMutation, useGetSportDataMutation, useGeusermoneyDataMutation } from './costiomMutatons';


const blankdata = {
    categories: [
        { name: '파워볼', dailyProfit: '0', totalProfit: '0' },
        { name: '카지노', dailyProfit: '0', totalProfit: '0' },
        { name: '슬롯', dailyProfit: '0', totalProfit: '0' },
        { name: '홀덤', dailyProfit: '0', totalProfit: '0' },
        { name: 'TOTAL', dailyProfit: '0', totalProfit: '0', isTotal: true },
    ],
    transactions: [
        { name: '충전 금액', dailyAmount: '0', totalAmount: '0' },
        { name: '환전 금액', dailyAmount: '0', totalAmount: '0' },
        { name: '충환 손익', dailyAmount: '0', totalAmount: '0', isTotal: true },
    ],
    userMoney: [
        { name: '보유 머니', dailyAmount: '0' },
        { name: '보유 포인트', dailyAmount: '0' },
        { name: 'TOTAL', dailyAmount: '0', isTotal: true },
    ],
    banks: [
        { name: '뱅크 1', dailyAmount: '0' },
        { name: 'TOTAL', dailyAmount: '0', isTotal: true },
    ],
};

export default function useSiteDailySettlementData() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);
    const [contentData, setContentData] = useState(blankdata);

    // 데이터 저장을 위한 useRef 생성
    const pbdataRef = useRef([{ name: '파워볼', dailyProfit: '0', totalProfit: '0' }]);
    const casinoslotDataRef = useRef([
        { name: '카지노', dailyProfit: '0', totalProfit: '0' },
        { name: '슬롯', dailyProfit: '0', totalProfit: '0' },
    ]);
    const holdemDataRef = useRef([{ name: '홀덤', dailyProfit: '0', totalProfit: '0' }]);
    const sprotDataRef = useRef([{ name: '스포츠', dailyProfit: '0', totalProfit: '0' }]);
    const gitaDataRef = useRef([{ name: '기타', dailyProfit: '0', totalProfit: '0' }]);

    const charge_exchangeDataRef = useRef([
        { name: '충전 금액', dailyAmount: '0', totalAmount: '0' },
        { name: '환전 금액', dailyAmount: '0', totalAmount: '0' },
    ]);

    const usermoneyDataRef = useRef([
        { name: '보유 머니', dailyAmount: '0' },
        { name: '보유 포인트', dailyAmount: '0' },
    ]);

    const bankTypeRef = useRef<IBankType[]>([]);
    const bankInfoRef = useRef<IBankInfo[]>([]);
    const bankInfoDataRef = useRef([{ name: '뱅크 1', dailyAmount: '0' }]);

    // 뮤테이션 훅 초기화
    const powerballDataMutation = useGetPBDataMutation(pbdataRef, SelectedMenu);
    const casinoslotDataMutation = useGetCasinoSlotDataMutation(casinoslotDataRef, SelectedMenu);
    const holdemDataMutation = useGetHoldemDataMutation(holdemDataRef, SelectedMenu);
    const sportDataMutation = useGetSportDataMutation(sprotDataRef, SelectedMenu);
    const gitaDataMutation = useGetGitaDataMutation(gitaDataRef, SelectedMenu);
    const charge_exchangeDataMutation = useGetChargeExchangeDataMutation(charge_exchangeDataRef, SelectedMenu);
    const usermoneyDataMutation = useGeusermoneyDataMutation(usermoneyDataRef, SelectedMenu);
    const bankTypeDataMutation = useGetBankTypeDataMutation(bankTypeRef);
    const bankInfoDataMutation = useGetBankInfoDataMutation(bankTypeRef, bankInfoRef, bankInfoDataRef);

    useEffect(() => {
        const dateRange = getMonthFirstAndLastDay(SelectedMenu.Date);
        if (SelectedMenu.site.pk !== -1 && SelectedMenu.Date !== '') {
            const pbParams = {
                siteinfo: SelectedMenu.site.pk,
                datatype: 'powerball',
                start_date: dateRange[0],
                end_date: dateRange[1],
            };

            const casinoParams = {
                siteinfo: SelectedMenu.site.pk,
                datatype: 'casinoslot',
                start_date: dateRange[0],
                end_date: dateRange[1],
            };

            const holdemParams = {
                siteinfo: SelectedMenu.site.pk,
                datatype: 'holdem',
                start_date: dateRange[0],
                end_date: dateRange[1],
            };

            const sprotParams = {
                siteinfo: SelectedMenu.site.pk,
                datatype: 'sport',
                start_date: dateRange[0],
                end_date: dateRange[1],
            };

            const gitaParams = {
                siteinfo: SelectedMenu.site.pk,
                datatype: 'gita',
                start_date: dateRange[0],
                end_date: dateRange[1],
            };

            const charge_exchangeParams = {
                siteinfo: SelectedMenu.site.pk,
                datatype: 'charge_exchange',
                start_date: dateRange[0],
                end_date: dateRange[1],
            };

            const usermoneyParams = {
                siteinfo: SelectedMenu.site.pk,
                datatype: 'usermoney',
                start_date: dateRange[1],
                end_date: dateRange[1],
            };

            const bankParms = {
                siteInfo_pk: SelectedMenu.site.pk,
                start_date: dateRange[1],
                end_date: dateRange[1],
            };

            Promise.all([
                bankTypeDataMutation.mutateAsync(
                    { siteInfo_pk: SelectedMenu.site.pk, target_month: dateRange[1].slice(0, 7) },
                ),
                powerballDataMutation.mutateAsync(pbParams),
                casinoslotDataMutation.mutateAsync(casinoParams),
                holdemDataMutation.mutateAsync(holdemParams),
                sportDataMutation.mutateAsync(sprotParams),
                gitaDataMutation.mutateAsync(gitaParams),
                charge_exchangeDataMutation.mutateAsync(charge_exchangeParams),
                usermoneyDataMutation.mutateAsync(usermoneyParams),
                bankInfoDataMutation.mutateAsync(bankParms),
            ]).then(() => {
                const finaldata = {
                    categories: [
                        ...pbdataRef.current,
                        ...casinoslotDataRef.current,
                        ...holdemDataRef.current,
                        ...sprotDataRef.current,
                        ...gitaDataRef.current,
                        {
                            name: 'TOTAL',
                            dailyProfit: calculateTotal(
                                [
                                    ...pbdataRef.current,
                                    ...casinoslotDataRef.current,
                                    ...holdemDataRef.current,
                                    ...sprotDataRef.current,
                                    ...gitaDataRef.current,
                                ],
                                'dailyProfit',
                            ),
                            totalProfit: calculateTotal(
                                [
                                    ...pbdataRef.current,
                                    ...casinoslotDataRef.current,
                                    ...holdemDataRef.current,
                                    ...sprotDataRef.current,
                                    ...gitaDataRef.current,
                                ],
                                'totalProfit',
                            ),
                            isTotal: true,
                        },
                    ],
                    transactions: [
                        ...charge_exchangeDataRef.current,
                        {
                            name: '충환 손익',
                            dailyAmount: (
                                parseInt(charge_exchangeDataRef.current[0].dailyAmount.replace(/,/g, ''), 10) -
                                parseInt(charge_exchangeDataRef.current[1].dailyAmount.replace(/,/g, ''), 10)
                            ).toLocaleString(),
                            totalAmount: (
                                parseInt(charge_exchangeDataRef.current[0].totalAmount.replace(/,/g, ''), 10) -
                                parseInt(charge_exchangeDataRef.current[1].totalAmount.replace(/,/g, ''), 10)
                            ).toLocaleString(),
                            isTotal: true,
                        },
                    ],
                    userMoney: [
                        ...usermoneyDataRef.current,
                        {
                            name: 'TOTAL',
                            dailyAmount: calculateTotal(usermoneyDataRef.current, 'dailyAmount'),
                            isTotal: true,
                        },
                    ],
                    banks: [
                        ...bankInfoDataRef.current,
                        {
                            name: 'TOTAL',
                            dailyAmount: calculateTotal(bankInfoDataRef.current, 'dailyAmount'),
                            isTotal: true,
                        },
                    ],
                };
                setContentData(finaldata);
            });
        }
    }, [SelectedMenu]);

    return contentData;
}



/**
 * 
 * const data = {
    siteName: '멜론',
    categories: [
        { name: '파워볼', dailyProfit: '8,355,671', totalProfit: '21,105,835' },
        { name: '카지노', dailyProfit: '18,365,542', totalProfit: '82,734,061' },
        { name: '슬롯', dailyProfit: '1,874,047', totalProfit: '77,927,826' },
        { name: '홀덤', dailyProfit: '1,000,000', totalProfit: '3,500,000' },
        { name: 'TOTAL', dailyProfit: '28,595,260', totalProfit: '181,767,722', isTotal: true }
    ],
    transactions: [
        { name: '충전 금액', dailyAmount: '18,365,542', totalAmount: '82,734,061' },
        { name: '환전 금액', dailyAmount: '25,874,047', totalAmount: '77,927,826' },
        { name: '충환 손익', dailyAmount: '-7,508,505', totalAmount: '4,806,235', isTotal: true }
    ],
    userMoney: [
        { name: '보유 머니', dailyAmount: '58,546,462' },
        { name: '보유 포인트', dailyAmount: '18,096,390' },
        { name: 'TOTAL', dailyAmount: '40,450,072', isTotal: true }
    ],
    banks: [
        { name: '뱅크 1', dailyAmount: '20,741,621' },
        { name: '뱅크 2', dailyAmount: '29,543,215' },
        { name: '뱅크 3', dailyAmount: '17,070,534' },
        { name: '뱅크 4', dailyAmount: '17,070,534' },
        { name: 'TOTAL', dailyAmount: '67,355,370', isTotal: true }
    ]
};
 * 
 * 
 [
    {
        "Date": "07/01",
        "EOS_3분": "-35,082",
        "EOS_5분": "11,755",
        "코인_3분": "-290,155",
        "코인_5분": "1,692,161",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "27,411",
        "코사_3분": "0",
        "코사_5분": "1,767",
        "합계": "1,407,857"
    },
    {
        "Date": "07/02",
        "EOS_3분": "216",
        "EOS_5분": "-24,608",
        "코인_3분": "-356,663",
        "코인_5분": "461,517",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "959,181",
        "코사_3분": "0",
        "코사_5분": "1,880",
        "합계": "1,041,523"
    },
    {
        "Date": "07/03",
        "EOS_3분": "929",
        "EOS_5분": "-18,612",
        "코인_3분": "-665,664",
        "코인_5분": "588,950",
        "더블유_3분": "0",
        "동행_파워볼": "0",
        "PBG_파워볼": "701,449",
        "코사_3분": "0",
        "코사_5분": "1,016",
        "합계": "608,068"
    },   
]



[
    {
        "pk": 1,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "name": "멜론뱅크1",
        "bankName": "뱅크닉네임",
        "siteInfo": {
            "pk": 1,
            "sitetype": {
                "pk": 1,
                "name": "melon"
            },
            "site_name": "멜론"
        },
        "isactivate": true,
        "memo": "멜론 테스트 뱅크1"
    },
    {
        "pk": 2,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "name": "멜뱅2",
        "bankName": "222",
        "siteInfo": {
            "pk": 1,
            "sitetype": {
                "pk": 1,
                "name": "melon"
            },
            "site_name": "멜론"
        },
        "isactivate": true,
        "memo": ""
    },
    {
        "pk": 3,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "name": "멜뱅3",
        "bankName": "333",
        "siteInfo": {
            "pk": 1,
            "sitetype": {
                "pk": 1,
                "name": "melon"
            },
            "site_name": "멜론"
        },
        "isactivate": true,
        "memo": ""
    },
    {
        "pk": 4,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "name": "맬뱅4",
        "bankName": "444",
        "siteInfo": {
            "pk": 1,
            "sitetype": {
                "pk": 1,
                "name": "melon"
            },
            "site_name": "멜론"
        },
        "isactivate": false,
        "memo": ""
    }
]


[
    {
        "pk": 17,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "siteInfo": 1,
        "banktype": {
            "pk": 1,
            "name": "멜론뱅크1",
            "bankName": "뱅크닉네임",
            "siteInfo": {
                "pk": 1,
                "sitetype": {
                    "pk": 1,
                    "name": "melon"
                },
                "site_name": "멜론"
            },
            "isactivate": true,
            "memo": "멜론 테스트 뱅크1"
        },
        "target_date": "2024-07-01",
        "balance": 323
    },
    {
        "pk": 19,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "siteInfo": 1,
        "banktype": {
            "pk": 2,
            "name": "멜뱅2",
            "bankName": "222",
            "siteInfo": {
                "pk": 1,
                "sitetype": {
                    "pk": 1,
                    "name": "melon"
                },
                "site_name": "멜론"
            },
            "isactivate": true,
            "memo": ""
        },
        "target_date": "2024-07-01",
        "balance": 3000
    }
]



[{ name: '멜론뱅크1', dailyAmount: '323' },
 { name: '멜뱅2', dailyAmount: '3000' },
  { name: '멜뱅3', dailyAmount: '0' },
   { name: '맬뱅4', dailyAmount: '0' },]

 */