import { useEffect, useRef } from 'react';
import { useMutation } from "@tanstack/react-query";

import { Avatar, Center, Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, useColorMode, useColorModeValue, useDisclosure, useToast } from "@chakra-ui/react";
import { ImDownload2 } from "react-icons/im";
import { FaMoon, FaSun } from 'react-icons/fa';

import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil";

import { Link } from "react-router-dom";
import LoginModal from "./LoginModal";
import { IsLoginAtom, IsManagerAtom, IsUserLoginVerifiedAtom, userInfoAtom } from "../atoms/user";
import { IUserInfo, getUserInfo, userLogout } from '../api/user';
import { IseverDetailReturn } from '../api/base';
import { darkTheme_colors, lightTheme_colors } from '../theme';
import SignupModal from './SignupModal';
import SetPasswordModel from './SetPasswordModel';
import { getSiteinfo } from '../api/siteInfo';

import { getPBComInfo } from '../api/pbcominfo';
import { SelectedMenuAtom, SelectedMenuDefault, ISelectMenu } from '../atoms/topSelect';
import { getCSComInfo } from '../api/cscominfo';


/*
- 로그인.
    * 로그인상태일때 유저정보 보여주기. - 완료
    * 로그인상태일때 로그인버튼을 로그아웃버튼으로 변경. - 완료
    * 로그인상태일때 로그아웃버튼을 누르면 로그아웃. - 완료  
    * 
    * isLogin 이 변경되면 유저정보를 가져온다. - 완료
    * 유저정보를 가져오면 유저정보를 저장한다. - 완료
    * 유저가 manager이면 isManager를 true로 변경한다. - 완료 
    
- 로그아웃.
    * 로그아웃버튼을 누르면 로그아웃. - 완료  
    * 로그아웃버튼을 누르면 로그인버튼으로 변경. - 완료
    * 로그아웃버튼을 누르면 유저정보를 지운다. - 완료
    
- 테마 변경.
    * 테마 변경버튼을 누르면 테마가 변경된다. - 완료
    * 테마 변경버튼을 누르면 테마 변경버튼이 테마 변경버튼으로 변경된다. - 완료
    * 테마에따른 색 코드를 추가한다. -완료 
    * 
    * 
- 로그인 완료되면
    사이트 리스트를 가져와서 아톰에 저장한다.    (사이트 선택에 이용됨.)
*/

export default function Header() {
    // 로그인 유저 정보관련 코드(로그인)
    const [isLogin, setIslogin] = useRecoilState(IsLoginAtom)
    const [userInfo, setUserInfo] = useRecoilState(userInfoAtom)
    const resetUserInfo = useResetRecoilState(userInfoAtom)
    const setIsManager = useSetRecoilState(IsManagerAtom)
    const setIsUserLoginVerified = useSetRecoilState(IsUserLoginVerifiedAtom)

    useEffect(() => {
        if (localStorage.getItem("token") !== null) {
            userInfoMutation.mutate()
        } else {
            setIslogin(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isLogin) {
            userInfoMutation.mutate()
            setIslogin(true)
        } else {
            setIsManager(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);

    const userInfoMutation = useMutation(getUserInfo, {
        onSuccess: (data: IUserInfo) => {
            if (data['authority'] === 'manager' || data['authority'] === 'distributor') {
                setIsManager(true)
            }
            setUserInfo(data)
            setIslogin(true)
            setIsUserLoginVerified(data['login_verified'])
        },
        onError: (error: IseverDetailReturn) => {
            if (error.response.data.detail === "토큰이 유효하지 않습니다.") {
                localStorage.removeItem("token")
            }
            setIslogin(false)
        }
    });

    //로그아웃
    const toast = useToast();

    const onLogOut = async () => {
        userlogoutmutation.mutate();
    };
    const userlogoutmutation = useMutation(userLogout, {
        onSuccess: (data) => {
            toast({
                position: 'top',
                title: "로그아웃 되었습니다. ",
                description: `${userInfo ? userInfo['username'] : " "}님 안녕히 가세요`,
                status: "info",
                isClosable: true,
            });

            localStorage.removeItem("token")
            setIslogin(false)
            resetUserInfo()
        },
        onError: (data: IseverDetailReturn) => {
            toast({
                position: 'top',
                title: "로그아웃에 실패했습니다. ",
                description: "관리자에게 문의해 주세요",
                status: "error",
                isClosable: true,
            });
        }
    });


    // 로그인성공일때 또는 새로고침시 사이트 정보를 가져와서 리스트를 만든다.   
    // 한번만 변화되도록!!!!  이 목적때문에 하단과 같은 코드가 나온다.  
    // setSelectedMenu 이 각 싸이틀당 한번만!!! 
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)


    const useImsiRef = useRef({ ...SelectedMenuDefault })

    const siteInfoMutation = useMutation(getSiteinfo, {
        onSuccess: (data) => {
            // sitetypt pk, name 리스트를 만든다.  
            let siteList = data.map((site: any) => {
                return { pk: site.pk, name: site.site_name } as ISelectMenu
            })
            useImsiRef.current = {
                ...useImsiRef.current, // 기존 내용 복사
                sitelist: siteList,
                site: siteList[0]
            };

        },
        onError: (error) => {
            console.error("사이트 정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });

    const getPBCominfoMutation = useMutation(getPBComInfo, {
        onSuccess: (data) => {
            //  pk, name 리스트를 만든다.  

            const sortedList = data.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

            let PBComList = sortedList.map((site: any) => {
                return { pk: site.pk, name: site.company_name } as ISelectMenu
            })

            if (PBComList.length === 0) {
                PBComList = [{ pk: -1, name: "등록된 업체 없슴" }]
            }

            useImsiRef.current = {
                ...useImsiRef.current, // 기존 내용 복사
                PBComList: PBComList,
                PBCom: PBComList[0]
            };
        }
    });

    const getCSCominfoMutation = useMutation(getCSComInfo, {
        onSuccess: (data) => {
            //  pk, name 리스트를 만든다. 
            const sortedList = data.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

            let CSComList = sortedList.map((site: any) => {
                return { pk: site.pk, name: site.company_name } as ISelectMenu
            })

            if (CSComList.length === 0) {
                CSComList = [{ pk: -1, name: "등록된 업체 없슴" }]
            }

            useImsiRef.current = {
                ...useImsiRef.current, // 기존 내용 복사
                CSComList: CSComList,
                CSCom: CSComList[0]
            };
        }
    });

    useEffect(() => {
        const executeMutations = async () => {
            if (isLogin) {
                try {
                    // SelectedMenu를 깊은 복사하여 useImsiRef.current에 저장
                    useImsiRef.current = JSON.parse(JSON.stringify(SelectedMenu));

                    // 첫 번째 Mutation 실행
                    await siteInfoMutation.mutateAsync();

                    // 두 번째 Mutation 실행                    
                    await getPBCominfoMutation.mutateAsync({ siteInfo_pk: useImsiRef.current.site.pk });

                    // 세 번째 작업 실행
                    await getCSCominfoMutation.mutateAsync({ siteInfo_pk: useImsiRef.current.site.pk });

                    // // 세 번째 작업 실행
                    setSelectedMenu(useImsiRef.current);

                } catch (error) {
                    console.error("오류 발생:", error);
                }

            } else {
                setSelectedMenu(SelectedMenuDefault)
            }
        }

        executeMutations();
        useImsiRef.current = JSON.parse(JSON.stringify(SelectedMenu))  // 초기화   
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);

    // 재설정 트리거 ( 사이트 설정은 뺴고 파워볼과 카지노 업체 설정만 변경)
    useEffect(() => {
        const executeMutations = async () => {
            if (isLogin) {
                try {
                    // SelectedMenu를 깊은 복사하여 useImsiRef.current에 저장
                    useImsiRef.current = JSON.parse(JSON.stringify(SelectedMenu));

                    // 두 번째 Mutation 실행              
                    await getPBCominfoMutation.mutateAsync({ siteInfo_pk: useImsiRef.current.site.pk });

                    // 세 번째 작업 실행
                    await getCSCominfoMutation.mutateAsync({ siteInfo_pk: useImsiRef.current.site.pk });

                    // // 세 번째 작업 실행
                    setSelectedMenu(useImsiRef.current);

                } catch (error) {
                    console.error("오류 발생:", error);
                }

            } else {
                setSelectedMenu(SelectedMenuDefault)
            }
        }
        executeMutations();

        useImsiRef.current = JSON.parse(JSON.stringify(SelectedMenu))  // 초기화          

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedMenu.reload_tag]);




    // 모달창 호출
    const {
        isOpen: isLoginOpen,
        onClose: onLoginClose,
        onOpen: onLoginOpen,
    } = useDisclosure();
    const {
        isOpen: isSignupOpen,
        onClose: onSignupClose,
        onOpen: onSignupOpen,
    } = useDisclosure();

    const {
        isOpen: isSetPasswordOpen,
        onClose: onSetPasswordClose,
        onOpen: onSetPasswordOpen,
    } = useDisclosure();

    // 테마 관련 코드
    const { toggleColorMode } = useColorMode();
    const Icon = useColorModeValue(FaMoon, FaSun);
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);

    return (
        <Flex height={"100%"} justifyContent={"space-between"} bgColor={bgColor} >
            <Center flex={"1"}>
                <Link to={"/"} >
                    <ImDownload2 size={"1.5rem"} />
                </Link>
            </Center>
            <Center flex={"3"} fontSize={"1.2rem"}>Bet Balance Pro</Center>
            <Flex flex={"1"} justifyContent={"space-around"} alignItems="center" >
                <IconButton
                    onClick={toggleColorMode}
                    variant={"ghost"}
                    aria-label="Toggle dark mode"
                    icon={<Icon />}
                    marginRight={"0.5rem"}
                />
                {isLogin ? (
                    <Menu>
                        <MenuButton>
                            <Avatar name={userInfo?.username} size={"sm"} />
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={onLogOut}>로그아웃</MenuItem>
                            <MenuItem onClick={onSetPasswordOpen}>정보수정</MenuItem>
                        </MenuList>
                        {/* <Button onClick={onLogOut} size={"sm"} variant='outline' marginRight={"3px"} bgColor={btnBgColor}>로그아웃</Button> */}
                    </Menu>
                ) : (
                    <Menu>
                        <MenuButton>
                            <Avatar name={userInfo?.username} size={"sm"} />
                        </MenuButton>
                        <MenuList>
                            <MenuItem onClick={onLoginOpen}>로그인</MenuItem>
                            <MenuItem onClick={onSignupOpen}>회원가입</MenuItem>
                        </MenuList>
                    </Menu>
                )}

            </Flex>
            <LoginModal isOpen={isLoginOpen} onClose={onLoginClose} />
            <SignupModal isOpen={isSignupOpen} onClose={onSignupClose} />
            <SetPasswordModel isOpen={isSetPasswordOpen} onClose={onSetPasswordClose} />

        </Flex>
    );
}

