import React from 'react';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    useColorModeValue,
    Flex,
    Text,
    Center,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Spinner,
} from '@chakra-ui/react';
import { darkTheme_colors, lightTheme_colors } from '../../../../theme';
import { useRecoilValue } from 'recoil';
import { SelectedMenuAtom } from '../../../../atoms/topSelect';
import { useFetchPBLosing3MonData } from '../../PBLosingMonTotal/contents/fetchDada';
import BaseTable from '../Table/BaseTable';

export interface IPBLosingShareData {
    "site_name": string,
    "company_name": string,
    "category": string,
    "start_date": string,
    "end_date": string,
    "rowTypes": string[],
    "colTypes": string[],
    "rate": number,
    "selectedOptions": string[],
    "values": {
        [key: string]: number;
    }
    "up_total": boolean | number;
    "total": number;
}

export const PBLosingShareDefaultData: IPBLosingShareData = {
    "site_name": "",
    "company_name": "",
    "category": "",
    "start_date": "",
    "end_date": "",
    "rowTypes": [],
    "colTypes": [],
    "rate": 0,
    "selectedOptions": [],
    "values": {},
    "up_total": false,
    "total": 0
}


export default function PBBaseTable({ site_name, company_name, start_date, end_date, category, rowTypes, colTypes, rate, selectedOptions, values, up_total, total }: IPBLosingShareData) {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);
    const { PBlosing3MonTableData, loading } = useFetchPBLosing3MonData(SelectedMenu);

    const calculateColumnTotal = (game: string) => {
        return rowTypes.reduce((sum, type) => {
            const key = `${game}_${type}`;
            return sum + (selectedOptions.includes(key) ? values[key] : 0);
        }, 0);
    };

    const totalRowSum = () => {
        return colTypes.reduce((sum, game) => {
            return sum + calculateColumnTotal(game);
        }, 0);
    };

    const shouldDisplayColumn = (game: string) => {
        return rowTypes.some(type => selectedOptions.includes(`${game}_${type}`));
    };

    const displayedColumns = colTypes.filter(shouldDisplayColumn);

    const shouldDisplayRow = (type: string) => {
        return colTypes.some(game => selectedOptions.includes(`${game}_${type}`));
    };

    const displayedRows = rowTypes.filter(shouldDisplayRow);

    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const titleColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);


    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    const formatValueWithColor = (value: number) => {
        const formattedValue = value.toLocaleString();
        const isNegative = value < 0;
        return (
            <Text as="span" color={isNegative ? negativeColor : undefined}>
                {formattedValue}
            </Text>
        );
    };

    return (
        <Box
            width="100%"
            overflowX="auto"
            bg={mainBgColor}
            color={mainFontColor}
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: scrollBarColor,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbColor,
                    borderRadius: '4px',
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbHoverColor,
                },
            }}
        >
            {/* 상단 타이틀 */}
            <Flex bg={inputBgColor} color={mainFontColor} alignItems={"center"} justifyContent={"space-around"} py={2} mb={2} borderRadius={"md"}>
                <Box>
                    <Center>{site_name}</Center>
                </Box>
                <Box>
                    <Center>{company_name} {category}</Center>
                </Box>
                <Box>
                    <Center>{start_date} ~ {end_date}</Center>
                </Box>
            </Flex>

            {/* 테이블 */}
            <Table variant="striped" bg={bgColor} colorScheme="gray" size="sm">
                <Thead bg={titleColor}>
                    <Tr>
                        <Th textAlign="center" padding="0.2rem">구분</Th>
                        {displayedColumns.map((game, index) => (
                            <Th key={index} textAlign="center" padding="0.2rem">
                                {game.replace('_', ' ')}
                            </Th>
                        ))}
                        <Th textAlign="center" padding="0.2rem">종목 합계</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {displayedRows.map((type, rowIndex) => (
                        <Tr key={rowIndex}>
                            <Td textAlign="center" padding="0.2rem">{type}</Td>
                            {displayedColumns.map((game, colIndex) => {
                                const key = `${game}_${type}`;
                                const rawValue = selectedOptions.includes(key) ? values[key] : '제외';
                                const cellValue = typeof rawValue === 'number' ? formatValueWithColor(rawValue) : rawValue;

                                return (
                                    <Td
                                        key={colIndex}
                                        textAlign="right"
                                        padding="0.2rem"
                                    >
                                        <Flex justify="flex-end">
                                            {cellValue}
                                        </Flex>
                                    </Td>
                                );
                            })}
                            <Td textAlign="right" padding="0.2rem">
                                {formatValueWithColor(
                                    Object.keys(values)
                                        .filter(key => key.endsWith(`_${type}`) && selectedOptions.includes(key))
                                        .reduce((sum, key) => sum + values[key], 0)
                                )}
                            </Td>
                        </Tr>
                    ))}
                    <Tr
                        sx={{
                            bg: titleColor,
                            '& > td': {
                                bg: `${titleColor} !important`,
                            }
                        }}
                    >
                        <Td textAlign="center" padding="0.2rem">TOTAL</Td>
                        {displayedColumns.map((game, index) => (
                            <Td key={index} textAlign="right" padding="0.2rem">
                                {formatValueWithColor(calculateColumnTotal(game))}
                            </Td>
                        ))}
                        <Td textAlign="right" padding="0.2rem">{formatValueWithColor(totalRowSum())}</Td>
                    </Tr>
                </Tbody>
            </Table>

            {/* 하단 루징율 및 정산 금액 */}
            {typeof up_total === "number" ? (
                <Flex bg={inputBgColor} color={mainFontColor} alignItems={"center"} justifyContent={"space-around"} py={2} my={2} borderRadius={"md"}>
                    <Box>
                        <Center>Total</Center>
                        <Center>{formatValueWithColor(total)}</Center>
                    </Box>
                    <Box>
                        <Center>{category.slice(0, 2)}율</Center>
                        <Center>{rate}%</Center>
                    </Box>
                    <Box>
                        <Center>상위 수수료</Center>
                        <Center>{formatValueWithColor(Math.round(up_total * (rate / 100)) - Math.round(total * (rate / 100)))}</Center>
                    </Box>
                    <Box>
                        <Center>정산 금액</Center>
                        <Center>
                            {category === "루징금" ? (
                                <Popover placement="auto-end">
                                    <PopoverTrigger>
                                        <Box cursor="pointer" _hover={{ textDecoration: "underline" }}>
                                            {formatValueWithColor(Math.round(up_total * (rate / 100)))}
                                        </Box>
                                    </PopoverTrigger>
                                    <PopoverContent borderRadius="md" boxShadow="md" zIndex={4} width="auto" maxWidth="100%">
                                        <PopoverBody>
                                            {loading ? (
                                                <Center flexDirection="column">
                                                    <Spinner size="lg" />  {/* 스피너 추가 */}
                                                    <Text mt={2}>로딩 중...</Text>  {/* 로딩 메시지 표시 */}
                                                </Center>
                                            ) : PBlosing3MonTableData ? (
                                                <BaseTable tableData={PBlosing3MonTableData} />
                                            ) : (
                                                <Text>데이터를 불러오지 못했습니다.</Text>
                                            )}
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            ) : (
                                formatValueWithColor(Math.round(up_total * (rate / 100)))
                            )}
                        </Center>
                    </Box>
                </Flex>
            ) : (
                <Flex bg={inputBgColor} color={mainFontColor} alignItems={"center"} justifyContent={"space-around"} py={2} my={2} borderRadius={"md"}>
                    <Box>
                        <Center>Total</Center>
                        <Center>{formatValueWithColor(total)}</Center>
                    </Box>
                    <Box>
                        <Center>{category.slice(0, 2)}율</Center>
                        <Center>{rate}%</Center>
                    </Box>
                    <Box>
                        <Center>정산 금액</Center>
                        <Center>
                            {category === "루징금" ? (
                                <Popover placement="auto-end">
                                    <PopoverTrigger>
                                        <Box cursor="pointer" _hover={{ textDecoration: "underline" }}>
                                            {formatValueWithColor(Math.round(total * (rate / 100)))}
                                        </Box>
                                    </PopoverTrigger>
                                    <PopoverContent borderRadius="md" boxShadow="md" zIndex={4} width="auto" maxWidth="100%">
                                        <PopoverBody>
                                            {loading ? (
                                                <Center flexDirection="column">
                                                    <Spinner size="lg" />  {/* 스피너 추가 */}
                                                    <Text mt={2}>로딩 중...</Text>  {/* 로딩 메시지 표시 */}
                                                </Center>
                                            ) : PBlosing3MonTableData ? (
                                                <BaseTable tableData={PBlosing3MonTableData} />
                                            ) : (
                                                <Text>데이터를 불러오지 못했습니다.</Text>
                                            )}
                                        </PopoverBody>
                                    </PopoverContent>
                                </Popover>
                            ) : (
                                formatValueWithColor(Math.round(total * (rate / 100)))
                            )}
                        </Center>
                    </Box>
                </Flex>
            )}

        </Box>
    );
}


