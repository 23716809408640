

import { useCasinoSlotData } from '../../../userMainContesnts/SiteDetailSettlement/consponents/CasinoSlotData';
import MBaseTable from '../../CommContents/Table/MBaseTable';

export default function MCasinoSlotTable() {
    const casinoslotData = useCasinoSlotData();

    return (
        <MBaseTable headers={casinoslotData.headers} rows={casinoslotData.rows} />
    );
}
