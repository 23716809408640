import { axiosInstance } from "./base";

export interface IBankInfo {
    pk: number;
    user: {
        pk: number;
        username: string;
        authority: string;
        distributor: number;
        login_verified: boolean;
    }
    siteInfo: number;
    banktype: {
        pk: number;
        name: string;
        bankName: string;
        siteInfo: {
            pk: number;
            sitetype: {
                pk: number;
                name: string;
            }
        }
        isactivate: boolean;
        memo: string;
    }
    target_date: string;
    balance: number;
}

interface IBankInfoParams {
    siteInfo_pk: number;
    start_date: string;
    end_date: string;
}

export const getBankInfo = ({ siteInfo_pk, start_date, end_date }: IBankInfoParams) => (
    /*
    params = start_date, end_date, 
    */
    axiosInstance.get(
        `bankinfo/`,
        {
            params: {
                siteinfo_pk: siteInfo_pk,
                start_date: start_date,
                end_date: end_date,
            },
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)

interface IpostBankInfoParams {
    siteInfo_pk: number;
    bankType_pk: number;
    target_date: string;
    balance: number;
}

export const postBankInfo = (postBankInfoParams: IpostBankInfoParams) => (
    axiosInstance.post(
        `bankinfo/`,
        {
            siteInfo_pk: postBankInfoParams.siteInfo_pk,
            bankType_pk: postBankInfoParams.bankType_pk,
            target_date: postBankInfoParams.target_date,
            balance: postBankInfoParams.balance,
        },
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)

)


/*
[
    {
        "pk": 1,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "siteInfo": 1,
        "banktype": {
            "pk": 1,
            "name": "멜론뱅크1",
            "bankName": "뱅크닉네임",
            "siteInfo": {
                "pk": 1,
                "sitetype": {
                    "pk": 1,
                    "name": "melon"
                },
                "site_name": "멜론"
            },
            "isactivate": true,
            "memo": "멜론 테스트 뱅크1"
        },
        "target_date": "2024-06-01",
        "balance": 5000
    },
    {
        "pk": 2,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "siteInfo": 1,
        "banktype": {
            "pk": 1,
            "name": "멜론뱅크1",
            "bankName": "뱅크닉네임",
            "siteInfo": {
                "pk": 1,
                "sitetype": {
                    "pk": 1,
                    "name": "melon"
                },
                "site_name": "멜론"
            },
            "isactivate": true,
            "memo": "멜론 테스트 뱅크1"
        },
        "target_date": "2024-06-02",
        "balance": -1000
    },
    {
        "pk": 3,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "siteInfo": 1,
        "banktype": {
            "pk": 2,
            "name": "멜뱅2",
            "bankName": "222",
            "siteInfo": {
                "pk": 1,
                "sitetype": {
                    "pk": 1,
                    "name": "melon"
                },
                "site_name": "멜론"
            },
            "isactivate": true,
            "memo": ""
        },
        "target_date": "2024-06-01",
        "balance": 500000
    },
    {
        "pk": 4,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "siteInfo": 1,
        "banktype": {
            "pk": 2,
            "name": "멜뱅2",
            "bankName": "222",
            "siteInfo": {
                "pk": 1,
                "sitetype": {
                    "pk": 1,
                    "name": "melon"
                },
                "site_name": "멜론"
            },
            "isactivate": true,
            "memo": ""
        },
        "target_date": "2024-06-03",
        "balance": -3000
    },
    {
        "pk": 5,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "siteInfo": 1,
        "banktype": {
            "pk": 3,
            "name": "멜뱅3",
            "bankName": "333",
            "siteInfo": {
                "pk": 1,
                "sitetype": {
                    "pk": 1,
                    "name": "melon"
                },
                "site_name": "멜론"
            },
            "isactivate": true,
            "memo": ""
        },
        "target_date": "2024-06-03",
        "balance": 60000
    },
    {
        "pk": 6,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "siteInfo": 1,
        "banktype": {
            "pk": 4,
            "name": "맬뱅4",
            "bankName": "444",
            "siteInfo": {
                "pk": 1,
                "sitetype": {
                    "pk": 1,
                    "name": "melon"
                },
                "site_name": "멜론"
            },
            "isactivate": false,
            "memo": ""
        },
        "target_date": "2024-06-02",
        "balance": 50000
    }
]
*/