import { useRecoilValue } from "recoil";
import { Box, Button, Flex, Input, Switch, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import { useMutation } from "@tanstack/react-query";
import { getBankType, IBankType, postBankType, putBankType } from "../../../../api/banktype";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";
import { get_api_month_str, post_api_month_str } from "../../../../lib/util";


const defaultBankType: IBankType[] = [
    {
        "pk": 0,
        "distributor": {
            "pk": 0,
            "name": "",
        },
        "name": "",
        "bankName": "",
        "siteInfo": {
            "pk": 0,
            "sitetype": {
                "pk": 0,
                "name": ""
            },
            "site_name": ""
        },
        "isactivate": true,
        "memo": ""
    },]

export default function BankSettingTable() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    const [bankName, setBankName] = useState<string>('');
    const [banks, setBanks] = useState<IBankType[]>(defaultBankType);

    useEffect(() => {
        BankTypeDataMutation.mutate({ siteInfo_pk: SelectedMenu.site.pk, target_month: get_api_month_str(SelectedMenu.Month) });
    }, [SelectedMenu]);

    // 뱅크 타입 데이터 가져오기
    const BankTypeDataMutation = useMutation(getBankType, {
        onSuccess: (data: IBankType[]) => {
            setBanks(data);
        },
        onError: (error) => {
            console.error("뱅크타입을 가져오는 중 오류가 발생했습니다:", error);
        },
    });

    // 뱅크 등록
    const PostBankTypeMutation = useMutation(postBankType, {
        onSuccess: (data: any) => {
            BankTypeDataMutation.mutate({ siteInfo_pk: SelectedMenu.site.pk, target_month: get_api_month_str(SelectedMenu.Month) });
            setBankName('');
        },
        onError: (error) => {
            console.error("뱅크를 등록하는 중 오류가 발생했습니다:", error);
        },
    });

    const handleAddBank = () => {
        if (bankName.trim()) {
            const postBaniInfoParams = {
                siteInfo_pk: SelectedMenu.site.pk,
                name: bankName,
                bankName: bankName,
                target_month: post_api_month_str(SelectedMenu.Month),
                isactivate: true,
                memo: ''
            };
            PostBankTypeMutation.mutate(postBaniInfoParams);
        }
    };

    //뱅크 목록 수정, 보임/숨김
    const [prevNameValue, setPrevNameValue] = useState(''); // 이전 값 저장

    const PutBankTypeMutation = useMutation(putBankType, {
        onSuccess: (data: any) => {
            BankTypeDataMutation.mutate({ siteInfo_pk: SelectedMenu.site.pk, target_month: get_api_month_str(SelectedMenu.Month) });
        },
        onError: (error) => {
            console.error("뱅크타입을 수정하는 중 오류가 발생했습니다:", error);
        },
    });

    const handleNameFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select(); // 포커스 시 텍스트 전체 선택
        setPrevNameValue(e.target.value); // 입력 필드가 포커스될 때 이전 값 업데이트
    };

    const handleNameChange = (index: number, newName: string) => {
        setBanks(banks.map((bank, i) => i === index ? { ...bank, name: newName } : bank));
    };

    const handleNameBlur = (e: React.FocusEvent<HTMLInputElement>, index: number) => {
        const newValue = e.target.value.trim();

        // 변화 없으면 서버와 통신하지 않는다. 
        if (prevNameValue === e.target.value) return;

        // 빈칸이 입력되었을시 이전값으로 복구
        if (e.target.value == "") {
            e.target.value = prevNameValue;
        }

        PutBankTypeMutation.mutate({
            banktypePk: banks[index].pk,
            params: {
                name: newValue,
                bankName: banks[index].bankName,
                target_month: post_api_month_str(SelectedMenu.Month),
                isactivate: banks[index].isactivate,
                memo: banks[index].memo
            }

        });
    }

    const handleToggleChange = (index: number) => {
        PutBankTypeMutation.mutate({
            banktypePk: banks[index].pk,
            params: {
                name: banks[index].name,
                bankName: banks[index].bankName,
                target_month: post_api_month_str(SelectedMenu.Month),
                isactivate: !banks[index].isactivate,
                memo: banks[index].memo
            }
        },);
    };

    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);

    return (
        <Flex direction="column" p={8} bg={bgColor} color={mainFontColor} borderRadius={"md"}>
            <Box bg={mainBgColor} borderRadius="md" boxShadow="md" p={4}>
                <Flex mb="4" alignItems="center">
                    <Box mr="4" fontWeight="bold">뱅크 등록</Box>
                    <Input
                        placeholder="뱅크이름"
                        value={bankName}
                        // onChange={(e) => setBankName(e.target.value)}
                        onChange={(e) => {
                            const value = e.target.value;
                            // 숫자로 시작하는지 확인
                            if (/^\d/.test(value)) {
                                // 숫자로 시작하면 입력을 무시
                                return;
                            }
                            setBankName(e.target.value);
                        }}
                        mr="4"
                        width="200px"
                        bg={inputBgColor}
                        color={mainFontColor}
                    />
                    <Button onClick={handleAddBank} colorScheme="blue">등록</Button>
                </Flex>
            </Box>

            <Box mt={4} bg={mainBgColor} borderRadius="md" boxShadow="md" p={4}>
                <Table variant="simple">
                    <Thead>
                        <Tr>
                            <Th fontSize="lg" fontWeight="bold">뱅크 목록</Th>
                            <Th fontSize="lg" fontWeight="bold">보임/숨김</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {banks.map((bank, index) => (
                            <Tr key={index}>
                                <Td>
                                    <Input
                                        value={bank.name}
                                        onFocus={handleNameFocus}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            // 숫자로 시작하는지 확인
                                            if (/^\d/.test(value)) {
                                                // 숫자로 시작하면 입력을 무시
                                                return;
                                            }
                                            handleNameChange(index, value);
                                        }}
                                        onBlur={(e) => handleNameBlur(e, index)}
                                        bg={inputBgColor}
                                        color={mainFontColor}
                                    />
                                </Td>
                                <Td>
                                    <Switch
                                        isChecked={bank.isactivate}
                                        onChange={() => handleToggleChange(index)}
                                        colorScheme={bank.isactivate ? 'green' : 'red'}
                                    />
                                    {bank.isactivate ? ' on' : ' off'}
                                </Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </Box>
        </Flex>
    );
}

/**
 [
    {
        "pk": 1,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "name": "멜론뱅크1",
        "bankName": "뱅크닉네임",
        "siteInfo": {
            "pk": 1,
            "sitetype": {
                "pk": 1,
                "name": "melon"
            },
            "site_name": "멜론"
        },
        "isactivate": true,
        "memo": "멜론 테스트 뱅크1"
    },
    {
        "pk": 2,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "name": "멜뱅2",
        "bankName": "222",
        "siteInfo": {
            "pk": 1,
            "sitetype": {
                "pk": 1,
                "name": "melon"
            },
            "site_name": "멜론"
        },
        "isactivate": true,
        "memo": ""
    },
    {
        "pk": 3,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "name": "멜뱅3",
        "bankName": "333",
        "siteInfo": {
            "pk": 1,
            "sitetype": {
                "pk": 1,
                "name": "melon"
            },
            "site_name": "멜론"
        },
        "isactivate": true,
        "memo": ""
    },
    {
        "pk": 4,
        "user": {
            "pk": 2,
            "username": "testuser",
            "authority": "user",
            "distributor": 1,
            "login_verified": true
        },
        "name": "맬뱅4",
        "bankName": "444",
        "siteInfo": {
            "pk": 1,
            "sitetype": {
                "pk": 1,
                "name": "melon"
            },
            "site_name": "멜론"
        },
        "isactivate": false,
        "memo": ""
    }
]
 */