import { Box, Flex, Table, Tbody, Td, Th, Thead, Tr, useColorModeValue, Center } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";

// Define the types for the table data
interface TableData {
    headers: string[];
    rows: TableRow[];
}

interface TableRow {
    [key: string]: string | number;
}

export interface BaseTableProps {
    tableData?: TableData; // Optional로 설정
}

export default function BaseTable({ tableData }: BaseTableProps) {
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const titleColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);

    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    // tableData가 undefined일 경우 기본값으로 설정
    const effectiveTableData = tableData || {
        headers: [],
        rows: []
    };

    return (
        <Box
            width={"40rem"}
            overflowX="auto"
            bg={mainBgColor}
            color={mainFontColor}
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: scrollBarColor,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbColor,
                    borderRadius: '4px',
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbHoverColor,
                },
            }}
        >
            <Table variant="striped" bg={bgColor} colorScheme="gray" size="sm">
                <Thead bg={titleColor}>
                    <Tr>
                        {effectiveTableData.headers.length > 0 ? (
                            effectiveTableData.headers.map((header: string, index: number) => (
                                <Th key={index} textAlign="center" padding="0.2rem">{header}</Th>
                            ))
                        ) : (
                            <Th textAlign="center">---</Th>
                        )}
                    </Tr>
                </Thead>
                <Tbody>
                    {effectiveTableData.rows.length === 0 ? (
                        <Tr>
                            <Td colSpan={effectiveTableData.headers.length || 1} textAlign="center" padding="0.5rem">
                                <Center color={mainFontColor}>데이터가 없습니다.</Center>
                            </Td>
                        </Tr>
                    ) : (
                        effectiveTableData.rows.map((row: any, rowIndex: number) => (
                            <Tr
                                key={rowIndex}
                                sx={{
                                    bg: rowIndex === effectiveTableData.rows.length - 1 ? titleColor : undefined,
                                    '& > td': {
                                        bg: rowIndex === effectiveTableData.rows.length - 1 ? `${titleColor} !important` : undefined,
                                    }
                                }}
                            >
                                {effectiveTableData.headers.map((header: string, colIndex: number) => {
                                    const cellValue = row[header];
                                    const numericValue = parseFloat(cellValue.toString().replace(/,/g, ''));
                                    const isNegative = !isNaN(numericValue) && numericValue < 0;

                                    return (
                                        <Td
                                            key={colIndex}
                                            textAlign={colIndex === 0 ? "center" : "right"}
                                            padding="0.2rem"
                                            color={isNegative ? negativeColor : undefined}
                                        >
                                            <Flex justify={colIndex === 0 ? "center" : "flex-end"}>
                                                {typeof cellValue === 'number' ? cellValue.toLocaleString() : cellValue}
                                            </Flex>
                                        </Td>
                                    );
                                })}
                            </Tr>
                        ))
                    )}
                </Tbody>
            </Table>
        </Box>
    );
}
