import { Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";

import Header from "../components/Header";


export default function Root() {
    return (
        <Box width="100%" height="100vh" minHeight="100vh" display="flex" flexDirection="column" overflow="hidden">
            <Box width="100%" height="3rem" flexShrink={0}> {/* 헤더의 높이는 고정 */}
                <Header />
            </Box>
            <Box width="100%" flex="1" overflow="hidden"> {/* 나머지 영역을 Outlet으로 사용 */}
                <Outlet />
            </Box>
        </Box>
    )
}

