import { useMutation } from "@tanstack/react-query";
import { getCSIdbOption } from "../../../../api/csidvoption";
import { getSiteData } from "../../../../api/sitedata";
import { getCombinedData } from "../../../../lib/conversionData";
import { getUserTreeDatas } from "../../CSLosiongShreComm/util/util";


export const useGetCSIdvOption = (UserTreeDataProps: any) => {
    return useMutation(getCSIdbOption, {
        onSuccess: (data, variables) => {
            const type = variables.type;
            UserTreeDataProps.current.IvdOptin = data;
        }
    });
};

export const useGetAtomData = (SelectedMenu: any, UserTreeDataProps: any, LoginData: any) => {
    return useMutation(getSiteData, {
        onSuccess: (data, variables) => {
            let target_atom_data = [] as any;

            data.forEach((item: any) => {
                // 날짜  데이터
                target_atom_data.push(JSON.parse(item.data));
            });
            // 리스트를 역순으로 뒤집는다.
            target_atom_data = target_atom_data.reverse();

            // 역순으로 뒤집어서 데이터를 합친다.
            let target_combined_data = [] as any;
            for (const atom_data of target_atom_data) {
                target_combined_data = getCombinedData(target_combined_data, atom_data);
            }

            // 합친 데이터에서. user_id가 SelectedMenu.company_name인 데이터와 status_id_list에 SelectedMenu.company_name이 있는 데이터를 찾는다.
            let AtomUserTreeDatas = target_combined_data.filter(
                (item: any) => item.user_id === SelectedMenu.CSCom.name || item.status_id_list.includes(SelectedMenu.CSCom.name)
            );

            // 한달 데이터를 가져온다.
            const targetMonData = getUserTreeDatas(AtomUserTreeDatas, UserTreeDataProps.current.IvdOptin);

            // 루징 데이터를 만든다. targetMonData 리스트의 "카지노 손익금" 합계
            const mon_line_data = {
                "기간": `${variables.start_date.slice(5).replace("-", "/")} ~ ${variables.end_date.slice(5).replace("-", "/")}`,
                "카지노 루징금": targetMonData.reduce((acc: number, cur: any) => acc + cur["카지노 루징금"], 0),
                "슬롯 루징금": targetMonData.reduce((acc: number, cur: any) => acc + cur["슬롯 루징금"], 0),
                "홀덤 루징금": targetMonData.reduce((acc: number, cur: any) => acc + cur["홀덤 루징금"], 0),
                "금액 조정": targetMonData.reduce((acc: number, cur: any) => acc + cur["금액 조정"], 0),
                "루징금 합계": targetMonData.reduce((acc: number, cur: any) => acc + cur["루징금 합계"], 0),
            };

            // 방어 코드: LoginData.current가 배열이 아닐 경우 빈 배열로 초기화
            if (!Array.isArray(LoginData.current)) {
                LoginData.current = [];
            }

            // 배열에 데이터를 추가
            LoginData.current = [...LoginData.current, mon_line_data];
        }
    });
};


/**
이 useMutation 을 커스텀으로 분리해서 사용한다. 
     
const getCSIdvOptionMutation = useMutation(getCSIdbOption, {
    onSuccess: (data, variables) => {
        const type = variables.type;
        UserTreeDataProps.current.IvdOptin = data;
    }
});

const atomDataMutation = useMutation(getSiteData, {
    onSuccess: (data, variables) => {
        let target_atom_data = [] as any;

        data.forEach((item: any) => {
            // 날짜  데이터
            target_atom_data.push(JSON.parse(item.data));
        });
        // 리스트를 역순으로 뒤집는다.
        target_atom_data = target_atom_data.reverse();

        // 역순으로 뒤집어서 데이터를 합친다.
        let target_combined_data = [] as any;
        for (const atom_data of target_atom_data) {
            target_combined_data = getCombinedData(target_combined_data, atom_data);
        }

        // 합친 데이터에서. user_id가 SelectedMenu.company_name인 데이터와 status_id_list에 SelectedMenu.company_name이 있는 데이터를 찾는다.
        let AtomUserTreeDatas = target_combined_data.filter(
            (item: any) => item.user_id === SelectedMenu.CSCom.name || item.status_id_list.includes(SelectedMenu.CSCom.name)
        );

        // 한달 데이터를 가져온다.
        const targetMonData = getUserTreeDatas(AtomUserTreeDatas, UserTreeDataProps.current.IvdOptin);

        // 루징 데이터를 만든다. targetMonData 리스트의 "카지노 손익금" 합계
        const mon_line_data = {
            "기간": `${variables.start_date.slice(5).replace("-", "/")} ~ ${variables.end_date.slice(5).replace("-", "/")}`,
            "카지노 루징금": targetMonData.reduce((acc: number, cur: any) => acc + cur["카지노 루징금"], 0),
            "슬롯 루징금": targetMonData.reduce((acc: number, cur: any) => acc + cur["슬롯 루징금"], 0),
            "홀덤 루징금": targetMonData.reduce((acc: number, cur: any) => acc + cur["홀덤 루징금"], 0),
            "금액 조정": targetMonData.reduce((acc: number, cur: any) => acc + cur["금액 조정"], 0),
            "루징금 합계": targetMonData.reduce((acc: number, cur: any) => acc + cur["루징금 합계"], 0),
        };

        // 방어 코드: LoginData.current가 배열이 아닐 경우 빈 배열로 초기화
        if (!Array.isArray(LoginData.current)) {
            LoginData.current = [];
        }

        // 배열에 데이터를 추가
        LoginData.current = [...LoginData.current, mon_line_data];
    }
});
 */