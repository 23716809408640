import { Box, Center, Flex, Table, Thead, Tbody, Tr, Th, Td, useColorModeValue, Text } from '@chakra-ui/react';
import { darkTheme_colors, lightTheme_colors } from '../../../../theme';

interface IMBaseTableProps {
    headers: string[];
    rows: Record<string, string>[]; // Record<string, string>은 { [key: string]: string }와 같은 의미
}

export default function MBaseTable({ headers, rows }: IMBaseTableProps) {
    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const titleColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);
    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    return (
        <Box
            overflow="auto"
            width={"100%"}
            bg={mainBgColor}
            color={mainFontColor}
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                    height: '8px',
                    backgroundColor: scrollBarColor,
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbColor,
                    borderRadius: '4px',
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: scrollBarThumbHoverColor,
                },
            }}
        >
            {headers.length === 0 || rows.length === 0 ? (
                <Center h="100%">
                    <Text fontSize="2xl" color={negativeColor}>데이터가 없습니다!</Text>
                </Center>
            ) : (
                <Table variant="striped" bg={bgColor} colorScheme="gray" size="sm" width="100%">
                    <Thead bg={titleColor}>
                        <Tr>
                            {headers.map((header, index) => (
                                <Th
                                    key={index}
                                    textAlign="center"
                                    padding="0.2rem"
                                    width={`${100 / headers.length}%`}
                                    whiteSpace="nowrap"
                                    position={index === 0 || index === headers.length - 1 ? "sticky" : "static"}
                                    left={index === 0 ? 0 : undefined}
                                    right={index === headers.length - 1 ? 0 : undefined}
                                    bg={titleColor}>
                                    {header}
                                </Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {rows.map((row, rowIndex) => (
                            <Tr
                                key={rowIndex}
                                sx={{
                                    bg: rowIndex === rows.length - 1 ? titleColor : undefined,
                                    '& > td': {
                                        bg: rowIndex === rows.length - 1 ? `${titleColor} !important` : undefined,
                                    }
                                }}
                            >
                                {Object.keys(row).map((key, colIndex) => {
                                    const cellValue = row[key];
                                    const isNegative = typeof cellValue === 'string' && parseFloat(cellValue.replace(/,/g, '')) < 0;

                                    return (
                                        <Td
                                            key={colIndex}
                                            textAlign={colIndex === 0 ? "center" : "right"}
                                            padding="0.2rem"
                                            color={isNegative ? negativeColor : undefined}
                                            width={`${100 / headers.length}%`}
                                            whiteSpace="nowrap"
                                            position={colIndex === 0 || colIndex === headers.length - 1 ? "sticky" : "static"}
                                            left={colIndex === 0 ? 0 : undefined}
                                            right={colIndex === headers.length - 1 ? 0 : undefined}
                                            bg={bgColor}
                                        >
                                            <Flex justify={colIndex === 0 ? "center" : "flex-end"}>
                                                {cellValue}
                                            </Flex>
                                        </Td>
                                    );
                                })}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </Box>
    );
};
