import { Center, Flex, Grid, GridItem, Image, VStack, useColorModeValue } from "@chakra-ui/react"
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { IsLoginAtom } from "../atoms/user";
import { darkTheme_colors, lightTheme_colors } from "../theme";
import { isMobile } from 'react-device-detect';

import logoimg01 from "../assets/loginfirstimg01.png"

export default function LoginFirst() {
    const navigate = useNavigate();
    const isLogin = useRecoilValue(IsLoginAtom)
    useEffect(() => {
        if (isLogin) {
            navigate("/");  // 로그인후 사용가능 페이지로 이동.
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLogin]);


    // 테마 관련 코드
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);
    const titleColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);
    return (
        <Grid padding={"0.2rem"}
            h='100%'
            templateRows='repeat(8, 1fr)'
            templateColumns='repeat(1, 1fr)'
            gap={1}
        >
            <GridItem borderRadius={"xl"} padding={"0.5rem"} rowSpan={3} bg={mainBgColor} >
                <VStack height={"100%"} justifyContent={"center"} >
                    <Image objectFit={"contain"} h={"33vh"} width={"98.5vw"} borderRadius={"2xl"} src={logoimg01} />
                </VStack>
            </GridItem>
            <GridItem borderRadius={"xl"} rowSpan={5} bg={mainBgColor} >
                <Flex h={"100%"} w={"100%"} justifyContent={"flex-start"} alignItems={"center"} flexDirection={"column"}>
                    <Flex
                        flexDirection={"column"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        h={"90%"}
                        w={'90%'}
                        bg={inputBgColor}
                        fontSize={"1rem"}
                        borderRadius={"xl"}
                        marginTop={"1rem"}
                    >
                        {isMobile ? (
                            <>
                                <Center fontSize={"2.5rem"} marginY={"0.5rem"}>인증이 완료되면</Center>
                                <Center fontSize={"2.5rem"} marginY={"0.5rem"}>이용가능합니다.</Center>

                                <Center fontSize={"1.5rem"} marginY={"5rem"}></Center>
                                <Center fontSize={"1.5rem"} marginY={"0.5rem"}>- 문의 -</Center>
                                <Center fontSize={"1.5rem"}>텔레그램 : bkparser</Center>
                            </>

                        ) : (
                            <>
                                <Center fontSize={"4rem"} marginY={"0.5rem"}>로그인후 이용가능합니다.</Center>
                                <Center fontSize={"1.5rem"} marginY={"5rem"}></Center>
                                <Center fontSize={"1.5rem"} marginY={"0.5rem"}>- 문의 -</Center>
                                <Center fontSize={"1.5rem"}>텔레그램 : bkparser</Center>
                            </>
                        )}


                    </Flex>
                </Flex>
            </GridItem >
        </Grid >
    )
}


