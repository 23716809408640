// useBankTypeData.ts

import { useEffect, useRef } from 'react';
import { getBankType, IBankType } from '../../../../api/banktype';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SelectedMenuAtom } from '../../../../atoms/topSelect';
import { useMutation } from '@tanstack/react-query';
import { get_api_month_str, getMonthRangeFromString } from '../../../../lib/util';
import { getBankInfo, IBankInfo, postBankInfo } from '../../../../api/bankinfo';

// 뱅크 정보를 입력, 수정
export function usePostBankInfoMutation() {
    const setSelectedMenu = useSetRecoilState(SelectedMenuAtom);

    return useMutation(postBankInfo, {
        onSuccess: (data: IBankInfo) => {
            // 서버 응답 후 즉시 상태 업데이트   
            setSelectedMenu((prev) => ({ ...prev, reload_tag: new Date().getTime() }));
        },
        onError: (error) => {
            console.error("뱅크정보를 입력하는 중 오류가 발생했습니다:", error);
        },
    });
}


// 뱅크 타입데이터 가져오기
interface UseBankTypeDataProps {
    bankTypeRef: React.MutableRefObject<IBankType[]>;
}

export function useBankTypeData({ bankTypeRef }: UseBankTypeDataProps) {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    const BankTypeDataMutation = useMutation(getBankType, {
        onSuccess: (data: IBankType[]) => {
            bankTypeRef.current = data;
        },
        onError: (error) => {
            console.error("뱅크타입을 가져오는 중 오류가 발생했습니다:", error);
        },
    });

    useEffect(() => {
        BankTypeDataMutation.mutate({ siteInfo_pk: SelectedMenu.site.pk, target_month: get_api_month_str(SelectedMenu.Month) });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedMenu]);

    return BankTypeDataMutation;
}


// 뱅크 데이터 가져와서 타입과 조합해서 테이블 데이터로 만들기
interface UseBankInfoDataProps {
    bankTypeRef: React.MutableRefObject<IBankType[]>;
    setTableData: React.Dispatch<React.SetStateAction<InitialData>>;
}

export function useBankInfoData({ bankTypeRef, setTableData }: UseBankInfoDataProps) {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);
    const bankInfoRef = useRef<IBankInfo[]>([]);

    const BankInfoDataMutation = useMutation(getBankInfo, {
        onSuccess: (data: IBankInfo[]) => {
            bankInfoRef.current = data;
            if (bankTypeRef.current.length) {
                setTableData(getBankTable(SelectedMenu.Month, bankTypeRef.current, bankInfoRef.current));
            } else {
                setTableData({ headers: [], rows: [] });
            }
        },
        onError: (error) => {
            console.error("뱅크정보를 가져오는 중 오류가 발생했습니다:", error);
        },
    });

    useEffect(() => {
        const monthRange = getMonthRangeFromString(SelectedMenu.Month);
        const parms = {
            siteInfo_pk: SelectedMenu.site.pk,
            start_date: monthRange[0],
            end_date: monthRange[1],
        };

        BankInfoDataMutation.mutate(parms);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [SelectedMenu]);
}


export interface BankTableRow {
    date: string;
    total: string;
    [key: string]: string;
}

export interface InitialData {
    headers: string[];
    rows: BankTableRow[];
}

const getBankTable = (selectedMonth_str: string, bankData: IBankType[], balanceData: IBankInfo[]): InitialData => {
    // 활성화된 뱅크 추출
    const activeBanks = bankData.filter(bank => bank.isactivate).map(bank => bank.name);

    // 날짜별 데이터를 정리
    const groupedData = balanceData.reduce<Record<string, BankTableRow>>((acc, cur) => {
        const date = cur.target_date;
        if (!acc[date]) {
            acc[date] = { date: date, total: '0' };
            activeBanks.forEach(bank => {
                acc[date][bank] = '0';
            });
        }
        if (activeBanks.includes(cur.banktype.name)) {
            acc[date][cur.banktype.name] = cur.balance.toString();
            acc[date].total = (parseInt(acc[date].total.replace(/,/g, '')) + cur.balance).toLocaleString();
        }
        return acc;
    }, {});

    // selectedMonth_str에서 연도와 월 추출
    const [yearStr, monthStr] = selectedMonth_str.split('년').map(s => s.trim().replace('월', ''));
    const year = parseInt(yearStr, 10);
    const month = parseInt(monthStr, 10) - 1; // 월은 0부터 시작하므로 1을 빼줍니다.

    // 첫 데이터의 월의 마지막 날 또는 오늘 날짜까지의 모든 날짜 생성    
    const today = new Date();
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 0);

    const finalDate = endDate > today ? today : endDate; // 월의 마지막 날과 오늘 중 더 빠른 날짜 선택
    let currentDate = new Date(startDate);

    while (true) {
        let cyear = currentDate.getFullYear();
        let cmonth = String(currentDate.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
        let cday = String(currentDate.getDate()).padStart(2, '0');
        let formattedDate = `${cyear}-${cmonth}-${cday}`;

        if (!groupedData[formattedDate]) {
            groupedData[formattedDate] = { date: formattedDate, total: '0' };
            activeBanks.forEach(bank => {
                groupedData[formattedDate][bank] = '0';
            });
        }
        currentDate.setDate(currentDate.getDate() + 1);
        if (currentDate > finalDate) break;
    }

    // 결과를 배열로 변환
    const rows = Object.values(groupedData).sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());


    // 날짜 포맷 변환 (YYYY-MM-DD -> M/D)
    rows.forEach((row, index) => {
        row.date = new Date(row.date).toLocaleDateString('ko-KR', {
            month: 'numeric',
            day: 'numeric'
        }).replace(/\.\s/g, '/').replace('.', '');

        // 각 은행의 값을 포맷팅
        activeBanks.forEach(bank => {
            row[bank] = parseInt(row[bank].replace(/,/g, '')).toLocaleString();
        });

        // 'total' 키를 마지막으로 이동
        const { total, ...rest } = row;
        rows[index] = { ...rest, total };
    });

    const initialData: InitialData = {
        headers: ['Date', ...activeBanks, '합계'],
        rows: rows
    };

    return initialData;
}




/**
 const initialData = {
    headers: [
        'Date', '뱅크1', '뱅크2', '뱅크3', '뱅크4', '뱅크5', '뱅크6', '뱅크7', '뱅크8', '뱅크9', '뱅크10', '합계'
    ],
    rows: [
        { date: '3/1', bank1: '-463,298', bank2: '-463,298', bank3: '-463,298', bank4: '-463,298', bank5: '-463,298', bank6: '-463,298', bank7: '-463,298', bank8: '-463,298', bank9: '-463,298', bank10: '-463,298', total: '-4,632,980' },
        { date: '3/2', bank1: '37,136,508', bank2: '37,136,508', bank3: '37,136,508', bank4: '37,136,508', bank5: '37,136,508', bank6: '37,136,508', bank7: '37,136,508', bank8: '37,136,508', bank9: '37,136,508', bank10: '37,136,508', total: '371,365,080' },
        { date: '3/3', bank1: '17,788,942', bank2: '17,788,942', bank3: '17,788,942', bank4: '17,788,942', bank5: '17,788,942', bank6: '17,788,942', bank7: '17,788,942', bank8: '17,788,942', bank9: '17,788,942', bank10: '17,788,942', total: '177,889,420' },
        { date: '3/4', bank1: '-14,660,163', bank2: '-14,660,163', bank3: '-14,660,163', bank4: '-14,660,163', bank5: '-14,660,163', bank6: '-14,660,163', bank7: '-14,660,163', bank8: '-14,660,163', bank9: '-14,660,163', bank10: '-14,660,163', total: '-146,601,630' },
        { date: '3/5', bank1: '2,625,833', bank2: '2,625,833', bank3: '2,625,833', bank4: '2,625,833', bank5: '2,625,833', bank6: '2,625,833', bank7: '2,625,833', bank8: '2,625,833', bank9: '2,625,833', bank10: '2,625,833', total: '26,258,330' },
        { date: '3/6', bank1: '8,625,833', bank2: '8,625,833', bank3: '8,625,833', bank4: '8,625,833', bank5: '8,625,833', bank6: '8,625,833', bank7: '8,625,833', bank8: '8,625,833', bank9: '8,625,833', bank10: '8,625,833', total: '86,258,330' },

    ]
};


 const initialData = {
    headers: [
        'Date', '멜론뱅크1', '멜뱅2', '멜뱅3','합계'
    ],
    rows: [
        { date: '6/1', 멜론뱅크1: '5,000', 멜뱅2: '500,000', 멜뱅3: '0', total: '505,000' },
        { date: '6/2', 멜론뱅크1: '-1,000', 멜뱅2: '0', 멜뱅3: '0', total: '-1,000' },
        { date: '6/3', 멜론뱅크1: '0', 멜뱅2: '-3,000', 멜뱅3: '60,000', total: '57,000' },
        { date: '6/4', 멜론뱅크1: '5,000', 멜뱅2: '0', 멜뱅3: '0', total: '5,000' },
        { date: '6/5', 멜론뱅크1: '0', 멜뱅2: '0', 멜뱅3: '0', total: '0' }, ...

    ]
};
 */
