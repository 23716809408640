import React, { useRef, useState } from 'react';
import { Box, Flex, Table, Thead, Tbody, Tr, Th, Td, Input, useColorModeValue } from '@chakra-ui/react';
import { darkTheme_colors, lightTheme_colors } from '../../../../theme';
import { IBankType } from '../../../../api/banktype';
import { useRecoilValue } from 'recoil';
import { SelectedMenuAtom } from '../../../../atoms/topSelect';
import { BankTableRow, InitialData, useBankInfoData, useBankTypeData, usePostBankInfoMutation } from '../consponents/BankData';

const formatNumberWithCommas = (number: string) => {
    const num = number.replace(/,/g, '');
    if (isNaN(Number(num))) return number;
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

const unformatNumber = (formattedNumber: string) => {
    return formattedNumber.replace(/,/g, '');
};

function combineDateStrings(yearMonth: string, day: string): string {
    // "2024년 06월"을 "2024-06"으로 변환
    const year_month_list = yearMonth.match(/\d+/g) || [];

    // "6/2"을 "06-02"으로 변환
    const [dayMonth, dayDay] = day.split('/').map(num => num.padStart(2, '0'));

    // 조합하여 "2024-06-02" 형식으로 반환
    return `${year_month_list[0]}-${dayMonth}-${dayDay}`;
}

export default function BankTable() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    const [prevValue, setPrevValue] = useState('');

    // 뱅크 타입 데이터 가져오기
    const bankTypeRef = useRef<IBankType[]>([]);
    useBankTypeData({ bankTypeRef });

    // 뱅크 정보 데이터 가져와서 테이블데이터 구성
    const [tableData, setTableData] = useState<InitialData>({ headers: [], rows: [] });
    useBankInfoData({ bankTypeRef, setTableData });

    // 입력처리 
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, rowIndex: number, key: keyof BankTableRow, date: string) => {
        const newValue = e.target.value;
        const regex = /^[0-9,-]*$/;

        if (regex.test(newValue)) { // 정규식 검사
            const formattedValue = formatNumberWithCommas(newValue);
            setTableData(prevState => {
                const newRows = [...prevState.rows];
                newRows[rowIndex] = {
                    ...newRows[rowIndex],
                    [key]: formattedValue,
                };
                return { ...prevState, rows: newRows };
            });
        } else {
            e.target.value = prevValue;
        }
    };

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        e.target.select(); // 포커스 시 텍스트 전체 선택
        setPrevValue(e.target.value); // 입력 필드가 포커스될 때 이전 값 업데이트
    };

    // 뱅크 정보를 입력, 수정 
    const postBankInfoMutation = usePostBankInfoMutation();

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>, key: keyof BankTableRow, date: string) => {
        // 변화 없으면 서버와 통신하지 않는다. 
        if (parseInt(unformatNumber(prevValue)) === parseInt(unformatNumber(e.target.value))) return;

        // 빈칸이 입력되었을시 이전값으로 복구
        if (e.target.value === "") {
            e.target.value = prevValue;
        }


        const postBankInfoParams = {
            siteInfo_pk: SelectedMenu.site.pk,
            bankType_pk: bankTypeRef.current.find(bank => bank.name === key)?.pk || 0,
            target_date: combineDateStrings(SelectedMenu.Month, date),
            balance: parseInt(unformatNumber(e.target.value)),
        }

        postBankInfoMutation.mutate(postBankInfoParams);
    };

    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const mainBgColor = useColorModeValue(lightTheme_colors['mainBgColor'], darkTheme_colors['mainBgColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const titleColor = useColorModeValue(lightTheme_colors['titleColor'], darkTheme_colors['titleColor']);
    const negativeColor = useColorModeValue(lightTheme_colors['negativeColor'], darkTheme_colors['negativeColor']);

    return (
        <Box
            width={"100%"}
            overflowX="auto"
            bg={mainBgColor}
            color={mainFontColor}
            css={{
                '&::-webkit-scrollbar': {
                    width: '8px',
                    backgroundColor: useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']),
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']),
                    borderRadius: '4px',
                },
                '&:hover::-webkit-scrollbar-thumb': {
                    backgroundColor: useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']),
                },
            }}
        >
            {tableData.headers.length === 0 && (
                <Flex justify="center" align="center" h="100%">
                    입력된 뱅크가 없습니다.
                </Flex>
            )}
            {tableData.rows.length > 0 && (
                <Table variant="striped" bg={bgColor} colorScheme="gray" size="sm">
                    <Thead bg={titleColor}>
                        <Tr >
                            {tableData.headers.map((header, index) => (
                                <Th
                                    key={index}
                                    textAlign="center"
                                    padding="0.2rem"
                                    bg={titleColor} // 배경색을 설정해주어야 스크롤 시 시각적으로 깨지지 않습니다.
                                    position="sticky" // Thead와 Th 모두 position을 sticky로 설정합니다.
                                    top="0" // 상단에 고정되도록 설정합니다.
                                    zIndex="sticky" // zIndex를 설정하여 다른 요소가 겹치지 않도록 합니다.
                                >
                                    {header}
                                </Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {tableData.rows.map((row, rowIndex) => (
                            <Tr key={rowIndex} >
                                {Object.keys(row).map((key, colIndex) => (
                                    <Td key={colIndex} textAlign={colIndex === 0 ? "center" : "right"} padding="0.2rem">
                                        {colIndex === 0 ? (
                                            <Flex justify="center">{row[key]}</Flex>
                                        ) : colIndex === Object.keys(row).length - 1 ? (
                                            <Flex justify="flex-end" color={parseInt(unformatNumber(row[key])) < 0 ? negativeColor : 'inherit'}>
                                                {row[key]}
                                            </Flex>
                                        ) : (
                                            <Flex justify="flex-end">
                                                <Input
                                                    variant="outline"
                                                    value={row[key]}
                                                    onChange={(e) => handleInputChange(e, rowIndex, key as keyof BankTableRow, row.date)}
                                                    onFocus={handleFocus}
                                                    onBlur={(e) => handleBlur(e, key as keyof BankTableRow, row.date)}
                                                    textAlign="right"
                                                    borderColor={mainFontColor}
                                                    _hover={{ borderColor: titleColor }}
                                                    _focus={{ borderColor: titleColor, boxShadow: `0 0 0 1px ${titleColor}` }}
                                                    color={parseInt(unformatNumber(row[key])) < 0 ? negativeColor : 'inherit'}
                                                    inputMode="numeric"
                                                    pattern="[0-9]*"
                                                />
                                            </Flex>
                                        )}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            )}
        </Box>
    );
}

