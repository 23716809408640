import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
    initialColorMode: "system",
    useSystemColorMode: false,
};

const theme = extendTheme({ config });

export default theme;


export const lightTheme_colors = {
    bgColor: "#c7dbe9",
    bgHoverColor: "#dcdfe0",
    mainBgColor: "#a6c7e2",
    mainFontColor: "#02012c",
    btnBgColor: "#a2baca",
    inputBgColor: "#e8f6ff",
    receSelectColor: "#706900",
    borderColor: "#eefafc",
    deactivationColor: "#5c1a05",
    impossibleColor: "#ff6262",
    titleColor: "#e28787",
    negativeColor: "#ff1a1a",
    errorColor: "#ff1a1a",

    scrollBarColor: "transparent",
    scrollBarThumbColor: "#7796aa",
    scrollBarThumbHoverColor: "#4e5f69",

    rowLineColor0: "#e6f2ff",  // 밝은 청록색 (연한 하늘색)
    rowLineColor1: "#d1e7ff",  // 밝은 파란색
    rowLineColor2: "#c2dfff",  // 중간 파란색
    rowLineColor3: "#b3d7ff",  // 연한 파란색
    rowLineColor4: "#e0d4ff",  // 연한 보라색
    rowLineColor5: "#f4ccff",  // 연한 자주색

}

export const darkTheme_colors = {
    bgColor: "#3c4d53",
    bgHoverColor: "#4a5052",
    mainBgColor: "#5b737c",
    mainFontColor: "#ced4d1",
    btnBgColor: "#5d7681",
    inputBgColor: "#1f272b",
    receSelectColor: "#928905",
    borderColor: "#121216",
    deactivationColor: "#360303",
    impossibleColor: "#9c1f16",
    titleColor: "#461f1f",
    negativeColor: "#e49b9b",
    errorColor: "#ff1a1a",

    scrollBarColor: "transparent",
    scrollBarThumbColor: "#3c3c4b",
    scrollBarThumbHoverColor: "#21212c",

    rowLineColor0: "#2b3a42",  // 짙은 청록색
    rowLineColor1: "#3b4d61",  // 짙은 파란색
    rowLineColor2: "#4d5f7a",  // 중간 정도의 파란색
    rowLineColor3: "#5e738d",  // 연한 파란색
    rowLineColor4: "#726b91",  // 짙은 보라색
    rowLineColor5: "#8a497b",  // 짙은 자주색

}