import { useUserMoneyData } from '../../../userMainContesnts/SiteDetailSettlement/consponents/UserMoneyData';
import MBaseTable from '../../CommContents/Table/MBaseTable';

export default function MUserMoneyTable() {
    const userMoneyData = useUserMoneyData();

    return (
        <MBaseTable headers={userMoneyData.headers} rows={userMoneyData.rows} />
    );
}
