import { useGitaData } from '../../../userMainContesnts/SiteDetailSettlement/consponents/GitaData';
import MBaseTable from '../../CommContents/Table/MBaseTable';

export default function MGitaTable() {
    const gitaData = useGitaData();

    return (
        <MBaseTable headers={gitaData.headers} rows={gitaData.rows} />
    );
}
