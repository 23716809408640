import { axiosInstance } from "./base";

export interface IBankType {
    pk: number;
    distributor: {
        pk: number;
        name: string;
    }
    siteInfo: {
        pk: number;
        sitetype: {
            pk: number;
            name: string;
        }
        site_name: string;
    }
    name: string;
    bankName: string;
    isactivate: boolean;
    memo: string;
}

export interface IgetBankTypeParams {
    siteInfo_pk: number;
    target_month: string; // "2021-09"
}
export const getBankType = (BankTypeParam: IgetBankTypeParams) => (
    axiosInstance.get(
        `banktype/`,
        {
            params: {
                siteinfo_pk: BankTypeParam.siteInfo_pk,
                target_month: BankTypeParam.target_month,
            },
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)

export interface IpostBankInfoParams {
    siteInfo_pk: number;
    name: string;
    bankName: string;
    target_month: string;
    isactivate: boolean;
    memo: string;
}

export const postBankType = (postBankInfoParams: IpostBankInfoParams) => (
    axiosInstance.post(
        `banktype/`,
        {
            siteInfo: postBankInfoParams.siteInfo_pk,
            name: postBankInfoParams.name,
            bankName: postBankInfoParams.bankName,
            target_month: postBankInfoParams.target_month,
            isactivate: postBankInfoParams.isactivate,
            memo: postBankInfoParams.memo,
        },
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
);

interface IBankTypeParams {
    name: string;
    bankName: string;
    isactivate: boolean;
    target_month: string;
    memo: string;
}

export interface IputBankTypeParams {
    banktypePk: number;
    params: IBankTypeParams;
}

export const putBankType = (putBankTypeParams: IputBankTypeParams) => (
    axiosInstance.put(
        `banktype/${putBankTypeParams.banktypePk}/`,
        putBankTypeParams.params,
        {
            headers: {
                "Authorization": `Token ${localStorage.getItem("token")}`,
            },
        }
    ).then((response) => response.data)
)


/*

[
    {
        "pk": 1,
        "distributor": {
            "pk": 1,
            "name": "관리자1"
        },
        "name": "멜멜메1",
        "bankName": "뱅크닉네임",
        "siteInfo": {
            "pk": 1,
            "sitetype": {
                "pk": 1,
                "name": "melon"
            },
            "site_name": "멜론"
        },
        "isactivate": true,
        "memo": "멜론 테스트 뱅크1"
    },
    {
        "pk": 2,
        "distributor": {
            "pk": 1,
            "name": "관리자1"
        },
        "name": "1123",
        "bankName": "222",
        "siteInfo": {
            "pk": 1,
            "sitetype": {
                "pk": 1,
                "name": "melon"
            },
            "site_name": "멜론"
        },
        "isactivate": true,
        "memo": ""
    },
    {
        "pk": 3,
        "distributor": {
            "pk": 1,
            "name": "관리자1"
        },
        "name": "멜뱅3",
        "bankName": "333",
        "siteInfo": {
            "pk": 1,
            "sitetype": {
                "pk": 1,
                "name": "melon"
            },
            "site_name": "멜론"
        },
        "isactivate": true,
        "memo": ""
    },
    {
        "pk": 4,
        "distributor": {
            "pk": 1,
            "name": "관리자1"
        },
        "name": "맬뱅4",
        "bankName": "444",
        "siteInfo": {
            "pk": 1,
            "sitetype": {
                "pk": 1,
                "name": "melon"
            },
            "site_name": "멜론"
        },
        "isactivate": true,
        "memo": ""
    },
    {
        "pk": 9,
        "distributor": {
            "pk": 1,
            "name": "관리자1"
        },
        "name": "메메",
        "bankName": "메메",
        "siteInfo": {
            "pk": 1,
            "sitetype": {
                "pk": 1,
                "name": "melon"
            },
            "site_name": "멜론"
        },
        "isactivate": true,
        "memo": ""
    }
]
 */
