import { useRecoilValue } from "recoil";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";
import { useEffect, useState } from "react";
import { getCSComInfo, IcsComInfo } from "../../../../api/cscominfo";
import { useMutation } from "@tanstack/react-query";
import { Box, Button, Divider, Flex, HStack, Text, useColorModeValue, useDisclosure, VStack } from "@chakra-ui/react";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";
import CSNewRegModal from "../Madal/NewReg";
import CSPutRegModal from "../Madal/PutReg";


const formatDateTime = (datetime_str: string) => {
    const date = new Date(datetime_str);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 1을 더합니다.
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}`;
};

export default function CSComRegList() {
    const SelectedMenu = useRecoilValue(SelectedMenuAtom);

    // 서버에서 데이터 가져와서 처리하자. 
    const [CSCominfoList, setCSCominfoList] = useState([] as IcsComInfo[]);

    const getCSCominfoMutation = useMutation(getCSComInfo, {
        onSuccess: (data) => {

            const sortedList = data.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

            setCSCominfoList(sortedList);
        }
    });

    // 모달을 위한 상태 관리
    const {
        isOpen: NewRegIsOpen,
        onOpen: NewRegOnOpen,
        onClose: NewRegOnClose,
    } = useDisclosure();

    const {
        isOpen: PutRegisOpen,
        onOpen: PutRegonOpen,
        onClose: PutRegonClose,
    } = useDisclosure();

    // 사이트 설정이 바뀌거나. 모달창 상태가 변할때 데이터를 가져오도록 설정
    useEffect(() => {
        getCSCominfoMutation.mutate({ siteInfo_pk: SelectedMenu.site.pk });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [NewRegIsOpen, PutRegisOpen, SelectedMenu]);


    const [selectedPutPk, setSelectedPutPk] = useState(-1);

    const handlePutOpenModal = (comInfo_pk: number) => {
        setSelectedPutPk(comInfo_pk);  // 선택한 pk 값을 설정
        PutRegonOpen();            // 모달 열기
    };


    const bgColor = useColorModeValue(lightTheme_colors['bgColor'], darkTheme_colors['bgColor']);
    const receSelectColor = useColorModeValue(lightTheme_colors['receSelectColor'], darkTheme_colors['receSelectColor']);
    const mainFontColor = useColorModeValue(lightTheme_colors['mainFontColor'], darkTheme_colors['mainFontColor']);
    const inputBgColor = useColorModeValue(lightTheme_colors['inputBgColor'], darkTheme_colors['inputBgColor']);
    const titleColor = useColorModeValue(lightTheme_colors.titleColor, darkTheme_colors.titleColor);

    const scrollBarColor = useColorModeValue(lightTheme_colors['scrollBarColor'], darkTheme_colors['scrollBarColor']);
    const scrollBarThumbColor = useColorModeValue(lightTheme_colors['scrollBarThumbColor'], darkTheme_colors['scrollBarThumbColor']);
    const scrollBarThumbHoverColor = useColorModeValue(lightTheme_colors['scrollBarThumbHoverColor'], darkTheme_colors['scrollBarThumbHoverColor']);

    return (
        <Box bg={bgColor} borderRadius="md" boxShadow="md" w={"40rem"} h={"100%"}>
            <Flex justifyContent={"space-between"} alignItems={"center"} bg={titleColor} p={4} borderTopRadius="md">
                <Text marginLeft={"1rem"} fontSize="lg" fontWeight="bold" color={mainFontColor}>
                    카지노 정산 업체 리스트
                </Text>
                <Button marginRight={"1rem"} colorScheme="blue" onClick={NewRegOnOpen}>
                    신규등록
                </Button>
            </Flex>
            <Box
                p={4}
                overflowY="auto"
                h="calc(100% - 80px)"
                css={{
                    '&::-webkit-scrollbar': {
                        width: '8px',
                        backgroundColor: `${scrollBarColor} `,
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: `${scrollBarThumbColor} `,
                        borderRadius: '4px',
                    },
                    '&:hover::-webkit-scrollbar-thumb': {
                        backgroundColor: `${scrollBarThumbHoverColor} `,
                    },
                }}
            > {/* 전체 높이에서 상단 Flex 높이를 뺀 값을 사용 */}

                <VStack
                    spacing={4}
                    overflowY="auto"  // VStack 내부에 스크롤 강제 적용
                    overflowX="hidden"

                >
                    {CSCominfoList.length > 0 ? (
                        CSCominfoList.map((site, index) => (
                            <Box
                                key={index}
                                w="100%"
                                p={4}
                                borderRadius="md"
                                bg={inputBgColor}
                                boxShadow="sm"
                                borderColor={mainFontColor}
                                borderWidth="1px"
                            >
                                <HStack justifyContent="space-between">
                                    <Text fontSize="md" fontWeight="bold">
                                        {site.company_name}
                                    </Text>
                                    <Text fontSize="md" fontWeight="bold">
                                        알값 : {site.r_rate}%
                                    </Text>
                                    <Button marginRight={"1rem"} colorScheme="green" onClick={() => handlePutOpenModal(site.pk)} >
                                        수정
                                    </Button>
                                </HStack>
                                <Divider my={2} />
                                <Flex justify="space-between">
                                    <Text fontSize="sm">루징 : {site.casino_login_rate}% / {site.slot_login_rate}% / {site.holdem_login_rate}%</Text>
                                    <Text fontSize="sm">쉐어 : {site.casino_share_rate}% / {site.slot_share_rate}% / {site.holdem_share_rate}%</Text>
                                    <Text fontSize="sm">등록(수정) : {formatDateTime(site.updated_at)}</Text>
                                </Flex>
                            </Box>
                        ))
                    ) : (
                        <Text fontSize="2xl" color={receSelectColor} p={4}>
                            데이터가 없습니다.
                        </Text>
                    )}
                </VStack>
            </Box>

            {/* 분리된 신규등록 모달 사용 */}
            <CSNewRegModal isOpen={NewRegIsOpen} onClose={NewRegOnClose} />
            <CSPutRegModal isOpen={PutRegisOpen} onClose={PutRegonClose} comInfo_pk={selectedPutPk} />
        </Box>
    );
}