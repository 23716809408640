import React, { useState } from 'react';
import {
    Box,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    IconButton,
    useColorModeValue,
    Flex,
    Spinner,

} from '@chakra-ui/react';
import { FaChevronDown, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useRecoilState } from 'recoil';

import { darkTheme_colors, lightTheme_colors } from '../../../../theme';
import { generateMonths, get_next_month_str, get_prev_month_str, getCurrentMonth } from '../../../../lib/util';
import { SelectedMenuAtom, ISelectMenu } from '../../../../atoms/topSelect';

const categories = ['파워볼', '카지노/슬롯', '홀덤', '스포츠', '기타', '충전/환전', '유저머니', '뱅크'];


export default function SiteGameDayTopSelect() {
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)

    // 사이트 선택
    const handleSiteSelect = (site: ISelectMenu) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.site = site;
        const reload_tag = new Date().getTime();
        before_SelectedMenu.reload_tag = reload_tag
        setSelectedMenu(before_SelectedMenu);
    };

    // 종목 선택
    const handleCategorySelect = (category: string) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.category = category;
        setSelectedMenu(before_SelectedMenu);
    }

    // 월 선택
    const months = generateMonths();
    const [monthIsLoading, setMonthIsLoading] = useState(false);

    const handleMonthSelect = (month: string) => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.Month = month;
        setSelectedMenu(before_SelectedMenu);
    };

    const handlePrevMonth = () => {
        setMonthIsLoading(true);
        setTimeout(() => {
            let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
            before_SelectedMenu.Month = get_prev_month_str(before_SelectedMenu.Month);
            setSelectedMenu(before_SelectedMenu);
            setMonthIsLoading(false);
        }, 100); // simulate a delay
    };

    const handleNextMonth = () => {
        setMonthIsLoading(true);
        setTimeout(() => {
            let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
            before_SelectedMenu.Month = get_next_month_str(before_SelectedMenu.Month);
            setSelectedMenu(before_SelectedMenu);
            setMonthIsLoading(false);
        }, 100); // simulate a delay
    };

    const formattedMonth = `${SelectedMenu.Month}`;

    const handleBankBtnClick = () => {
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.BankSetting = !before_SelectedMenu.BankSetting;
        setSelectedMenu(before_SelectedMenu);
    }


    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);

    return (
        <Flex
            h={"100%"}
            alignItems={"center"}
            direction="row"
            justifyContent={"flex-start"}
            bg={bgColor}
            borderRadius={"md"}
        >
            <Box padding={"1rem"}>
                <Menu>
                    <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                        {SelectedMenu.site.name}
                    </MenuButton>
                    <MenuList maxHeight="30rem" overflowY="auto">
                        {SelectedMenu.sitelist.map((site, index) => (
                            <MenuItem key={site.pk} onClick={() => handleSiteSelect(site)}>
                                {site.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>

            <Box padding={"1rem"}>
                <Menu>
                    <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                        {SelectedMenu.category}
                    </MenuButton>
                    <MenuList>
                        {categories.map((category, index) => (
                            <MenuItem key={index} onClick={() => handleCategorySelect(category)}>
                                {category}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>

            <Box padding={"1rem"}>
                <IconButton
                    aria-label="Previous Month"
                    icon={monthIsLoading ? <Spinner size="sm" /> : <FaChevronLeft />}
                    onClick={handlePrevMonth}
                    isDisabled={monthIsLoading}
                />
            </Box>

            <Box paddingY={"1rem"}>
                <Menu>
                    <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                        {formattedMonth}
                    </MenuButton>
                    <MenuList>
                        {months.map((month, index) => (
                            <MenuItem key={index} onClick={() => handleMonthSelect(month)}>
                                {month}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>

            <Box padding={"1rem"}>
                <IconButton
                    aria-label="Next Month"
                    icon={monthIsLoading ? <Spinner size="sm" /> : <FaChevronRight />}
                    onClick={handleNextMonth}
                    isDisabled={monthIsLoading || SelectedMenu.Month === getCurrentMonth()}
                />
            </Box>

            {/* 뱅크 설정 토글 버튼 */}
            {SelectedMenu.category === '뱅크' && (
                <Box padding="1rem" display="flex" alignItems="center" ml="auto">
                    <Button
                        onClick={handleBankBtnClick}
                        colorScheme={SelectedMenu.BankSetting ? 'green' : 'red'}
                    >
                        뱅크 설정 {SelectedMenu.BankSetting ? 'ON' : 'OFF'}
                    </Button>
                </Box>
            )}
        </Flex>
    );
}
