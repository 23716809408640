import { useRecoilState } from "recoil";
import { Box, Flex, Button, Menu, MenuButton, useColorModeValue, MenuList, MenuItem } from "@chakra-ui/react";

import { FaChevronDown } from "react-icons/fa";
import { SelectedMenuAtom } from "../../../../atoms/topSelect";
import { darkTheme_colors, lightTheme_colors } from "../../../../theme";



export default function RegTopSelect() {
    const [SelectedMenu, setSelectedMenu] = useRecoilState(SelectedMenuAtom)

    // 사이트 선택    
    // 사이트 선택    
    const handleSiteSelect = (site: any) => {
        const reload_tag = new Date().getTime();
        let before_SelectedMenu = JSON.parse(JSON.stringify(SelectedMenu));
        before_SelectedMenu.site = site;
        before_SelectedMenu.reload_tag = reload_tag
        setSelectedMenu(before_SelectedMenu);
    };

    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);

    return (
        <Flex
            h={"100%"}
            alignItems={"center"}
            direction="row"
            bg={bgColor}
            borderRadius={"md"}>
            <Box padding={"1rem"}
            >
                <Menu>
                    <MenuButton as={Button} rightIcon={<FaChevronDown />}>
                        {SelectedMenu.site.name}
                    </MenuButton>
                    <MenuList maxHeight="30rem" overflowY="auto">
                        {SelectedMenu.sitelist.map((site, index) => (
                            <MenuItem key={site.pk} onClick={() => handleSiteSelect(site)}>
                                {site.name}
                            </MenuItem>
                        ))}
                    </MenuList>
                </Menu>
            </Box>
        </Flex>
    );
}
