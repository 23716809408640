import { Box, Center, Flex, Grid, GridItem, useColorModeValue } from '@chakra-ui/react';
import React from 'react';
import { darkTheme_colors, lightTheme_colors } from '../../../../theme';



// const data1 = {
//     siteName: '종 합',
//     categories: [
//         { name: '파워볼', dailyProfit: '8,355,671', totalProfit: '21,105,835' },
//         { name: '카지노', dailyProfit: '18,365,542', totalProfit: '82,734,061' },
//         { name: '슬롯', dailyProfit: '1,874,047', totalProfit: '77,927,826' },
//         { name: '홀덤', dailyProfit: '1,000,000', totalProfit: '3,500,000' },
//         { name: 'TOTAL', dailyProfit: '28,595,260', totalProfit: '181,767,722', isTotal: true }
//     ],
//     transactions: [
//         { name: '충전', dailyAmount: '18,365,542', totalAmount: '82,734,061' },
//         { name: '환전', dailyAmount: '25,874,047', totalAmount: '77,927,826' },
//         { name: '충환 손익', dailyAmount: '-7,508,505', totalAmount: '4,806,235', isTotal: true }
//     ],
// };
interface Category {
    name: string;
    dailyProfit: string;
    totalProfit: string;
    isTotal?: boolean;
}

interface Transaction {
    name: string;
    dailyAmount: string;
    totalAmount: string;
    isTotal?: boolean;
}

interface Data {
    siteName: string;
    categories: Category[];
    transactions: Transaction[];
}

interface DashBoardTableProps {
    data1: Data;
    isSelected: boolean;
}

export default function DashBoardTable({ data1, isSelected }: DashBoardTableProps) {

    const titleColor = useColorModeValue(lightTheme_colors.titleColor, darkTheme_colors.titleColor);
    const bgColor = useColorModeValue(lightTheme_colors.bgColor, darkTheme_colors.bgColor);
    const bgHoverColor = useColorModeValue(lightTheme_colors.bgHoverColor, darkTheme_colors.bgHoverColor);
    const receSelectColor = useColorModeValue(lightTheme_colors.receSelectColor, darkTheme_colors.receSelectColor);

    return (
        <Box mb="4" bg={bgColor} borderRadius="md">
            <Grid templateColumns="repeat(1, 1fr)" bg={isSelected ? receSelectColor : titleColor} p="0.5rem" borderTopRadius="md">
                <GridItem bg={titleColor} borderTopRadius="md"><Center fontWeight="bold">{data1.siteName}</Center></GridItem>
            </Grid>

            <Grid templateColumns="repeat(6, 1fr)" p="0.5rem" bg={bgHoverColor} >
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold">게임 손익</Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold">파워볼</Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold">카지노</Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold">슬롯</Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold">홀덤</Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold">totel</Center></GridItem>
            </Grid>

            <Grid templateColumns="repeat(6, 1fr)" p="0.5rem"  >
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold">전일 손익</Center></GridItem>
                {data1.categories.map((category, index) => (
                    <GridItem key={index} rowSpan={1} colSpan={1}>
                        <Flex width={"70%"} justify="flex-end">{category.dailyProfit}</Flex>
                    </GridItem>
                ))}
            </Grid>
            <Grid templateColumns="repeat(6, 1fr)" p="0.5rem"  >
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold" overflow={"hidden"}>당월 누적 손익</Center></GridItem>
                {data1.categories.map((category, index) => (
                    <GridItem key={index} rowSpan={1} colSpan={1}>
                        <Flex width={"70%"} justify="flex-end">{category.totalProfit}</Flex>
                    </GridItem>
                ))}
            </Grid>

            <Grid templateColumns="repeat(6, 1fr)" p="0.5rem" bg={bgHoverColor} >
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold">충전 환전</Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold"></Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold">충전</Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold"></Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold">환전</Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold">충환 손익</Center></GridItem>
            </Grid>

            <Grid templateColumns="repeat(6, 1fr)" p="0.5rem"  >
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold">전일 충환</Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold"></Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}>
                    <Flex width={"70%"} justify="flex-end">{data1.transactions[0].dailyAmount}</Flex>
                </GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold"></Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}>
                    <Flex width={"70%"} justify="flex-end">{data1.transactions[1].dailyAmount}</Flex>
                </GridItem>
                <GridItem rowSpan={1} colSpan={1}>
                    <Flex width={"70%"} justify="flex-end">{data1.transactions[2].dailyAmount}</Flex>
                </GridItem>
            </Grid>


            <Grid templateColumns="repeat(6, 1fr)" p="0.5rem"  >
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold">당월 누적 충환</Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold"></Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}>
                    <Flex width={"70%"} justify="flex-end">{data1.transactions[0].totalAmount}</Flex>
                </GridItem>
                <GridItem rowSpan={1} colSpan={1}><Center fontWeight="bold"></Center></GridItem>
                <GridItem rowSpan={1} colSpan={1}>
                    <Flex width={"70%"} justify="flex-end">{data1.transactions[1].totalAmount}</Flex>
                </GridItem>
                <GridItem rowSpan={1} colSpan={1}>
                    <Flex width={"70%"} justify="flex-end">{data1.transactions[2].totalAmount}</Flex>
                </GridItem>
            </Grid>
        </Box>
    );
}
